import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { CourseResource } from "../../../../../stores/CoursesStore/Types";
import { CourseCategoryResource } from "../../../../../stores/CourseCategoriesStore/Types";
import PreviewImage from "../Course/PreviewImage";
import StatusImage from "../Course/StatusImage";
import Progress from "../Course/Progress";

interface Props {
  courseResource: CourseResource;
  idx: number;
  useATag?: boolean;
}

const CourseBody = ({ courseResource }) => (
  <div className="copyWrap short-course">
    <div className="bg">
      <div className="course-status">
        <StatusImage courseResource={courseResource} />
      </div>
      <div className="course-headshot">
        <PreviewImage courseResource={courseResource} />
        {courseResource.brand_logo_url && (
          <div className="course-logo">
            <img src={courseResource.brand_logo_url} alt="brand" />
          </div>
        )}
      </div>
    </div>
    <div className="main" style={{ margin: "1.5em" }}>
      <div className="title" style={{ fontSize: "1.5em" }}>
        {courseResource.name}
      </div>
      <p className="teaser">{courseResource.preview_mini_description}</p>
    </div>
    <div
      className={`course_footer ${
        courseResource.enrolled_student_present ? "enrolled-footer" : ""
      }`}
    >
      <Progress courseResource={courseResource} />
    </div>
  </div>
);

class ShortCourse extends Component<Props, {}> {
  render() {
    const { courseResource, idx, useATag } = this.props;

    return (
      <div className="column" style={{ height: "36em" }}>
        {useATag || courseResource.content_version == "upcoming" ? (
          <a
            className="content"
            href={
              courseResource.enrolment_id
                ? `/enrolments/${courseResource.enrolment_id}`
                : courseResource.course_path
            }
          >
            <CourseBody courseResource={courseResource} />
          </a>
        ) : (
          <Link
            className="content"
            to={
              courseResource.enrolment_id
                ? `/enrolments/${courseResource.enrolment_id}`
                : courseResource.course_path
            }
          >
            <CourseBody courseResource={courseResource} />
          </Link>
        )}
      </div>
    );
  }
}

export default observer(ShortCourse);
