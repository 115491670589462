import React, { Component } from "react";
import { CoursesResource } from "../../stores/CoursesStore/Types";
import { Carousel } from "../common";
import Course from "../Home/components/Courses/Course";
import { getRequest } from "../../utils/httpRequest";
import { AxiosResponse } from "axios";

interface Props {
  courseSlug: string;
  sectionTitle?: string;
}

interface State {
  featuredCourses: CoursesResource;
  loaded: boolean;
}

class FeaturedCourses extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      featuredCourses: [],
      loaded: false
    };
  }

  componentDidMount() {
    const { courseSlug } = this.props;
    return getRequest(`/api/courses/${courseSlug}/suggested.json`).then(
      (response: AxiosResponse) => {
        this.setState({ featuredCourses: response.data, loaded: true });
      }
    );
  }

  render() {
    const { featuredCourses, loaded } = this.state;

    return (
      <div className="ui segment courses featured-courses">
        {!loaded && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        <div className="ui container">
          <h1 className="ui header aligned course-header featured-course-header">
            <span className="title">
              {this.props.sectionTitle || "You may also like"}
            </span>
          </h1>

          <div className="courses-container featured-courses-container ui inverted vertical segment center aligned">
            <div
              className="ui  centered doubling stackable three column"
              id="courses_grid"
            >
              <Carousel
                noOfMobileSlides={2}
                numberOfSlides={
                  featuredCourses.length < 3 ? featuredCourses.length : 3
                }
                arrows={true}
              >
                {featuredCourses.map((course, i) => (
                  <Course
                    courseResource={course}
                    idx={i}
                    key={course.id}
                    useATag={true}
                    categories={course.categories}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FeaturedCourses;
