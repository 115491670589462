import {
  CREATE_POST_SUCCESS,
  LOAD_POSTS,
  LOAD_POSTS_FAILURE,
  LOAD_POSTS_SUCCESS,
  RESET_POST_CREATED,
  LIKE_POST,
  UNLIKE_POST,
  DELETE_POST_SUCCESS,
  LOAD_MORE_POSTS,
  LOAD_MORE_POSTS_SUCCESS,
  LOAD_MORE_POSTS_FAILURE,
  FEATURE_POST,
  PostActionTypes,
  PostState,
} from "./types";
import {
  CREATE_COMMENT_SUCCESS,
  REPLY_COMMENT_SUCCESS,
  LIKE_COMMENT,
  UNLIKE_COMMENT,
  LIKE_COMMENT_REPLY,
  UNLIKE_COMMENT_REPLY,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_REPLY_SUCCESS,
} from "../comments/types";
import { updatePost, updateComment, updateReply } from "./helpers";

const initialState: PostState = {
  posts: [],
  loading: false,
  created: false,
  error: "",
};

export const postReducer = (
  state = initialState,
  action: PostActionTypes,
): PostState => {
  switch (action.type) {
    case LOAD_POSTS:
      return { ...state, loading: true };

    case LOAD_POSTS_SUCCESS:
      return { ...state, loading: false, posts: action.payload };

    case LOAD_POSTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case CREATE_POST_SUCCESS:
      return {
        ...state,
        created: true,
        posts: [action.payload, ...state.posts],
      };

    case CREATE_COMMENT_SUCCESS:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          comments: [...post.comments, action.comment],
        })),
      };

    case REPLY_COMMENT_SUCCESS:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          comments: updateComment(
            post.comments,
            action.parentCommentId,
            (comment) => ({
              ...comment,
              replies: [...(comment.replies || []), action.comment],
            }),
          ),
        })),
      };

    case LIKE_POST:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          likes: post.likes + 1,
          liked_by_current_user: true,
        })),
      };

    case UNLIKE_POST:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          likes: post.likes - 1,
          liked_by_current_user: false,
        })),
      };

    case FEATURE_POST:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          trending: action.trending,
        })),
      };

    case LIKE_COMMENT:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          comments: updateComment(
            post.comments,
            action.commentId,
            (comment) => ({
              ...comment,
              likes: comment.likes + 1,
              liked_by_current_user: true,
            }),
          ),
        })),
      };

    case UNLIKE_COMMENT:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          comments: updateComment(
            post.comments,
            action.commentId,
            (comment) => ({
              ...comment,
              likes: comment.likes - 1,
              liked_by_current_user: false,
            }),
          ),
        })),
      };

    case LIKE_COMMENT_REPLY:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          comments: updateComment(
            post.comments,
            action.commentId,
            (comment) => ({
              ...comment,
              replies: updateReply(
                comment.replies,
                action.replyId,
                (reply) => ({
                  ...reply,
                  likes: reply.likes + 1,
                  liked_by_current_user: true,
                }),
              ),
            }),
          ),
        })),
      };

    case UNLIKE_COMMENT_REPLY:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          comments: updateComment(
            post.comments,
            action.commentId,
            (comment) => ({
              ...comment,
              replies: updateReply(
                comment.replies,
                action.replyId,
                (reply) => ({
                  ...reply,
                  likes: reply.likes - 1,
                  liked_by_current_user: false,
                }),
              ),
            }),
          ),
        })),
      };

    case RESET_POST_CREATED:
      return { ...state, created: false };

    case DELETE_POST_SUCCESS:
      return {
        ...state,
        posts: state.posts.filter((post) => post.id !== action.postId),
      };

    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          comments: post.comments.filter(
            (comment) => comment.id !== action.commentId,
          ),
        })),
      };

    case DELETE_COMMENT_REPLY_SUCCESS:
      return {
        ...state,
        posts: updatePost(state.posts, action.postId, (post) => ({
          ...post,
          comments: updateComment(
            post.comments,
            action.commentId,
            (comment) => ({
              ...comment,
              replies: comment.replies.filter(
                (reply) => reply.id !== action.replyId,
              ),
            }),
          ),
        })),
      };

    case LOAD_MORE_POSTS:
      return { ...state, loading: true };

    case LOAD_MORE_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: [...state.posts, ...action.payload], // Append new posts
      };

    case LOAD_MORE_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
