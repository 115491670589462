export const updatePost = (posts, postId, updateCallback) =>
  posts.map((post) => (post.id === postId ? updateCallback(post) : post));

export const updateComment = (comments, commentId, updateCallback) =>
  comments.map((comment) =>
    comment.id === commentId ? updateCallback(comment) : comment,
  );

export const updateReply = (replies, replyId, updateCallback) =>
  replies.map((reply) =>
    reply.id === replyId ? updateCallback(reply) : reply,
  );
