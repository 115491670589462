import { AxiosError, AxiosResponse } from "axios";
import { patchRequest } from "../../../../utils/httpRequest";
import { PIN_POST_SUCCESS, PIN_POST_FAILURE } from "../types";
import { loadPostsSuccess } from "./fetchPosts";

const pinPostSuccess = (postId) => ({
  type: PIN_POST_SUCCESS,
  postId,
});

const pinPostFailure = (error) => ({
  type: PIN_POST_FAILURE,
  payload: error,
});

// Thunk Action Creator for API Call
export const pinPost =
  ({ postId, pinned }) =>
  async (dispatch) => {
    patchRequest(`/api/community/posts/${postId}/pin.json`, {
      pinned,
    })
      .then((response: AxiosResponse) => {
        dispatch(loadPostsSuccess(response.data));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
        dispatch(pinPostFailure(error.response?.data.error));
      });
  };
