/* eslint-disable */

export const initializeScroll = () => {
  window.onscroll = () => {
    desktopScroll();
    mobileScroll();
    purchaseHeaderScroll();
    lessonHeaderScroll()
  };
};

const desktopScroll = () => {
  const desktopHeader = document.querySelector(".new-navbar-desktop");

  if (desktopHeader) {
    const desktopSticky = desktopHeader.offsetTop;

    if (window.pageYOffset > desktopSticky) {
      desktopHeader.classList.add("stickyHeader");
    } else {
      desktopHeader.classList.remove("stickyHeader");
    }
  }
};

const mobileScroll = () => {
  const mobileHeader = document.querySelector(".new-navbar-mobile");

  if (mobileHeader) {
    const mobileSticky = mobileHeader.offsetTop;

    if (window.pageYOffset > mobileSticky) {
      mobileHeader.classList.add("stickyHeader");
    } else {
      mobileHeader.classList.remove("stickyHeader");
    }
  }
};

const purchaseHeaderScroll = () => {
  if ($(document).scrollTop() > 250) {
    $("#purchase_bar").removeClass("forceHide");
  } else {
    $("#purchase_bar").addClass("forceHide");
  }
};

const lessonHeaderScroll = () => {
  if ($(document).scrollTop() > 250) {
    $("#enrolment_navbar").css({display: "none"});
  } else {
    $("#enrolment_navbar").css({display: "flex"});
  }
};
/* eslint-enable */
