import { CREATE_POST_SUCCESS, Post } from "../posts/types";
export interface TrendingPostState {
  trendingPosts: Post[];
  loading: boolean;
  created: boolean;
  error: string;
}

export const LOAD_TRENDING_POSTS = "LOAD_TRENDING_POSTS";
export const LOAD_TRENDING_POSTS_SUCCESS = "LOAD_TRENDING_POSTS_SUCCESS";
export const LOAD_TRENDING_POSTS_FAILURE = "LOAD_TRENDING_POSTS_FAILURE";
export const FEATURE_POST_SUCCESS = "FEATURE_POST_SUCCESS";
export const FEATURE_POST_FAILURE = "FEATURE_POST_FAILURE";

export interface LoadTrendingPostsAction {
  type: typeof LOAD_TRENDING_POSTS;
}

export interface LoadTrendingPostsSuccessAction {
  type: typeof LOAD_TRENDING_POSTS_SUCCESS;
  payload: Post[];
}

export interface LoadTrendingPostsFailureAction {
  type: typeof LOAD_TRENDING_POSTS_FAILURE;
  error: string;
}

export interface FeaturePostSuccessAction {
  type: typeof FEATURE_POST_SUCCESS;
  payload: Post;
}

export interface FeaturePostFailureAction {
  type: typeof FEATURE_POST_FAILURE;
  error: string;
}

export interface LoadTrendingPostsFailureAction {
  type: typeof LOAD_TRENDING_POSTS_FAILURE;
  error: string;
}

export type TrendingPostActionTypes =
  | LoadTrendingPostsAction
  | LoadTrendingPostsSuccessAction
  | LoadTrendingPostsFailureAction
  | FeaturePostSuccessAction
  | FeaturePostFailureAction;
