import { observer } from "mobx-react";
import { GiftState } from "./Types";
import { AxiosResponse, AxiosError } from "axios";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { formErrors, Errors } from "../../utils/helpers";
import GiftStore from ".";

interface State {
  state: GiftState;
}

export interface Props extends BaseReactionProps<GiftStore> {
  store: GiftStore;
  createUrl: string;
  newUrl: string;
  defaultsUrl: string;
  kind: string;
}

class GiftReactions extends BaseReaction<GiftStore, State, Props> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    switch (state.kind) {
      case "loading":
        if (this.props.kind === "course") {
          return getRequest(`/api/courses/ranked`).then(
            (coursesResponse: AxiosResponse) => {
              getRequest(this.props.newUrl).then((response: AxiosResponse) => {
                this.props.store.ready(
                  this.props.defaultsUrl,
                  response.data,
                  coursesResponse.data
                );
              });
            }
          );
        } else {
          return getRequest(this.props.newUrl).then(
            (response: AxiosResponse) => {
              this.props.store.ready(this.props.defaultsUrl, response.data, []);
            }
          );
        }

        break;
      case "saving":
        const giftBody = {
          gift: {
            sender_name: state.gift.sender_name,
            sender_email: state.gift.sender_email,
            team_name: state.gift.team_name,
            team_logo_url: state.gift.team_logo_url,
            deliver_at: state.gift.deliver_at,
            timezone: state.gift.timezone,
            template: state.gift.template,
            message: state.gift.message,
            quotation_id: state.gift.quotation.id,
            recipients: state.gift.recipients
          }
        };
        return postRequest(this.props.createUrl, giftBody)
          .then((response: AxiosResponse) => {
            this.props.store.created(response.data);
          })
          .catch((error: AxiosError<{ errors: Errors }>) => {
            this.props.store.readyWithErrors(error.response.data.errors);
          });
        break;
      case "changingCourse":
        (window as any).location = `/courses/${state.courseSlug}/gift`;
      case "waiting":
      case "ready":
      case "readyWithErrors":
      case "created":
        break;
    }
  };
}

export default observer(GiftReactions);
