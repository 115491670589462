import { observer } from "mobx-react";
import CoursesStore from ".";
import { CoursesResource, CoursesState } from "./Types";
import { AxiosResponse, AxiosError } from "axios";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { Api } from "../../utils/Api";

interface State {
  state: CoursesState;
}

export interface Props extends BaseReactionProps<CoursesStore> {
  store: CoursesStore;
  kind?: string;
}

const getFilters = (filters, name) =>
  filters.filter(val => val.name === name).map(val => val.value);

class CoursesReactions extends BaseReaction<CoursesStore, State, Props> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    const { store, kind } = this.props;

    const url =
      kind === "profile"
        ? "/api/dashboard_courses/profile.json"
        : "/api/dashboard_courses.json";
    const client = new Api({});
    switch (state.kind) {
      case "loading":
        client
          .get<CoursesResource>(url)
          .then((response: AxiosResponse<CoursesResource>) => {
            const { data } = response;

            this.props.store.ready(data, "rated");
          })
          .catch((error: AxiosError) => {
            console.log(error.response?.statusText);
            this.props.store.ready([], "rated");
          });

        break;
      case "sorting":
        {
          const categories = getFilters(state.filters, "category");

          const brands = getFilters(state.filters, "brand");

          console.log("called", state.filters, brands);

          client
            .get<CoursesResource>(
              `/api/dashboard_courses/${state.mode}.json?categories=${categories}&brands=${brands}`
            )
            .then((response: AxiosResponse<CoursesResource>) => {
              const { data } = response;

              this.props.store.ready(data, state.mode);
            })
            .catch((error: AxiosError) => {
              console.log(error.response?.statusText);
              this.props.store.ready(state.courses, "rated");
            });
        }

        break;
      case "waiting":
      case "ready":
        break;
    }
  };
}

export default observer(CoursesReactions);
