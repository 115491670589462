import { observable, decorate, action, computed } from "mobx";
import { PricingState, waiting, loading, ready, PricingPayload } from "./Types";
import { noop } from "../../utils/noop";

class PricingStore {
  pricingState: PricingState;

  constructor() {
    this.pricingState = waiting();
  }

  loading = () => {
    this.pricingState = loading();
  };

  ready = (pricing: PricingPayload) => {
    this.pricingState = ready(pricing);
  };
}

decorate(PricingStore, {
  pricingState: observable,
  loading: action,
  ready: action
});

export default PricingStore;
