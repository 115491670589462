import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal, Transition, Dropdown, Checkbox } from "semantic-ui-react";
import { AxiosResponse, AxiosError } from "axios";
import { Link } from "react-router-dom";
import {
  getRequest,
  patchRequest,
  putRequest,
  deleteRequest
} from "../../../utils/httpRequest";
import {
  formErrors,
  notification,
  randomNumber,
  Errors
} from "../../../utils/helpers";
import AllocateUsers from "./AllocateUsers";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import { CurrentTeam, TeamResource } from "../../../stores/TeamStore/Types";
import TeamStore from "../../../stores/TeamStore";

interface Props {
  showSettingsModal: boolean;
  handleShowSettingsModal: (val: boolean) => void;
  teamResource: TeamResource;
  teamStore: TeamStore;
  getHeroes: () => void;
}

interface State {
  name: string;
  logoUrl: string;
  showAllocateUserModal: boolean;
  [x: string]: any;
  savingTeam: boolean;
  teamErrors: any;
  uploadNewImage: boolean;
  progress: number;
  uploadingImage: boolean;
}

class Settings extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      logoUrl: "",
      showAllocateUserModal: false,
      savingTeam: false,
      teamErrors: {},
      uploadNewImage: false,
      progress: 0,
      uploadingImage: false
    };

    (this as any).uppy = Uppy({
      autoProceed: true
    });

    (this as any).uppy
      .use(Transloadit, {
        params: {
          auth: {
            key: process.env.TRANSLOADIT_AUTH_KEY,
            secret: process.env.TRANSLOADIT_AUTH_SECRET,
            duration: 1800
          },
          templates: {
            s3_store: process.env.TRANSLOADIT_FILE_TEMPLATE_ID
          },
          template_id: process.env.TRANSLOADIT_PROFILE_PIC_TEMPLATE_ID,

          // It’s more secure to use a template_id and enable
          // Signature Authentication
          steps: {
            resize: {
              robot: "/image/resize",
              width: 250,
              height: 250,
              resize_strategy: "fit",
              format: "webp"
            }
          }
        },
        waitForEncoding: true
      })
      .on("error", error => {
        this.setState({ uploadingImage: false });
        notification("Error uploading image. Please try again", "error");
        console.error(error);
        if (error.assembly) {
          console.error(`Assembly ID ${error.assembly.assembly_id} failed!`);
          console.error(error.assembly);
        }
        (window as any).honeybadger?.notify(`Error uploading logo ${error}`);
      })
      .on("upload-error", (file, error, response) => {
        console.error("error with file:", file.id);
        console.error("error message:", error);
        console.error("error response:", response);
        this.setState({ uploadingImage: false });
        notification("Error uploading image. Please try again", "error");
        (window as any).honeybadger?.notify(`Error uploading logo ${error}`);
      })
      .on("transloadit:result", (stepName, result) => {
        this.uploadFinished(result.url);
      });
  }

  uploadFinished = url => {
    this.setState({ logoUrl: url, progress: 100 });
    setTimeout(() => {
      this.setState({ uploadingImage: false });
    }, 500);
  };

  componentDidMount() {
    this.initialState();
  }

  initialState = () => {
    const { teamResource } = this.props;

    this.setState({
      name: teamResource.name,
      logoUrl: teamResource.logo_url,
      teamErrors: {},
      uploadNewImage: false
    });
  };

  handleShowAllocateUserModal = val => {
    this.setState({ showAllocateUserModal: val });

    if (val) {
      this.props.handleShowSettingsModal(false);
    }
  };

  hideModals = () => {
    this.props.handleShowSettingsModal(false);
    this.handleShowAllocateUserModal(false);
    this.initialState();
  };

  //Add your search logic here.
  customFilter(option, searchText) {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleChange(key, e) {
    this.setState({ [key]: e.target.value });
  }

  updateTeam = e => {
    const { teamResource, handleShowSettingsModal } = this.props;
    const { name, logoUrl } = this.state;

    const data = {
      organization: {
        name,
        logo_url: logoUrl
      }
    };

    e.preventDefault();
    this.setState({ savingTeam: true });

    return putRequest(`/api/teams/${teamResource.slug}.json`, data)
      .then((response: AxiosResponse) => {
        this.props.teamStore.setTeamResource(response.data);
        handleShowSettingsModal(false);
        notification("Team was successfully updated.", "notification");
        this.setState({
          savingTeam: false,
          teamErrors: {}
        });
      })
      .catch((error: AxiosError<{ errors: Errors }>) => {
        const teamErrors = formErrors(error.response.data.errors);
        this.setState({ teamErrors, savingTeam: false });
      });
  };

  disableUploadingImage = () => {
    this.setState({ uploadingImage: false });
  };

  addFile(file) {
    const that = this;

    const descriptor = {
      id: "FileInput",
      name: randomNumber() + file.name,
      type: file.type,
      data: file
    };

    try {
      (this as any).uppy.addFile(descriptor);
    } catch (err) {
      this.disableUploadingImage();
      console.log(err);

      (this as any).uppy.log(err);

      notification("Error uploading image. Please try again", "error");
      return;
    }
  }

  toArray(list) {
    return Array.prototype.slice.call(list || [], 0);
  }

  handleUploadNewImage = e => {
    e.preventDefault();

    this.setState({ uploadNewImage: true });
  };

  uploadFile = e => {
    (this as any).uppy.log("[FileInput] Something selected through input...");
    const file = e.target.files[0];
    this.setState({ uploadingImage: true, progress: 50 });
    this.addFile(file);

    e.target.value = null;
  };

  render() {
    const {
      showSettingsModal,
      handleShowSettingsModal,
      teamResource,
      getHeroes
    } = this.props;

    const {
      name,
      logoUrl,
      showAllocateUserModal,
      savingTeam,
      uploadNewImage,
      progress,
      uploadingImage,
      teamErrors
    } = this.state;

    const getErrorMessageFor = type => {
      return teamErrors[type];
    };

    const nameError = getErrorMessageFor("name");

    return (
      <>
        <Modal
          open={showSettingsModal}
          className="profile-settings-modal team-settings-modal"
        >
          <Modal.Header>
            <div className="close">
              <a
                className="ui left button"
                id="closeNav"
                onClick={this.hideModals}
              >
                <i className="close icon" />
              </a>
            </div>
            <h2>Team Settings</h2>
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="ui segment account-information">
              <h4>Team information</h4>
              <div className="information">
                {uploadNewImage ? (
                  <div className="image-holder">
                    <input
                      id="file-upload"
                      type="file"
                      onChange={this.uploadFile}
                      accept="image/*"
                    />
                    <label htmlFor="file-upload">
                      <div className="ui image circular avatar dimmable dimmed">
                        <img
                          className="ui preview"
                          src={
                            logoUrl
                              ? logoUrl
                              : process.env.TRANSLOADIT_IMAGE_PLACEHOLDER
                          }
                        />
                        <div
                          className="ui dimmer visible active"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <div className="imageContent">
                            <div className="center">
                              <div
                                className="ui inverted icon header"
                                id="uploadImageIcon"
                              >
                                <div
                                  className="imageContent upload-file"
                                  id="uploadFile"
                                >
                                  <i className="camera icon"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                    {uploadingImage && (
                      <div className="ui progress image-upload-progress">
                        <div className="bar" style={{ width: `${progress}%` }}>
                          <div className="progress">{progress}%</div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="profile-picture">
                    <img src={teamResource.logo_url} className="user-image" />
                    <a href="#" onClick={this.handleUploadNewImage}>
                      <p>
                        <i className="upload icon" />
                        Change image
                      </p>
                    </a>
                  </div>
                )}

                <div className="name-section">
                  <div className="ui column input">
                    <input
                      type="text"
                      placeholder="Name"
                      className="general-input account-input"
                      value={name}
                      onChange={this.handleChange.bind(this, "name")}
                    />
                  </div>
                  {nameError && (
                    <div className="ui red pointing above label error">
                      {nameError}
                    </div>
                  )}
                  <div className="member">
                    <span style={{ color: "#7e7981" }}>Member since:</span>
                    <span className="time" style={{ paddingLeft: "8px" }}>
                      <strong>{teamResource.formatted_created_at}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ui segment login-settings">
              <div className="ui two column grid stackable">
                <div className="ui column login">
                  <button
                    className="ui button general-button"
                    onClick={() => this.handleShowAllocateUserModal(true)}
                  >
                    Add Learners
                  </button>
                  <p style={{ color: "#7e7981", paddingTop: "10px" }}>
                    Add existing 42courses members to your team
                  </p>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="ui segment">
              <button
                className="ui right large button cancel"
                onClick={this.hideModals}
              >
                Cancel
              </button>
              <button
                className={`ui right large button save ${
                  savingTeam ? "loading" : ""
                }`}
                onClick={this.updateTeam}
              >
                Save
              </button>
            </div>
          </Modal.Actions>
        </Modal>
        <AllocateUsers
          showAllocateUserModal={showAllocateUserModal}
          handleShowAllocateUserModal={this.handleShowAllocateUserModal}
          handleShowSettingsModal={handleShowSettingsModal}
          hideModals={this.hideModals}
          teamResource={teamResource}
          getHeroes={getHeroes}
        />
      </>
    );
  }
}

export default observer(Settings);
