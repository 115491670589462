import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { AxiosError, AxiosResponse } from "axios";
import { Quotation } from "../../../../stores/CoursePreviewStore/Types";
import { postRequest, putRequest } from "../../../../utils/httpRequest";
import Action from "../../common/Action";
import FreeForm from "./FreeForm";
import {
  acceptableCountries,
  stripeStyles,
  validateFormFields,
  requestButtonStyles,
} from "../helpers";

interface Props {
  isCurrentUserPresent: boolean;
  payUrl: string;
  activeQuote: Quotation;
  userDetails: {
    name: string;
    email: string;
  };
  params: {
    billingEmail: string;
    billingName: string;
    vatNumber: string;
    vatType: string;
    address: string;
    city: string;
    postalCode: string;
    country: string;
  };
  handlePaymentSuccess: (data: any) => void;
  previousAction: (e: any) => void;
  updateUserDetails: (key: string, value: string) => void;
}

const ProcessPayment: React.FC<Props> = ({
  userDetails,
  params,
  activeQuote,
  payUrl,
  previousAction,
  handlePaymentSuccess,
  updateUserDetails,
  isCurrentUserPresent,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [showPayRequestButton, setShowPayRequestButton] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailure, setPaymentFailure] = useState(false);

  useEffect(() => {
    if (stripe && acceptableCountries.includes(params.country)) {
      let pr = stripe.paymentRequest({
        country: params.country,
        currency: activeQuote?.currency_code.toLowerCase(),
        total: {
          label: "Total",
          amount: activeQuote?.fractional,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.on("paymentmethod", async (event) => {
        setSubmitting(true);
        await sendPayment(event.paymentMethod, event);
        confirmNativePayment(event);
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setShowPayRequestButton(true);
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  const sendPayment = (paymentMethod = null, event = null) => {
    let payment = {
      quote_id: activeQuote.id,
      vat_number: params.vatNumber,
      vat_type: params.vatType,
      stripe_token: paymentMethod?.id,
      billing_email: params.billingEmail,
      billing_name: params.billingName,
    };

    return putRequest(payUrl, { payment })
      .then((response: AxiosResponse) => {
        handleServerResponse(event, response);
      })
      .catch((error: AxiosError) => {
        setSubmitting(false);
        const message =
          (error.response?.data as string) || error.message || error.toString();
        setError(message);
      });
  };

  const confirmNativePayment = (ev) => {
    if (paymentFailure) {
      ev.complete("fail");
    } else if (paymentSuccess) {
      ev.complete("success");
    }
  };

  const upsertTempUserData = () => {
    return postRequest("/api/temp_users", {
      email: params.billingEmail,
      name: params.billingName,
    })
      .then((response: AxiosResponse) => {
        console.log("called");
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });
  };

  const onClickPaymentRequestElement = (e) => {
    if (!params.billingEmail || !params.billingName) {
      alert("Please enter your name and email to proceed.");
      e.preventDefault();
    }

    upsertTempUserData();

    paymentRequest.update({
      currency: activeQuote?.currency_code.toLowerCase(),
      total: {
        label: "Total",
        amount: activeQuote?.fractional,
      },
    });
  };

  const handleServerResponse = async (event, response) => {
    if (response.data.error) {
      setSubmitting(false);
      setError(response.data.error);
    } else if (response.data.requires_action) {
      event?.complete("success");
      // scrollToDiv();
      const { error: errorAction, paymentIntent } =
        await stripe.handleCardAction(
          response.data.payment_intent_client_secret,
        );

      if (errorAction) {
        setSubmitting(false);
        setPaymentFailure(true);
        setError(errorAction.message);
      } else {
        await sendPayment(paymentIntent);
      }
    } else {
      setPaymentSuccess(true);
      handlePaymentSuccess(response.data);
    }
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setSubmitting(true);
    setError("");

    const errors = validateFormFields(params, activeQuote);

    if (errors.length > 0) {
      setSubmitting(false);
      setError(
        `${errors.join(", ")} ${errors.length > 1 ? "are" : "is"} invalid`,
      );

      return;
    }

    if (!(activeQuote?.amount > 0)) {
      // if not payment method is set, its basically for free
      sendPayment();
      return;
    }

    if (!stripe || !elements) {
      setSubmitting(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: params.billingName,
        email: params.billingEmail,
        address: {
          line1: params.address,
          postal_code: params.postalCode,
          city: params.city,
          country: params.country,
        },
      },
    });

    handleResultsOfStripePaymentMethod(payload);
  };

  const handleResultsOfStripePaymentMethod = (payload) => {
    if (payload.error) {
      setSubmitting(false);
      setError(payload.error.message);
    } else {
      sendPayment(payload.paymentMethod);
    }
  };

  const renderPayOptionButton = () => {
    if (showPayRequestButton && paymentRequest) {
      return (
        <div style={{ width: "50%" }}>
          <PaymentRequestButtonElement
            options={{
              ...requestButtonStyles,
              paymentRequest,
            }}
            onClick={(e) => onClickPaymentRequestElement(e)}
          />
        </div>
      );
    } else {
      return (
        <button className="pay-option inactive">
          <img
            src={require("../../../../../../assets/images/facelift/checkout/apple.svg")}
          />
          <span>Pay</span>
        </button>
      );
    }
  };

  return (
    <div>
      {activeQuote?.amount <= 0 && (
        <FreeForm
          error={error}
          setError={setError}
          isSubmitting={submitting}
          userDetails={userDetails}
          previousAction={previousAction}
          updateUserDetails={updateUserDetails}
          handleSubmit={handleSubmit}
          isCurrentUserPresent={isCurrentUserPresent}
        />
      )}
      {activeQuote?.amount > 0 && (
        <>
          <div>
            <div className="payment-options">
              <button className="pay-option active">
                <span>Credit/Debit Card</span>
              </button>
              {renderPayOptionButton()}
            </div>
            <div className="field">
              <div id="card-element">
                <CardNumberElement
                  onChange={() => {}}
                  options={{ style: stripeStyles }}
                />
              </div>
              <label id="card-label">Card number</label>
            </div>
            <div className="fields">
              <div className="field">
                <div id="expiry-element">
                  <CardExpiryElement
                    onChange={() => {}}
                    options={{ style: stripeStyles }}
                  />
                </div>
                <label id="card-label">Expiry</label>
              </div>
              <div className="field">
                <div id="cvc-element">
                  <CardCvcElement
                    onChange={() => {}}
                    options={{ style: stripeStyles }}
                  />
                </div>
                <label id="card-label">CVC</label>
              </div>
            </div>
          </div>
          <div className="badges">
            <div>
              <img
                className="ui image tiny security"
                src={require("../../../../../../assets/images/checkout/secure-min.png")}
              />
            </div>
            <div className="right floated">
              {["Apple-Pay-Mark", "Google-Pay-Mark", 1, 2, 3, 22].map((n) => (
                <img
                  key={n}
                  className="ui image mini"
                  src={require(
                    `../../../../../../assets/images/checkout/cards/${n}.png`,
                  )}
                />
              ))}
            </div>
          </div>
          {error && (
            <div id="card-errors" role="alert">
              <i
                className="close icon util-clickable"
                onClick={() => setError("")}
              />
              <span>{error}</span>
            </div>
          )}
          <Action
            isSubmitting={submitting}
            isDisabled={submitting}
            name="Confirm payment!"
            backButtonId="checkout-payment-back"
            nextButtonId="checkout-payment-submit"
            nextAction={(e) => handleSubmit(e)}
            previousAction={(e) => previousAction(e)}
          />
        </>
      )}
    </div>
  );
};

export default ProcessPayment;
