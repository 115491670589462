import React, { Component } from "react";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import { notification, randomNumber } from "../../../src/utils/helpers";

interface Props {
  setFieldValue: any;
  name: string;
  fileUrl: string;
}

interface State {
  uploadingImage: boolean;
  uploadProgress: number;
  url: string;
}

class FileUpload extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      uploadingImage: false,
      uploadProgress: 0,
      url: props.fileUrl,
    };

    (this as any).uppy = Uppy({
      autoProceed: true,
    });

    (this as any).uppy
      .use(Transloadit, {
        params: {
          auth: {
            key: process.env.TRANSLOADIT_AUTH_KEY,
            secret: process.env.TRANSLOADIT_AUTH_SECRET,
            duration: 1800,
          },

          templates: {
            s3_store: process.env.TRANSLOADIT_FILE_TEMPLATE_ID,
          },
          template_id:
            process.env.TRANSLOADIT_ADMIN_COURSE_PREVIEW_MASTHEAD_TEMPLATE_ID,
          steps: {
            resize: {
              robot: "/image/resize",
              width: 1400,
              height: 575,
              format: "webp",
              resize_strategy: "fit",
            },
          },
        },
        waitForEncoding: true,
      })
      .on("error", (error) => {
        if (error.assembly) {
          console.log(`Assembly ID ${error.assembly.assembly_id} failed!`);
          console.log(error.assembly);
          notification("Error uploading image. Please try again", "error");
        }
      })
      .on("transloadit:result", (stepName, result) => {
        this.uploadFinished(result.url);
      });
  }

  addFile(file) {
    const descriptor = {
      id: "FileInput",
      name: randomNumber() + file.name,
      type: file.type,
      data: file,
    };

    try {
      (this as any).uppy.addFile(descriptor);
    } catch (err) {
      this.setState({ uploadingImage: false });
      console.log(err);

      (this as any).uppy.log(err);

      notification("Error uploading image. Please try again", "error");
      return;
    }
  }

  uploadFile = (e) => {
    (this as any).uppy.log("[FileInput] Something selected through input...");
    const file = e.target.files[0];
    this.setState({ uploadingImage: true, uploadProgress: 50, url: "" });
    this.addFile(file);

    e.target.value = null;
  };

  uploadFinished = (url) => {
    this.props.setFieldValue(this.props.name, url);
    this.setState({ uploadProgress: 100, url });
    setTimeout(() => {
      this.setState({ uploadingImage: false });
    }, 500);
  };

  render() {
    const { uploadingImage, uploadProgress, url } = this.state;

    return (
      <div className="fields file-upload">
        <div className="upload-file field eight wide">
          <label>Masthead</label>
          <label htmlFor="file-upload" className="custom-file-upload">
            <i className="fa fa-cloud-upload"></i> Choose File
          </label>

          <input
            id="file-upload"
            type="file"
            onChange={this.uploadFile}
            accept="image/*"
          />
          {uploadingImage && (
            <div className="ui progress image-upload-progress">
              <div className="bar" style={{ width: `${uploadProgress}%` }}>
                <div className="progress">{uploadProgress}%</div>
              </div>
            </div>
          )}
          {url && (
            <div className="field file optional section_transloadit_masthead_image">
              <div className="ui image image-label dimmable">
                <img className="ui image preview" src={url} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FileUpload;
