import React, { Component } from "react";
import { observer } from "mobx-react";
import { AxiosResponse } from "axios";
import { Message } from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { GiftState } from "../../../stores/GiftStore/Types";
import GiftStore from "../../../stores/GiftStore";
import { getRequest } from "../../../utils/httpRequest";
import { notification, randomNumber } from "../../../utils/helpers";
import CouponModal from "../CouponModal";
import Hero from "./Hero";
import Templates from "./Templates";
import GiftForm from "./GiftForm";
import Preview from "./Preview";

interface Props {
  state: GiftState;
  store: GiftStore;
  quoteUrl: string;
  defaultsUrl: string;
  kind: string;
}

interface State {
  uploadingImage: boolean;
  uploadProgress: number;
  showCouponModal: boolean;
  couponCode: string;
  refCode: string;
}

interface Values {
  sender_name: string;
  sender_email: string;
  message: string;
  deliver_at: string;
  team_name: string;
  create_team: boolean;
  recipients: {
    name: string;
    email: string;
  }[];
}

class GiftWrapper extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      uploadingImage: false,
      uploadProgress: 0,
      showCouponModal: false,
      couponCode: "",
      refCode: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.couponCode !== this.state.couponCode ||
      prevState.refCode !== this.state.refCode
    ) {
      this.refreshQuote();
    }
  }

  handleShowCouponModal = (e, val) => {
    e.preventDefault();
    this.setState({ showCouponModal: val });
  };

  honorCouponOrReferral = (code, ref) => {
    this.setState({ couponCode: code, refCode: ref });
  };

  refreshQuote = () => {
    const { couponCode, refCode } = this.state;

    let quote_id = this.props.store.quotation?.id;
    let count = this.props.store.recipients?.length;

    if (quote_id && count) {
      return getRequest(
        `${this.props.quoteUrl}?quote_id=${quote_id}&count=${count}&coupon=${couponCode}&ref=${refCode}`
      ).then((response: AxiosResponse) => {
        this.props.store.ready(
          this.props.defaultsUrl,
          response.data,
          this.props.store.courses
        );
      });
    }
  };

  refreshQuoteWithoutCoupon = () => {
    let quote_id = this.props.store.quotation.id;
    let count = this.props.store.recipients.length;

    this.setState({ couponCode: "" });

    return getRequest(
      `${this.props.quoteUrl}?quote_id=${quote_id}&count=${count}&remove_coupon=true`
    ).then((response: AxiosResponse) => {
      this.props.store.setQuotation(response.data.quotation);
    });
  };

  onSubmit = () => {
    this.props.store.saving();
  };

  getRecipients = gift => {
    if (gift.recipients) {
      return gift.recipients.map(recipient => {
        return {
          name: recipient.name || "",
          email: recipient.email || ""
        };
      });
    } else {
      [{ name: "", email: "" }];
    }
  };

  renderView = () => {
    const { state, store, kind } = this.props;
    const gift = store.fetchGift();
    const { couponCode, refCode, showCouponModal } = this.state;

    switch (state.kind) {
      case "loading":
      case "changingCourse":
        return (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        );
      case "readyWithErrors":
      case "ready":
      case "saving":
        return (
          <>
            <Formik
              initialValues={{
                sender_name: state.gift?.sender_name || "",
                sender_email: state.gift?.sender_email || "",
                message: state.gift?.message || "",
                deliver_at: "send_now",
                team_name: state.gift?.team_name || "",
                create_team: false,
                recipients: this.getRecipients(state.gift)
              }}
              onSubmit={this.onSubmit}
            >
              {({ values, touched, setFieldValue }) => (
                <Form className="ui form">
                  <div className="ui grid doubling stackable three column">
                    <div className="eight wide column">
                      <div className="section-one">
                        <Hero
                          quotation={state?.gift?.quotation}
                          kind={kind}
                          gift={state.gift}
                          store={store}
                        />
                        <Templates store={store} />
                        <GiftForm
                          values={values}
                          touched={touched}
                          store={store}
                          gift={state.gift}
                          errors={state.errors || []}
                          setFieldValue={setFieldValue}
                          refreshQuote={this.refreshQuote}
                        />
                      </div>
                    </div>
                    <div className="eight wide column section-two">
                      <Preview
                        store={store}
                        couponCode={couponCode}
                        refCode={refCode}
                        quotation={state?.gift?.quotation}
                        refreshQuoteWithoutCoupon={
                          this.refreshQuoteWithoutCoupon
                        }
                        handleShowCouponModal={this.handleShowCouponModal}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <CouponModal
              showCouponModal={showCouponModal}
              handleShowCouponModal={this.handleShowCouponModal}
              honorCouponOrReferral={this.honorCouponOrReferral}
              priceable={{ kind: gift?.category, id: gift?.priceable.id }}
            />
          </>
        );
        break;
      case "created":
        return (
          <Redirect
            to={{
              pathname: `/gift/${gift?.token}/${
                gift?.state == "paid" ? "success" : "pay"
              }`
            }}
          />
        );
        break;
      default:
        return <div />;
        break;
    }
  };

  render() {
    return this.renderView();
  }
}
export default observer(GiftWrapper);
