import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal } from "semantic-ui-react";
import { AxiosResponse, AxiosError } from "axios";
import { postRequest } from "../../utils/httpRequest";

interface Props {
  showCouponModal: boolean;
  priceable: any;
  removeCoupon?: boolean;
  handleShowCouponModal: (e: any, val: boolean) => void;
  honorCouponOrReferral: (code: string, ref: string) => void;
}

interface State {
  couponCode: string;
  refCode: string;
  showSubmitButton: boolean;
  checkError: string;
  successMessage: string;
  claimable: boolean;
  referral: boolean;
  submitting: boolean;
}

class CouponModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
      refCode: "",
      showSubmitButton: false,
      checkError: null,
      successMessage: "",
      claimable: null,
      referral: false,
      submitting: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.removeCoupon !== this.props.removeCoupon &&
      this.props.removeCoupon
    ) {
      this.setState({ couponCode: "", refCode: "" });
    }
  }

  handleChange = e => {
    const { showSubmitButton } = this.state;
    this.setState({ checkError: null, claimable: null, referral: false });
    this.setState({ couponCode: e.target.value });

    if (!showSubmitButton && e.target.value.length > 1) {
      this.setState({ showSubmitButton: true });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    const { priceable } = this.props;
    const { couponCode, refCode } = this.state;
    const data = {
      code: couponCode,
      priceable_type: priceable.kind,
      priceable_id: priceable.id
    };
    this.setState({ showSubmitButton: false, submitting: true });

    return postRequest("/api/merchant/coupon_claimability.json", data)
      .then((response: AxiosResponse) => {
        this.setState({
          checkError: response.data.error,
          claimable: response.data.claimable,
          referral: response.data.referral,
          submitting: false
        });

        if (this.state.referral) {
          this.setState({ refCode: couponCode, couponCode: "" });
        }

        if (this.state.claimable) {
          this.props.honorCouponOrReferral(couponCode, refCode);
          this.setState({ successMessage: "Coming right up..." });
          ($(".coupon-code") as any).transition("tada");

          setTimeout(() => {
            this.props.handleShowCouponModal(e, false);
            this.setState({ successMessage: "" });
          }, 2000);
        } else {
          ($(".coupon-code") as any).transition("shake");
        }
      })
      .catch((error: AxiosError) => {
        this.setState({ submitting: false });
        console.log(error.response.data);
      });
  };

  render() {
    const {
      showSubmitButton,
      checkError,
      successMessage,
      claimable,
      submitting
    } = this.state;

    const { showCouponModal, handleShowCouponModal } = this.props;

    const statusClass = () => {
      switch (claimable) {
        case true:
          return "positive";
          break;
        case false:
          return "negative";
          break;
        default:
          return "";
          break;
      }
    };

    return (
      <Modal
        basic
        closeIcon
        dimmer="blurring"
        open={showCouponModal}
        className="coupon-modal"
        onClose={e => handleShowCouponModal(e, false)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <h1 className="ui center aligned">Add gift / Promo code</h1>
        </Modal.Header>
        <Modal.Content>
          <form className="ui form" onSubmit={this.onSubmit}>
            <div className="ui transparent inverted input">
              <input
                autoFocus
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                className={`coupon-code ${statusClass()}`}
                placeholder="Type your code..."
                spellCheck="false"
                type="text"
                onChange={this.handleChange}
              />
            </div>
          </form>
          {(checkError || claimable) && (
            <div id="check_coupon_alert">{checkError || successMessage}</div>
          )}
          {showSubmitButton && (
            <div className="ui sumbit-button">
              <button
                className={submitting ? "loading" : "ui huge primary button"}
                onClick={this.onSubmit}
              >
                <i className="gift icon" />
                Gimmie
              </button>
            </div>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default observer(CouponModal);
