import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { CurrentUser } from "../../../stores/CurrentUserStore/Types";
import { postRequest } from "../../../utils/httpRequest";
import { errorNotification } from "../../../utils/helpers";

interface Props {
  currentUser: CurrentUser;
  lessonId: number;
  updateComments: () => void;
}

interface State {
  content: string;
  submitting: boolean;
}

class Discussion extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      submitting: false
    };
  }

  setContent = e => {
    this.setState({ content: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { lessonId, updateComments } = this.props;

    return postRequest(`/api/comments.json?question_id=${lessonId}`, {
      content: this.state.content
    })
      .then((response: AxiosResponse) => {
        this.setState({ content: "", submitting: false });
        updateComments();
      })
      .catch(error => {
        this.setState({ submitting: false });

        errorNotification(
          "Error loading comments",
          JSON.stringify(error.response.data)
        );
      });
  };

  render() {
    const { currentUser } = this.props;
    const { content, submitting } = this.state;

    const countryFlagImage = countryCode => {
      let img;
      try {
        img = require(`../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
      } catch {
        img = "";
      }

      return img;
    };

    return (
      <div className="ui container discussion">
        <h2 className="discussion-target" style={{ width: "fit-content" }}>
          Discussion
        </h2>

        <div className="discussion__body">
          <div className="discussion__body--avatar desktop">
            <img src={currentUser?.profile_picture} className="avatar" />
            <div className="country">
              <img src={countryFlagImage(currentUser?.country)} />
            </div>
          </div>
          <div className="discussion__body--avatar mobile">
            <div className="avatar-mobile">
              <img src={currentUser?.profile_picture} className="avatar" />
              <div className="country">
                <img src={countryFlagImage(currentUser?.country)} />
              </div>
            </div>
            <p>{currentUser?.short_name} (me)</p>
          </div>
          <form className="ui form">
            <div className="form-header desktop">
              <p>{currentUser?.short_name} (me)</p>
            </div>
            <div className="field">
              <textarea
                value={content}
                rows={3}
                placeholder="Write your comment"
                onChange={this.setContent}
              />
            </div>
            <div className="action">
              <button
                className={`ui button ${submitting ? "loading" : ""}`}
                type="submit"
                onClick={this.onSubmit}
              >
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Discussion;
