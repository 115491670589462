import { AxiosError, AxiosResponse } from "axios";
import { postRequest, deleteRequest } from "../../../../utils/httpRequest";
import {
  LIKE_POST_SUCCESS,
  LIKE_POST_FAILURE,
  UNLIKE_POST_SUCCESS,
} from "../types";

const likePostSuccess = () => ({
  type: LIKE_POST_SUCCESS,
});

const likePostFailure = (error) => ({
  type: LIKE_POST_FAILURE,
  payload: error,
});

const unlikePostSuccess = () => ({
  type: UNLIKE_POST_SUCCESS,
});

export const likePost =
  ({ postId }) =>
  async (dispatch) => {
    dispatch({ type: "LIKE_POST", postId });

    postRequest("/api/community/likes.json", { post_id: postId })
      .then((response: AxiosResponse) => {
        dispatch(likePostSuccess());
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
        // dispatch(createPostFailure(error.response?.data.error));
      });
  };

export const unlikePost =
  ({ postId }) =>
  async (dispatch) => {
    dispatch({ type: "UNLIKE_POST", postId });

    deleteRequest(`/api/community/likes/unlike.json?post_id=${postId}`)
      .then((response: AxiosResponse) => {
        dispatch(unlikePostSuccess());
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
        // dispatch(createPostFailure(error.response?.data.error));
      });
  };
