import React from "react";
import { Modal } from "semantic-ui-react";

interface Props {
  showDeleteUsersModal: boolean;
  submitting: boolean;
  handleHideDeleteUsersModal: (e: any) => void;
  deleteUsers: (e: any) => void;
}

const DeleteUsersModal: React.FC<Props> = ({
  showDeleteUsersModal,
  submitting,
  handleHideDeleteUsersModal,
  deleteUsers
}) => (
  <Modal
    closeIcon
    open={showDeleteUsersModal}
    onClose={e => handleHideDeleteUsersModal(e)}
    className="actions-modal"
    size="small"
  >
    <div className="ui internally celled stackable grid">
      <div className="column row">
        <div className="column center aligned">
          <div className="ui image tiny">
            <img
              src={require("../../../../../../assets/images/team_admin/enrolmentsIcon.png")}
            />
          </div>
          <h1>Well Done!</h1>
          <p>Are you sure you want to remove these team members?</p>
          <div className="ui actions">
            <button
              className="ui right large button cancel"
              onClick={e => handleHideDeleteUsersModal(e)}
            >
              Cancel
            </button>
            <button
              className={`ui right large button save ${
                submitting ? "loading" : ""
              }`}
              onClick={e => deleteUsers(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default DeleteUsersModal;
