import { AxiosError, AxiosResponse } from "axios";
import { postRequest } from "../../../../utils/httpRequest";
import { CREATE_POST_SUCCESS, CREATE_POST_FAILURE } from "../types";

export const createPostSuccess = (data) => ({
  type: CREATE_POST_SUCCESS,
  payload: data,
});

const createPostFailure = (error) => ({
  type: CREATE_POST_FAILURE,
  payload: error,
});

// Thunk Action Creator for API Call
export const createPost = (content, topicUuid) => async (dispatch) => {
  postRequest(`/api/community/posts.json?topic_uuid=${topicUuid}`, {
    community_post: {
      content,
    },
  })
    .then((response: AxiosResponse) => {
      dispatch(createPostSuccess(response.data));
    })
    .catch((error: AxiosError<{ error: string }>) => {
      console.error(error);
      // dispatch(createPostFailure(error.response?.data.error));
    });
};
