import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { Courses } from "../Home/components";
import Landing from "./Landing";
import LandingLeaderboard from "./Landing/LandingLeaderboard";
import { TeamState } from "../../stores/TeamStore/Types";
import { CurrentUser } from "../../stores/CurrentUserStore/Types";
import { Hero } from "./Leaderboard/Types";
import TeamStore from "../../stores/TeamStore";
import TeamReactions from "../../stores/TeamStore/TeamReactions";
import Settings from "./Settings";
import Leaderboard from "./Leaderboard";

interface Props {
  getCurrentUser: () => void;
  currentUser: CurrentUser;
  match: any;
}

interface State {
  showSettingsModal: boolean;
  heroes: Hero[];
}

interface Response {
  heroes: Hero[];
  total_pages: number;
}

class TeamManagement extends Component<Props, State> {
  teamStore = new TeamStore();

  constructor(props) {
    super(props);

    this.state = {
      showSettingsModal: false,
      heroes: []
    };
  }

  componentDidMount() {
    this.teamStore.loading();
    this.getHeroes();
  }

  getHeroes = () => {
    return getRequest(
      `/api/teams/${this.props.match.params.slug}/leaderboard_search.json?count=20`
    ).then((response: AxiosResponse<Response>) => {
      const { data } = response;

      let heroes = data.heroes.map(hero => {
        return { ...hero, selected: false };
      });

      this.setState({
        heroes
      });
    });
  };

  updateHeroes = heroes => {
    this.setState({ heroes });
  };

  handleShowSettingsModal = val => this.setState({ showSettingsModal: val });

  renderContent = (teamState: TeamState) => {
    const { currentUser } = this.props;
    const { showSettingsModal } = this.state;

    switch (teamState.kind) {
      case "ready":
        return (
          <div>
            <div className="ui container profile-header team-profile-header">
              <div className="ui two column stackable grid">
                <Landing
                  teamResource={teamState.teamResource}
                  handleShowSettingsModal={this.handleShowSettingsModal}
                />
                <LandingLeaderboard
                  slug={this.props.match.params.slug}
                  currentUser={currentUser}
                />
              </div>
              <Settings
                showSettingsModal={showSettingsModal}
                teamResource={teamState.teamResource}
                handleShowSettingsModal={this.handleShowSettingsModal}
                teamStore={this.teamStore}
                getHeroes={this.getHeroes}
              />
            </div>
            <Leaderboard
              slug={this.props.match.params.slug}
              heroes={this.state.heroes}
              updateHeroes={this.updateHeroes}
            />
            <div className="ui segment" style={{ paddingTop: "2px" }}></div>
          </div>
        );
        break;
      case "loading":
        return (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        );

      default:
        return <div />;
        break;
    }
  };

  render() {
    const { state } = this.teamStore;

    return (
      <div className="ui facelift-new-homepage facelift-profile-page facelift-team-page">
        <div className="profile-header-wrapper team-profile-header-wrapper">
          {this.renderContent(state)}
        </div>
        <TeamReactions
          store={this.teamStore}
          slug={this.props.match.params.slug}
        />
      </div>
    );
  }
}

export default observer(TeamManagement);
