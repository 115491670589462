import React from "react";
import { Quotation } from "../../../../stores/CoursePreviewStore/Types";
import { PaymentType } from "../../Types";

interface Props {
  paymentType: PaymentType;
  couponCode: string;
  refCode: string;
  activeQuote: Quotation;
  handleShowCouponModal: (e: any, val: boolean) => void;
  refreshQuoteWithoutCoupon: (e: any) => void;
}

const Coupon: React.FC<Props> = ({
  paymentType,
  couponCode,
  refCode,
  activeQuote,
  handleShowCouponModal,
  refreshQuoteWithoutCoupon
}) => {
  if (["course", "subscription"].includes(paymentType)) {
    if (couponCode.length > 0 || refCode.length > 0) {
      return <div className="coupon"></div>;
    } else {
      return (
        <div className="coupon">
          <a
            className="coupon_modal_trigger"
            href="#"
            onClick={e => handleShowCouponModal(e, true)}
          >
            Redeem voucher
          </a>
        </div>
      );
    }
  }

  return <div />;
};

export default Coupon;
