import * as React from "react";

const Twitter = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="#504257" />
    <path
      d="M26.3337 12.912C25.7139 13.1796 25.0535 13.3571 24.3649 13.4432C25.0732 13.0268 25.6139 12.3725 25.868 11.5838C25.2076 11.9714 24.4785 12.2453 23.7014 12.3981C23.0743 11.7407 22.1805 11.3335 21.2055 11.3335C19.3139 11.3335 17.791 12.8453 17.791 14.6986C17.791 14.9653 17.8139 15.2217 17.8701 15.4658C15.0295 15.3294 12.516 13.9889 10.8274 11.9468C10.5326 12.4504 10.3597 13.0268 10.3597 13.6473C10.3597 14.8125 10.9691 15.8453 11.8774 16.4432C11.3285 16.433 10.7899 16.2761 10.3337 16.0289C10.3337 16.0391 10.3337 16.0525 10.3337 16.0658C10.3337 17.7007 11.518 19.0586 13.0712 19.3714C12.793 19.4463 12.4899 19.4822 12.1753 19.4822C11.9566 19.4822 11.7357 19.4699 11.5285 19.4248C11.9712 20.7571 13.2274 21.7366 14.7212 21.7684C13.5587 22.6638 12.0826 23.2032 10.4847 23.2032C10.2045 23.2032 9.93574 23.1909 9.66699 23.1571C11.1805 24.1181 12.9743 24.6668 14.9087 24.6668C21.1962 24.6668 24.6337 19.5386 24.6337 15.0935C24.6337 14.9448 24.6285 14.8012 24.6212 14.6586C25.2993 14.1848 25.8691 13.593 26.3337 12.912Z"
      fill="white"
    />
  </svg>
);

export default Twitter;
