import React, { Component } from "react";
import { observer } from "mobx-react";
import { PieChart } from "react-minimal-pie-chart";
import { compareCourses } from "../../../stores/CoursesStore/helpers";
import { OngoingEnrolment } from "../../../stores/ProfileStore/Types";

interface Props {
  ongoingEnrolments: OngoingEnrolment[];
}

class Courses extends Component<Props, {}> {
  render() {
    const { ongoingEnrolments } = this.props;
    const sortedCourses = ongoingEnrolments.sort(compareCourses);

    return (
      <div className="courses-container">
        {sortedCourses.length > 0 ? (
          <div className="courses">
            {sortedCourses.map((course, i) => (
              <div className="course" key={i}>
                <a href={`/enrolments/${course.id}`}>
                  <div className="name">
                    <p>{course.name}</p>
                  </div>

                  <div className="progress">
                    <PieChart
                      data={[
                        {
                          value: course.percentage_completed,
                          color: "#36CBBD"
                        },
                        {
                          label: "",
                          value: 100 - course.percentage_completed,
                          isEmpty: true,
                          color: "#dbdee4"
                        }
                      ]}
                      totalValue={100}
                      lineWidth={10}
                      label={() => `${course.percentage_completed}%`}
                      labelStyle={{
                        fontSize: "30px",
                        fill: "392D40"
                      }}
                      labelPosition={0}
                      startAngle={270}
                    />
                  </div>
                  <div className="next">
                    <i className="icon angle right" />
                  </div>
                </a>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-courses">You have no courses ongoing.</div>
        )}
        {/* <div className="view-all">
          <a>View all</a>
        </div> */}
      </div>
    );
  }
}

export default Courses;
