import * as React from "react";
import { Carousel } from "../../../common";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";

const Certification = () => (
  <div className="ui segment certification">
    <div className="ui container">
      <Carousel autoplay={true} dots={true} arrows={true} numberOfSlides={1} noOfMiddleSlides={1}>
        <FirstSection />
        <SecondSection />
        <ThirdSection />
      </Carousel>
    </div>
  </div>
);

export default Certification;
