import React, { Component } from "react";
import { observer } from "mobx-react";
import GiftWrapper from "./components/GiftWrapper";
import GiftStore from "../../stores/GiftStore";
import GiftReactions from "../../stores/GiftStore/GiftReactions";
import { Quotation } from "../../stores/CoursePreviewStore/Types";
import { Gift } from "../../stores/GiftStore/Types";

interface Props {
  match: any;
}

interface State {}

class PassGift extends Component<Props, State> {
  giftStore = new GiftStore();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.giftStore.loading();
  }

  render() {
    const { state } = this.giftStore;
    const slug = this.props.match.params.slug;
    const createUrl = `/api/passes/${slug}/gift`;
    const defaultsUrl = `/api/passes/${slug}/gift/defaults`;
    const newUrl = `/api/passes/${slug}/gift/new.json`;
    const quoteUrl = `/api/passes/${slug}/gift/quote.json`;

    return (
      <div className="gifts pass">
        <GiftWrapper
          state={state}
          store={this.giftStore}
          defaultsUrl={defaultsUrl}
          quoteUrl={quoteUrl}
          kind="pass"
        />
        <GiftReactions
          store={this.giftStore}
          createUrl={createUrl}
          newUrl={newUrl}
          defaultsUrl={defaultsUrl}
          kind="pass"
        />
      </div>
    );
  }
}

export default observer(PassGift);
