import React from "react";
import { observer } from "mobx-react";
import { Select } from "semantic-ui-react";
import { Quotation } from "../../../stores/CoursePreviewStore/Types";
import { MiniCourseResource } from "../../../stores/CoursesStore/Types";
import GiftStore from "../../../stores/GiftStore";

interface Props {
  quotation: Quotation;
  kind: string;
  gift: any;
  store: GiftStore;
}

const Hero: React.FC<Props> = ({ quotation, kind, gift, store }) => {
  const courses = () => {
    return store.courses.map(c => {
      return {
        text: c.name,
        value: c.slug,
        key: c.slug
      };
    });
  };

  const onCourseChange = (e, data) => {
    store.changingCourse(data.value);
  };

  return (
    <div className="ui component hero">
      <div className="team-info">
        <h2>
          Give the gift of <br /> knowledge
        </h2>

        <p className="message">
          Congrats on making the world a smarter place and not choosing the well
          trodden path of flowers and chocolates!
        </p>
        {kind === "pass" && (
          <>
            <div className="pass">
              <h5>All Access Pass</h5>
              <p>
                This voucher gives learners access all current and upcoming
                courses for 1 year.
              </p>
            </div>
            <div className="price">
              {!quotation ? (
                <div className="ui active inline loader" />
              ) : (
                <>
                  <div className="country">
                    <div
                      className={`currency-flag currency-flag-${quotation?.currency_code.toLowerCase()}`}
                      data-content={quotation?.currency_name}
                    />
                  </div>
                  <p>
                    <span className="currency-symbol">
                      {quotation?.currency}
                    </span>
                    <span className="amount">
                      {quotation?.per_priceable?.amount}
                    </span>{" "}
                    <span className="per">per person</span>
                  </p>
                </>
              )}
            </div>
          </>
        )}
        {kind === "course" && (
          <>
            <div className="course">
              <p>Select a course to gift</p>
            </div>

            {courses().length > 0 && (
              <>
                <Select
                  fluid
                  search
                  selection
                  options={courses()}
                  name="course"
                  className="ui field sixteen wide"
                  id="field"
                  onChange={onCourseChange}
                  defaultValue={gift?.priceable.slug}
                />
                <br />
              </>
            )}
          </>
        )}
      </div>
      {kind === "pass" && <hr />}
    </div>
  );
};

export default observer(Hero);
