import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postRequest } from "../../utils/httpRequest";

interface Response {
  signup_datalayer: {
    new_user_tag: any;
  };
}

const SignUpForm = ({ setError }) => {
  const [submiting, setSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required")
  });

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: ""
  };

  const handleSubmit = values => {
    setSubmitting(true);
    setError("");

    postRequest("/session/email.json", {
      session: {
        email: values.email,
        password: values.password,
        password_confirmation: values.confirmPassword,
        registration: true,
        react_session: true
      }
    })
      .then((response: AxiosResponse<Response>) => {
        const { data } = response;

        if (data?.signup_datalayer?.new_user_tag) {
          (window as any)?.dataLayer.push(data.signup_datalayer.new_user_tag);
        }

        (window as any).location =
          "https://chat.42courses.com/?token=betatester42chat";
      })
      .catch(error => {
        setSubmitting(false);
        setError(error.response.data?.errors);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, handleChange }) => (
        <Form className="ui form">
          <div>
            <div className="field">
              <div className="ui left icon action input">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                />
              </div>
              <ErrorMessage
                name="email"
                component="div"
                className="ui red pointing above label error"
              />
            </div>
          </div>
          <div>
            <div className="field">
              <div className="ui left icon action input">
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  onChange={handleChange}
                />
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="ui red pointing above label error"
              />
            </div>
          </div>
          <div>
            <div className="field">
              <div className="ui left icon action input">
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  onChange={handleChange}
                />
              </div>
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="ui red pointing above label error"
              />
            </div>
          </div>

          <div className="action">
            <button
              type="submit"
              className={`ui button ${submiting ? "loading" : ""}`}
              disabled={!isValid || submiting}
            >
              Let's go!
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
