import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Modal } from "semantic-ui-react";
import { Enrolment } from "../../../../stores/EnrolmentStore/Types";
import { QuestionPreview } from "../../../../stores/CoursePreviewStore/Types";
import { postRequest } from "../../../../utils/httpRequest";

interface Props {
  answerId: number;
  enrolment: Enrolment;
  showModal: boolean;
  answerState: string;
  lesson: QuestionPreview;
  answerStateTagline: string;
  imageUrl: string;
  handleHideAnswerStateModal: (e: any) => void;
}

interface State {}

class AnswerStateModal extends Component<Props, State> {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    const { enrolment } = this.props;

    if (enrolment.state == "passed") {
      $(".modals").css({
        "background-image": `url(${require("../../../../../../assets/images/banners/confetti.png")})`,
        "background-size": "contain"
      });
    }
  }

  enrolmentPassed = () => {
    const { enrolment } = this.props;

    return (
      <>
        <h2>Congratulations you've passed 🍾</h2>
        {enrolment.course?.certificate_landscape_url && (
          <img
            src={enrolment.course?.certificate_landscape_url}
            alt="certificate"
          />
        )}
        <div className="ui buttons">
          <Link
            className="ui certificate circular button"
            to={{ pathname: `/enrolments/${enrolment.id}/certificate` }}
          >
            Get your Certificate <i className="icon angle right" />
          </Link>
        </div>
      </>
    );
  };

  activeEnrolment = () => {
    const { enrolment, answerState, answerStateTagline } = this.props;

    return (
      <>
        {this.answerStateImage()}
        <h2 className="tagline">{answerStateTagline}</h2>

        {answerState == "failed" &&
          enrolment.state == "started" &&
          enrolment.lives_count < 1 && (
            <p>
              <em>You've run out of lives!</em>
            </p>
          )}

        {answerState == "submitted" && (
          <h3>
            Your answer will be marked by one of our marvellous tutors within
            45mins, keep going, you’ve got this!
          </h3>
        )}
      </>
    );
  };

  useLive = e => {
    const { answerId, answerState, handleHideAnswerStateModal } = this.props;
    e.preventDefault();
    handleHideAnswerStateModal(e);

    postRequest(`/api/answers/${answerId}/life_uses`, {})
      .then((response: AxiosResponse) => {
        window.location.reload();
      })
      .catch(error => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
      });
  };

  answerStateImage = () => (
    <img
      src={this.props.imageUrl}
      className={`answer-state ${this.props.answerState}`}
    />
  );

  render() {
    const {
      answerId,
      enrolment,
      showModal,
      handleHideAnswerStateModal,
      answerState,
      lesson
    } = this.props;

    return (
      <Modal
        size="tiny"
        dimmer="blurring"
        closeIcon
        open={showModal && answerState.length > 0}
        onClose={handleHideAnswerStateModal}
        className={`answer-state-modal ${enrolment.state}`}
      >
        <Modal.Content
          className={
            enrolment.state == "passed"
              ? "passed certificate-passed"
              : answerState
          }
        >
          <div className="center" style={{ color: "#fff" }}>
            {enrolment.state === "passed" && this.enrolmentPassed()}
            {enrolment.state !== "passed" && this.activeEnrolment()}
          </div>

          {enrolment.state !== "passed" && (
            <div className="ui buttons">
              {answerState == "failed" &&
                enrolment.state == "started" &&
                enrolment.lives_count > 0 && (
                  <a
                    href="#"
                    className="ui retake circular button click-busy"
                    onClick={this.useLive}
                  >
                    Retake for 1 <i className="icon heart" />
                  </a>
                )}
              {!["passed", "failed"].includes(enrolment.state) && (
                <Link
                  to={lesson?.next_question_path}
                  className="ui continue circular button"
                  onClick={handleHideAnswerStateModal}
                >
                  Continue
                  <i className="angle double right icon"></i>
                </Link>
              )}
            </div>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default AnswerStateModal;
