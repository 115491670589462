import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import Feed from "../Feed";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";
import { Post } from "../../../../stores/CommunityStore/posts/types";
import { fetchPosts } from "../../../../stores/CommunityStore/posts/actions/fetchPosts";
import { AppState } from "../../../../stores/CommunityStore";

interface Props {
  currentUser: CurrentUser;
  posts: Post[];
  featuredCommentId?: string;
  featuredReplyCommentId?: string;
}

const Feeds: React.FC<Props> = ({
  currentUser,
  posts,
  featuredCommentId,
  featuredReplyCommentId,
}) => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState<string | null>(null);
  const usersForPost = useSelector((state: AppState) => state.users.users);

  const onClickSortOption = (e, data, mode) => {
    e.preventDefault();

    setSortBy(data.text);
    dispatch(
      fetchPosts({
        sortBy: mode,
      }),
    );
  };

  const postUsers = (post) => {
    return (
      usersForPost?.find((postUser) => postUser.id === post.id)?.users || []
    );
  };

  return (
    <div className="ui community__feeds">
      <div className="community__feeds-divider">
        <hr />
        <div className="community__feeds-divider--sort">
          Sort by:{" "}
          <Dropdown
            floating
            inline
            direction="left"
            text={sortBy ? sortBy : ""}
            className="courses-sort-dropdown"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text="Date"
                onClick={(e, data) => onClickSortOption(e, data, "date")}
              />
              <Dropdown.Item
                text="Most Comments"
                onClick={(e, data) =>
                  onClickSortOption(e, data, "most_comments")
                }
              />
              <Dropdown.Item
                text="Likes"
                onClick={(e, data) => onClickSortOption(e, data, "most_likes")}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <>
        {posts.map((post) => (
          <React.Fragment key={post.id}>
            <Feed
              post={post}
              currentUser={currentUser}
              featuredCommentId={featuredCommentId}
              featuredReplyCommentId={featuredReplyCommentId}
              postUsers={postUsers(post)}
            />
            {posts.length > 0 && <hr />}
            <br />
          </React.Fragment>
        ))}
      </>
    </div>
  );
};

export default Feeds;
