import React, { Component } from "react";
import Form from "./Form";
import { Checkbox } from "semantic-ui-react";
import { postRequest } from "../../../src/utils/httpRequest";

interface Currency {
  key: string;
  value: string;
}

interface Props {
  currencies: Currency[];
  courses: {
    name: string;
    value: number;
    selected: boolean;
  }[];
}

interface State {
  submitting: boolean;
  allCoursesSelected: boolean;
  courses: {
    name: string;
    value: number;
    selected: boolean;
  }[];
  selectedCurrencies: {
    name: string;
    amount: string;
  }[];
}

class BulkUpdatePricing extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      allCoursesSelected: false,
      courses: [],
      selectedCurrencies: [
        {
          name: "",
          amount: "",
        },
      ],
      submitting: false,
    };
  }

  componentDidMount() {
    const courses = this.props.courses.map((course) => {
      return { ...course, selected: false };
    });

    this.setState({ courses });
  }

  addCurrency = (e) => {
    e.preventDefault();

    this.setState({
      selectedCurrencies: [
        ...this.state.selectedCurrencies,
        {
          name: "",
          amount: "",
        },
      ],
    });
  };

  updateCurrency = (index, newName) => {
    const { selectedCurrencies } = this.state;

    selectedCurrencies[index] = {
      ...selectedCurrencies[index],
      name: newName,
    };

    this.setState({ selectedCurrencies });
  };

  updateAmount = (index, amount) => {
    const { selectedCurrencies } = this.state;

    selectedCurrencies[index] = {
      ...selectedCurrencies[index],
      amount,
    };

    this.setState({ selectedCurrencies });
  };

  handleAllCoursesSelected = (e, data) => {
    let { courses } = this.props;

    this.setState({ allCoursesSelected: data.checked });

    const updatedCourses = courses.map((course) => {
      return { ...course, selected: data.checked };
    });

    this.setState({ courses: updatedCourses });
  };

  handleSelectCourse = (e, value, idx, key) => {
    let courses = [...this.state.courses];

    courses[idx] = {
      ...courses[idx],
      [key]: value,
    };
    this.setState({ courses });
  };

  getSelectedCourses = () => {
    return this.state.courses
      .filter((course) => course.selected)
      .map((course) => course.value);
  };

  validateForm = () => {
    const { selectedCurrencies } = this.state;
    const selectedCourses = this.getSelectedCourses();

    // Validate currencies using reduce
    const currencyError = selectedCurrencies.reduce(
      (error, { name, amount }, index) => {
        if (error) return error; // Return early if an error is already found
        if (!name) return "Please select a currency for all fields.";
        if (!amount) return "Please enter an amount for all fields.";
        return null;
      },
      null,
    );

    if (currencyError) {
      return currencyError;
    }

    // Check if no course is selected
    if (selectedCourses.length === 0) {
      return "At least one course must be selected.";
    }

    // Return null if no errors are found
    return null;
  };

  removeCurrency = (e, index) => {
    e.preventDefault();

    const { selectedCurrencies } = this.state;
    const updatedCurrencies = selectedCurrencies.filter((_, i) => i !== index);

    this.setState({ selectedCurrencies: updatedCurrencies });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const error = this.validateForm();

    if (error) {
      alert(error);
      return;
    }

    this.setState({ submitting: true });

    postRequest("/admin/merchant/fixed_prices/bulk_update_save.json", {
      currencies: this.state.selectedCurrencies,
      course_ids: this.getSelectedCourses(),
    })
      .then((response) => {
        window.location.href = "/admin/merchant/fixed_prices";
      })
      .catch((error) => {
        console.log(error);

        this.setState({ submitting: false });
      });
  };

  render() {
    const { currencies } = this.props;
    const { selectedCurrencies, allCoursesSelected, courses, submitting } =
      this.state;

    return (
      <form className="ui form admin-uploader create-page">
        <div className="ui attached segment very padded shell-texture">
          <div className="ui buttons right floated">
            <a className="ui button" href="/admin/pages">
              Cancel
            </a>
            <div className="or"></div>
            <button
              className={`ui button primary ${submitting ? "loading" : ""}`}
              type="submit"
              onClick={this.onSubmit}
            >
              <i className="ui icon check"></i>Save
            </button>
          </div>
          <h1 className="ui header">
            <i className="icon plus"></i>Bulk Pricing (Default)
          </h1>
        </div>
        <div className="ui attached secondary segment very padded">
          {selectedCurrencies.map((currency, index) => (
            <Form
              key={index}
              index={index}
              currencies={currencies}
              currency={currency}
              updateCurrency={this.updateCurrency}
              updateAmount={this.updateAmount}
              removeCurrency={this.removeCurrency}
              canRemove={selectedCurrencies.length > 1}
            />
          ))}
          <div className="field string required page_title ten wide">
            <button className="ui button primary" onClick={this.addCurrency}>
              <i className="icon plus" /> New currency
            </button>
          </div>
          <br />
          <br />
          <div>
            <div>
              <Checkbox
                label="Select All"
                checked={allCoursesSelected}
                onChange={this.handleAllCoursesSelected}
              />
            </div>
            <hr style={{ float: "left", width: "40%" }} />
            {courses.map((course, idx) => (
              <div key={idx} className="field">
                <Checkbox
                  label={course.name}
                  checked={course.selected}
                  className="checkBox"
                  onChange={(e, data) =>
                    this.handleSelectCourse(e, data.checked, idx, "selected")
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </form>
    );
  }
}

export default BulkUpdatePricing;
