import React, { Component } from "react";
import { observer } from "mobx-react";
import { PieChart } from "react-minimal-pie-chart";
import { AxiosResponse } from "axios";
import { getRequest } from "../../../utils/httpRequest";
import { EnrolmentsResource } from "../../../stores/EnrolmentStore/Types";
import { compareCourses } from "../../../stores/CoursesStore/helpers";
import { OngoingEnrolment } from "../../../stores/ProfileStore/Types";

interface State {
  ongoingEnrolments: OngoingEnrolment[];
  loading: boolean;
}

class Courses extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      ongoingEnrolments: [],
      loading: true
    };
  }

  componentDidMount() {
    return getRequest("/api/users/enrolments.json").then(
      (response: AxiosResponse<OngoingEnrolment[]>) => {
        this.setState({ ongoingEnrolments: response.data, loading: false });
      }
    );
  }

  render() {
    const { ongoingEnrolments, loading } = this.state;
    const sortedCourses = ongoingEnrolments?.sort(compareCourses);

    return (
      <>
        {loading && (
          <div className="courses-container">
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          </div>
        )}
        {sortedCourses.length > 0 ? (
          <div className="courses-container">
            <div className="header">
              <h3>Your active Courses</h3>
            </div>
            <div className="courses">
              {sortedCourses.map((course, i) => (
                <div className="course" key={i}>
                  <a href={`/enrolments/${course.id}`}>
                    <div className="name">
                      <p>{course.name}</p>
                    </div>

                    <div className="progress">
                      <PieChart
                        data={[
                          {
                            value: course.percentage_completed,
                            color: "#36CBBD"
                          },
                          {
                            label: "",
                            value: 100 - course.percentage_completed,
                            isEmpty: true,
                            color: "#dbdee4"
                          }
                        ]}
                        totalValue={100}
                        lineWidth={10}
                        label={() => `${course.percentage_completed}%`}
                        labelStyle={{
                          fontSize: "30px",
                          fill: "392D40"
                        }}
                        labelPosition={0}
                        startAngle={270}
                      />
                    </div>
                    <div className="next">
                      <i className="icon angle right" />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            className="courses-container no-courses"
            style={{ height: "auto", overflowY: "auto" }}
          >
            <div className="header">
              <h3>Ongoing Courses</h3>
            </div>
            <div className="courses">
              <br />
              <p>You have no ongoing courses.</p>
              <br />
              <br />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default observer(Courses);
