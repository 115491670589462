import * as React from "react";

const FirstSection = () => (
  <div className="certification-wrapper">
    <div className="mobile">
      <h2 className="header">Show the world how smart you are!</h2>
      <span className="sub-header">Accredited by global brands</span>
      <div className="images">
        <img
          src={require("../../../../../../assets/images/landings/certifications/certificates.webp")}
          alt="certificate"
        />
      </div>
    </div>

    <div className="content">
      <div className="desktop">
        <h2 className="header">Show the world how smart you are!</h2>
        <span className="sub-header">
          Accredited by the world’s best creative brands
        </span>
      </div>
      <p className="description">
        We get it, when you learn something, you need proof. So good news, when
        you pass a course with us, you get a shiny, attractive certification
        that’s signed and dated with your name on it. So when someone asks you
        if you really know about that thing you said you know, you can say “Yes”
        and show them your fine looking 42courses certificate.
      </p>

      <a className="fl-button basic" data-smooth-scroll-to="#pricing-container">
        Get started
      </a>
    </div>

    <div className="images desktop">
      <img
        className="image certificate"
        src={require("../../../../../../assets/images/landings/certifications/certificates.webp")}
        alt="certificate"
      />
    </div>
  </div>
);

export default FirstSection;
