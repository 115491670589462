import React from "react";

const AboutNanoMbas = () => (
  <div className="ui segment about-nano-mbas">
    <div className="ui container">
      <div className="wrapper">
        <div className="images mobile">
          <img
            className="ui image medium"
            src={require("../../../../../assets/images/teams/course_preview_one.png")}
          />
        </div>
        <div className="text">
          <h2>Our Nano MBAs</h2>
          <p>
            Creativity isn't just for creatives. In a survey of more than 1,500
            CEOs, creativity was ranked as the top factor for future business
            success.
          </p>
          <p>
            Every department benefits from creative thinking — it's the key to
            new ideas and innovations. At 42courses, we offer a range of Nano
            MBA courses designed to unlock your team's creative potential.
          </p>
          <p>
            Our courses are developed in partnership with world-leading creative
            minds and big-name brands, including Cannes Lions, Parsons, Ogilvy
            and more. Equip your team with the tools to imagine and develop
            innovative solutions.
          </p>
          <div className="ui actions">
            <a
              className="fl-button large highlight"
              href="/courses"
              target="_blank"
            >
              View all courses
            </a>
          </div>
        </div>
        <div className="images desktop">
          <img
            className=""
            src={require("../../../../../assets/images/teams/course_preview_one.png")}
          />
          <img
            className="course-preview-two"
            src={require("../../../../../assets/images/teams/course_preview_two.png")}
          />
        </div>
      </div>
    </div>
  </div>
);

export default AboutNanoMbas;
