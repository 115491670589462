import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Facebook, Twitter, LinkedIn, Instagram } from "./Icons";
import Currencies from "./Currencies";
import currencyStore from "../../stores/CurrencyStore";
import ModalStore from "../../stores/ModalStore";
import PricingStore from "../../stores/PricingStore";

interface Props {
  pricingStore?: PricingStore;
  updateSelectedCurrency?: (isoCode: string) => void;
  layout: string;
}

class Footer extends Component<Props, {}> {
  modalStore = new ModalStore();

  componentDidMount() {
    this.modalStore.ready();
  }

  renderCurrencies = () => {
    const { layout } = this.props;

    switch (currencyStore.state.kind) {
      case "ready":
        if (layout !== "lesson") {
          return (
            <Currencies
              modalStore={this.modalStore}
              currencies={currencyStore.currencies}
              pricingStore={this.props.pricingStore}
              updateSelectedCurrency={this.props.updateSelectedCurrency}
            />
          );
        } else {
          return <div />;
        }
        break;

      default:
        return <div />;
        break;
    }
  };

  render() {
    const { layout } = this.props;
    const currentYear = new Date().getFullYear();

    return (
      <div id="footer" className="facelift-footer">
        <div className="ui vertical segment center aligned">
          <div className="ui container">
            <div className="ui two column grid stackable">
              <div className="column top left">
                <div className="motto">Make yourself irreplaceable</div>
              </div>
              <div className="column top right">
                {layout !== "lesson" && (
                  <div className="currency">
                    <div className="menu-button">
                      <a
                        id="currency-button"
                        className="ui button"
                        onClick={() => this.modalStore.open()}
                      >
                        <div
                          className={`currency-flag currency-flag-${currencyStore.isoCode.toLowerCase()}`}
                        ></div>
                        <div className="menu-button-copy-currency">
                          <span>{currencyStore.isoCode.toUpperCase()}</span>
                          <i className="setting icon"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                )}
              </div>

              <div className="column bottom left">
                <div className="copy">
                  <p></p>Connect with us
                </div>
                <div className="icons">
                  <a target="_blank" href="https://www.facebook.com/42courses">
                    <Facebook />
                  </a>
                  <a target="_blank" href="https://twitter.com/42courses">
                    <Twitter />
                  </a>
                  <a
                    target="_blank"
                    href="http://www.linkedin.com/company/42courses"
                  >
                    <LinkedIn />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/42courses">
                    <Instagram />
                  </a>
                </div>
                <div className="copy">
                  <p></p>info@42courses.com
                </div>
              </div>
              <div className="column bottom push right">
                <div className="copy privacy-policy">
                  <p className=""></p>
                  <Link to="/privacy">Privacy Policy</Link>
                </div>
                <div className="copy copy-right">
                  <p>© 2015 - {currentYear} 42courses.com ltd.</p>
                </div>
              </div>
              {this.renderCurrencies()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Footer);
