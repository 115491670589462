import React from "react";
import { Quotation } from "../../../stores/CoursePreviewStore/Types";

interface Props {
  quote: Quotation;
  loading: boolean;
}

const Hero: React.FC<Props> = ({ quote, loading }) => (
  <div className="ui component hero">
    <div className="wrapper">
      <div className="ui medium image hero-image small-mobile">
        <img
          src={require("../../../../../assets/images/teams/access/cert.png")}
          className="bg small-mobile"
        />
      </div>
      <div className="team-info">
        <h2>
          Behavioural Science and <br />
          Marketing Training to <br />
          Transform Your Talent.
        </h2>
        <p className="message">
          Access all our Nano MBA courses on-demand, from the <br /> top
          creative minds, and <strong>unlock your team's potential.</strong>
        </p>
        <div className="cta">
          <a
            className="fl-button large highlight"
            data-smooth-scroll-to="#team-form"
            href="#"
          >
            Find Out More
          </a>
        </div>
      </div>
      <div>
        <div className="ui medium image hero-image">
          <img
            src={require("../../../../../assets/images/teams/access/cert.png")}
            className="bg hero-desktop"
          />
        </div>
        <div className="cpd">
          <img
            className="ui mini image"
            src={require("../../../../../assets/images/teams/CPD-teams.png")}
          />
          <p>All courses CPD Accredited</p>
        </div>
      </div>
    </div>
  </div>
);

export default Hero;
