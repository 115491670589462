import React, { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import NumberFormat from "react-number-format";
import { CountriesResource } from "../../../../stores/LeaderboardStore/Types";
import { Api } from "../../../../utils/Api";

interface Props {
  countries: CountriesResource;
  totalCountries: number;
  prevPage: (page: number) => number;
  nextPage: (page: number, total_pages) => number;
}

interface State {
  countries: CountriesResource;
  topThreeCountries: CountriesResource;
  totalCountries: number;
  page: number;
  loading: boolean;
}

const countryFlagImage = countryCode => {
  let img;
  try {
    img = require(`../../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
  } catch {
    img = "";
  }

  return img;
};

class Countries extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      topThreeCountries: [],
      totalCountries: 0,
      page: 0,
      loading: false
    };
  }

  componentDidMount() {
    const { countries, totalCountries } = this.props;

    this.setState({
      countries: countries.slice(3, 10),
      topThreeCountries: countries.slice(0, 3),
      totalCountries
    });
  }

  loadPrev = event => {
    const { page } = this.state;
    const newPage = this.props.prevPage(page);

    this.getCountries(newPage);
  };

  loadNext = event => {
    const { page, totalCountries } = this.state;
    const nextPageNum = this.props.nextPage(page, totalCountries);
    const newPage =
      nextPageNum === totalCountries ? totalCountries - 1 : nextPageNum;

    this.getCountries(newPage);
  };

  getCountries = (newPage: number) => {
    const { page, totalCountries } = this.state;

    const client = new Api({});

    if (totalCountries > page) {
      this.setState({ loading: true });
      client
        .get<any>(`/api/leaderboards/countries.json?page=${newPage + 1}`)
        .then((response: AxiosResponse<any>) => {
          const { data } = response;
          this.setState({
            countries: data.countries,
            page: newPage,
            loading: false
          });
        })
        .catch((error: AxiosError) => {
          console.error(error.response?.statusText);
        });
    }
  };

  render() {
    const { countries, topThreeCountries, page, loading } = this.state;
    return (
      <div className="leaderboard-card countries">
        <h3 className="card-header">Top Countries</h3>
        <div className="top-three">
          {loading && <div className="ui big active loader" />}
          {topThreeCountries.map((country, idx) => (
            <div
              className={`large_column_classes leading-position-${idx}`}
              key={idx}
            >
              <div className="country">
                <div className="leaderboard-img-wrapper">
                  <div className="leaderboard-img">
                    <div className="image-wrap">
                      <img
                        src={countryFlagImage(country.code)}
                        alt={country.name}
                      />
                    </div>
                  </div>
                  <div className="position">
                    <span className={`position-${idx}`}>{idx + 1}</span>
                  </div>
                </div>
                <div className="name">{country.name}</div>
                <div className="score">
                  <NumberFormat
                    value={country.score}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  <span className="score-label">XP</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="bottom-seven">
          {countries.map((country, idx) => (
            <div className="small_column_classes" key={idx}>
              <div className="user">
                <span className="position">{idx + 4 + page * 7}.</span>
                <span className="leaderboard-img">
                  <img
                    src={countryFlagImage(country.code)}
                    alt={country.name}
                  />
                </span>
                <span className="name">{country.name}</span>
                <span className="score">
                  <NumberFormat
                    value={country.score}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  <span className="score-label">XP</span>
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-buttons">
          <button
            className="circular ui icon button next"
            onClick={e => this.loadPrev(e)}
          >
            <i className="icon angle left"></i>
          </button>
          <button
            className="circular ui icon button next"
            onClick={e => this.loadNext(e)}
          >
            <i className="icon angle right"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default Countries;
