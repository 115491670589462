import React, { useEffect, useState } from "react";
import { getRequest } from "../../utils/httpRequest";

interface PermissionData {
  message: string;
  type: string;
}

const Permission: React.FC = () => {
  const [permission, setPermission] = useState({} as PermissionData);

  useEffect(() => {
    let isActive = true;

    const fetchPermissions = async () => {
      try {
        const response: any = await getRequest("/api/permissions");
        if (isActive) {
          setPermission(response.data);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <div className="ui facelift-landing-page" id="login">
      <div className="ui container">
        <div className="wrapper">
          <div className="eight wide column section-two" id="column">
            <div>
              <div className="ui facelift-landing-page">
                {permission && (
                  <>
                    <p className="permission-message">{permission.message}</p>
                    {permission.type === "login" ? (
                      <a href="/login" className="block ui button primary">
                        Login
                      </a>
                    ) : (
                      <a
                        href="/passes/year/pay"
                        className="block ui button primary"
                      >
                        Subscribe
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permission;
