import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { CurrentTeam, TeamResource } from "../../../stores/TeamStore/Types";

interface Props {
  teamResource: TeamResource;
  handleShowSettingsModal: (val: boolean) => void;
}

class Landing extends Component<Props, {}> {
  countryFlagImage = countryCode => {
    let img;
    try {
      img = require(`../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
    } catch {
      img = "";
    }

    return img;
  };

  componentDidMount() {
    ($(".fit-text.team-name") as any).fitText(1.4);
  }

  render() {
    const { teamResource, handleShowSettingsModal } = this.props;

    return (
      <div className="ui column left content">
        <div className="content-container">
          <h1 className="user-name team-name fit-text">{teamResource?.name}</h1>
          <div className="profile-container">
            <div className="bio">
              <div className="profile-pic">
                <img src={teamResource.logo_url} className="user-image" />
              </div>
              <div className="ranking">
                <div className="rank-and-xp">
                  <div className="rank">
                    <span className="intro">users</span>
                    <h2 className="value">{teamResource.user_count}</h2>
                  </div>
                  <div className="xp">
                    <span className="intro">member since</span>
                    <h2 className="value">
                      {teamResource.formatted_created_at}
                    </h2>
                  </div>
                </div>
                <div className="settings">
                  <button
                    className="ui button fl-button"
                    onClick={() => handleShowSettingsModal(true)}
                  >
                    <span>Settings</span>
                    <i className="setting icon"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="badges-and-certs">
              <div>
                <div className="badges">
                  <div className="icon">
                    <svg
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="40" cy="40" r="40" fill="#58C7EC" />
                      <path
                        d="M41.8529 36.6869V28.7702H38.6862V39.8536H48.1862V36.6869H41.8529ZM50.3459 46.7632L40.2695 56.8396L30.1932 46.7632C28.2003 44.7703 26.8432 42.2312 26.2933 39.467C25.7435 36.7028 26.0257 33.8376 27.1043 31.2337C28.1828 28.6299 30.0093 26.4044 32.3527 24.8386C34.6961 23.2728 37.4512 22.437 40.2695 22.437C43.0879 22.437 45.843 23.2728 48.1864 24.8386C50.5298 26.4044 52.3562 28.6299 53.4348 31.2337C54.5134 33.8376 54.7956 36.7028 54.2457 39.467C53.6959 42.2312 52.3387 44.7703 50.3459 46.7632V46.7632Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="desc">
                    <h3>{teamResource.learning_time} hours</h3>
                    <span>Upskilling time</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="certs">
                  <div className="icon">
                    <img
                      src={require("../../../../../assets/images/profile/cert.png")}
                      alt="certificates"
                    />
                  </div>
                  <div className="desc">
                    <h3>{teamResource.certificates_count}</h3>
                    <span>Certificates earned</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="zen-message">
              <div className="header">
                <h3>Fact of the day </h3>
                <img
                  src={require("../../../../../assets/images/profile/quote.png")}
                />
              </div>
              <div className="zen-content">
                <span>{teamResource.zen_pool}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Landing);
