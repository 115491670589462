import React, { Component } from "react";
import { observer } from "mobx-react";
import ReviewsStore from "../../../../stores/ReviewsStore";
import ReviewsReactions from "../../../../stores/ReviewsStore/ReviewsReactions";
import ReviewsWrapper from "./ReviewsWrapper";

class Reviews extends Component {
  reviewsStore = new ReviewsStore();
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.reviewsStore.loading();
  }

  render() {
    const { state } = this.reviewsStore;
    return (
      <div className="ui segment reviews-container">
        <div className="ui">
          <ReviewsWrapper reviewsState={state} />
          <ReviewsReactions store={this.reviewsStore} />
        </div>
      </div>
    );
  }
}

export default observer(Reviews);
