import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import NumberFormat from "react-number-format";
import SingleCourse from "./SingleCourse";
import MonthlyPricing from "./MonthlyPricing";
import YearlyPricing from "./YearlyPricing";
import LifetimePricing from "./LifetimePricing";
import MediumMobile from "./MediumMobile";
import Mobile from "./Mobile";
import { PricingPayload } from "../../../../stores/PricingStore/Types";

interface Props {
  handleShowSignInSideBar: (redirectUrl?: string) => void;
  pricing: PricingPayload;
  courseSlug?: string;
  singleCourseHeading?: string;
  showSigninSidebar: boolean;
}

class PricingWrapper extends Component<Props, {}> {
  renderPricingWrapper = () => {
    const {
      pricing,
      courseSlug,
      handleShowSignInSideBar,
      showSigninSidebar,
      singleCourseHeading,
    } = this.props;
    switch (pricing.kind) {
      case "pricing":
        return (
          <div className="pricing ui center aligned segment">
            <div className="ui pricing-container" id="pricing-container">
              <h1>
                Pick your plan and join{" "}
                {
                  <NumberFormat
                    value={pricing?.enrolment_count}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }{" "}
                happy learners today
              </h1>
              <div className="grid-container desktop">
                <div className="subscription-cards">
                  <div className="title">
                    <strong>Subscriptions</strong>
                    <p className="courses-count">
                      (Access to{" "}
                      <Link to="/courses" target="_blank">
                        <span>{pricing.course_count} courses</span>
                      </Link>{" "}
                      valued at <strong>{pricing.courses_value}</strong>)
                    </p>
                  </div>
                  <div className="grid-wrapper">
                    {!pricing.is_restricted_pricing_disabled && (
                      <div className="grid-item yearly-item">
                        <MonthlyPricing
                          handleShowSignInSideBar={handleShowSignInSideBar}
                          monthlyPricing={pricing.monthly_pricing}
                          showSigninSidebar={showSigninSidebar}
                        />
                      </div>
                    )}
                    <div className="grid-item monthly-item">
                      <YearlyPricing
                        handleShowSignInSideBar={handleShowSignInSideBar}
                        yearlyPricing={pricing.yearly_pricing}
                        fee={pricing.fee}
                        existingSubscription={
                          pricing.existing_subscription_present
                        }
                        showSigninSidebar={showSigninSidebar}
                      />
                    </div>
                    <div className="grid-item yearly-item">
                      <LifetimePricing
                        handleShowSignInSideBar={handleShowSignInSideBar}
                        lifetimePricing={pricing.lifetime_pricing}
                        showSigninSidebar={showSigninSidebar}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="divider" />
                </div>
                <div
                  className={`course-card ${pricing.is_restricted_pricing_disabled ? "restricted" : "not-restricted"}`}
                >
                  <div className="title">
                    <strong>Single course</strong>
                    <p
                      className="courses-count"
                      style={{ color: "transparent" }}
                    >
                      (access all <span></span> courses)
                    </p>
                  </div>
                  <div className="grid-item">
                    <div>
                      <SingleCourse
                        showTitle={false}
                        courseSlug={courseSlug}
                        singleCourseHeading={singleCourseHeading}
                        handleShowSignInSideBar={handleShowSignInSideBar}
                        singleCoursePricing={pricing.single_course_pricing}
                        fee={pricing.fee}
                        existingSubscription={
                          pricing.existing_subscription_present
                        }
                        enrolment={pricing.enrolment_present}
                        showSigninSidebar={showSigninSidebar}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <MediumMobile
                courseSlug={courseSlug}
                handleShowSignInSideBar={handleShowSignInSideBar}
                pricing={pricing}
                showSigninSidebar={showSigninSidebar}
                singleCourseHeading={singleCourseHeading}
              />
              <Mobile
                courseSlug={courseSlug}
                handleShowSignInSideBar={handleShowSignInSideBar}
                pricing={pricing}
                showSigninSidebar={showSigninSidebar}
                singleCourseHeading={singleCourseHeading}
              />
              <br />
              <br />
              <Link
                className="fl-button highlight student-pricing"
                to="/team_plans"
                target="_blank"
              >
                Company Pricing Options
              </Link>
            </div>
          </div>
        );
        break;

      default:
        return <div className="no-pricing" />;
        break;
    }
  };

  render() {
    return <div>{this.renderPricingWrapper()}</div>;
  }
}

export default observer(PricingWrapper);
