import React, { Component } from "react";
import { observer } from "mobx-react";
import { AxiosResponse } from "axios";
import { Carousel } from "../../common";
import { getRequest } from "../../../utils/httpRequest";
import { CertificatesResource } from "../../../stores/CoursesStore/Types";
import CertificateImage from "./CertificateImage";

interface State {
  certificates: CertificatesResource;
  loaded: boolean;
}

class Certificates extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      certificates: [],
      loaded: false
    };
  }

  componentDidMount() {
    return getRequest("/api/profile/certificates.json").then(
      (response: AxiosResponse<CertificatesResource>) => {
        this.setState({ certificates: response.data, loaded: true });
      }
    );
  }

  render() {
    const { loaded, certificates } = this.state;

    return (
      <div className="ui segment courses certificates" id="certificates">
        {!loaded && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        <div className="ui container">
          <h1 className="ui header aligned course-header certificates-header">
            <span className="title">Certificates</span>
          </h1>

          <div className="courses-container certificates-container ui inverted vertical segment center aligned">
            <div
              className="ui centered doubling stackable three column"
              id="certificates_grid"
            >
              <Carousel noOfMobileSlides={2} numberOfSlides={4} arrows={true}>
                {certificates.map((course, i) => (
                  <CertificateImage course={course} idx={i} key={i} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(Certificates);
