import React, { Component } from "react";
import { observer } from "mobx-react";
import Landing from "./Leaderboards";
import { Api } from "../../../utils/Api";
import { AxiosResponse, AxiosError } from "axios";
import {
  HeroesResource,
  CountriesResource,
  TeamsResource
} from "../../../stores/LeaderboardStore/Types";

interface Props {}

interface State {
  heroes: HeroesResource;
  countries: CountriesResource;
  teams: TeamsResource;
  heroes_page: number;
  countries_page: number;
  teams_page: number;
  total_heroes: number;
  total_countries: number;
  total_teams: number;
  loading: boolean;
}

class LeaderBoard extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      heroes: [],
      countries: [],
      teams: [],
      total_heroes: 0,
      total_countries: 0,
      total_teams: 0,
      heroes_page: 0,
      countries_page: 0,
      teams_page: 0,
      loading: true
    };
  }

  componentDidMount() {
    const client = new Api({});
    client
      .get<any>("/api/leaderboards/landing.json")
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        this.setState({
          heroes: data.heroes,
          countries: data.countries,
          teams: data.teams,
          total_heroes: data.total_heroes,
          total_countries: data.total_countries,
          total_teams: data.total_teams,
          loading: false
        });
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  }

  prevPage = page => {
    if (page < 1) {
      return 0;
    } else if (page + 1 === 1) {
      return 1;
    } else {
      return page - 1;
    }
  };

  nextPage = (page, total_pages) =>
    page > total_pages ? total_pages : page + 1;

  render() {
    const {
      heroes,
      countries,
      teams,
      total_heroes,
      total_countries,
      total_teams,
      loading
    } = this.state;

    return (
      <div className="ui segment leaderboard">
        <div className="container">
          {loading && (
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          )}

          <div className="leaderboard-header">
            <h2 className="header">Leaderboard</h2>
          </div>
          {!loading && (
            <Landing
              heroes={heroes}
              countries={countries}
              teams={teams}
              total_heroes={total_heroes}
              total_countries={total_countries}
              total_teams={total_teams}
              prevPage={this.prevPage}
              nextPage={this.nextPage}
              loading={loading}
            />
          )}
        </div>
      </div>
    );
  }
}

export default observer(LeaderBoard);
