import {
  LOAD_TOPICS,
  LOAD_TOPICS_FAILURE,
  LOAD_TOPICS_SUCCESS,
  SET_TOPIC,
  TopicActionTypes,
  TopicState,
} from "./types";

const initialState: TopicState = {
  topics: [],
  loading: false,
  selectedTopicUuid: "",
  error: "",
};

export const topicReducer = (
  state = initialState,
  action: TopicActionTypes,
): TopicState => {
  switch (action.type) {
    case LOAD_TOPICS:
      return { ...state, loading: true };

    case LOAD_TOPICS_SUCCESS:
      return { ...state, loading: false, topics: action.payload };

    case LOAD_TOPICS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case SET_TOPIC:
      return { ...state, selectedTopicUuid: action.topicUuid };

    default:
      return state;
  }
};
