import React from "react";

// A simple regex to detect URLs in the text
const urlRegex = /(https?:\/\/[^\s]+)/g;

const LinkifyText = ({ text }) => {
  const createMarkup = (text) => {
    // Regular expression to detect URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with <a> tags
    const htmlText = text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`,
    );

    return { __html: htmlText };
  };

  return <div dangerouslySetInnerHTML={createMarkup(text)} />;
};

export default LinkifyText;
