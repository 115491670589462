import React, { Component } from "react";
import { observer } from "mobx-react";
import ProfileStore from "../../stores/ProfileStore";
import { ProfileState } from "../../stores/ProfileStore/Types";
import Landing from "./Landing";
import ProgressAndPosition from "./Landing/ProgressAndPosition";
import OngoingCourses from "./OngoingCourses";
import { CourseCategories, Pricing, AiCoursePicker } from "../Home/components";
import CompletedCourses from "./CompletedCourses";
import Certificates from "./Certificates";
import Settings from "./Settings";
import Leaderboard from "./Leaderboard";
import { CurrentUser } from "../../stores/CurrentUserStore/Types";
import PricingStore from "../../stores/PricingStore";

interface Props {
  profileStore: ProfileStore;
  pricingStore: PricingStore;
  getCurrentUser: () => void;
  currentUser: CurrentUser;
}

interface State {
  showSettingsModal: boolean;
}

class Profile extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showSettingsModal: false
    };
  }

  handleShowSettingsModal = val => this.setState({ showSettingsModal: val });

  renderPricing = state => {
    switch (state.kind) {
      case "ready":
        return (
          <Pricing
            handleShowSignInSideBar={() => {}}
            pricing={state.pricing}
            showSigninSidebar={false}
          />
        );
      case "loading":
        return (
          <div className="pricing ui vertical center aligned segment">
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  renderContent = (profileState: ProfileState) => {
    const { currentUser, pricingStore } = this.props;
    const { pricingState } = pricingStore;

    switch (profileState.kind) {
      case "ready":
        if (profileState.profileResource.kind === "current-user-absent") {
          return (
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          );
        } else {
          const { profileResource } = profileState;
          const { showSettingsModal } = this.state;
          return (
            <div>
              <div className="ui container profile-header">
                <div className="ui two column stackable grid">
                  <Landing
                    profileResource={profileResource}
                    handleShowSettingsModal={this.handleShowSettingsModal}
                    profilePicture={
                      currentUser.kind === "current-user"
                        ? currentUser.profile_picture
                        : ""
                    }
                  />
                  <ProgressAndPosition profileResource={profileResource} />
                </div>
                <Settings
                  showSettingsModal={showSettingsModal}
                  handleShowSettingsModal={this.handleShowSettingsModal}
                  profileResource={profileResource}
                  profileStore={this.props.profileStore}
                  getCurrentUser={this.props.getCurrentUser}
                  profilePicture={
                    currentUser.kind === "current-user"
                      ? currentUser.profile_picture
                      : ""
                  }
                />
              </div>
              {profileResource.enrolments?.ongoing === 0 && <AiCoursePicker />}
              <br />
              <OngoingCourses />
              {profileResource.enrolments?.ongoing > 0 && <AiCoursePicker />}
              <CourseCategories headerTitle="Available courses" />
              <CompletedCourses />
              <Certificates />
              <Leaderboard countries={profileResource.countries} />
              {this.renderPricing(pricingState)}
            </div>
          );
        }
        break;
      case "loading":
        return (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        );

      default:
        break;
    }
  };

  render() {
    const { profileStore } = this.props;
    return (
      <div className="ui facelift-new-homepage facelift-profile-page">
        <div className="profile-header-wrapper">
          {this.renderContent(profileStore.state)}
        </div>
      </div>
    );
  }
}

export default observer(Profile);
