import React, { Component } from "react";

interface Props {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  className?: string; // Added className prop
}

interface State {
  isFocused: boolean;
}

class EditableSpan extends Component<Props, State> {
  spanRef: React.RefObject<HTMLSpanElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false,
    };
    this.spanRef = React.createRef();
  }

  componentDidMount() {
    this.updateInnerText();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value && !this.state.isFocused) {
      this.updateInnerText();
    }
  }

  updateInnerText = () => {
    const element = this.spanRef.current;
    if (element) {
      element.innerText = this.props.value || "";
    }
  };

  handleFocus = () => {
    this.setState({ isFocused: true }, () => {
      const element = this.spanRef.current;
      if (element) {
        // Place cursor at end
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(element);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    });
  };

  handleBlur = () => {
    const element = this.spanRef.current;
    if (element) {
      const text = element.innerText.trim();
      this.props.onChange(text);
    }
    this.setState({ isFocused: false });
  };

  render() {
    const { isFocused } = this.state;
    const { className: propClassName } = this.props;

    const className = [
      "editable-span",
      isFocused ? "focused" : "",
      propClassName,
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <span
        ref={this.spanRef}
        className={className}
        contentEditable
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        data-placeholder={this.props.placeholder}
        suppressContentEditableWarning={true}
      ></span>
    );
  }
}

export default EditableSpan;
