import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import CurrentUser from "../CurrentUser";
import { CurrentUserResource } from "../../../stores/CurrentUserStore/Types";

interface Props {
  currentUser: CurrentUserResource;
  sideBarClass: string;
  handleHideSideBar: () => void;
  onClickHeaderLogo: () => void;
}

const SideBar: React.FC<Props> = ({
  currentUser,
  sideBarClass,
  handleHideSideBar,
  onClickHeaderLogo,
}) => {
  return (
    <div className="ui">
      <div className={sideBarClass}>
        <div className="ui one column stackable grid container">
          <div className="header menu">
            <a
              href={currentUser.kind === "current-user" ? "/profile" : "/"}
              className="logo"
            >
              <img
                className="dark"
                src={require("../../../../../assets/images/facelift/logos/dark_rectangle.png")}
                alt=""
              />
            </a>
            <div className="close">
              <a
                className="ui left button"
                id="closeNav"
                onClick={() => handleHideSideBar()}
              >
                <i className="close icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="ui two column stackable grid container menuContainer">
          <div className="menu">
            <a
              href={currentUser.kind === "current-user" ? "/profile" : "/"}
              className={`ui item home ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              Home
            </a>
            <div className="divider" />
          </div>

          <div className="menu links">
            <a
              href="/team_plans"
              className={`ui item  ${
                location.pathname === "/team_plans" ? "active" : ""
              }`}
            >
              For teams
            </a>
            <a
              href="/courses"
              className={`ui item  ${
                location.pathname === "/courses" ? "active" : ""
              }`}
            >
              Courses
            </a>
            <a
              href="/about_us"
              className={`ui item  ${
                location.pathname === "/about_us" ? "active" : ""
              }`}
            >
              About
            </a>
            <a
              href="https://www.42courses.com/blog"
              target="_blank"
              className="ui item"
            >
              Blog
            </a>
            <a
              href="/faqs"
              className={`ui item  ${
                location.pathname === "/faqs" ? "active" : ""
              }`}
            >
              FAQs
            </a>
          </div>
        </div>
        <footer className="menu">
          <a href="/contact" className="item">
            <i className="icon envelope outline" />
            <span>Contact us</span>
          </a>
          <a href="/faqs" className="item">
            <i className="icon question circle outline" />
            <span>FAQ</span>
          </a>
        </footer>
      </div>
    </div>
  );
};

export default withRouter(SideBar);
