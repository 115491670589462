import React, { Component } from "react";
import { NotificationResource } from "../../../../stores/RootStore/Types";
import Notification from "./Notification";

interface State {}

interface Props {
  notifications: NotificationResource[];
}

class RecentNotifications extends Component<Props, State> {
  render() {
    const { notifications } = this.props;
    return (
      <div>
        <div className="notifications-modal toast-wrapper">
          {notifications.map(notification => (
            <div className={`item ${notification.mood}`} key={notification.id}>
              <Notification notification={notification} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default RecentNotifications;
