import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { OngoingCourse } from "../../stores/EnrolmentStore/Types";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { AxiosResponse } from "axios";
import { errorNotification } from "../../utils/helpers";
import EditableSpan from "../common/EditableSpan";

interface Props {
  enrolment: OngoingCourse;
}

interface State {
  loading: boolean;
  isSkipBusy: boolean;
  isEnableBusy: boolean;
  personalizationState: string;
  personalizationName: string;
  personalizationCountry: string;
  personalizationWorkingOn: string;
  personalizationMustImprove: string;
  errors: {
    personalizationName?: boolean;
    personalizationCountry?: boolean;
    personalizationWorkingOn?: boolean;
    personalizationMustImprove?: boolean;
  };
  personalizationProgress: number;
}

class PersonalizationModal extends Component<Props, State> {
  timerId: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      isSkipBusy: false,
      isEnableBusy: false,
      personalizationState: props.enrolment.personalization_state,
      personalizationName: "",
      personalizationCountry: "",
      personalizationWorkingOn: "",
      personalizationMustImprove: "",
      errors: {},
      personalizationProgress: 0,
    };
  }

  componentDidMount() {
    this.getPersonalizationConfig();
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  updatePersonalizationStateFromAPI = (data: any) => {
    this.setState({
      personalizationState: data.personalization_state,
      personalizationName: data.personalization_name || "",
      personalizationCountry: data.personalization_country || "",
      personalizationWorkingOn: data.personalization_working_on || "",
      personalizationMustImprove: data.personalization_must_improve || "",
    });
  };

  getPersonalizationConfig = () => {
    this.setState({ loading: true });
    getRequest(
      `/api/enrolments/${this.props.enrolment.id}/personalization`
    )
      .then((response: AxiosResponse) => {
        this.updatePersonalizationStateFromAPI(response.data);
      })
      .catch((error) => {
        errorNotification(
          "Error when fetching personalization",
          JSON.stringify(error?.response?.data)
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  skipPersonalization = () => {
    this.setState({ isSkipBusy: true });

    postRequest(
      `/api/enrolments/${this.props.enrolment.id}/personalization/skip`,
      {}
    )
      .then((response: AxiosResponse) => {
        this.setState({
          personalizationState: response.data.personalization_state,
        });
      })
      .catch((error) => {
        errorNotification(
          "Error when skipping personalization",
          JSON.stringify(error?.response?.data)
        );
      })
      .finally(() => {
        this.setState({ isSkipBusy: false });
      });
  };

  activatePersonalization = () => {
    const errors = {
      personalizationName: !this.state.personalizationName.trim(),
      personalizationCountry: !this.state.personalizationCountry.trim(),
      personalizationWorkingOn: !this.state.personalizationWorkingOn.trim(),
      personalizationMustImprove: !this.state.personalizationMustImprove.trim(),
    };

    const hasErrors = Object.values(errors).some((error) => error);

    if (hasErrors) {
      this.setState({ errors });
      return;
    }

    this.setState({ isEnableBusy: true, errors: {} });

    const data = {
      personalization_name: this.state.personalizationName,
      personalization_country: this.state.personalizationCountry,
      personalization_working_on: this.state.personalizationWorkingOn,
      personalization_must_improve: this.state.personalizationMustImprove,
    };

    postRequest(
      `/api/enrolments/${this.props.enrolment.id}/personalization/activate`,
      data
    )
      .then((response: AxiosResponse) => {
        this.setState({
          personalizationState: response.data.personalization_state,
        });
        this.increasePersonalizationProgress();
        this.startPolling();
      })
      .catch((error) => {
        errorNotification(
          "Error when activating personalization",
          JSON.stringify(error?.response?.data)
        );
      })
      .finally(() => {
        this.setState({ isEnableBusy: false });
      });
  };

  increasePersonalizationProgress = () => {
    const randomIncrease = Math.floor(Math.random() * (15)) + 20;
    this.setState(prevState => ({
      personalizationProgress: Math.min(prevState.personalizationProgress + randomIncrease, 95)
    }));
  }

  startPolling = () => {
    if (this.timerId) clearTimeout(this.timerId);

    const poll = () => {
      this.getPersonalizationConfig();
      this.increasePersonalizationProgress();
      const { personalizationState } = this.state;

      if (personalizationState === "generating") {
        this.timerId = setTimeout(poll, 3000);
      } else {
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
      }
    };

    this.timerId = setTimeout(poll, 3000);
  };

  onClose = () => {
    if (this.state.personalizationState === "asking") {
      this.skipPersonalization();
    }
  };

  handleFieldChange = (
    field:
      | "personalizationName"
      | "personalizationCountry"
      | "personalizationWorkingOn"
      | "personalizationMustImprove",
    value: string
  ) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: { ...prevState.errors, [field]: false },
    }));
  };

  render() {
    const {
      personalizationState,
      loading,
      isEnableBusy,
      isSkipBusy,
      errors,
    } = this.state;
    const isOpen =
      personalizationState === "asking" ||
      personalizationState === "generating";

    const allFieldsFilled =
      this.state.personalizationName.trim() !== "" &&
      this.state.personalizationCountry.trim() !== "" &&
      this.state.personalizationWorkingOn.trim() !== "" &&
      this.state.personalizationMustImprove.trim() !== "";

    const buttonClass = `ui large button primary ${isEnableBusy ? "loading" : ""}`;

    const isButtonDisabled = isEnableBusy || isSkipBusy || loading;

    return (
      <Modal
        size="small"
        open={isOpen}
        className="personalization-modal"
        closeIcon={personalizationState != 'generating'}
        onClose={this.onClose}
      >
        <Modal.Content>
          {personalizationState === "asking" && (
            <>
              <h2>
                Let's get personal
              </h2>

              <p>
                Tell <strong>Deep Thought AI</strong> how to tailor your course content to
                you. The more info you give, the more accurate and helpful it will be.
              </p>

              {loading && (
                <div className="ui placeholder" style={{ marginBottom: 30 }}>
                  <div className="paragraph">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>
              )}

              {!loading && (<div className='inputs'>
                I'm{" "}
                <EditableSpan
                  placeholder="Your marvellous name"
                  value={this.state.personalizationName}
                  onChange={(value) =>
                    this.handleFieldChange("personalizationName", value)
                  }
                  className={
                    errors.personalizationName ? "error" : undefined
                  }
                />{" "}
                from{" "}
                <EditableSpan
                  placeholder="Country"
                  value={this.state.personalizationCountry}
                  onChange={(value) =>
                    this.handleFieldChange("personalizationCountry", value)
                  }
                  className={
                    errors.personalizationCountry ? "error" : undefined
                  }
                />
                . I'm currently{" "}
                <EditableSpan
                  placeholder="role / company / what you're working on"
                  value={this.state.personalizationWorkingOn}
                  onChange={(value) =>
                    this.handleFieldChange(
                      "personalizationWorkingOn",
                      value
                    )
                  }
                  className={
                    errors.personalizationWorkingOn ? "error" : undefined
                  }
                />{" "}
                and I'm here to level-up my skills in{" "}
                <EditableSpan
                  placeholder="specific skills or future self-desire, feel free to think big"
                  value={this.state.personalizationMustImprove}
                  onChange={(value) =>
                    this.handleFieldChange(
                      "personalizationMustImprove",
                      value
                    )
                  }
                  className={
                    errors.personalizationMustImprove ? "error" : undefined
                  }
                />
                .
              </div>)}

              <button
                className={buttonClass}
                onClick={this.activatePersonalization}
                disabled={isButtonDisabled}
              >
                Personalise my course
              </button>
            </>
          )}
          {personalizationState === "generating" && (
            <>
              <h2>Creating amazing content for you...</h2>
              <div className="ui progress" style={{ margin: 0 }}>
                <div className="bar" style={{ width: `${this.state.personalizationProgress}%` }} />
              </div>
            </>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default PersonalizationModal;
