import * as React from "react";
import { CourseResource } from "../../../../../stores/CoursesStore/Types";

interface Props {
  courseResource: CourseResource;
}

const PreviewImage: React.FC<Props> = ({ courseResource }) =>
  courseResource.preview_headshot_image_url ? (
    <img
      src={courseResource.preview_headshot_image_url}
      alt="headshot"
      className="headshot"
    />
  ) : (
    <img
      src={require("../../../../../../../assets/images/courses/decal.png")}
      alt="headshot"
      className="decal"
    />
  );

export default PreviewImage;
