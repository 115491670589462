import * as React from "react";
import { CourseResource } from "../../../../../stores/CoursesStore/Types";

interface Props {
  courseResource: CourseResource;
}

const StatusImage: React.FC<Props> = ({ courseResource }) => {
  if (
    courseResource.content_version == "upcoming" ||
    courseResource.content_version == "early_access"
  ) {
    return (
      <img
        src={require("../../../../../../../assets/images/statuses/new/coming-soon.gif")}
        alt="coming-soon"
        className="upcoming"
      />
    );
  }

  if (
    !(courseResource.content_version == "upcoming") &&
    courseResource.best_seller &&
    courseResource.paid
  ) {
    return (
      <img
        src={require("../../../../../../../assets/images/statuses/new/best-seller.gif")}
        alt="best-seller"
        className="best_seller"
      />
    );
  }

  if (courseResource.new_course) {
    return (
      <img
        src={require("../../../../../../../assets/images/statuses/new/new.gif")}
        alt="new-course"
        className="early_access"
      />
    );
  }

  if (courseResource.sample) {
    return (
      <img
        src={require("../../../../../../../assets/images/statuses/new/sample-course.gif")}
        alt="sample-course"
        className="sample"
      />
    );
  }

  return <div />;
};

export default StatusImage;
