import { Dispatch } from "redux";
import { AppThunk } from "../../../CommunityStore";
import {
  LOAD_POSTS,
  LOAD_POSTS_SUCCESS,
  LOAD_POSTS_FAILURE,
  LOAD_MORE_POSTS,
  LOAD_MORE_POSTS_SUCCESS,
  LOAD_MORE_POSTS_FAILURE,
  LoadPostsAction,
  LoadPostsSuccessAction,
  PostActionTypes,
  Post,
} from "../types";
import { getRequest } from "../../../../utils/httpRequest";
import { AxiosError, AxiosResponse } from "axios";
import { fetchUsers } from "../../users/actions/fetchUsers";
import { UserActionTypes } from "../../users/types";

export const loadPosts = (): LoadPostsAction => ({
  type: LOAD_POSTS,
});

export const loadPostsSuccess = (posts: Post[]): LoadPostsSuccessAction => ({
  type: LOAD_POSTS_SUCCESS,
  payload: posts,
});

export const loadMorePosts = () => ({ type: LOAD_MORE_POSTS });

export const loadMorePostsSuccess = (posts) => ({
  type: LOAD_MORE_POSTS_SUCCESS,
  payload: posts,
});

export const loadMorePostsFailure = (error) => ({
  type: LOAD_MORE_POSTS_FAILURE,
  payload: error,
});

type FetchPostsProps = {
  topicUuid?: string;
  sortBy?: string;
  page?: number;
  featuredPostId?: string;
};

export const fetchPosts =
  ({ topicUuid, sortBy, featuredPostId }: FetchPostsProps): AppThunk =>
  async (dispatch: Dispatch<PostActionTypes | UserActionTypes>) => {
    dispatch({ type: LOAD_POSTS });

    getRequest(
      `/api/community/posts.json?&featured_post_id=${featuredPostId}&topic_uuid=${topicUuid}&sort_by=${sortBy || ""}`,
    )
      .then((response: AxiosResponse) => {
        dispatch(loadPostsSuccess(response.data));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        dispatch({ type: LOAD_POSTS_FAILURE, payload: error.message });
      });
  };

export const fetchMorePosts =
  ({ topicUuid, sortBy, page }: FetchPostsProps) =>
  async (dispatch) => {
    dispatch(loadMorePosts());

    getRequest(
      `/api/community/posts.json?topic_uuid=${topicUuid}&sort_by=${sortBy || ""}&page=${page}`,
    )
      .then((response: AxiosResponse) => {
        dispatch(loadMorePostsSuccess(response.data));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        dispatch(loadMorePostsFailure(error.message));
      });
  };
