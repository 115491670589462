import React from "react";
import { Carousel } from "../../common";
import reviews from "./reviews.json";

const Reviews = () => (
  <div className="ui segment team-plans-reviews">
    <div className="ui container">
      <div className="ui middle aligned grid">
        <div className="column sixteen wide">
          <Carousel
            noOfMobileSlides={1}
            numberOfSlides={1}
            noOfMiddleSlides={1}
            autoplay={true}
            arrows={false}
            dots={true}
            arrowFillCircleColor="#FFFFFF"
            arrowFillArrowColor="#392D40"
            opacity="1"
            className="review-highlights-carousel"
          >
            {reviews.map((review, i) => (
              <div className="review-highlight" key={i}>
                <div className="user">
                  <h2 className="ui header">{review.name}</h2>
                </div>
                <p className="review-content">{review.review}</p>
                <div className="org-logo">
                  <img
                    src={require(`../../../../../assets/images/teams/brand_logos/${review.logo}`)}
                    className={`ui ${review.imageSize || "small"} image`}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  </div>
);

export default Reviews;
