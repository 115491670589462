import React, { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { Api } from "../../../utils/Api";
import { CoursesResource } from "../../../stores/CoursesStore/Types";
import { Carousel } from "../../common";
import Course from "./Courses/Course";

interface Props {
  showHeading: boolean;
}

interface State {
  featuredCourses: CoursesResource;
  loading: boolean;
}

class FeaturedCourses extends Component<Props, State> {
  static defaultProps = { showHeading: true };

  constructor(props) {
    super(props);

    this.state = {
      featuredCourses: [],
      loading: true
    };
  }

  componentDidMount() {
    const client = new Api({});
    client
      .get<CoursesResource>("/api/featured_courses.json")
      .then((response: AxiosResponse<CoursesResource>) => {
        const { data } = response;
        this.setState({
          featuredCourses: data,
          loading: false
        });
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  }

  render() {
    const { showHeading } = this.props;

    const { featuredCourses, loading } = this.state;
    const categories = featuredCourses.map(course => course.categories);

    return (
      <div
        className="ui segment courses featured-courses"
        id="course-grid-popular-title"
      >
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        <div className="ui container">
          {showHeading && (
            <h1 className="ui header aligned course-header featured-course-header">
              <span className="title">Most popular</span>
            </h1>
          )}

          <div className="courses-container featured-courses-container ui inverted vertical segment center aligned">
            <div
              className="ui centered doubling stackable three column"
              id="courses_grid"
            >
              {featuredCourses.length > 0 && (
                <Carousel
                  noOfMobileSlides={2}
                  numberOfSlides={
                    featuredCourses.length < 3 ? featuredCourses.length : 3
                  }
                  arrows={true}
                  slidesToScroll={3}
                  dots={true}
                >
                  {featuredCourses.map((course, i) => (
                    <Course
                      courseResource={course}
                      idx={i}
                      key={course.id}
                      increaseHeight={categories.length > 0}
                      categories={course.categories}
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FeaturedCourses;
