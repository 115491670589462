import React from "react";

const How = () => (
  <div className="ui segment how-it-works">
    <h2>How it works</h2>

    <div className="ui four stackable cards">
      <div className="card">
        <div
          className="image red"
          style={{
            backgroundImage: `url(${require("../../../../../assets/images/teams/smart-people.png")})`
          }}
        />
        <div className="content">
          <a className="header">On demand for convenience</a>
          <div className="description">
            Learning designed to easily fit <br /> around your work day.
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="image yellow"
          style={{
            backgroundImage: `url(${require("../../../../../assets/images/teams/learning.png")})`
          }}
        />
        <div className="content">
          <a className="header">Gamified for enjoyment</a>
          <div className="description">
            Increased engagement and <br /> higher completion rates.
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="image red"
          style={{
            backgroundImage: `url(${require("../../../../../assets/images/teams/trackprogress.png")})`
          }}
        />
        <div className="content">
          <a className="header">Team tracking and analytics</a>
          <div className="description">
            Easily track learner progress and
            <br /> identify top talent.
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="image yellow"
          style={{
            backgroundImage: `url(${require("../../../../../assets/images/teams/reallife.png")})`
          }}
        />
        <div className="content">
          <a className="header">Marked by actual humans</a>
          <div className="description">
            Real-time personalised marking <br /> with feedback.
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default How;
