import React, { Component } from "react";
import { observer } from "mobx-react";
import { Message, Select } from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Field, FormikHelpers, FieldArray, ErrorMessage } from "formik";
import { required, email } from "../../common/FormValidations";
import CreateTeam from "./CreateTeam";
import GiftStore from "../../../stores/GiftStore";

interface Values {
  sender_name;
  sender_email: string;
  message: string;
  deliver_at: string;
  recipients: {
    name: string;
    email: string;
  }[];
  team_name: string;
  create_team: boolean;
}

interface Props {
  values: Values;
  touched: any;
  setFieldValue: any;
  gift: any;
  store: GiftStore;
  errors: {
    detail: string;
  }[];
  refreshQuote: () => void;
}

class GiftForm extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  setSelectAttribute = (e, { name, value }) => {
    const date = value ? moment.utc(value).format() : null;
    this.props.store.setSelectAttribute(name, date);
    this.props.store.setSelectAttribute("timezone", momentTimezone.tz.guess());
  };

  handleChange = (e, key) => {
    e.persist();

    this.props.setFieldValue(key, e.target.value);

    this.props.store.setAttributes(e, key);
  };

  handleCollectonChange = (e, key, attribute, idx) => {
    e.persist();

    this.props.setFieldValue(key, e.target.value);

    this.props.store.setCollectionAttributes(e.target.value, idx, attribute);
  };

  handleSendNowChange = e => {
    this.props.setFieldValue("deliver_at", e.target.value);

    this.props.store.resetAttribute("deliver_at");
  };

  removeRecipient = (e, index, remove) => {
    e.preventDefault();
    remove(index);
    this.props.store.recipients.splice(index, 1);
    this.props.refreshQuote();
  };

  addRecipient = (e, push) => {
    e.preventDefault();
    push({ name: "", email: "" });
    this.props.store.addRecipients();
    this.props.refreshQuote();
  };

  formatDate = date => {
    const timezone = momentTimezone.tz.guess();

    return momentTimezone(date)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm z");
  };

  render() {
    const {
      values,
      touched,
      store,
      errors,
      gift,
      setFieldValue,
      refreshQuote
    } = this.props;

    const currentDate = store.deliverAt
      ? this.formatDate(moment(store.deliverAt).format())
      : this.formatDate(moment().format());

    return (
      <div className="ui component gift-form">
        <div className="ui form">
          {errors.length > 0 && (
            <Message
              className="error-message-banner"
              error
              header="Error creating gift order"
              list={errors.map(error => error.detail)}
            />
          )}

          <div className="sender">
            <h4>Sender</h4>
            <div className="form-fields">
              <div className="field">
                <Field
                  id="sender_name"
                  name="sender_name"
                  placeholder="Your name*"
                  className=""
                  validate={required}
                  onChange={e => this.handleChange(e, "sender_name")}
                />
                <ErrorMessage name="sender_name">
                  {msg => <div className="error">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="field">
                <Field
                  id="sender_email"
                  name="sender_email"
                  placeholder="Your email*"
                  type="email"
                  className=""
                  validate={email}
                  onChange={e => this.handleChange(e, "sender_email")}
                />
                <ErrorMessage name="sender_email">
                  {msg => <div className="error">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div className="recipient">
            <h4>Recipients</h4>
            <FieldArray name="recipients">
              {({ insert, remove, push }) => (
                <div>
                  {values.recipients.length > 0 &&
                    values.recipients.map((recipient, index) => (
                      <div className="row" key={index}>
                        <div className="form-fields">
                          {values.recipients.length > 1 && (
                            <a
                              href="#"
                              type="button"
                              className="ui remove-recipient"
                              onClick={e =>
                                this.removeRecipient(e, index, remove)
                              }
                            >
                              <i className="close icon"></i>
                            </a>
                          )}
                          <div className="field">
                            <Field
                              name={`recipients.${index}.name`}
                              placeholder="Recipient name*"
                              type="text"
                              className=""
                              validate={required}
                              onChange={e =>
                                this.handleCollectonChange(
                                  e,
                                  `recipients.${index}.name`,
                                  "name",
                                  index
                                )
                              }
                            />
                            <ErrorMessage name={`recipients.${index}.name`}>
                              {msg => <div className="error">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="field" id="recipient-email">
                            <Field
                              name={`recipients.${index}.email`}
                              placeholder="Recipient email address*"
                              type="email"
                              className="second"
                              validate={email}
                              onChange={e =>
                                this.handleCollectonChange(
                                  e,
                                  `recipients.${index}.email`,
                                  "email",
                                  index
                                )
                              }
                            />
                            <ErrorMessage name={`recipients.${index}.email`}>
                              {msg => <div className="error">{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="add-recipient">
                    <a href="#" onClick={e => this.addRecipient(e, push)}>
                      <i className="plus circle icon"></i>
                      <span>Add another learner</span>
                    </a>
                  </div>
                </div>
              )}
            </FieldArray>
          </div>
          <div className="">
            <h4>Add a personal message (optional)</h4>
            <div className="form-fields">
              <Field
                id="message"
                name="message"
                placeholder={`e.g. Dear Einstein,
                I know you always love to learn new things,
                I found you this incredible new course so you can be even smarter!
                Enjoy and big love.
                Marie Curie x`}
                className=""
                component="textarea"
                onChange={e => this.handleChange(e, "message")}
              />
            </div>
          </div>
          <div className="deliver-at">
            <h4>When do you want this sent?</h4>
            <div className="grouped fields">
              <div className="field">
                <div className="ui radio checkbox">
                  <Field
                    type="radio"
                    name="deliver_at"
                    value="send_now"
                    className="field"
                    onChange={e => this.handleSendNowChange(e)}
                  />
                  <label>Send now</label>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="ui radio checkbox">
                <Field
                  type="radio"
                  name="deliver_at"
                  value="send_later"
                  className="field"
                />
                <label>Send later</label>
              </div>
              {values.deliver_at === "send_later" && (
                <div className="date">
                  <DateTimeInput
                    label={"Send at:"}
                    inlineLabel={true}
                    value={currentDate}
                    dateTimeFormat="MM-DD-YYYY HH:mm"
                    closable={true}
                    disableMinute={true}
                    minDate={moment().format("MM-DD-YYYY")}
                    iconPosition="left"
                    name="deliver_at"
                    onChange={this.setSelectAttribute}
                    closeOnMouseLeave={false}
                  />
                </div>
              )}
            </div>
          </div>
          {values.recipients.length > 1 && (
            <CreateTeam
              store={store}
              values={values}
              setFieldValue={setFieldValue}
              handleChange={this.handleChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default observer(GiftForm);
