import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../../utils/httpRequest";
import { AdminTeam } from "../../../stores/ProfileStore/Types";
import Teams from "./Teams";

interface TeamsModalProps {
  teams: AdminTeam[];
  showTeams: boolean;
  handleShowTeams: (e: any, val: boolean) => void;
}

interface Props {
  adminUser: boolean;
  qaUser: boolean;
  superUser: boolean;
}

interface State {
  teams: AdminTeam[];
  showTeams: boolean;
  loading: boolean;
}

const TeamsModal: React.FC<TeamsModalProps> = ({
  showTeams,
  handleShowTeams,
  teams
}) => {
  return (
    <Modal
      closeIcon
      size="tiny"
      open={showTeams}
      onClose={e => handleShowTeams(e, false)}
      className="teams-modal"
      style={{ textAlign: "center" }}
    >
      <Modal.Header>Teams</Modal.Header>
      <Modal.Content>
        <Teams teams={teams} />
      </Modal.Content>
    </Modal>
  );
};

class AdminLinks extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      teams: [],
      showTeams: false,
      loading: true
    };
  }

  componentDidMount() {
    return getRequest("/api/users/teams.json").then(
      (response: AxiosResponse<AdminTeam[]>) => {
        this.setState({ teams: response.data, loading: false });
      }
    );
  }

  handleShowTeams = (e, val) => {
    e.preventDefault();

    this.setState({ showTeams: val });
  };

  render() {
    const { adminUser, qaUser, superUser } = this.props;
    const { teams, showTeams, loading } = this.state;
    return (
      <div className="ui link list admin-links">
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        {adminUser && (
          <a className="item" href="/admin">
            <span>Admin</span>
            <i className="protect icon" />
          </a>
        )}
        {qaUser && (
          <a className="item" href="/qa" data-turbolinks={false}>
            <span>QA</span>
            <i className="doctor icon" />
          </a>
        )}
        {superUser && (
          <a className="item" href="/admin/impersonation" data-method="delete">
            <span>Authentic</span>
            <i className="spy icon" />
          </a>
        )}
        {teams?.length > 0 && teams?.length < 2 && (
          <a className="item" href={teams[0].link}>
            <span>{teams[0].name}</span>
            <i className="sidebar icon" />
          </a>
        )}
        {teams?.length > 1 && (
          <a className="item" onClick={e => this.handleShowTeams(e, true)}>
            <span>Teams</span>
            <i className="sidebar icon" />
          </a>
        )}
        {teams?.length > 1 && (
          <TeamsModal
            showTeams={showTeams}
            handleShowTeams={this.handleShowTeams}
            teams={teams}
          />
        )}
      </div>
    );
  }
}

export default AdminLinks;
