export interface Waiting {
  kind: "waiting";
}

export interface Ready {
  kind: "ready";
}

export interface Open {
  kind: "open";
}

export interface Close {
  kind: "close";
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const ready = (): Ready => {
  return {
    kind: "ready"
  };
};

export const open = (): Open => {
  return {
    kind: "open"
  };
};

export const close = (): Close => {
  return {
    kind: "close"
  };
};

export type ModalState = Waiting | Ready | Open | Close;
