import React from "react";
import { Brand } from "./Types";

interface Props {
  brand: Brand;
}

const Hero: React.FC<Props> = ({ brand }) => {
  return (
    <div className="ui segment hero">
      <img src={brand?.masthead_image_url} className="bg" alt="brand" />
      <div className="top">
        <h2
          className="ui header brand-title"
          style={{ color: brand.masthead_header_color }}
        >
          {brand?.masthead_header_text}
        </h2>
      </div>
      <div className="ui basic vertical segment description">
        <div className="ui very padded text container center aligned">
          <div
            style={{ color: brand?.description_color || "#000" }}
            dangerouslySetInnerHTML={{
              __html: brand?.formatted_description
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
