// feedReducer.ts
import {
  CommentState,
  CommentActionTypes,
  LOAD_COMMENTS,
  LOAD_COMMENTS_SUCCESS,
  LIKE_COMMENT,
  LOAD_COMMENTS_FAILURE,
  CREATE_COMMENT_SUCCESS,
  RESET_COMMENT_CREATED,
  REPLY_COMMENT_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_REPLY_SUCCESS,
} from "./types";

const initialState: CommentState = {
  comments: [],
  loading: false,
  created: false,
  error: "",
};

export const commentReducer = (
  state = initialState,
  action: CommentActionTypes,
): CommentState => {
  switch (action.type) {
    case LOAD_COMMENTS:
      return { ...state, loading: true };
    case LOAD_COMMENTS_SUCCESS:
      return { ...state, loading: false, comments: action.payload };
    case LIKE_COMMENT:
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment.id === action.commentId
            ? { ...comment, likes: comment.likes + 1 }
            : comment,
        ),
      };
    case LOAD_COMMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CREATE_COMMENT_SUCCESS:
    case REPLY_COMMENT_SUCCESS:
      return {
        ...state,
        created: true,
      };
    case RESET_COMMENT_CREATED:
      return { ...state, created: false };
    default:
      return state;
  }
};
