import React from "react";

const BillingArea = ({ quadernoBillingUrl }) => (
  <div
    className="ui segment subscription-info billing-area"
    style={{ paddingBottom: "0px !important" }}
  >
    <h4>Your billing info</h4>
    <p className="billing-area__invoice-link">
      <a href={quadernoBillingUrl} target="_blank">
        View invoices
      </a>
    </p>
  </div>
);

export default BillingArea;
