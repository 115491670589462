import React, { Component } from "react";
import { CSVReader } from "react-papaparse";
import { Dropdown } from "semantic-ui-react";
import { postRequest } from "../../../src/utils/httpRequest";
import { AxiosResponse } from "axios";
import { any } from "prop-types";

interface User {
  name: string;
  email: string;
}

interface Props {
  teams: {
    key: string;
    value: string;
    text: string;
    coupons: { key: string; value: string; text: string }[];
  }[];
}

interface State {
  importedUsers: User[];
  submitting: boolean;
  [x: string]: any;
  selectedTeam: string;
  selectedCoupon: number;
  logoUrl: string;
}

class EnrolUsers extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      emails: "",
      importedUsers: [],
      selectedTeam: "",
      logoUrl: "",
      selectedCoupon: null,
      submitting: false,
    };
  }

  fileExtension = (fileName: string) => {
    return fileName?.split(".")?.pop()?.toLowerCase();
  };

  isCSVFile = (fileName: string): boolean =>
    this.fileExtension(fileName) === "csv";

  private handleOnDrop = (data, file) => {
    if (this.isCSVFile(file.name)) {
      data.shift();
      const importedUsers = data.map((d) => {
        return {
          name: d.data[0],
          email: d.data[1],
        };
      });

      this.setState({ importedUsers });
    } else {
      this.setState({
        importError:
          "Looks like this is not a valid CSV file. Please make sure to save your file as a CSV",
      });
    }
  };

  handleTeamChange = (e, data) => {
    this.setState({ selectedTeam: data.value });
  };

  handleCouponChange = (e, data) => {
    this.setState({ selectedCoupon: data.value });
  };

  getCoupon = (teamSlug) => {
    const { teams } = this.props;

    const org = teams.find((team) => team.value === teamSlug);
    return org?.coupons || [];
  };

  handleLogoUrlChange = (e) => {
    this.setState({ logoUrl: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { importedUsers, selectedTeam, selectedCoupon, logoUrl } = this.state;

    if (importedUsers.length === 0) {
      alert("Please upload a csv file with your users.");
      return;
    }

    if (!selectedTeam) {
      alert("Please select a team.");
      return;
    }

    if (!selectedCoupon) {
      alert("Please select a coupon.");
      return;
    }

    this.setState({ submitting: true });

    postRequest("/admin/enrol_users.json", {
      users: importedUsers,
      team_slug: selectedTeam,
      coupon_id: selectedCoupon,
      logo_url: logoUrl,
    })
      .then((response: AxiosResponse<Response>) => {
        const { data } = response;
        this.setState({
          submitting: false,
          importedUsers: [],
          selectedTeam: "",
          selectedCoupon: null,
          logoUrl: "",
        });

        alert("Request submitted successfully.");
      })
      .catch((error) => {
        console.log(error);
        this.setState({ submitting: false });
      });
  };

  render() {
    const { teams } = this.props;

    const { importedUsers, selectedTeam, selectedCoupon, submitting } =
      this.state;

    return (
      <form className="ui form admin-uploader create-page">
        <div className="ui attached segment very padded shell-texture">
          <div className="ui buttons right floated">
            <a className="ui button" href="/admin/pages">
              Cancel
            </a>
            <div className="or"></div>
            <button
              className={`ui button primary ${submitting ? "loading" : ""}`}
              type="submit"
              onClick={this.handleSubmit}
            >
              <i className="ui icon check"></i>Save
            </button>
          </div>
          <h1 className="ui header">
            <i className="icon plus"></i>Enrol Users
          </h1>
        </div>
        <div className="ui attached secondary segment very padded">
          <div className="fields">
            <div className="field string required page_title eight wide">
              <label className="string" htmlFor="page_title">
                Upload File
              </label>

              <div className="field uploadButton">
                <div className="field file required import_members_csv_file">
                  <CSVReader
                    onDrop={this.handleOnDrop}
                    style={{}}
                    config={{
                      header: false,
                    }}
                    addRemoveButton
                  >
                    <span>Drop CSV file here or click to upload.</span>
                  </CSVReader>
                </div>
              </div>
              <br />
            </div>
            <div className="field string required page_title four wide">
              <label className="string" htmlFor="page_title">
                Select Team
              </label>
              <div className="field">
                <Dropdown
                  placeholder="Select Team"
                  id="select-country-dropdown"
                  fluid
                  search
                  selection
                  options={teams}
                  value={selectedTeam}
                  onChange={this.handleTeamChange}
                />
              </div>
            </div>
            <div className="field string required page_title four  wide">
              <label className="string" htmlFor="page_title">
                Select Coupon
              </label>
              <div className="field">
                <Dropdown
                  placeholder="Select Coupon"
                  id="select-country-dropdown"
                  fluid
                  search
                  selection
                  options={this.getCoupon(selectedTeam)}
                  value={selectedCoupon}
                  onChange={this.handleCouponChange}
                />
              </div>
            </div>
          </div>
          <div className="fields">
            <div className="field string page_title ten wide">
              <label className="string" htmlFor="page_title">
                Enter Logo Url
              </label>
              <div className="field">
                <input
                  type="text"
                  name="logo_url"
                  id="logo_url"
                  placeholder="Enter Logo Url"
                  onChange={this.handleLogoUrlChange}
                />
              </div>
            </div>
          </div>
          {importedUsers.length > 0 && (
            <div className="sample_csv">
              <table className="ui celled table">
                <thead>
                  <tr>
                    <th colSpan={2}>Users</th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {importedUsers.map((user, i) => (
                    <tr key={i}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default EnrolUsers;
