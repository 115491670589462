import React, { useState } from "react";
import { Modal, ModalHeader, ModalContent, Dropdown } from "semantic-ui-react";
import { postRequest } from "../../../../utils/httpRequest";

interface Props {
  showModal: boolean;
  hideModal: (e: any) => void;
  users: any[];
  courses: any[];
  organization_id: string;
}

const AllocateCoursesModal: React.FC<Props> = ({
  showModal,
  hideModal,
  users,
  courses,
  organization_id,
}) => {
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const handleSelectCourses = (e, data) => {
    setSelectedCourses(data.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedCourses.length === 0) {
      alert("Please select at least one course");
      return;
    }

    setSubmitting(true);
    const params = {
      user_ids: users,
      course_ids: selectedCourses,
      organization_id,
    };
    postRequest(`/api/teams/${organization_id}/allocate_courses.json`, params)
      .then(() => {
        setSubmitting(false);
        setSuccessMessage(true);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const onCloseModal = (e) => {
    setSelectedCourses([]);
    setSuccessMessage(false);
    hideModal(e);
  };

  return (
    <Modal
      closeIcon
      open={showModal}
      onClose={(e) => onCloseModal(e)}
      size="small"
      className="allocate-courses-modal"
    >
      <ModalHeader>Allocate courses</ModalHeader>
      <ModalContent>
        {successMessage ? (
          <div>
            <p>Bravo on allocating training 🍾</p>
            <p>
              Marvellous emails will be sent out in the next few hours, so they
              can start getting smarter very shortly.
            </p>{" "}
            <p>
              Have a fantastic day and if you need anything, just email us at{" "}
              <a href="mailto:groundcontrol@42courses.com">
                groundcontrol@42courses.com
              </a>
            </p>
          </div>
        ) : (
          <div>
            <h1>Almost There!</h1>
            <div className="ui form">
              <div className="field" style={{ padding: "0 2em" }}>
                <p>
                  Select the courses you would like to allocate to the selected
                  users
                </p>
                <Dropdown
                  className="ui form-control"
                  placeholder="Select Courses"
                  fluid
                  multiple
                  search
                  selection
                  options={courses}
                  onChange={(e, data) => handleSelectCourses(e, data)}
                />
              </div>

              <div className="ui actions">
                <button
                  className="ui right button cancel"
                  onClick={(e) => onCloseModal(e)}
                >
                  Cancel
                </button>
                <button
                  className={`ui right button primary save ${
                    submitting ? "loading" : ""
                  }`}
                  onClick={(e) => onSubmit(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AllocateCoursesModal;
