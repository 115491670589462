import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Popup, PopupContent } from "semantic-ui-react";
import Comments from "./Comments";
import LinkifyText from "./LinkifyText";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";
import {
  LikeIcon,
  CommentIcon,
  EmptyCommentIcon,
  UnLikeIcon,
} from "../../Icon";
import {
  likePost,
  unlikePost,
} from "../../../../stores/CommunityStore/posts/actions/likePost";
import { deletePost } from "../../../../stores/CommunityStore/posts/actions/deletePost";
import { pinPost } from "../../../../stores/CommunityStore/posts/actions/pinPost";
import { featurePost } from "../../../../stores/CommunityStore/trendingPosts/actions/featurePost";
import { Post } from "../../../../stores/CommunityStore/posts/types";

interface Props {
  currentUser: CurrentUser;
  post: Post;
  featuredCommentId?: string;
  featuredReplyCommentId?: string;
  postUsers: string[];
}

const Feed: React.FC<Props> = ({
  currentUser,
  post,
  postUsers,
  featuredCommentId,
  featuredReplyCommentId,
}) => {
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    if (featuredCommentId || featuredReplyCommentId) {
      setShowComments(true);
    }
  }, [featuredCommentId]);

  const toggleShowComments = (e) => {
    e.preventDefault();

    setShowComments(!showComments);
  };

  const onClickLikePost = (e) => {
    e.preventDefault();
    dispatch(
      likePost({
        postId: post.id,
      }),
    );
  };

  const onClickUnLikePost = (e) => {
    e.preventDefault();

    dispatch(
      unlikePost({
        postId: post.id,
      }),
    );
  };

  const onClickPinPost = (e) => {
    e.preventDefault();

    dispatch(
      pinPost({
        postId: post.id,
        pinned: true,
      }),
    );
  };

  const onClickUnPinPost = (e) => {
    e.preventDefault();

    dispatch(
      pinPost({
        postId: post.id,
        pinned: false,
      }),
    );
  };

  const onClickFeaturePost = (e) => {
    e.preventDefault();

    dispatch(
      featurePost({
        postId: post.id,
        trending: true,
      }),
    );
  };

  const onClickUnFeaturePost = (e) => {
    e.preventDefault();

    dispatch(
      featurePost({
        postId: post.id,
        trending: false,
      }),
    );
  };

  const onClickDeletePost = (e) => {
    e.preventDefault();

    let text = "Are you sure you want to delete this post?";

    if (confirm(text) == true) {
      dispatch(
        deletePost({
          postId: post.id,
        }),
      );
    } else {
      return;
    }
  };

  return (
    <div className="ui community__feed">
      <div className="community__feed-profile">
        <img className="" src={post?.user?.picture_url} alt="profile picture" />
        <span>
          <strong>{post?.user?.name}</strong>
        </span>
        {currentUser.admin && post.pinned && (
          <span>
            <i className="ui icon pin" />
          </span>
        )}
        {currentUser.admin && post.trending && (
          <span>
            <i className="ui icon bookmark icon" />
          </span>
        )}
        {(currentUser.id === post?.user?.id || currentUser.admin) && (
          <Popup
            className="community__feed-popup"
            position="left center"
            trigger={
              <span style={{ float: "right" }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  ...
                </a>
              </span>
            }
            on="click"
            hideOnScroll
          >
            <PopupContent>
              {currentUser.admin && (
                <>
                  {post.pinned ? (
                    <button
                      className="ui button"
                      onClick={(e) => onClickUnPinPost(e)}
                    >
                      <i className="ui icon pin" />
                      <span>Unpin</span>
                    </button>
                  ) : (
                    <button
                      className="ui button"
                      onClick={(e) => onClickPinPost(e)}
                    >
                      <i className="ui icon pin" />
                      <span>Pin</span>
                    </button>
                  )}
                </>
              )}
              {currentUser.admin && (
                <>
                  {post.trending ? (
                    <button
                      className="ui button"
                      onClick={(e) => onClickUnFeaturePost(e)}
                    >
                      <i className="ui icon bookmark icon" />
                      <span>Unfeature</span>
                    </button>
                  ) : (
                    <button
                      className="ui button"
                      onClick={(e) => onClickFeaturePost(e)}
                    >
                      <i className="ui icon bookmark icon" />
                      <span>Feature</span>
                    </button>
                  )}
                </>
              )}
              {(currentUser.id === post?.user?.id || currentUser.admin) && (
                <button
                  className="ui button"
                  onClick={(e) => onClickDeletePost(e)}
                >
                  <i className="ui icon trash red alternate outlineDelete" />
                  <span style={{ color: "red" }}>Delete</span>
                </button>
              )}
            </PopupContent>
          </Popup>
        )}
      </div>
      <div className="community__feed-post">
        <p>
          <LinkifyText text={post.formatted_content} /> <br />
          {post.metadata && post.metadata?.image && (
            <div
              className="link-preview"
              style={{
                width: "100%",
                height: "500px",
                borderRadius: "25px",
                backgroundPosition: "center",
                backgroundImage: `url(${post.metadata.image})`,
              }}
            ></div>
          )}
        </p>

        <div className="community__feed-reactions">
          {post.liked_by_current_user ? (
            <a href="#" onClick={(e) => onClickUnLikePost(e)}>
              <span className="community__feed-reactions-like liked-by-current-user">
                <UnLikeIcon />
                <span>{post.likes}</span>
              </span>
            </a>
          ) : (
            <a href="#" onClick={(e) => onClickLikePost(e)}>
              <span className="community__feed-reactions-like">
                <LikeIcon />
                <span>{post.likes}</span>
              </span>
            </a>
          )}
          <a href="#" onClick={(e) => toggleShowComments(e)}>
            <span className="community__feed-reactions-comment">
              {post?.comments?.length === 0 ? (
                <EmptyCommentIcon />
              ) : (
                <CommentIcon />
              )}
              <span>{post?.comments?.length}</span>
            </span>
          </a>
        </div>
      </div>
      {showComments && (
        <Comments
          currentUser={currentUser}
          post={post}
          postUsers={postUsers}
          featuredCommentId={featuredCommentId}
          featuredReplyCommentId={featuredReplyCommentId}
        />
      )}
    </div>
  );
};

export default Feed;
