import React from "react";
import { Priceable, PaymentType } from "../../Types";
import { Quotation } from "../../../../stores/CoursePreviewStore/Types";
import Coupon from "./Coupon";

interface Props {
  isCourseError: boolean;
  course: string;
  priceable: Priceable;
  activeQuote: Quotation;
  paymentType: PaymentType;
  couponCode: string;
  refCode: string;
  handleShowCouponModal: (e: any, val: boolean) => void;
  refreshQuoteWithoutCoupon: (e: any) => void;
}

const Course: React.FC<Props> = ({
  isCourseError = false,
  course,
  priceable,
  activeQuote,
  couponCode,
  refCode,
  paymentType,
  handleShowCouponModal,
  refreshQuoteWithoutCoupon
}) => {
  const placeholderError = isCourseError ? "placeholder error" : "placeholder";

  return (
    <div className="ui card">
      <div className="content">
        <div className="content-wrapper">
          {(course && priceable) || (paymentType === "gift" && priceable) ? (
            <div className="info">
              <div
                className="ui small image"
                style={{
                  backgroundImage: `url(${priceable.checkout_image})`,
                  height: couponCode || refCode ? "auto" : "100px"
                }}
              ></div>
              <div className="plan-info">
                <div className="title">
                  <strong>{priceable?.name}</strong>
                </div>
                <div className="description">{priceable.brand_name}</div>
                {(couponCode.length > 0 || refCode.length > 0) && (
                  <div className="coupon-code">
                    <div className="ui label">
                      <i className="tag icon"></i>
                      {couponCode || refCode}
                      <a onClick={e => refreshQuoteWithoutCoupon(e)}>
                        <i className="delete icon"></i>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={placeholderError}>
              <div className="title">
                <strong>No course selected</strong>
              </div>
              <div className="description">Please select a course!</div>
            </div>
          )}
        </div>
        <Coupon
          couponCode={couponCode}
          refCode={refCode}
          activeQuote={activeQuote}
          paymentType={paymentType}
          handleShowCouponModal={handleShowCouponModal}
          refreshQuoteWithoutCoupon={refreshQuoteWithoutCoupon}
        />
      </div>

      <div className="extra content">
        <>
          {(couponCode.length > 0 || refCode.length > 0) && (
            <div className="price month discount">
              <div className="name">
                <strong>Discount:</strong>
              </div>
              <div className="discount-info">
                <span className="minus">-</span>{" "}
                <span className="symbol">{activeQuote?.currency}</span>
                <span className="amount">
                  <strong>{activeQuote?.discount_amount}</strong>
                </span>{" "}
                <span className="coupon-name">({couponCode || refCode})</span>
              </div>
            </div>
          )}

          <div className="price month total">
            <div className="payment-due">
              <strong>Total:</strong>
            </div>
            <div className="payment-info">
              <span className="symbol">{activeQuote?.currency}</span>
              <span className="amount">
                <strong>{activeQuote?.formatted_amount}</strong>
              </span>
              <span className="code">{activeQuote?.currency_code}</span>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Course;
