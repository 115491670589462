export const getOrdinalRank = (rank) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = rank % 100;

  if (remainder >= 11 && remainder <= 13) {
    return `${rank}th`;
  }

  return `${rank}${suffixes[rank % 10] || "th"}`;
};
