import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { ResourcesActiveIcon, LivesActiveIcon } from "../Learning/Icons";
import { QuestionPreview } from "../../../stores/CoursePreviewStore/Types";
import { Enrolment } from "../../../stores/EnrolmentStore/Types";

interface Props {
  handleShowSidebar: () => void;
  lesson: QuestionPreview;
  enrolment: Enrolment;
  handleShowAiChat: (e, val) => void;
}

const Navbar: React.FC<Props> = ({
  enrolment,
  lesson,
  handleShowSidebar,
  handleShowAiChat,
}) => {
  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        $("#enrolment_navbar").css({ display: "flex" });
      }

      setY(window.scrollY);
    },
    [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const percentageCompleted = enrolment?.percentage_completed || 0;
  const navTitle = () => {
    if (["passed", "failed"].includes(enrolment?.state)) {
      return "Completed";
    } else {
      return (
        <>
          Chapter {(lesson?.topic?.position || 0) + 1} / Lesson{" "}
          {(lesson?.position || 0) + 1}
        </>
      );
    }
  };

  return (
    <div className="ui menu dimmable" id="enrolment_navbar">
      <div className="left menu large only answer-tour">
        <div className="ui column mobile">
          <div className="enrolment_navbar--current-chapter">
            <div className="progress">
              <PieChart
                data={[
                  { value: percentageCompleted, color: "#fff" },
                  {
                    label: "",
                    value: 100 - percentageCompleted,
                    isEmpty: true,
                    color: "#625768",
                  },
                ]}
                totalValue={100}
                lineWidth={20}
                label={() => `${percentageCompleted}%`}
                labelStyle={{
                  fontSize: "25px",
                  fill: "#ffffff",
                }}
                labelPosition={0}
                startAngle={270}
              />
              <div className="next">
                <Link to="#" onClick={() => handleShowSidebar()}>
                  <p className="lesson">
                    Chap {(lesson?.topic?.position || 0) + 1} / Lesson{" "}
                    {(lesson?.position || 0) + 1}
                    <span>
                      <i className="dropdown icon" />
                    </span>
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="ui column desktop">
          <div className="enrolment_navbar--current-chapter">
            <div className="progress">
              <PieChart
                data={[
                  { value: percentageCompleted, color: "#fff" },
                  {
                    label: "",
                    value: 100 - percentageCompleted,
                    isEmpty: true,
                    color: "#625768",
                  },
                ]}
                totalValue={100}
                lineWidth={20}
                label={() => `${percentageCompleted}%`}
                labelStyle={{
                  fontSize: "25px",
                  fill: "#ffffff",
                }}
                labelPosition={0}
                startAngle={270}
              />
              <div className="next">
                <Link to="#" onClick={() => handleShowSidebar()}>
                  <p className="lesson">
                    {navTitle()}
                    <span>
                      <i className="dropdown icon" />
                    </span>
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right menu large only mobile">
        <div className="ui two column middle aligned buttons">
          <div className="column resources">
            <a
              className="ui traditional button fluid item"
              href={`/enrolments/${enrolment?.id}/resources`}
            >
              <ResourcesActiveIcon className="resources-svg" />
            </a>
          </div>
          <div className="column lives">
            <a
              className="ui traditional button fluid item"
              href={`/enrolments/${enrolment?.id}/lives`}
            >
              <LivesActiveIcon className="lives-svg" />
              <span>{enrolment?.lives_count}</span>
            </a>
          </div>
          <div className="column lives chat">
            <button
              className="ui traditional button fluid item"
              onClick={(e) => handleShowAiChat(e, true)}
            >
              <span>✨AI</span>
            </button>
          </div>
        </div>
      </div>
      <div className="right menu large only desktop">
        <div className="ui three column middle aligned buttons">
          <div className="column">
            <a
              className="ui traditional button fluid item"
              href={`/enrolments/${enrolment?.id}/resources`}
              target="_blank"
            >
              <ResourcesActiveIcon className="resources-svg" />
              <span>Resources</span>
            </a>
          </div>
          <div className="column lives">
            <a
              className="ui traditional button fluid item"
              href={`/enrolments/${enrolment?.id}/lives`}
              target="_blank"
            >
              <LivesActiveIcon className="lives-svg" />
              <span>{enrolment?.lives_count} Lives</span>
            </a>
          </div>
          <div className="column lives chat">
            <button
              className="ui traditional button fluid item"
              onClick={(e) => handleShowAiChat(e, true)}
            >
              <span>✨AI</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
