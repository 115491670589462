import React from "react";
import { Sidebar, Menu, Progress, Popup } from "semantic-ui-react";
import Topics from "./Topics";
import TrendingPosts from "./TrendingPosts";
import { Topic } from "../../../../stores/CommunityStore/topics/types";
import { Post } from "../../../../stores/CommunityStore/posts/types";

interface Props {
  showSidebar: boolean;
  handleHideSidebar: (e: any, value: boolean) => void;
  topics: Topic[];
  trendingPosts: Post[];
}

const MobileSidebar: React.FC<Props> = ({
  showSidebar,
  handleHideSidebar,
  topics,
  trendingPosts,
}) => {
  return (
    <Sidebar
      className="community__sidebar-mobile lesson-sidebar"
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      onHide={(e) => handleHideSidebar(e, false)}
      vertical
      visible={showSidebar}
      width="thin"
    >
      <div className="">
        <div className="header menu">
          <div className="close">
            <a
              className="ui left button"
              id="closeNav"
              onClick={(e) => handleHideSidebar(e, false)}
            >
              <i className="close icon"></i>
            </a>
          </div>
        </div>
        <div className="">
          <Topics
            topics={topics}
            handleHideSidebar={handleHideSidebar}
            isMobile={true}
          />
          <TrendingPosts
            trendingPosts={trendingPosts}
            handleHideSidebar={handleHideSidebar}
            isMobile={true}
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default MobileSidebar;
