import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AxiosResponse } from "axios";
import qs from "qs";
import Choice from "./Choice";
import FileAction from "./FileAction";
import Opinion from "./Opinion";
import Poll from "./Poll";
import TextAction from "./TextAction";
import AnswerStateModal from "./AnswerStateModal";
import { QuestionPreview } from "../../../../stores/CoursePreviewStore/Types";
import { Answer, Enrolment } from "../../../../stores/EnrolmentStore/Types";
import { getRequest, postRequest } from "../../../../utils/httpRequest";
import { errorNotification } from "../../../../utils/helpers";
import { Button, Confirm } from "semantic-ui-react";
import { CommentResource } from "../../../../stores/CommentStore/Types";

interface Props {
  mode: string;
  enrolment: Enrolment;
  question: QuestionPreview;
  answer: Answer;
  page: string;
  loadingAnswer: boolean;
  refreshAnswers: () => void;
  getRecentNotifications: () => void;
  getXp: () => void;
  currentUser: any;
  search?: string;
}

interface State {
  selectedAnswer: any;
  submitting: boolean;
  resetting: boolean;
  answerState: string;
  showModal: boolean;
  confirmReset: boolean;
  redirectUrl: string;
  imageUrl: string;
  enrolment: Enrolment;
  answerStateTagline: string;
  pollAnswerOptions: any;
  pollAnswerOptionsWithPercent: any;
  comments: CommentResource[];
}

const passedTaglines = [
  "BRAVO!",
  "MARVELLOUS!",
  "KEEP IT UP",
  "JOLLY GOOD",
  "WONDERFUL",
  "FANTASTIC",
  "KEEP GOING",
  "KEEP UP THE GOOD WORK",
  "YOU GOT THIS",
  "NICE ONE",
  "SUPERB",
  "REMARKABLE"
];

const failedTaglines = [
  "OOPS!",
  "SO CLOSE!",
  "WHOOPSIE DAISY",
  "D'OH!",
  "TRY AGAIN",
  "HOUSTON, WE HAVE A PROBLEM!",
  "KEEP CALM AND CARRY ON"
];

class Assignment extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      redirectUrl: "",
      selectedAnswer: null,
      submitting: false,
      resetting: false,
      answerState: "",
      showModal: false,
      confirmReset: false,
      imageUrl: "",
      enrolment: props.enrolment,
      answerStateTagline: "",
      pollAnswerOptions: props.question?.answer_options,
      pollAnswerOptionsWithPercent:
        props.question?.answer_options_with_vote_percentages,
      comments: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getComments();
  }

  getComments = () => {
    const { answer, mode } = this.props;

    if (mode === "Opinion") {
      getRequest(`/api/comments.json?answer_id=${answer?.id}`)
        .then((response: AxiosResponse<CommentResource[]>) => {
          this.setState({ comments: response.data }, () =>
            this.scrollToComment()
          );
        })
        .catch(error => {
          (window as any).honeybadger?.notify(error?.response?.data?.error);
        });
    }
  };

  scrollToComment = () => {
    const { search } = this.props;

    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

    if (queryParams?.scroll_to) {
      document
        .getElementById(`${queryParams?.scroll_to}`)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  updateSelectedAnswer = selectedAnswer => this.setState({ selectedAnswer });

  answerStateTagline = () => {
    const { answerState } = this.state;

    if (!answerState) {
      return "";
    }

    switch (answerState) {
      case "pending":
        return "Please vote";
      case "submitted":
        return "We'll mark this for you now";
      case "passed":
        return passedTaglines[
          Math.floor(Math.random() * passedTaglines.length)
        ];
      case "failed":
        return failedTaglines[
          Math.floor(Math.random() * failedTaglines.length)
        ];
      default:
        return "Something went wrong";
    }
  };

  imageUrl = () => {
    const { answerState } = this.state;

    const { answer } = this.props;

    if (!answerState) {
      return "";
    }

    let base_url =
      "https://42courses-course-material.s3-us-west-1.amazonaws.com/answers/giphys";
    let flavors = answerState == "passed" ? this.range(70) : this.range(45);
    let flavor = answer
      ? answer.id % flavors.length
      : Math.floor(Math.random() * flavors.length);

    switch (answerState) {
      case "pending":
      case "submitted":
        return `${base_url}/team-pending.png`;
      case "passed":
        return `${base_url}/pass/passed-${flavor}.gif`;
      case "failed":
        return `${base_url}/fail/failed-${flavor}.gif`;
      default:
        return "Something went wrong";
    }
  };

  resetAnswer = e => {
    e.preventDefault();
    this.setState({ resetting: true, confirmReset: false });
    const {
      question,
      enrolment,
      answer,
      mode,
      page,
      refreshAnswers,
      getRecentNotifications,
      currentUser,
      search
    } = this.props;

    return postRequest(
      `/api/enrolments/${enrolment?.id}/questions/${question?.id}/answer/reset`,
      {}
    )
      .then((response: AxiosResponse) => {
        this.setState({
          resetting: false,
          selectedAnswer: null,
          answerState: "",
          answerStateTagline: "",
          imageUrl: ""
        });
        refreshAnswers();
        getRecentNotifications();

        if (page === "answerUpvotes" && mode === "Opinion") {
          window.location.href = `/enrolments/${enrolment.id}/questions/${question.id}`;
        }
      })
      .catch(error => {
        this.setState({ submitting: false });
        getRecentNotifications();
        errorNotification(
          "Error resetting answer. Please try again",
          JSON.stringify(error.response.data)
        );
      });
  };

  submitAnswer = e => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { selectedAnswer } = this.state;
    const {
      mode,
      question,
      enrolment,
      refreshAnswers,
      getRecentNotifications,
      getXp
    } = this.props;
    const answerData = () => {
      if (["Poll", "Choice"].includes(mode)) {
        return { answer_option_id: selectedAnswer };
      } else if (mode == "FileAction") {
        return { content_url: selectedAnswer };
      } else if (mode == "Opinion" && question.uploader_type) {
        return { content_url: selectedAnswer };
      } else {
        return { content: selectedAnswer };
      }
    };

    return postRequest(
      `/api/answers.json?enrolment_id=${enrolment?.id}&question_id=${question?.id}`,
      answerData()
    )
      .then((response: AxiosResponse) => {
        if (mode === "Opinion") {
          this.setState({
            redirectUrl: `/enrolments/${enrolment?.id}/questions/${question.id}/answers/${response.data.id}/votes`
          });
        } else if (mode === "Poll") {
          refreshAnswers();
          getRequest(
            `/api/courses/${enrolment?.course?.slug}/questions/${question.id}.json`
          )
            .then((questionRes: AxiosResponse) => {
              this.setState({
                pollAnswerOptions: questionRes.data.answer_options,
                pollAnswerOptionsWithPercent:
                  questionRes.data.answer_options_with_vote_percentages,
                submitting: false,
                answerState: response.data.state,
                enrolment: response.data.enrolment
              });

              if (
                ["passed", "failed"].includes(response.data.enrolment.state)
              ) {
                this.setState({
                  answerStateTagline: this.answerStateTagline(),
                  imageUrl: this.imageUrl(),
                  showModal: true
                });
              }
            })
            .catch(error => {
              (window as any).honeybadger?.notify(error?.response?.data?.error);
            });
        } else {
          this.setState(
            {
              submitting: false,
              selectedAnswer: null,
              answerState: response.data.state,
              enrolment: response.data.enrolment
            },
            () => {
              this.setState({
                answerStateTagline: this.answerStateTagline(),
                imageUrl: this.imageUrl(),
                showModal: true
              });
            }
          );
          refreshAnswers();
          getRecentNotifications();
          getXp();
        }
      })
      .catch(error => {
        this.setState({ submitting: false });
        getRecentNotifications();
        errorNotification(
          "Error submitting answer. Please try again",
          JSON.stringify(error?.response?.data)
        );
      });
  };

  handleHideAnswerStateModal = e => {
    this.setState({ showModal: false });
  };

  range = max => {
    return Array.from({ length: max }, (v, k) => k + 1);
  };

  toggleConfirmReset = e => {
    e.preventDefault();
    this.setState({ confirmReset: !this.state.confirmReset });
  };

  render() {
    const {
      mode,
      question,
      answer,
      loadingAnswer,
      currentUser,
      search
    } = this.props;

    const {
      selectedAnswer,
      submitting,
      showModal,
      answerState,
      redirectUrl,
      answerStateTagline,
      imageUrl,
      pollAnswerOptions,
      pollAnswerOptionsWithPercent,
      confirmReset,
      comments,
      enrolment
    } = this.state;

    return (
      <div className="current-lesson-assignment">
        {answer && enrolment && enrolment?.super_user && (
          <>
            <Button
              circular
              floated="right"
              icon="undo"
              className="reset"
              onClick={this.toggleConfirmReset}
            />
            <Confirm
              content="Reset this answer for realsies?"
              open={confirmReset}
              cancelButton="Never mind"
              confirmButton="Let's do it"
              onCancel={this.toggleConfirmReset}
              onConfirm={this.resetAnswer}
              size="mini"
            />
          </>
        )}
        <h3>Question</h3>

        {question && (
          <>
            {redirectUrl && <Redirect to={{ pathname: redirectUrl }} />}
            {mode === "Choice" && (
              <Choice
                question={question}
                answer={answer}
                selectedAnswer={selectedAnswer}
                submitting={submitting}
                loadingAnswer={loadingAnswer}
                submitAnswer={this.submitAnswer}
                updateSelectedAnswer={this.updateSelectedAnswer}
              />
            )}
            {mode === "FileAction" && (
              <FileAction
                question={question}
                answer={answer}
                submitAnswer={this.submitAnswer}
                selectedAnswer={selectedAnswer}
                submitting={submitting}
                loadingAnswer={loadingAnswer}
                updateSelectedAnswer={this.updateSelectedAnswer}
                mode={mode}
                comments={comments}
                currentUser={currentUser}
                search={search}
                enrolmentId={enrolment?.id}
              />
            )}
            {mode === "Opinion" && (
              <Opinion
                enrolmentId={enrolment?.id}
                question={question}
                answer={answer}
                selectedAnswer={selectedAnswer}
                submitting={submitting}
                loadingAnswer={loadingAnswer}
                mode={mode}
                comments={comments}
                search={search}
                currentUser={currentUser}
                submitAnswer={this.submitAnswer}
                updateSelectedAnswer={this.updateSelectedAnswer}
              />
            )}
            {mode === "Poll" && (
              <Poll
                question={question}
                answer={answer}
                selectedAnswer={selectedAnswer}
                submitAnswer={this.submitAnswer}
                loadingAnswer={loadingAnswer}
                updateSelectedAnswer={this.updateSelectedAnswer}
                submitting={submitting}
                pollAnswerOptions={pollAnswerOptions}
                pollAnswerOptionsWithPercent={pollAnswerOptionsWithPercent}
              />
            )}
            {mode === "TextAction" && (
              <TextAction
                question={question}
                answer={answer}
                selectedAnswer={selectedAnswer}
                submitting={submitting}
                loadingAnswer={loadingAnswer}
                submitAnswer={this.submitAnswer}
                updateSelectedAnswer={this.updateSelectedAnswer}
              />
            )}
          </>
        )}
        {enrolment && (
          <AnswerStateModal
            answerId={answer?.id}
            enrolment={enrolment}
            showModal={showModal}
            answerState={answerState}
            lesson={question}
            answerStateTagline={answerStateTagline}
            imageUrl={imageUrl}
            handleHideAnswerStateModal={this.handleHideAnswerStateModal}
          />
        )}
      </div>
    );
  }
}

export default Assignment;
