import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentUser } from "../../../../../../stores/CurrentUserStore/Types";
import { createComment } from "../../../../../../stores/CommunityStore/comments/actions/createComment";
import { AppState } from "../../../../../../stores/CommunityStore";
import MentionInput from "./MentionInput";

interface Props {
  currentUser: CurrentUser;
  commentId: number;
  postId: number;
  postUsers: string[];
  setShowReplies: (value: boolean) => void;
}

const ReplyCommentForm: React.FC<Props> = ({
  currentUser,
  commentId,
  postId,
  postUsers,
  setShowReplies,
}) => {
  const dispatch = useDispatch();
  const created = useSelector((state: AppState) => state.comments.created);

  const [text, setText] = useState("");
  const [formattedText, setFormattedText] = useState(""); // State for formatted text
  const [rows, setRows] = useState(1);

  useEffect(() => {
    if (created) {
      setText("");
      dispatch({ type: "RESET_COMMENT_CREATED" });
    }
  }, [commentId, created]);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      createComment({
        postId,
        commentId,
        content: formattedText || text,
      }),
    );
    setShowReplies(true);
  };

  return (
    <div className="ui community__add-comment community__add-reply">
      <div className="ui form">
        <div className="field">
          <div className="profile">
            <img
              className="ui avatar image"
              src={currentUser.profile_picture}
              alt="profile picture"
            />
          </div>
          <MentionInput
            users={postUsers}
            value={text}
            rows={rows}
            setText={setText}
            setRows={setRows}
            setFormattedText={setFormattedText}
          />
        </div>
        <div className="actions">
          <button className="ui button" onClick={(e) => onSubmit(e)}>
            Reply
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReplyCommentForm;
