import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { CourseResource } from "../../../../../stores/CoursesStore/Types";

interface Props {
  courseResource: CourseResource;
}

const Progress: React.FC<Props> = ({ courseResource }) => {
  if (courseResource.enrolled_student_present) {
    return (
      <>
        <div className="course-progress-completed">
          {/* @ts-ignore */}
          <div
            className={`course-progress-completed-copy label-completed-${courseResource.enrolment_percent_completion}`}
          >
            {/* @ts-ignore */}
            {ReactHtmlParser(courseResource.status_icon)}
            <p>{courseResource.enrolment_percent_completion}% completed</p>
          </div>
        </div>
        <div
          className={`course-progress-bar course-progress-bar-completed-${courseResource.enrolment_percent_completion}`}
        >
          <div
            className="course-progress-bar-completed"
            style={{
              width: courseResource.enrolment_percent_completion
            }}
          />
        </div>
      </>
    );
  }

  return (
    <div className="course-progress-completed">
      <div className="course-progress-completed-copy">
        {/* @ts-ignore */}
        {ReactHtmlParser(courseResource.status_icon)}
      </div>
    </div>
  );
};

export default Progress;
