import React from "react";
import { observer } from "mobx-react";

interface Props {}

const Hero: React.FC<Props> = () => (
  <div className="ui segment hero">
    <div className="section-one">
      <h2>Courses to unlock your creative potential</h2>
      <p>
        Feast your eyes on our range of courses designed to help you become a
        creative genius.
      </p>
    </div>
  </div>
);

export default observer(Hero);
