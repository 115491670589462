import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import SingleCourse from "./SingleCourse";
import MonthlyPricing from "./MonthlyPricing";
import YearlyPricing from "./YearlyPricing";
import LifetimePricing from "./LifetimePricing";
import { Pricing } from "../../../../stores/PricingStore/Types";

interface Props {
  handleShowSignInSideBar: (redirectUrl?: string) => void;
  pricing: Pricing;
  showSigninSidebar: boolean;
  courseSlug: string;
  singleCourseHeading: string;
}

class MediumMobile extends Component<Props, {}> {
  render() {
    const {
      handleShowSignInSideBar,
      pricing,
      showSigninSidebar,
      courseSlug,
      singleCourseHeading,
    } = this.props;

    return (
      <div className="medium-mobile-view">
        {pricing.is_restricted_pricing_disabled ? (
          <div className="ui column centered relaxed grid stackable doubling container">
            <YearlyPricing
              handleShowSignInSideBar={handleShowSignInSideBar}
              yearlyPricing={pricing.yearly_pricing}
              fee={pricing.fee}
              existingSubscription={pricing.existing_subscription_present}
              showSigninSidebar={showSigninSidebar}
            />
            <LifetimePricing
              handleShowSignInSideBar={handleShowSignInSideBar}
              lifetimePricing={pricing.lifetime_pricing}
              showSigninSidebar={showSigninSidebar}
              isRestrictedPricingDisabled={
                pricing.is_restricted_pricing_disabled
              }
            />
            <SingleCourse
              showTitle={false}
              courseSlug={courseSlug}
              handleShowSignInSideBar={handleShowSignInSideBar}
              singleCoursePricing={pricing.single_course_pricing}
              fee={pricing.fee}
              existingSubscription={pricing.existing_subscription_present}
              enrolment={pricing.enrolment_present}
              showSigninSidebar={showSigninSidebar}
              singleCourseHeading={singleCourseHeading}
            />
          </div>
        ) : (
          <div className="ui four column centered relaxed grid stackable doubling container">
            <SingleCourse
              showTitle={false}
              courseSlug={courseSlug}
              handleShowSignInSideBar={handleShowSignInSideBar}
              singleCoursePricing={pricing.single_course_pricing}
              fee={pricing.fee}
              existingSubscription={pricing.existing_subscription_present}
              enrolment={pricing.enrolment_present}
              showSigninSidebar={showSigninSidebar}
              singleCourseHeading={singleCourseHeading}
            />
            <MonthlyPricing
              handleShowSignInSideBar={handleShowSignInSideBar}
              monthlyPricing={pricing.monthly_pricing}
              showSigninSidebar={showSigninSidebar}
            />
            <YearlyPricing
              handleShowSignInSideBar={handleShowSignInSideBar}
              yearlyPricing={pricing.yearly_pricing}
              fee={pricing.fee}
              existingSubscription={pricing.existing_subscription_present}
              showSigninSidebar={showSigninSidebar}
            />
            <LifetimePricing
              handleShowSignInSideBar={handleShowSignInSideBar}
              lifetimePricing={pricing.lifetime_pricing}
              showSigninSidebar={showSigninSidebar}
              isRestrictedPricingDisabled={
                pricing.is_restricted_pricing_disabled
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default observer(MediumMobile);
