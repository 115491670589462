import React, { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { Modal, Transition } from "semantic-ui-react";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { RootContext } from "../../stores/RootStore/Types";
import { notification } from "../../utils/helpers";

interface State {
  showAnnouncement: boolean;
  content: string;
  backgroundColor: string;
  textColor: string;
  url: string;
  bannerId: string;
}

class Announcement extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      showAnnouncement: false,
      content: "",
      backgroundColor: "",
      textColor: "",
      url: "",
      bannerId: ""
    };
  }

  componentDidMount() {
    getRequest("/api/announcements.json")
      .then((response: AxiosResponse<any>) => {
        const { data } = response;

        this.setState({
          content: data.banner?.formatted_content || "",
          backgroundColor: data.banner?.background_color || "#392d40",
          textColor: data.banner?.text_color || "#fff",
          url: data.banner?.url,
          bannerId: data.banner_id
        });

        setTimeout(
          () =>
            this.setState({
              showAnnouncement: data.show_announcement
            }),
          3000
        );
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });

    const that = this;

    $(document).on("click", "#close", function(e) {
      that.closeAnnouncement(e);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showAnnouncement !== prevState.showAnnouncement) {
      if (this.state.showAnnouncement) {
        this.resize(80);
      }
    }
  }

  dynamicHeight = offset => {
    if ($(".new-navbar").css("display") == "block") {
      $("#landing").css("margin-top", $(".new-navbar").height() - offset);
    }

    if ($(".new-navbar-mobile").css("display") == "block") {
      $("#landing").css(
        "margin-top",
        $(".new-navbar-mobile").height() - offset
      );
    }

    $(".forgot-password-page").css("margin-top", $(".new-navbar").height());
  };

  resize = offset => {
    console.log("called");
    ($(document) as any).ready(this.dynamicHeight(offset));
    ($(window) as any).resize(this.dynamicHeight(offset));
  };

  closeAnnouncement = e => {
    e.preventDefault();

    postRequest("/api/announcements.json", { banner_id: this.state.bannerId })
      .then((response: AxiosResponse<any>) => {
        this.setState(
          {
            showAnnouncement: false
          },
          () => {
            this.resize(80);
          }
        );
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  };

  render() {
    const {
      showAnnouncement,
      backgroundColor,
      content,
      textColor,
      url
    } = this.state;

    return (
      <RootContext.Consumer>
        {({ loaded }) => {
          return (
            <div>
              <Transition.Group duration={400}>
                {showAnnouncement && (
                  <>
                    <a
                      href={url}
                      target="_blank"
                      className="ui item two inverted secondary menu mobile"
                      id="announcement"
                      style={{ background: backgroundColor }}
                    >
                      <div
                        className="menu item right labeled button"
                        id="close"
                        onClick={this.closeAnnouncement}
                      >
                        <i className="close icon" />
                      </div>
                      <div className="item menu center url">
                        <div
                          className="content"
                          style={{ color: textColor }}
                          dangerouslySetInnerHTML={{
                            __html: content
                          }}
                        ></div>
                      </div>
                    </a>
                    <a
                      href={url}
                      target="_blank"
                      className="ui inverted secondary menu desktop"
                      id="announcement"
                      style={{
                        background: backgroundColor
                      }}
                    >
                      <div
                        className="url"
                        style={{ background: backgroundColor }}
                      >
                        <div
                          className="content"
                          style={{ color: textColor }}
                          dangerouslySetInnerHTML={{
                            __html: content
                          }}
                        ></div>
                        <div
                          className="right labeled button"
                          id="close"
                          onClick={this.closeAnnouncement}
                        >
                          <i className="close icon" />
                        </div>
                      </div>
                    </a>
                  </>
                )}
              </Transition.Group>
            </div>
          );
        }}
      </RootContext.Consumer>
    );
  }
}

export default Announcement;
