import { observer } from "mobx-react";
import RootStore from ".";
import { RootState, RootPayloadResponse } from "./Types";
import { AxiosResponse, AxiosError } from "axios";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { Api } from "../../utils/Api";
import currencyStore from "../CurrencyStore";
import { CurrencyPayload } from "../CurrencyStore/Types";

interface State {
  state: RootState;
}

export interface Props extends BaseReactionProps<RootStore> {
  store: RootStore;
}

class RootReactions extends BaseReaction<RootStore, State, Props> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case "waiting":
      case "loading":
        const client = new Api({});
        client
          .get<RootPayloadResponse>("/api/root.json")
          .then((response: AxiosResponse<RootPayloadResponse>) => {
            const { data } = response;
            currencyStore.ready(data.currencies_payload);

            this.props.store.ready({
              blog_posts: data.blog_posts
            });
          })
          .catch((error: AxiosError) => {
            console.log(error.response?.statusText);
          });
      case "ready":
        break;
    }
  };
}

export default observer(RootReactions);
