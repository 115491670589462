import React, { Component, ReactNode, ReactNodeArray } from "react";
import Slider from "react-slick";

interface Props {
  className?: string;
  useButtons?: boolean;
  numberOfSlides?: number;
  noOfMobileSlides?: number;
  noOfMiddleSlides?: number;
  dots?: boolean;
  autoplay?: boolean;
  arrows?: boolean;
  opacity?: string;
  arrowFillCircleColor?: string;
  arrowFillArrowColor?: string;
  slidesToScroll?: number;
  children: ReactNode | ReactNodeArray;
}

interface ArrowProps {
  opacity?: string;
  arrowFillCircleColor?: string;
  arrowFillArrowColor?: string;
}

const PrevArrowSvg = ({
  opacity,
  arrowFillArrowColor,
  arrowFillCircleColor
}: ArrowProps) => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity={opacity || "0.5"}
      width="52"
      height="52"
      rx="26"
      fill={arrowFillCircleColor || "#EDEFF3"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.7071 19.2929C30.0976 19.6834 30.0976 20.3166 29.7071 20.7071L24.4142 26L29.7071 31.2929C30.0976 31.6834 30.0976 32.3166 29.7071 32.7071C29.3166 33.0976 28.6834 33.0976 28.2929 32.7071L22.2929 26.7071C21.9024 26.3166 21.9024 25.6834 22.2929 25.2929L28.2929 19.2929C28.6834 18.9024 29.3166 18.9024 29.7071 19.2929Z"
      fill={arrowFillArrowColor || "#392D40"}
    />
  </svg>
);

const NextArrowSvg = ({
  opacity,
  arrowFillArrowColor,
  arrowFillCircleColor
}: ArrowProps) => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity={opacity || "0.5"}
      width="52"
      height="52"
      rx="26"
      fill={arrowFillCircleColor || "#EDEFF3"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2929 19.2929C21.9024 19.6834 21.9024 20.3166 22.2929 20.7071L27.5858 26L22.2929 31.2929C21.9024 31.6834 21.9024 32.3166 22.2929 32.7071C22.6834 33.0976 23.3166 33.0976 23.7071 32.7071L29.7071 26.7071C30.0976 26.3166 30.0976 25.6834 29.7071 25.2929L23.7071 19.2929C23.3166 18.9024 22.6834 18.9024 22.2929 19.2929Z"
      fill={arrowFillArrowColor || "#392D40"}
    />
  </svg>
);

const PrevArrow = props => {
  const {
    className,
    style,
    onClick,
    opacity,
    arrowFillArrowColor,
    arrowFillCircleColor
  } = props;
  return props.useButtons ? (
    <button
      className={`${className} circular ui icon button`}
      onClick={onClick}
      style={{ ...style, display: "block" }}
    >
      <i className="icon angle left"></i>
    </button>
  ) : (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <PrevArrowSvg
        opacity={opacity}
        arrowFillArrowColor={arrowFillArrowColor}
        arrowFillCircleColor={arrowFillCircleColor}
      />
    </div>
  );
};

const NextArrow = props => {
  const {
    className,
    style,
    onClick,
    opacity,
    arrowFillArrowColor,
    arrowFillCircleColor
  } = props;
  return props.useButtons ? (
    <button
      className={`${className} circular ui icon button`}
      onClick={onClick}
      style={{ ...style, display: "block" }}
    >
      <i className="icon angle right"></i>
    </button>
  ) : (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <NextArrowSvg
        opacity={opacity}
        arrowFillArrowColor={arrowFillArrowColor}
        arrowFillCircleColor={arrowFillCircleColor}
      />
    </div>
  );
};

class Carousel extends Component<Props, {}> {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    (this as any).slider.slickNext();
  }

  previous() {
    (this as any).slider.slickPrev();
  }

  render() {
    const {
      numberOfSlides,
      className,
      useButtons,
      noOfMobileSlides,
      noOfMiddleSlides,
      dots,
      autoplay,
      arrows,
      opacity,
      arrowFillArrowColor,
      arrowFillCircleColor,
      slidesToScroll
    } = this.props;
    const settings = {
      dots: dots || false,
      autoplay: autoplay || false,
      arrows: arrows || true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: numberOfSlides || 3,
      slidesToScroll: slidesToScroll || 1,
      initialSlide: 0,
      className: `ui default-carousel ${className}`,
      nextArrow: (
        <NextArrow
          useButtons={useButtons}
          opacity={opacity}
          arrowFillArrowColor={arrowFillArrowColor}
          arrowFillCircleColor={arrowFillCircleColor}
        />
      ),
      prevArrow: (
        <PrevArrow
          useButtons={useButtons}
          opacity={opacity}
          arrowFillArrowColor={arrowFillArrowColor}
          arrowFillCircleColor={arrowFillCircleColor}
        />
      ),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: noOfMiddleSlides || 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: noOfMobileSlides || 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="carousel">
        <Slider ref={c => ((this as any).slider = c)} {...settings}>
          {this.props.children}
        </Slider>
        {arrows ? (
          <div className="mobile-carousel-handles">
            {useButtons ? (
              <button
                className="circular ui icon button prev"
                onClick={this.previous}
              >
                <i className="icon angle left"></i>
              </button>
            ) : (
              <button
                className="button"
                onClick={this.previous}
                style={{ background: "#fff", border: "none" }}
              >
                <PrevArrowSvg
                  opacity={opacity}
                  arrowFillArrowColor={arrowFillArrowColor}
                  arrowFillCircleColor={arrowFillCircleColor}
                />
              </button>
            )}

            {useButtons ? (
              <button
                className="circular ui icon button next"
                onClick={this.next}
              >
                <i className="icon angle right"></i>
              </button>
            ) : (
              <button
                className="button"
                onClick={this.next}
                style={{ background: "#fff", border: "none" }}
              >
                <NextArrowSvg
                  opacity={opacity}
                  arrowFillArrowColor={arrowFillArrowColor}
                  arrowFillCircleColor={arrowFillCircleColor}
                />
              </button>
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default Carousel;
