import { action, computed, observable, decorate } from "mobx";
import { noop } from "../../utils/noop";
import {
  LoginState,
  Mode,
  waiting,
  loading,
  readyWithErrors,
  ready,
  saving
} from "./Types";

class LoginStore {
  state: LoginState;

  constructor() {
    this.state = waiting();
  }

  waiting = () => {
    this.state = waiting();
  };

  loading = () => {
    this.state = loading();
  };

  ready = (
    mode: Mode,
    username: string,
    email: string,
    password: string,
    passwordConfirmation: string
  ) => {
    this.state = ready(mode, username, email, password, passwordConfirmation);
  };

  saving = (redirectUrl: string) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;

      case "ready":
      case "readyWithErrors":
        {
          const { mode, username, email, password, passwordConfirmation } = this.state;
          this.state = saving(
            mode,
            username,
            email,
            password,
            passwordConfirmation,
            redirectUrl
          );
        }

        break;
    }
  };

  readyWithErrors = (
    mode: Mode,
    username: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    errors: string
  ) => {
    this.state = readyWithErrors(
      mode,
      username,
      email,
      password,
      passwordConfirmation,
      errors
    );
  };

  login = () => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;

      case "ready":
      case "readyWithErrors":
        this.state.mode = "login";
        break;
    }
  };

  signUp = () => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.mode = "signUp";
        break;
    }
  };

  setUsername = e => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.username = e.target.value;
        break;
    }
  };

  setEmail = e => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.email = e.target.value;
        break;
    }
  };

  setPassword = e => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.password = e.target.value;
        break;
    }
  };

  setPasswordConfirmation = e => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.passwordConfirmation = e.target.value;
        break;
    }
  };

  setErrors = () => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "ready":
      case "saving":
        noop();
        break;
      case "readyWithErrors":
        this.state.errors = "";
        break;
    }
  };

  get loginState() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return true;
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.mode === "login";
        break;
    }
  }

  get signUpState() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return true;
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.mode === "signUp";
        break;
    }
  }

  get username() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.username;
        break;
    }
  }

  get email() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.email;
        break;
    }
  }

  get password() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.password;
        break;
    }
  }

  get passwordConfirmation() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.passwordConfirmation;
        break;
    }
  }

  get errors() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "ready":
      case "saving":
        return "";
        break;
      case "readyWithErrors":
        return this.state.errors;
        break;
    }
  }
}

decorate(LoginStore, {
  state: observable,
  waiting: action,
  loading: action,
  saving: action,
  ready: action,
  readyWithErrors: action,
  signUp: action,
  setEmail: action,
  setUsername: action,
  setPassword: action,
  setPasswordConfirmation: action,
  setErrors: action,
  loginState: computed,
  signUpState: computed,
  email: computed,
  username: computed,
  password: computed,
  passwordConfirmation: computed,
  errors: computed
});

export default LoginStore;
