import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postRequest } from "../../utils/httpRequest";

const LoginForm = ({ setError }) => {
  const [submiting, setSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required")
  });

  const initialValues = {
    email: "",
    password: ""
  };

  const handleSubmit = values => {
    setSubmitting(true);
    setError("");

    postRequest("/session/email.json", {
      session: {
        email: values.email,
        password: values.password,
        login: true,
        react_session: true
      }
    })
      .then((response: AxiosResponse<Response>) => {
        const { data } = response;

        (window as any).location =
          "https://chat.42courses.com/?token=betatester42chat";
      })
      .catch(error => {
        setSubmitting(false);
        setError(error.response.data?.errors);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className="ui form">
          <div>
            <div className="field">
              <div className="ui left icon action input">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                />
              </div>
              <ErrorMessage
                name="email"
                component="div"
                className="ui red pointing above label error"
              />
            </div>
          </div>
          <div>
            <div className="field">
              <div className="ui left icon action input">
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                />
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="ui red pointing above label error"
              />
            </div>
          </div>

          <div className="action">
            <button
              type="submit"
              className={`ui button ${submiting ? "loading" : ""}`}
              disabled={!isValid || submiting}
            >
              Let's go!
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
