import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { AxiosResponse } from "axios";
import { CommentResource } from "../../../../stores/CommentStore/Types";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";
import { postRequest, deleteRequest } from "../../../../utils/httpRequest";
import { errorNotification } from "../../../../utils/helpers";
import Comment from "./Comment";

interface Props {
  currentUser: CurrentUser;
  conversation: CommentResource;
  enrolmentId: number;
  questionId: number;
  updateComments: () => void;
}

interface State {
  reply: string;
  showReplyForm: boolean;
  liking: boolean;
  replying: boolean;
  viewReplies: boolean;
  deleting: boolean;
}

class Conversation extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      reply: "",
      showReplyForm: false,
      liking: false,
      replying: false,
      viewReplies: false,
      deleting: false
    };
  }

  handleShowReplyForm = e => {
    e.preventDefault();
    this.setState({ showReplyForm: !this.state.showReplyForm });
  };

  handleViewReplies = e => {
    e.preventDefault();
    this.setState({ viewReplies: !this.state.viewReplies });
  };

  likeComment = (e, commentId) => {
    e.preventDefault();
    this.setState({ liking: true });

    const {
      conversation: { liker_ids },
      currentUser
    } = this.props;

    if (liker_ids && liker_ids.includes(currentUser?.id)) {
      return this.unlikeComment(commentId);
    }

    return postRequest(`/api/likes.json?comment_id=${commentId}`, {})
      .then((response: AxiosResponse) => {
        this.setState({ liking: false });
        this.props.updateComments();
      })
      .catch(error => {
        this.setState({ liking: false });

        errorNotification(
          "Error liking comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  unlikeComment = commentId => {
    return postRequest(`/api/likes/unlike.json?comment_id=${commentId}`, {})
      .then((response: AxiosResponse) => {
        this.setState({ liking: false });
        this.props.updateComments();
      })
      .catch(error => {
        this.setState({ liking: false });

        errorNotification(
          "Error unliking comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  replyComment = (e, commentId) => {
    e.preventDefault();
    const { enrolmentId, questionId } = this.props;

    this.setState({ replying: true });

    return postRequest(`/api/comments.json?comment_id=${commentId}`, {
      content: this.state.reply,
      enrolment_id: enrolmentId,
      question_id: questionId
    })
      .then((response: AxiosResponse) => {
        this.setState({ reply: "", replying: false, viewReplies: true });
        this.props.updateComments();
      })
      .catch(error => {
        this.setState({ reply: "", replying: false });

        errorNotification(
          "Error replying comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  deleteComment = e => {
    e.preventDefault();
    this.setState({ deleting: true });

    const {
      conversation: { id },
      questionId
    } = this.props;

    return deleteRequest(`/api/comments/${id}.json?question_id=${questionId}`)
      .then((response: AxiosResponse) => {
        this.props.updateComments();
      })
      .catch(error => {
        this.setState({ deleting: false });

        errorNotification(
          "Error deleting comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  setReply = e => {
    this.setState({ reply: e.target.value });
  };

  countryFlagImage = countryCode => {
    let img;
    try {
      img = require(`../../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
    } catch {
      img = "";
    }

    return img;
  };

  likeClasses = () => {
    const { liking } = this.state;
    const {
      conversation: { liker_ids, parent },
      currentUser
    } = this.props;

    let classes = ["like space"];
    if (liking) {
      classes.push("loading");
    }

    if (liker_ids.includes(currentUser?.id)) {
      classes.push("liked");
    }
    if (parent == "Question") {
      classes.push("repliable");
    }
    return classes.join(" ");
  };

  render() {
    const {
      conversation: {
        id,
        user,
        created_at,
        formatted_content,
        likes_count,
        comments,
        parent,
        liker_ids
      },
      conversation,
      currentUser,
      updateComments,
      enrolmentId,
      questionId
    } = this.props;

    const {
      reply,
      showReplyForm,
      liking,
      replying,
      viewReplies,
      deleting
    } = this.state;

    return (
      <div className="ui container conversation">
        {deleting && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="conversation__body">
          <div className="conversation__body--avatar">
            <img src={user?.profile_picture_url} className="avatar" />
            <div className="country">
              <img src={this.countryFlagImage(user?.country)} />
            </div>
          </div>
          <div className="conversation__body--content">
            <div className="name-container">
              <span className="name">{user?.protected_name}</span>
              <span className="time">{created_at}</span>
            </div>
            {/* @ts-ignore */}
            <div className="content">{ReactHtmlParser(formatted_content)}</div>
            <div className="reaction">
              {parent == "Question" && (
                <a
                  className="reply space"
                  href="#"
                  onClick={this.handleShowReplyForm}
                >
                  Reply
                </a>
              )}
              <span className={this.likeClasses()}>
                <a href="#" onClick={e => this.likeComment(e, id)}>
                  <i className="thumbs up icon"></i>
                </a>
                <span>{likes_count}</span>
              </span>
              {comments?.length > 0 && parent == "Question" && (
                <a
                  className="reply view_replies space"
                  href="#"
                  onClick={this.handleViewReplies}
                >
                  View replies
                </a>
              )}
              {user?.id === currentUser?.id && (
                <a className="reply" href="#" onClick={this.deleteComment}>
                  Delete
                </a>
              )}
            </div>
            {viewReplies && (
              <>
                {comments.map(comment => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    currentUser={currentUser}
                    updateComments={updateComments}
                    commentType="conversation"
                    commentableId={conversation.id}
                    enrolmentId={enrolmentId}
                    questionId={questionId}
                  />
                ))}
              </>
            )}
            {showReplyForm && (
              <div className="reply__body">
                <div className="reply__body--avatar">
                  <img src={currentUser?.profile_picture} className="avatar" />
                </div>
                <form className="ui form">
                  <div className="form-header">
                    <p>{currentUser?.short_name} (me)</p>
                  </div>
                  <div className="field">
                    <textarea
                      value={reply}
                      rows={3}
                      placeholder="Write your reply"
                      onChange={this.setReply}
                    />
                  </div>
                  <div className="action">
                    <button
                      className={`ui button ${replying ? "loading" : ""}`}
                      type="submit"
                      onClick={e => this.replyComment(e, id)}
                    >
                      Post
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default Conversation;
