import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../utils/httpRequest";
import {
  EnrolmentCertificate,
  OngoingCourse
} from "../../stores/EnrolmentStore/Types";
import { errorNotification } from "../../utils/helpers";
import { Link } from "react-router-dom";

interface Props {
  match: any;
}

interface State {
  certificate: EnrolmentCertificate;
  enrolment: OngoingCourse;
  loading: boolean;
}

class Certification extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      certificate: null,
      enrolment: null,
      loading: true
    };
  }

  componentDidMount() {
    const certificateId = this.props.match.params.id;

    return getRequest(`/api/certifications/${certificateId}.json`)
      .then((response: AxiosResponse) => {
        const { enrolment } = response.data;
        this.setState({
          certificate: response.data,
          enrolment: enrolment,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });

        errorNotification(
          "Error loading certificate data",
          JSON.stringify(error.response.data)
        );
      });
  }

  render() {
    const { certificate, enrolment, loading } = this.state;

    return (
      <>
        {loading ? (
          <>
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
            <div className="ui facelift-certifications">
              <div className="ui component hero">
                <div
                  className="bg"
                  style={{
                    backgroundImage: `url(${require("../../../../assets/images/banners/confetti.png")})`
                  }}
                ></div>
              </div>
            </div>
          </>
        ) : (
          <div className="ui facelift-certifications">
            <div className="ui component hero">
              <div
                className="bg"
                style={{
                  backgroundImage: `url(${require("../../../../assets/images/banners/confetti.png")})`
                }}
              ></div>
              <div className="course-header">
                <h1>{certificate?.course_name}</h1>
              </div>
            </div>

            <div className="ui segment">
              <div className="smarter-container">
                {!certificate?.current && (
                  <div className="smarter">
                    <h3>
                      Think you're as smart as{" "}
                      <strong>{certificate?.user_name}</strong>?
                    </h3>
                    <Link to={certificate?.course_url}>
                      <button className="ui large traditional click-busy fluid button">
                        Get started
                      </button>
                    </Link>
                  </div>
                )}
              </div>
              <div
                className="ui text container files"
                style={{
                  backgroundImage: `url(${certificate?.landscape_url})`
                }}
              ></div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Certification;
