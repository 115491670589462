import { CoursesResource } from "../CoursesStore/Types";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  courseCategories: CourseCategoriesResource;
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (courseCategories: CourseCategoriesResource): Ready => {
  return {
    kind: "ready",
    courseCategories
  };
};

export type CourseCategoryState = Waiting | Loading | Ready;

export interface CourseCategoryResource {
  id: number;
  slug: string;
  name: string;
  description: string;
  label_tile_color: string;
  label_text_color: string;
  courses: CoursesResource;
}

export type CourseCategoriesResource = CourseCategoryResource[];
