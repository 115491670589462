import React, { Component } from "react";
import { observer } from "mobx-react";
import ReactHtmlParser from "react-html-parser";
import { AxiosResponse } from "axios";
import { getRequest } from "../../utils/httpRequest";
import AboutUsImage from "../../../../assets/images/mastheads/responsive/about-us.jpg";
import AboutUsImageMobile from "../../../../assets/images/mastheads/responsive/about-us-mobile.jpg";

interface State {
  content: string;
  loaded: boolean;
}

class About extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      loaded: false
    };
  }

  componentDidMount() {
    return getRequest("/api/pages/about.json").then(
      (response: AxiosResponse) => {
        this.setState({ content: response.data, loaded: true });
      }
    );
  }

  render() {
    const { loaded, content } = this.state;

    return (
      <div className="ui pages" id="about">
        {!loaded && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        <div className="responsive-masthead about-us">
          <div
            className="backdrop desktop"
            style={{
              backgroundImage: `url(
                ${AboutUsImage}
                )`
            }}
          ></div>
          <div
            className="backdrop mobile"
            style={{
              backgroundImage: `url(
                ${AboutUsImageMobile}
                )`
            }}
          ></div>
        </div>
        <div className="content">
          <div className="ui vertical segment caret-push padded">
            <div className="ui text container padded">
              {/* @ts-ignore */}
              {ReactHtmlParser(content)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(About);
