import React, { Component } from "react";
import { observer } from "mobx-react";
import { Dropdown } from "semantic-ui-react";
import CoursesStore from "../../../../stores/CoursesStore";
import { CourseResource } from "../../../../stores/CoursesStore/Types";
import CoursesReactions from "../../../../stores/CoursesStore/CoursesReactions";
import Course from "./Course";

interface Props {
  store: CoursesStore;
  headerTitle?: string;
}

interface State {
  courses: CourseResource[];
  showMore: boolean;
  mobileCourses: CourseResource[];
  mobileShowMore: boolean;
  mode: string;
}

class CoursesWrapper extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      courses: [],
      showMore: false,
      mobileCourses: [],
      mode: "",
      mobileShowMore: false
    };
  }

  componentDidMount() {
    const { store } = this.props;
    this.setState({
      courses: store.courses.slice(0, 9),
      showMore: store.courses.length > 9,
      mobileCourses: store.courses.slice(0, 6),
      mobileShowMore: store.courses.length > 6,
      mode: store.mode
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { store } = props;

    if (store.mode !== state.mode && store.state.kind === "ready") {
      return {
        courses: store.courses.slice(0, 9),
        showMore: store.courses.length > 9,
        mobileCourses: store.courses.slice(0, 6),
        mobileShowMore: store.courses.length > 6,
        mode: store.mode
      };
    }

    return null;
  }

  onClickSortOption = (e, mode) => {
    const { store } = this.props;
    e.preventDefault();

    if (store !== mode) {
      store.sorting(mode, []);
    }
  };

  showMoreCourses = e => {
    const { store } = this.props;
    e.preventDefault();

    const { courses, mobileCourses } = this.state;
    this.setState(
      {
        courses: store.courses.slice(0, courses.length + 3),
        mobileCourses: store.courses.slice(0, mobileCourses.length + 4)
      },
      this.checkShouldShowMore
    );
  };

  checkShouldShowMore = () => {
    const { store } = this.props;
    const { courses } = this.state;

    if (store.courses.length === courses.length) {
      this.setState({ showMore: false });
    }
  };

  render() {
    const { store, headerTitle } = this.props;
    const { courses, showMore, mobileCourses, mobileShowMore } = this.state;

    return (
      <div className="ui container">
        <div className="header-with-filter">
          <h1
            className="ui header aligned course-header"
            id="course-grid-title"
          >
            <span className="title">
              {headerTitle ? headerTitle : "Courses"}
            </span>
          </h1>
          <Dropdown text="Sort by" className="courses-sort-dropdown">
            <Dropdown.Menu>
              <Dropdown.Item
                text="Top rated"
                onClick={e => this.onClickSortOption(e, "rated")}
                className={`${store.mode === "rated" ? "selected" : ""}`}
              />
              <Dropdown.Item
                text="Most popular"
                onClick={e => this.onClickSortOption(e, "popularity")}
                className={`${store.mode === "popularity" ? "selected" : ""}`}
              />
              <Dropdown.Item
                text="Most recent"
                onClick={e => this.onClickSortOption(e, "recency")}
                className={`${store.mode === "recency" ? "selected" : ""}`}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {store.state.kind === "sorting" && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="courses-container ui inverted vertical segment center aligned">
          <div
            className="ui centered grid doubling stackable three column desktop"
            id="courses_grid"
          >
            {courses.map((course, i) => (
              <Course
                courseResource={course}
                idx={i}
                key={course.id}
                categories={[]}
              />
            ))}
          </div>

          <div
            className="ui centered grid doubling stackable two column mobile"
            id="courses_grid"
          >
            {mobileCourses.map((course, i) => (
              <Course
                courseResource={course}
                idx={i}
                key={course.id}
                categories={[]}
              />
            ))}
          </div>
        </div>
        {showMore && (
          <div className="more-courses-button desktop">
            <a
              className="ui large button more-courses"
              onClick={this.showMoreCourses}
            >
              See more courses
            </a>
          </div>
        )}

        {mobileShowMore && (
          <div className="more-courses-button mobile">
            <a
              className="ui large button more-courses"
              onClick={this.showMoreCourses}
            >
              See more courses
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default observer(CoursesWrapper);
