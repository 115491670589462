import React, { Component } from "react";
import { QuestionPreview } from "../../../stores/CoursePreviewStore/Types";
import { Enrolment } from "../../../stores/EnrolmentStore/Types";

interface Props {
  enrolment: Enrolment;
  question: QuestionPreview;
}

class PersonalLessonSummary extends Component<Props> {
 // render nothing if the attribute is blank

 render() {
  const { question, enrolment } = this.props;
  
  if (!question || !question.formatted_personal_lesson_summary) {
    return null;
  }

  return <div className='personal-lesson-summary'>
    <h3>Personal Insights</h3>
    <div className="content" dangerouslySetInnerHTML={{__html: question.formatted_personal_lesson_summary}}/>
  </div>
 }
};

export default PersonalLessonSummary;