import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { putRequest } from "../../../../utils/httpRequest";

interface Props {
  notification: any;
  history?: any;
}

interface State {
  showNotification: boolean;
}

class Notification extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: true
    };
  }

  componentDidMount() {
    $(document).on("click", "span[data-url]", function(e) {
      window.open($(this).data("url"), "_blank");
    });

    setTimeout(this.closeNotification, 10000);
  }

  visitNotificationHref = e => {
    setTimeout(() => {
      this.closeNotification();
    }, 200);
  };

  closeNotification = () => {
    const { notification } = this.props;

    ($(`.toast-${notification.id}`) as any).transition("fly left");
    return putRequest(
      `/api/notifications/${this.props.notification.id}/mark_as_seen.json`,
      {}
    )
      .then(() => this.setState({ showNotification: false }))
      .catch(error =>
        (window as any).honeybadger?.notify(
          `Error updating recent notification for ${notification.id}, data: ${error.response.data}`
        )
      );
  };

  onCloseNotification = e => {
    e.preventDefault();

    this.closeNotification();
  };

  render() {
    const { notification } = this.props;
    const { showNotification } = this.state;
    return (
      <>
        {showNotification && (
          <a
            className={`toast toast-${notification.id}`}
            href="#"
            onClick={this.onCloseNotification}
          >
            {notification.avatar ? (
              <div
                className="toast-avatar ui circular image"
                style={{
                  backgroundImage: `url(${notification.avatar})`
                }}
              />
            ) : (
              <div className={`toast-avatar ${notification.mood}`} />
            )}
            <div className="toast-content">
              <div className="close-button">
                <i className="icon close" />
              </div>
              <div className="body">
                <div className="toast-title">
                  {/* @ts-ignore */}
                  {ReactHtmlParser(notification.title)}
                </div>
                <div className="toast-body">
                  {/* @ts-ignore */}
                  {ReactHtmlParser(notification.body)}
                </div>
                <div className="toast-meta">
                  {notification.target_name && (
                    <Link
                      className="target"
                      to={notification.href}
                      onClick={this.visitNotificationHref}
                    >
                      {ReactHtmlParser(notification.target_name)}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </a>
        )}
      </>
    );
  }
}

export default Notification;
