import React, { Component } from "react";
import Checkout from "../Checkout";

interface Props {
  match: any;
  selectedCurrency: string;
  currentUser: {
    country: string;
    kind: string;
  };
}

class GiftPay extends Component<Props, {}> {
  render() {
    const token = this.props.match.params.token;
    return (
      <div className="facelift-checkout">
        <Checkout
          path="gift"
          paymentType="gift"
          token={token}
          paymentUrl={`/api/gift/${token}/pay`}
          selectedCurrency={this.props.selectedCurrency}
          isCurrentUserPresent={this.props.currentUser.kind === "current-user"}
        />
      </div>
    );
  }
}

export default GiftPay;
