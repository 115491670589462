export const compareCourses = (courseA, courseB) => {
  const percentA = courseA.percentage_completed;
  const percentB = courseB.percentage_completed;

  let comparison = 0;
  if (percentA > percentB) {
    comparison = -1;
  } else if (percentA < percentB) {
    comparison = 1;
  }
  return comparison;
};
