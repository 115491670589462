import React from "react";

export const DesktopIcon = () => (
  <svg
    className="desktop"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="6" fill="#392D40" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 14.75C16.25 13.7554 16.6451 12.8016 17.3483 12.0983C18.0516 11.3951 19.0054 11 20 11C20.9946 11 21.9484 11.3951 22.6517 12.0983C23.3549 12.8016 23.75 13.7554 23.75 14.75C23.75 15.7446 23.3549 16.6984 22.6517 17.4017C21.9484 18.1049 20.9946 18.5 20 18.5C19.0054 18.5 18.0516 18.1049 17.3483 17.4017C16.6451 16.6984 16.25 15.7446 16.25 14.75ZM23.75 17.75C23.75 16.9544 24.0661 16.1913 24.6287 15.6287C25.1913 15.0661 25.9544 14.75 26.75 14.75C27.5456 14.75 28.3087 15.0661 28.8713 15.6287C29.4339 16.1913 29.75 16.9544 29.75 17.75C29.75 18.5456 29.4339 19.3087 28.8713 19.8713C28.3087 20.4339 27.5456 20.75 26.75 20.75C25.9544 20.75 25.1913 20.4339 24.6287 19.8713C24.0661 19.3087 23.75 18.5456 23.75 17.75ZM10.25 17.75C10.25 16.9544 10.5661 16.1913 11.1287 15.6287C11.6913 15.0661 12.4544 14.75 13.25 14.75C14.0456 14.75 14.8087 15.0661 15.3713 15.6287C15.9339 16.1913 16.25 16.9544 16.25 17.75C16.25 18.5456 15.9339 19.3087 15.3713 19.8713C14.8087 20.4339 14.0456 20.75 13.25 20.75C12.4544 20.75 11.6913 20.4339 11.1287 19.8713C10.5661 19.3087 10.25 18.5456 10.25 17.75ZM14.31 23.117C14.92 22.161 15.7611 21.3743 16.7556 20.8294C17.7502 20.2846 18.866 19.9994 20 20C20.9498 19.9991 21.8891 20.1989 22.7564 20.5862C23.6237 20.9734 24.3994 21.5395 25.0327 22.2474C25.6661 22.9552 26.1428 23.7888 26.4317 24.6936C26.7205 25.5985 26.815 26.5541 26.709 27.498C26.696 27.6153 26.6556 27.7278 26.591 27.8265C26.5263 27.9252 26.4393 28.0073 26.337 28.066C24.4086 29.1725 22.2233 29.7532 20 29.75C17.695 29.75 15.53 29.138 13.663 28.066C13.5607 28.0073 13.4737 27.9252 13.409 27.8265C13.3444 27.7278 13.304 27.6153 13.291 27.498C13.1231 25.9646 13.4825 24.4198 14.31 23.118V23.117Z"
      fill="white"
    />
    <path
      d="M13.0821 22.2539C12.0958 23.7763 11.6363 25.5802 11.7741 27.3889C11.1736 27.2979 10.583 27.1505 10.0101 26.9489L9.89508 26.9089C9.79248 26.8725 9.70263 26.8071 9.63643 26.7207C9.57023 26.6342 9.53051 26.5305 9.52208 26.4219L9.51208 26.3009C9.47169 25.7989 9.53284 25.2938 9.69188 24.816C9.85093 24.3381 10.1046 23.8971 10.4378 23.5194C10.771 23.1417 11.1768 22.835 11.6312 22.6176C12.0855 22.4001 12.5789 22.2765 13.0821 22.2539ZM28.2261 27.3889C28.3638 25.5802 27.9043 23.7763 26.9181 22.2539C27.4212 22.2765 27.9147 22.4001 28.369 22.6176C28.8233 22.835 29.2292 23.1417 29.5624 23.5194C29.8955 23.8971 30.1492 24.3381 30.3083 24.816C30.4673 25.2938 30.5285 25.7989 30.4881 26.3009L30.4781 26.4219C30.4695 26.5303 30.4297 26.6338 30.3635 26.7201C30.2973 26.8063 30.2075 26.8716 30.1051 26.9079L29.9901 26.9479C29.4231 27.1479 28.8341 27.2969 28.2261 27.3889Z"
      fill="white"
    />
  </svg>
);

export const MobileIcon = () => (
  <svg
    className="mobile"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="6" fill="#392D40" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1875 12.0625C13.1875 11.3166 13.4838 10.6012 14.0113 10.0738C14.5387 9.54632 15.2541 9.25 16 9.25C16.7459 9.25 17.4613 9.54632 17.9887 10.0738C18.5162 10.6012 18.8125 11.3166 18.8125 12.0625C18.8125 12.8084 18.5162 13.5238 17.9887 14.0512C17.4613 14.5787 16.7459 14.875 16 14.875C15.2541 14.875 14.5387 14.5787 14.0113 14.0512C13.4838 13.5238 13.1875 12.8084 13.1875 12.0625ZM18.8125 14.3125C18.8125 13.7158 19.0496 13.1435 19.4715 12.7215C19.8935 12.2996 20.4658 12.0625 21.0625 12.0625C21.6592 12.0625 22.2315 12.2996 22.6535 12.7215C23.0754 13.1435 23.3125 13.7158 23.3125 14.3125C23.3125 14.9092 23.0754 15.4815 22.6535 15.9035C22.2315 16.3254 21.6592 16.5625 21.0625 16.5625C20.4658 16.5625 19.8935 16.3254 19.4715 15.9035C19.0496 15.4815 18.8125 14.9092 18.8125 14.3125ZM8.6875 14.3125C8.6875 13.7158 8.92455 13.1435 9.34651 12.7215C9.76847 12.2996 10.3408 12.0625 10.9375 12.0625C11.5342 12.0625 12.1065 12.2996 12.5285 12.7215C12.9504 13.1435 13.1875 13.7158 13.1875 14.3125C13.1875 14.9092 12.9504 15.4815 12.5285 15.9035C12.1065 16.3254 11.5342 16.5625 10.9375 16.5625C10.3408 16.5625 9.76847 16.3254 9.34651 15.9035C8.92455 15.4815 8.6875 14.9092 8.6875 14.3125ZM11.7325 18.3377C12.19 17.6208 12.8208 17.0307 13.5667 16.6221C14.3126 16.2135 15.1495 15.9995 16 16C16.7124 15.9993 17.4168 16.1492 18.0673 16.4396C18.7178 16.7301 19.2995 17.1547 19.7745 17.6855C20.2496 18.2164 20.6071 18.8416 20.8237 19.5202C21.0404 20.1989 21.1113 20.9156 21.0317 21.6235C21.022 21.7114 20.9917 21.7959 20.9432 21.8699C20.8947 21.9439 20.8295 22.0054 20.7528 22.0495C19.3064 22.8794 17.6675 23.3149 16 23.3125C14.2712 23.3125 12.6475 22.8535 11.2473 22.0495C11.1705 22.0054 11.1053 21.9439 11.0568 21.8699C11.0083 21.7959 10.978 21.7114 10.9682 21.6235C10.8423 20.4735 11.1118 19.3148 11.7325 18.3385V18.3377Z"
      fill="white"
    />
    <path
      d="M10.8116 17.6904C10.0719 18.8322 9.72725 20.1852 9.83056 21.5417C9.38019 21.4734 8.93724 21.3629 8.50756 21.2117L8.42131 21.1817C8.34436 21.1544 8.27697 21.1053 8.22732 21.0405C8.17767 20.9757 8.14788 20.8978 8.14156 20.8164L8.13406 20.7257C8.10376 20.3492 8.14963 19.9704 8.26891 19.612C8.3882 19.2535 8.57846 18.9228 8.82835 18.6396C9.07824 18.3563 9.38264 18.1262 9.72337 17.9632C10.0641 17.8001 10.4342 17.7073 10.8116 17.6904ZM22.1696 21.5417C22.2729 20.1852 21.9282 18.8322 21.1886 17.6904C21.5659 17.7073 21.936 17.8001 22.2768 17.9632C22.6175 18.1262 22.9219 18.3563 23.1718 18.6396C23.4217 18.9228 23.6119 19.2535 23.7312 19.612C23.8505 19.9704 23.8964 20.3492 23.8661 20.7257L23.8586 20.8164C23.8521 20.8977 23.8222 20.9754 23.7726 21.0401C23.723 21.1047 23.6557 21.1537 23.5788 21.1809L23.4926 21.2109C23.0673 21.3609 22.6256 21.4727 22.1696 21.5417Z"
      fill="white"
    />
  </svg>
);

export const LikeIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.97475 7.6875C5.57925 7.6875 6.1245 7.353 6.498 6.8775C7.0802 6.13471 7.81103 5.52164 8.64375 5.0775C9.186 4.7895 9.65625 4.3605 9.8835 3.79125C10.0431 3.39244 10.1251 2.96681 10.125 2.53725V2.0625C10.125 1.91332 10.1843 1.77024 10.2898 1.66475C10.3952 1.55926 10.5383 1.5 10.6875 1.5C11.1351 1.5 11.5643 1.67779 11.8807 1.99426C12.1972 2.31072 12.375 2.73995 12.375 3.1875C12.375 4.0515 12.18 4.86975 11.8328 5.601C11.6333 6.0195 11.913 6.5625 12.3765 6.5625M12.3765 6.5625H14.721C15.4905 6.5625 16.1798 7.083 16.2615 7.84875C16.2953 8.16525 16.3125 8.48625 16.3125 8.8125C16.3156 10.8648 15.6143 12.8559 14.3258 14.4532C14.0348 14.8147 13.5855 15 13.122 15H10.11C9.74775 15 9.387 14.9415 9.04275 14.8275L6.70725 14.0475C6.36308 13.933 6.00271 13.8748 5.64 13.875H4.428M12.3765 6.5625H10.6875M4.428 13.875C4.49025 14.0287 4.55775 14.1787 4.6305 14.3265C4.77825 14.6265 4.572 15 4.23825 15H3.55725C2.8905 15 2.2725 14.6115 2.07825 13.974C1.81849 13.1214 1.6868 12.235 1.6875 11.3438C1.6875 10.179 1.90875 9.06675 2.31075 8.04525C2.54025 7.46475 3.12525 7.125 3.75 7.125H4.53975C4.89375 7.125 5.0985 7.542 4.91475 7.845C4.27417 8.89946 3.93632 10.11 3.93825 11.3438C3.93825 12.2393 4.11225 13.0935 4.42875 13.875H4.428Z"
      stroke="#94A3B8"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UnLikeIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    style={{ "--svg-fill": "none" } as React.CSSProperties}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.97475 7.6875C5.57925 7.6875 6.1245 7.353 6.498 6.8775C7.0802 6.13471 7.81103 5.52164 8.64375 5.0775C9.186 4.7895 9.65625 4.3605 9.8835 3.79125C10.0431 3.39244 10.1251 2.96681 10.125 2.53725V2.0625C10.125 1.91332 10.1843 1.77024 10.2898 1.66475C10.3952 1.55926 10.5383 1.5 10.6875 1.5C11.1351 1.5 11.5643 1.67779 11.8807 1.99426C12.1972 2.31072 12.375 2.73995 12.375 3.1875C12.375 4.0515 12.18 4.86975 11.8328 5.601C11.6333 6.0195 11.913 6.5625 12.3765 6.5625M12.3765 6.5625H14.721C15.4905 6.5625 16.1798 7.083 16.2615 7.84875C16.2953 8.16525 16.3125 8.48625 16.3125 8.8125C16.3156 10.8648 15.6143 12.8559 14.3258 14.4532C14.0348 14.8147 13.5855 15 13.122 15H10.11C9.74775 15 9.387 14.9415 9.04275 14.8275L6.70725 14.0475C6.36308 13.933 6.00271 13.8748 5.64 13.875H4.428M12.3765 6.5625H10.6875M4.428 13.875C4.49025 14.0287 4.55775 14.1787 4.6305 14.3265C4.77825 14.6265 4.572 15 4.23825 15H3.55725C2.8905 15 2.2725 14.6115 2.07825 13.974C1.81849 13.1214 1.6868 12.235 1.6875 11.3438C1.6875 10.179 1.90875 9.06675 2.31075 8.04525C2.54025 7.46475 3.12525 7.125 3.75 7.125H4.53975C4.89375 7.125 5.0985 7.542 4.91475 7.845C4.27417 8.89946 3.93632 10.11 3.93825 11.3438C3.93825 12.2393 4.11225 13.0935 4.42875 13.875H4.428Z"
      stroke="#94A3B8"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="var(--svg-fill)"
    />
  </svg>
);

export const CommentIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.04 2.30917C6.01344 2.0195 8.00541 1.87439 10 1.875C12.025 1.875 14.0142 2.02334 15.96 2.30834C17.6083 2.55167 18.75 3.995 18.75 5.61667V10.6333C18.75 12.255 17.6083 13.6983 15.96 13.9417C14.9986 14.0826 14.0324 14.189 13.0633 14.2608C13.014 14.2641 12.9661 14.2785 12.9232 14.3031C12.8803 14.3276 12.8436 14.3616 12.8158 14.4025L10.52 17.8467C10.4629 17.9323 10.3856 18.0025 10.2949 18.051C10.2042 18.0995 10.1029 18.1249 10 18.1249C9.89712 18.1249 9.79583 18.0995 9.70511 18.051C9.6144 18.0025 9.53708 17.9323 9.48 17.8467L7.18417 14.4025C7.1564 14.3616 7.11967 14.3276 7.07678 14.3031C7.0339 14.2785 6.98598 14.2641 6.93667 14.2608C5.9676 14.1887 5.00143 14.082 4.04 13.9408C2.39167 13.6992 1.25 12.2542 1.25 10.6325V5.6175C1.25 3.99584 2.39167 2.55084 4.04 2.30917ZM5.625 6.875C5.625 6.70924 5.69085 6.55027 5.80806 6.43306C5.92527 6.31585 6.08424 6.25 6.25 6.25H13.75C13.9158 6.25 14.0747 6.31585 14.1919 6.43306C14.3092 6.55027 14.375 6.70924 14.375 6.875C14.375 7.04076 14.3092 7.19973 14.1919 7.31694C14.0747 7.43415 13.9158 7.5 13.75 7.5H6.25C6.08424 7.5 5.92527 7.43415 5.80806 7.31694C5.69085 7.19973 5.625 7.04076 5.625 6.875ZM6.25 8.75C6.08424 8.75 5.92527 8.81585 5.80806 8.93306C5.69085 9.05027 5.625 9.20924 5.625 9.375C5.625 9.54076 5.69085 9.69973 5.80806 9.81694C5.92527 9.93415 6.08424 10 6.25 10H10C10.1658 10 10.3247 9.93415 10.4419 9.81694C10.5592 9.69973 10.625 9.54076 10.625 9.375C10.625 9.20924 10.5592 9.05027 10.4419 8.93306C10.3247 8.81585 10.1658 8.75 10 8.75H6.25Z"
      fill="#36CBBD"
    />
  </svg>
);

export const EmptyCommentIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.625 6.1875H12.375M5.625 8.4375H9M1.6875 9.57C1.6875 10.77 2.52975 11.8155 3.71775 11.9903C4.5645 12.1148 5.42025 12.21 6.285 12.2745C6.5475 12.294 6.7875 12.432 6.93375 12.6503L9 15.75L11.0663 12.6503C11.1387 12.5425 11.2348 12.4526 11.3472 12.3875C11.4597 12.3224 11.5854 12.2837 11.715 12.2745C12.5739 12.2104 13.4302 12.1156 14.2823 11.9903C15.4703 11.8155 16.3125 10.7708 16.3125 9.56925V5.05575C16.3125 3.85425 15.4703 2.8095 14.2823 2.63475C12.5332 2.37804 10.7678 2.24944 9 2.25C7.206 2.25 5.442 2.38125 3.71775 2.63475C2.52975 2.8095 1.6875 3.855 1.6875 5.05575V9.56925V9.57Z"
      stroke="#94A3B8"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13L13 1M1 1L13 13"
      stroke="#1E293B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReplyIcon = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.205061 5.98933L5.80508 0.211549C5.90296 0.110544 6.02768 0.0417556 6.16346 0.0138833C6.29923 -0.013989 6.43997 0.000306683 6.56788 0.0549626C6.69578 0.109619 6.8051 0.20218 6.88201 0.32094C6.95893 0.439701 6.99998 0.579327 6.99998 0.722161V3.28238C8.91218 3.465 10.6895 4.37723 11.9842 5.84058C13.2789 7.30393 13.9977 9.21306 14 11.1944V12.2777C14 12.4277 13.9548 12.574 13.8705 12.6962C13.7863 12.8185 13.6673 12.9106 13.53 12.9599C13.3927 13.0092 13.2439 13.0131 13.1043 12.9712C12.9647 12.9293 12.8413 12.8436 12.7511 12.7259C12.0653 11.8845 11.2239 11.1925 10.276 10.6904C9.32811 10.1882 8.29273 9.88604 7.23035 9.80143C7.19549 9.79684 7.10799 9.7898 6.99998 9.78274V12.2777C6.99998 12.4206 6.95893 12.5602 6.88201 12.6789C6.8051 12.7977 6.69578 12.8903 6.56788 12.9449C6.43997 12.9996 6.29923 13.0139 6.16346 12.986C6.02768 12.9581 5.90296 12.8893 5.80508 12.7883L0.205061 7.01055C0.140051 6.94351 0.08848 6.86391 0.0532951 6.7763C0.0181093 6.68868 0 6.59478 0 6.49994C0 6.40511 0.0181093 6.3112 0.0532951 6.22359C0.08848 6.13598 0.140051 6.05637 0.205061 5.98933ZM5.59998 10.5342V9.02772C5.59995 8.93287 5.61804 8.83894 5.65321 8.75131C5.68838 8.66367 5.73994 8.58405 5.80495 8.51698C5.86996 8.44991 5.94713 8.39671 6.03207 8.36042C6.11701 8.32414 6.20805 8.30547 6.29998 8.3055C6.47839 8.3055 7.20711 8.34112 7.39305 8.36689C9.27984 8.54618 11.0758 9.28576 12.5638 10.4961C12.395 8.90316 11.6626 7.43039 10.5068 6.35988C9.35104 5.28937 7.85315 4.69635 6.29998 4.69439C6.20805 4.69441 6.11701 4.67575 6.03207 4.63946C5.94713 4.60317 5.86996 4.54998 5.80495 4.48291C5.73994 4.41584 5.68838 4.33621 5.65321 4.24857C5.61804 4.16094 5.59995 4.06701 5.59998 3.97216V2.46565L1.68981 6.49994L5.59998 10.5342Z"
      fill="#94A3B8"
    />
  </svg>
);

export const DeleteIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59833 5.24982L8.3965 10.4998M5.6035 10.4998L5.40167 5.24982M11.2163 3.37732C11.4158 3.40765 11.6142 3.43973 11.8125 3.47415M11.2163 3.37732L10.5933 11.4757C10.5679 11.8054 10.419 12.1134 10.1763 12.338C9.93357 12.5627 9.61503 12.6874 9.28433 12.6873H4.71567C4.38497 12.6874 4.06643 12.5627 3.82374 12.338C3.58105 12.1134 3.43209 11.8054 3.40667 11.4757L2.78367 3.37732M11.2163 3.37732C10.5431 3.27553 9.86636 3.19829 9.1875 3.14573M2.78367 3.37732C2.58417 3.40707 2.38583 3.43915 2.1875 3.47357M2.78367 3.37732C3.45691 3.27553 4.13364 3.19829 4.8125 3.14573M9.1875 3.14573V2.6114C9.1875 1.92307 8.65667 1.34907 7.96833 1.32748C7.32294 1.30685 6.67706 1.30685 6.03167 1.32748C5.34333 1.34907 4.8125 1.92365 4.8125 2.6114V3.14573M9.1875 3.14573C7.73134 3.03319 6.26866 3.03319 4.8125 3.14573"
      stroke="#1E293B"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
