import React, { Component } from "react";
import { CommentResource } from "../../../../stores/CommentStore/Types";
import Comment from "../Conversations/Comment";
import { Answer } from "../../../../stores/EnrolmentStore/Types";

interface Props {
  comments: CommentResource[];
  currentUser: any;
  answer: Answer;
  search: string;
  enrolmentId: number;
  questionId: number;
}

interface State {
  viewReplies: boolean;
}

class Comments extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      viewReplies: true
    };
  }

  componentDidMount() {
    const { search } = this.props;

    if (!search || !search.match("comment_")) {
      this.setState({ viewReplies: false });
    }
  }

  handleViewReplies = e => {
    e.preventDefault();
    this.setState({ viewReplies: !this.state.viewReplies });
  };

  render() {
    const {
      comments,
      currentUser,
      answer,
      enrolmentId,
      questionId
    } = this.props;
    const { viewReplies } = this.state;

    return (
      <div className="conversation">
        <div className="conversation__body--content">
          <div className="reaction">
            {comments.length > 0 && (
              <a
                className="reply view-replies"
                href="#"
                onClick={this.handleViewReplies}
              >
                {viewReplies ? "Hide replies" : "View replies"}
              </a>
            )}
          </div>

          {viewReplies && (
            <>
              {comments.map(comment => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  currentUser={currentUser}
                  updateComments={() => {}}
                  commentType="answer"
                  commentableId={answer.id}
                  enrolmentId={enrolmentId}
                  questionId={questionId}
                  canReply={true}
                />
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Comments;
