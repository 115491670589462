import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import LoginStore from "../../../../stores/LoginStore";
import { buttonCss, buttonText } from "../../../../stores/LoginStore/Types";

interface Props {
  store: LoginStore;
  loginUser: (e: React.MouseEvent<HTMLElement>) => void;
}

class LoginForm extends Component<Props, {}> {
  render() {
    const { store, loginUser } = this.props;
    return (
      <form noValidate={true} className="ui form">
        <div className="field">
          <div className="ui left icon action input">
            <input
              className="string email optional fluid input-fields"
              placeholder="Your email"
              type="email"
              name="session[email]"
              id="session_email"
              autoComplete="off"
              value={store.email}
              onChange={e => store.setEmail(e)}
            />
          </div>
          <div className="ui left icon action input">
            <input
              className="password optional fluid input-fields"
              placeholder="Your password"
              type="password"
              name="session[password]"
              id="session_password"
              autoComplete="off"
              value={store.password}
              onChange={e => store.setPassword(e)}
            />
          </div>
          <div className="ui one column grid center aligned">
            <a id="forgot_password" href="/session/password_resets/new">
              Forgot password?
            </a>
          </div>
          <div className="action">
            <button
              className={buttonCss(store.state.kind)}
              onClick={e => loginUser(e)}
            >
              {buttonText(store.state.kind)}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default observer(LoginForm);
