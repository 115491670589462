import React from "react";
import { observer } from "mobx-react";
import { Modal, Rating } from "semantic-ui-react";
import { ReviewResource } from "../../../../stores/ReviewsStore/Types";

interface Props {
  showModal: boolean;
  onClickShowModal: (e: any, val: boolean) => void;
  review: ReviewResource;
}

const ReviewModal: React.FC<Props> = ({
  showModal,
  onClickShowModal,
  review
}) => {
  return (
    <Modal
      closeIcon
      open={showModal}
      onClose={e => onClickShowModal(e, false)}
      className="review-modal"
    >
      <Modal.Content>
        <div className="ratings">
          <Rating
            icon="star"
            defaultRating={review.rating}
            maxRating={5}
            disabled={true}
          />
        </div>
        <div className="user">
          <img
            className="ui circular image profile-picture"
            src={review.profile_picture}
            alt=""
          />
          <div className="name">
            <h2 className="ui header">{review.user_name}</h2>
            <h4 className="ui header">
              <i className={`flag ${review.user_country.toLowerCase()}`} />
              {review.user_country_name}
            </h4>
          </div>
        </div>
        <p className="review-content">{review.content}</p>
      </Modal.Content>
    </Modal>
  );
};

export default observer(ReviewModal);
