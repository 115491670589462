import React, { Component } from "react";
import { observer } from "mobx-react";
import { Badge } from "../../stores/BadgeStore/Types";
import { putRequest } from "../../utils/httpRequest";
import { AxiosResponse } from "axios";

interface Props {
 badge: Badge;
 onClickShowModal: (e: any, val: boolean, badge: Badge) => void;
}

interface State {
  activeBadge: Badge;
}

class BadgeWrapper extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
          activeBadge: null
        };
    }

    render() {
        const { badge } = this.props;

        const renderAchievementImage = () => {
            if (badge.persisted && !badge.opened) {
                return <img src={require(`../../../../assets/images/achievements/badges/mystery-${badge.badge_shape}.png`)} className="stateful state--new infinite animated pulse badge--teaser-image"/>
            } else if (!badge.persisted) {
                return <img src={require(`../../../../assets/images/achievements/badges/mystery-circle.png`)} className="stateful state--new badge--teaser-image"/>
            } else {
                return <img src={badge.badge_url} className="stateful state--awarded badge--actual-image"/>
            }
        }

        return (
            <div className={`ui card badge-card ${badge.badge_class}`} data-achievement-id={badge.id} data-state={badge.state} onClick={e => this.props.onClickShowModal(e, (badge.state !== 'unawarded'), badge)}>
                <div className="ui image">
                  {renderAchievementImage()}
                </div>
                <div className="stateful state--awarded state--unawarded">
                    {(badge.persisted) && (<div className="header stateful state--awarded">{badge.badge_name}</div>)}
                    <div className="description stateful state--unawarded">
                        <div className="meta">{badge.badge_unawarded_instruction}</div>
                    </div>
                </div>
                {(badge.persisted && !badge.opened) && (
                    <>
                        <div className="stateful state--new">
                            <div className="ui blue basic huge fluid button">
                                <span>Open achievement</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
export default observer(BadgeWrapper);