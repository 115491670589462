import React, { Component } from "react";
import { observer } from "mobx-react";
import ModalStore from "../../../stores/ModalStore";
import LoginStore from "../../../stores/LoginStore";
import SignInFormContainer from "./SignInFormContainer";

interface Props {
  showSignInSidebar: boolean;
  redirectUrl: string;
  handleHideSignInSideBar: () => void;
  loginUser: (e: React.MouseEvent<HTMLElement>) => void;
  modalStore: ModalStore;
  loginStore: LoginStore;
}

class SignInSidebar extends Component<Props, {}> {
  render() {
    const {
      showSignInSidebar,
      redirectUrl,
      handleHideSignInSideBar,
      modalStore,
      loginStore,
      loginUser
    } = this.props;

    const sideBarClass = showSignInSidebar
      ? "ui sidebar vertical menu right overlay uncover visible new-side-bar util-width-50"
      : "ui sidebar vertical menu right overlay animating new-side-bar util-width-50";

    return (
      <div className="ui">
        <div className={sideBarClass} id="sign_in_sidebar">
          <div className="ui one column stackable grid container">
            <div className="header menu">
              <div className="close">
                <a
                  className="ui left button"
                  id="closeNav"
                  onClick={() => handleHideSignInSideBar()}
                >
                  <i className="close icon"></i>
                </a>
              </div>
            </div>
          </div>
          <SignInFormContainer
            redirectUrl={redirectUrl}
            loginStore={loginStore}
            loginUser={loginUser}
            modalStore={modalStore}
          />
        </div>
      </div>
    );
  }
}

export default observer(SignInSidebar);
