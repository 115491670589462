import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { observer } from "mobx-react";
import ErrorBoundary from "@honeybadger-io/react";
import Routes from "src/routes";
import RootStore from "src/stores/RootStore";
import { RootContext } from "src/stores/RootStore/Types";
import RootReactions from "src/stores/RootStore/RootReactions";
import Cookies from "src/containers/Cookies";
import Ads from "src/containers/Ads";
import NewsLetter from "src/containers/NewsLetter";
import Announcement from "src/containers/Announcement";
import "currency-flags/dist/currency-flags.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "src/styles/index.scss";

class App extends Component {
  rootStore = new RootStore();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.rootStore.loading();
  }

  renderProvider = (rootPayload, loaded) => (
    <ErrorBoundary honeybadger={window?.honeybadger}>
      <Router>
        <RootContext.Provider
          value={{
            rootPayload: rootPayload,
            loaded
          }}
        >
          <Routes />
          <RootReactions store={this.rootStore} />
          <Cookies />
          <Ads />
          <NewsLetter />
        </RootContext.Provider>
      </Router>
    </ErrorBoundary>
  );

  render() {
    switch (this.rootStore.state.kind) {
      case "ready":
        return this.renderProvider(this.rootStore.state.rootPayload, true);

      default:
        return this.renderProvider(undefined, false);
    }
  }
}

export default observer(App);
