export const emailRegex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const required = value => {
  if (!value) {
    return "Required";
  }
};

export const email = value => {
  if (!value) {
    return "Required";
  } else if (!value.match(emailRegex)) {
    return `${value} must be an email`;
  }
};
