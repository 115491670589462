import * as React from "react";

const FirstSection = () => (
  <div className="certification-wrapper">
    <div className="mobile">
      <h2 className="header">Learning on the go</h2>
      <span className="sub-header">Learn from anywhere at anytime</span>
      <div className="images">
        <img
          className="phone"
          src={require("../../../../../../assets/images/landings/certifications/phone.webp")}
          alt="phone"
        />
      </div>
    </div>

    <div className="content">
      <div className="desktop">
        <h2 className="header">Learning on the go</h2>
        <span className="sub-header">Learn from anywhere at anytime</span>
      </div>
      <p className="description">
        All our learning is on demand, split into bite sized stories and very
        mobile friendly, so whether you want to learn on a train to work, a
        SpaceX mission to mars, sipping a cocktail on a beach, or even while
        you’re having some “me time” on the loo, we've got your back.
      </p>

      <a className="fl-button basic" data-smooth-scroll-to="#pricing-container">
        Get started
      </a>
    </div>

    <div className="images desktop">
      <img
        className="image phone"
        src={require("../../../../../../assets/images/landings/certifications/phone.webp")}
        alt="phone"
      />
    </div>
  </div>
);

export default FirstSection;
