import * as React from "react";
import { CourseResource } from "../../../../../stores/CoursesStore/Types";

interface Props {
  courseResource: CourseResource;
}

const Rating: React.FC<Props> = ({ courseResource }) => {
  if (courseResource.content_version == "upcoming") {
    return (
      <h5
        className={`${
          courseResource.enrolled_student_present ? "enrolled" : ""
        }`}
      >
        Coming {courseResource.course_release_date}
      </h5>
    );
  }

  return (
    <div
      className={`rating-info ${
        courseResource.enrolled_student_present ? "enrolled" : ""
      }`}
    >
      <div
        className="ui massive star rating disabled"
        data-max-rating="5"
        data-rating={Number(courseResource.rating).toFixed(1)}
      >
        <i className="icon active"></i>
        <i className="icon active"></i>
        <i className="icon active"></i>
        <i className="icon active"></i>
        <i className="icon active"></i>
      </div>
      <p>{Number(courseResource.rating).toFixed(1)}</p>
    </div>
  );
};

export default Rating;
