import React from "react";

const Learners = () => (
  <div className="learners">
    <p className="mobile">Trusted by learners at</p>
    <div className="images">
      <span className="desktop">Trusted by learners at</span>
      <img
        className="ui tiny image"
        src={require("../../../../../assets/images/courses/logos/google.png")}
        alt="google"
      />
      <img
        className="ui small image kroger"
        src={require("../../../../../assets/images/courses/logos/kroger.png")}
        alt="kroger"
      />
      <img
        className="ui tiny image"
        src={require("../../../../../assets/images/courses/logos/forbes.png")}
        alt="forbes"
      />
      <img
        className="ui tiny image facebook"
        src={require("../../../../../assets/images/courses/logos/facebook.png")}
        alt="facebook"
      />
      <img
        className="ui tiny image"
        src={require("../../../../../assets/images/courses/logos/amazon.png")}
        alt="amazon"
      />
      <img
        className="ui tiny image"
        src={require("../../../../../assets/images/courses/logos/ideo.png")}
        alt="ideo"
      />
    </div>
  </div>
);

export default Learners;
