export default {
  payments: {
    successfulPaymentPath({
      userActivationStatus,
      redirectUrl,
      userEmail,
      activationToken
    }: PaymentSuccessfulState): string {
      const query = [
        `redirectUrl=${redirectUrl}`,
        `userEmail=${userEmail}`,
        `activationToken=${activationToken}`
      ]
      const queryString = query.join('&')
      return `/payments/successful/${userActivationStatus}?${queryString}`
    }
  },
  userActivations: {
    editSessionUserActivationsPath(activationToken: string): string {
      return `/session/user_activations/${activationToken}/edit`
    }
  },
  api: {
    userActivations: {
      updateUserActivationsPath(activationToken: string): string {
        return `/api/user_activations/${activationToken}`
      }
    }
  }
}