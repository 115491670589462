import React, { Component } from "react";
import { observer } from "mobx-react";
import qs from "qs";
import PricingStore from "../../stores/PricingStore";
import {
  Landing,
  AiCoursePicker,
  FeaturedCourses,
  CourseCategories,
  Introduction,
  Reviews,
  Alumni,
  Pricing,
  Certification,
  GetStarted,
  LeaderBoard,
  Subscription,
  Blog
} from "./components";
import { RootContext } from "../../stores/RootStore/Types";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";

interface Props {
  handleShowSignInSideBar: () => void;
  pricingStore: PricingStore;
  location: {
    search: string;
  };
  currentUser: CurrentUserResource;
}

interface State {}

class Home extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { search } = this.props.location;
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

    if (queryParams?.scroll_to) {
      setTimeout(() => {
        const elem = document.getElementById(`${queryParams?.scroll_to}`);
        const headerOffset = 90;
        const elementPosition = elem.getBoundingClientRect().top;
        let offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }, 3000);
    }
  }

  renderPricing = state => {
    switch (state.kind) {
      case "ready":
        return (
          <Pricing
            handleShowSignInSideBar={this.props.handleShowSignInSideBar}
            pricing={state.pricing}
            showSigninSidebar={true}
            singleCourseHeading="Lifetime single <br/> course access"
          />
        );
      case "loading":
        return (
          <div
            className="pricing ui vertical center aligned segment"
            id="pricing-container"
          >
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  render() {
    const { pricingState } = this.props.pricingStore;

    return (
      <RootContext.Consumer>
        {({ loaded }) => {
          return (
            <div>
              <div className="new-landing-page">
                <div className="ui facelift-landing-page">
                  <Landing />
                  <AiCoursePicker />
                  <FeaturedCourses />
                  <Introduction />
                  <div id="courses">
                    <CourseCategories />
                  </div>
                  <Reviews />
                  <Alumni />
                  {this.renderPricing(pricingState)}
                  <Certification />
                  <LeaderBoard />
                  <Subscription />
                  <Blog />
                </div>
              </div>
            </div>
          );
        }}
      </RootContext.Consumer>
    );
  }
}

export default observer(Home);
