import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  CoursePreviewResource,
  QuotationResource
} from "../../stores/CoursePreviewStore/Types";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";
import Landing from "./Landing";
import Description from "./Description";
import PurchaseNavbar from "./PurchaseNavbar";
import ThoughtLeaders from "./ThoughtLeaders";
import CourseChapters from "./CourseChapters";
import Reviews from "./Reviews";
import CoursePricing from "./CoursePricing";
import FeaturedCourses from "./FeaturedCourses";
import {
  Certification,
  Pricing,
  Introduction,
  Alumni
} from "../Home/components";
import PricingStore from "../../stores/PricingStore";

interface Props {
  mode?: string;
  course: CoursePreviewResource;
  refresh: (slug: string) => void;
  handleShowSignInSideBar: () => void;
  currentUser: CurrentUserResource;
  pricingStore: PricingStore;
  loadingQuotations: boolean;
  quotationResource: QuotationResource;
  loadingEnrolmentInfo: boolean;
  enrolmentInfo: {
    existing_subscription: boolean;
    enrolment_id: number;
  };
}

class Wrapper extends Component<Props, {}> {
  renderPricing = state => {
    const { course } = this.props;

    switch (state.kind) {
      case "ready":
        return (
          <Pricing
            handleShowSignInSideBar={this.props.handleShowSignInSideBar}
            pricing={state.pricing}
            singleCourseHeading={course.name}
            showSigninSidebar={
              this.props.currentUser.kind === "current-user-absent"
            }
            courseSlug={course.subscription_only ? null : course.slug}
          />
        );
      case "loading":
        return (
          <div
            className="pricing ui vertical center aligned segment"
            id="pricing-container"
          >
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  render() {
    const {
      mode,
      refresh,
      course,
      currentUser,
      handleShowSignInSideBar,
      pricingStore,
      quotationResource,
      loadingQuotations,
      loadingEnrolmentInfo,
      enrolmentInfo
    } = this.props;
    const { pricingState } = pricingStore;

    return (
      <React.Fragment>
        {quotationResource && (
          <PurchaseNavbar
            course={course}
            currentUser={currentUser}
            quotationResource={quotationResource}
            loadingQuotations={loadingQuotations}
            loadingEnrolmentInfo={loadingEnrolmentInfo}
            enrolmentInfo={enrolmentInfo}
            handleShowSignInSideBar={handleShowSignInSideBar}
            showSigninSidebar={currentUser.kind === "current-user-absent"}
          />
        )}
        <div className="ui facelift-course-preview">
          <Landing
            mode={mode}
            refresh={refresh}
            course={course}
            currentUser={currentUser}
            quotationResource={quotationResource}
            loadingQuotations={loadingQuotations}
            loadingEnrolmentInfo={loadingEnrolmentInfo}
            enrolmentInfo={enrolmentInfo}
            handleShowSignInSideBar={handleShowSignInSideBar}
            showSigninSidebar={currentUser.kind === "current-user-absent"}
          />
          <Description course={course} />
          {this.props.mode !== "upcoming" && (
            <>
              <Reviews courseSlug={course?.slug} />
            </>
          )}
          <Introduction />
          <ThoughtLeaders courseSlug={course?.slug} />
          >
          <CourseChapters topics={course?.topics} courseSlug={course?.slug} />
          {this.props.mode !== "upcoming" && (
            <>{this.renderPricing(pricingState)}</>
          )}
          <Certification />
          {this.props.mode !== "upcoming" && (
            <>
              <Alumni />
              <CoursePricing
                course={course}
                quotationResource={quotationResource}
                currentUser={currentUser}
                handleShowSignInSideBar={handleShowSignInSideBar}
                loadingQuotations={loadingQuotations}
                loadingEnrolmentInfo={loadingEnrolmentInfo}
                enrolmentInfo={enrolmentInfo}
                showSigninSidebar={currentUser.kind === "current-user-absent"}
              />
            </>
          )}
          {this.props.mode == "upcoming" && <Alumni />}
          <FeaturedCourses courseSlug={course?.slug} />
        </div>
      </React.Fragment>
    );
  }
}

export default observer(Wrapper);
