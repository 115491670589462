import React from "react";

const Access = ({
  number,
  header,
  text,
  imageUrl,
  inverted = false,
  size = "medium"
}: {
  number: number;
  header: string;
  text: string;
  imageUrl: string;
  inverted?: boolean;
  size?: string;
}) => (
  <>
    <div className="access mobile">
      <div
        className={`ui ${size} image`}
        style={{
          backgroundImage: `url(${require(`../../../../../assets/images/teams/access/${imageUrl}`)})`
        }}
      ></div>
      <div className="info">
        <div className="number">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
          >
            <path
              d="M5.9761 38.7482C1.43801 33.2796 -6.66946 17.8356 10.2364 4.21781C20.6773 -4.19362 30.2142 1.07898 39.78 10.5128C49.3457 19.9466 41.4501 35.0018 32.9954 41.5606C24.5408 48.1195 10.5142 44.2169 5.9761 38.7482Z"
              fill="#139AD6"
            />
          </svg>
          <span> {number}</span>
        </div>
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: header }}></h4>
          <p dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
      </div>
    </div>
    <div className={`access desktop ${inverted ? "inverted" : ""}`}>
      {inverted ? (
        <>
          <div
            className={`ui ${size} image`}
            style={{
              backgroundImage: `url(${require(`../../../../../assets/images/teams/access/${imageUrl}`)})`
            }}
          ></div>
          <div className="info">
            <div className="text">
              <h4 dangerouslySetInnerHTML={{ __html: header }}></h4>
              <p dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
            <div className="number">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
              >
                <path
                  d="M5.9761 38.7482C1.43801 33.2796 -6.66946 17.8356 10.2364 4.21781C20.6773 -4.19362 30.2142 1.07898 39.78 10.5128C49.3457 19.9466 41.4501 35.0018 32.9954 41.5606C24.5408 48.1195 10.5142 44.2169 5.9761 38.7482Z"
                  fill="#139AD6"
                />
              </svg>
              <span>{number}</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="info">
            <div className="number">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
              >
                <path
                  d="M5.9761 38.7482C1.43801 33.2796 -6.66946 17.8356 10.2364 4.21781C20.6773 -4.19362 30.2142 1.07898 39.78 10.5128C49.3457 19.9466 41.4501 35.0018 32.9954 41.5606C24.5408 48.1195 10.5142 44.2169 5.9761 38.7482Z"
                  fill="#139AD6"
                />
              </svg>
              <span>{number}</span>
            </div>
            <div className="text">
              <h4>{header}</h4>
              <p dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
          </div>
          <div
            className={`ui ${size} image`}
            style={{
              backgroundImage: `url(${require(`../../../../../assets/images/teams/access/${imageUrl}`)})`
            }}
          ></div>
        </>
      )}
    </div>
  </>
);

const AccessForTeam = () => (
  <div className="ui segment access-for-team">
    <div className="ui container">
      <h2>What you access for your team</h2>

      <div className="ui">
        <Access
          number={1}
          header="Shareable Nano MBA Certifications"
          text="Your team gets real certifications from our <br /> partner brands,
          including Cannes Lions and <br /> Parsons, with options to add your
          own branding <br /> + course content customisation."
          imageUrl="cert.png"
        />
        <Access
          number={2}
          size="large"
          header="Team tracking & analytics"
          text="Easily track and manage team <br /> progress and identify top learners <br /> with your own leaderboard!"
          imageUrl="analytics.png"
          inverted={true}
        />
        <Access
          number={3}
          header="Marked by real humans"
          text="Real-time marking with personalised feedback <br /> to support your team’s development 24/7."
          imageUrl="human.png"
        />
        <Access
          number={4}
          size="large"
          header="Taught by industry leading <br/>practitioners"
          text="Learn from the people who do the job <br /> day in day out. Less academic theory
          <br />  and more real world solutions."
          imageUrl="teachers.png"
          inverted={true}
        />
        <Access
          number={5}
          size="large"
          header="80%+ Completion Rates"
          text="Learners love the training <br /> because it’s on demand,
          <br />  gamified, and immediately <br/> actionable."
          imageUrl="review.png"
        />
      </div>
    </div>
  </div>
);

export default AccessForTeam;
