import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Profile } from "../../../stores/ProfileStore/Types";

interface Props {
  profileResource: Profile;
  profilePicture: string;
  handleShowSettingsModal: (val: boolean) => void;
}

class Landing extends Component<Props, {}> {
  countryFlagImage = (countryCode) => {
    let img;
    try {
      img = require(
        `../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`,
      );
    } catch {
      img = "";
    }

    return img;
  };

  componentDidMount() {
    ($(".fit-text.user-name") as any).fitText(1.4);
  }

  renderSubscription = (profileResource) => (
    <>
      <div className="icon">
        <img
          src={require("../../../../../assets/images/profile/subscription-icon.svg")}
          alt="badges"
        />
      </div>
      <div className="desc">
        <h3>
          <strong style={{ textTransform: "capitalize" }}>
            {profileResource.can_upgrade ? (
              <div className="upgrade">
                <span>Upgrade</span>
              </div>
            ) : (
              <div className="status active">
                <span>Active</span>
              </div>
            )}
          </strong>
        </h3>

        <span>Subscription</span>
      </div>
    </>
  );

  render() {
    const { profileResource, handleShowSettingsModal, profilePicture } =
      this.props;
    return (
      <div className="ui column left content">
        <div className="content-container">
          <span className="welcome">Welcome back, intelligent being</span>
          <h1 className="user-name fit-text">{profileResource.name}</h1>
          <div className="profile-container">
            <div className="bio">
              <div className="profile-pic">
                <img src={profilePicture} className="user-image" />
                <div className="country">
                  <img src={this.countryFlagImage(profileResource.country)} />
                </div>
              </div>
              <div className="ranking">
                <div className="settings">
                  <button
                    className="ui button fl-button"
                    onClick={() => handleShowSettingsModal(true)}
                  >
                    <span>My Account</span>
                    <i className="setting icon"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="badges-and-certs">
              <Link to="/achievements/badges">
                <div className="badges">
                  <div className="icon">
                    <img
                      src={require("../../../../../assets/images/profile/badge.png")}
                      alt="badges"
                    />
                  </div>
                  <div className="desc">
                    <h3>
                      {profileResource.badge.awarded}/
                      {profileResource.badge.eligible}
                    </h3>
                    <span>Badges</span>
                  </div>
                </div>
              </Link>
              <a href="#" data-smooth-scroll-to="#certificates">
                <div className="certs">
                  <div className="icon">
                    <img
                      src={require("../../../../../assets/images/profile/cert.png")}
                      alt="certificates"
                    />
                  </div>
                  <div className="desc">
                    <h3>{profileResource.enrolments.finished}</h3>
                    <span>Certificates</span>
                  </div>
                </div>
              </a>
              {profileResource.subscription ? (
                <div>
                  {profileResource.subscription.active ? (
                    <>
                      {profileResource.can_upgrade ? (
                        <Link
                          to={profileResource.subscription_url}
                          className="subscription-pass"
                        >
                          {this.renderSubscription(profileResource)}
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          className="subscription-pass"
                          onClick={() => handleShowSettingsModal(true)}
                        >
                          {this.renderSubscription(profileResource)}
                        </Link>
                      )}
                    </>
                  ) : (
                    <Link
                      className="subscription-pass"
                      to={
                        profileResource.subscription.interval === "month"
                          ? "/pass/month/renew"
                          : "/pass/year/renew"
                      }
                    >
                      <div className="icon">
                        <img
                          src={require("../../../../../assets/images/profile/subscription-icon.svg")}
                          alt="badges"
                        />
                      </div>
                      <div className="desc">
                        <h3>
                          <strong style={{ textTransform: "capitalize" }}>
                            <div className="status inactive">
                              <span>Renew</span>
                            </div>
                          </strong>
                        </h3>
                        <span>Subscription</span>
                      </div>
                    </Link>
                  )}
                </div>
              ) : (
                <Link className="subscription-pass" to="/passes/month/pay">
                  <div className="icon">
                    <img
                      src={require("../../../../../assets/images/profile/subscription-icon.svg")}
                      alt="badges"
                    />
                  </div>
                  <div className="desc">
                    <h3>
                      <strong>
                        <div className="upgrade">
                          <span>Upgrade</span>
                        </div>
                      </strong>
                    </h3>

                    <span>Subscription</span>
                  </div>
                </Link>
              )}
            </div>
            <div className="zen-message">
              <div className="header">
                <h3>Fact of the day </h3>
                <img
                  src={require("../../../../../assets/images/profile/quote.png")}
                />
              </div>
              <div className="zen-content">
                <span>{profileResource.zen_pool}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
