import { Dispatch } from "redux";
import { AxiosError, AxiosResponse } from "axios";
import { AppThunk } from "../../../CommunityStore";
import { getRequest } from "../../../../utils/httpRequest";
import {
  LOAD_TOPICS,
  LOAD_TOPICS_SUCCESS,
  LOAD_TOPICS_FAILURE,
  LoadTopicsAction,
  LoadTopicsSuccessAction,
  TopicActionTypes,
  Topic,
} from "../types";

export const loadTopics = (): LoadTopicsAction => ({
  type: LOAD_TOPICS,
});

export const loadTopicsSuccess = (
  topics: Topic[],
): LoadTopicsSuccessAction => ({
  type: LOAD_TOPICS_SUCCESS,
  payload: topics,
});

// Thunk action creator
export const fetchTopics =
  (): AppThunk => async (dispatch: Dispatch<TopicActionTypes>) => {
    dispatch({ type: LOAD_TOPICS });
    getRequest("/api/community/topics.json")
      .then((response: AxiosResponse) => {
        dispatch(loadTopicsSuccess(response.data));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        dispatch({ type: LOAD_TOPICS_FAILURE, payload: error.message });
      });
  };
