import React, { useState, useEffect } from "react";

const ChatMessage = ({ message, animate }) => {
  const isUser = message.role === "user";

  const roleStyles = isUser
    ? "bg-chat text-white ml-auto"
    : "bg-gray-200 bg-chat mr-auto";

  return (
    <div className="flex items-start px-4 z-1">
      <div
        className={`px-3 py-2 mt-3 z-1 rounded-md whitespace-pre-wrap break-words ${roleStyles} transform transition-all duration-500 ease-out`}
        dangerouslySetInnerHTML={{ __html: message.content }}
      ></div>
    </div>
  );
};

export default ChatMessage;
