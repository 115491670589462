import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { AxiosResponse } from "axios";
import ReactHtmlParser from "react-html-parser";
import Discussion from "./Discussion";
import Conversations from "./Conversations";
import Assignment from "./Assignment";
import { QuestionPreview } from "../../../stores/CoursePreviewStore/Types";
import { Answer, Enrolment } from "../../../stores/EnrolmentStore/Types";
import { getRequest, postRequest } from "../../../utils/httpRequest";
import { errorNotification } from "../../../utils/helpers";
import { CommentResource } from "../../../stores/CommentStore/Types";
import PersonalLessonSummary from "./PersonalLessonSummary";

interface Props {
  enrolment: Enrolment;
  lesson: QuestionPreview;
  enrolmentId: number;
  lessonId: number;
  answer: Answer;
  page: string;
  refreshAnswers: () => void;
  loadingLesson: boolean;
  loadingAnswer: boolean;
  lastLesson: QuestionPreview;
  getXp: () => void;
  getRecentNotifications: () => void;
  currentUser: any;
}

interface State {
  comments: CommentResource[];
  loaded: boolean;
  usingLive: boolean;
}

class Landing extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      comments: [],
      loaded: false,
      usingLive: false
    };
  }

  fetchComments = () => {
    return getRequest(`/api/comments.json?question_id=${this.props.lessonId}`)
      .then((response: AxiosResponse) => {
        this.setState({ comments: response.data, loaded: true });
      })
      .catch(error => {
        this.setState({ loaded: true });

        errorNotification(
          "Error loading comments",
          JSON.stringify(error.response.data)
        );
      });
  };

  updateComments = () => {
    this.setState({ loaded: false });
    this.fetchComments();
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchComments();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lessonId !== this.props.lessonId) {
      this.updateComments();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useLive = e => {
    e.preventDefault();

    const { answer } = this.props;

    this.setState({ usingLive: true });

    postRequest(`/api/answers/${answer.id}/life_uses`, {})
      .then((response: AxiosResponse) => {
        window.location.reload();
      })
      .catch(error => {
        this.setState({ usingLive: false });

        errorNotification(
          "There was an error with using lives",
          JSON.stringify(error.response.data)
        );
      });
  };

  nextQuestionPath = () => {
    const { lesson, lastLesson, enrolment } = this.props;

    if (lesson?.id === lastLesson?.id) {
      if (enrolment.state === "passed") {
        return `/enrolments/${enrolment?.id}/certificate`;
      } else if (enrolment.state === "failed") {
        return `/enrolments/${enrolment?.id}`;
      } else {
        return lesson?.next_question_path;
      }
    } else {
      return lesson?.next_question_path;
    }
  };

  render() {
    const {
      enrolment,
      enrolmentId,
      lesson,
      lessonId,
      answer,
      refreshAnswers,
      loadingLesson,
      loadingAnswer,
      lastLesson,
      getXp,
      page,
      getRecentNotifications,
      currentUser
    } = this.props;

    const { comments, loaded, usingLive } = this.state;

    return (
      <>
        {loadingLesson ? (
          <>
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
            <div className="hero">
              <div
                className="bg"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${lesson?.topic?.masthead_image_url})`
                }}
              ></div>
            </div>
          </>
        ) : (
          <>
            {lesson?.kind === "Opinion" &&
              answer &&
              answer.state == "pending" && (
                <Redirect
                  to={`/enrolments/${enrolment.id}/questions/${lessonId}/answers/${answer.id}/votes`}
                />
              )}
            <div className="hero">
              <div
                className="bg"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${lesson?.topic?.masthead_image_url})`
                }}
              ></div>
              <div className="course-header">
                <p>
                  Chapter {(lesson?.topic.position || 0) + 1} | Lesson{" "}
                  {(lesson?.position || 0) + 1}
                </p>
                <h1>{lesson?.name}</h1>
                <p className="minutes">{lesson?.length_minutes} min</p>
              </div>
            </div>
            <div className="ui segment" style={{ zIndex: 30 }}>
              <div className="ui container current-lesson-container">
                <div className="current-lesson">
                  <div className="ui text container lesson-text-target">
                    {answer && ["pending", "submitted"].includes(answer.state) && (
                      <div className="status-container pending-message">
                        <h4>Hold tight, we’re marking this lesson for you</h4>
                      </div>
                    )}

                    {answer && answer.state === "passed" && (
                      <div className="status-container passed-message">
                        <h4>Bravo! You’ve passed this lesson</h4>
                      </div>
                    )}
                    {answer &&
                      answer.state === "failed" &&
                      enrolment.lives_count > 0 && (
                        <div className="status-container failed-message">
                          <h4>Whoopsie daisy</h4>
                          <button
                            className={`ui button ${
                              usingLive ? "loading" : ""
                            }`}
                            onClick={this.useLive}
                          >
                            Use 1 life to try again
                          </button>
                        </div>
                      )}

                    <div className="lesson-target" />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lesson?.formatted_foreword
                      }}
                    />
                    <PersonalLessonSummary enrolment={enrolment} question={lesson} />
                  </div>
                </div>
                {lesson && (
                  <Assignment
                    mode={lesson.kind}
                    enrolment={enrolment}
                    question={lesson}
                    answer={answer}
                    loadingAnswer={loadingAnswer}
                    refreshAnswers={refreshAnswers}
                    getRecentNotifications={getRecentNotifications}
                    getXp={getXp}
                    page={page}
                    currentUser={currentUser}
                  />
                )}
              </div>
              <div className="ui container">
                <div className="controls">
                  <div className="previous action">
                    <Link
                      to={lesson?.previous_question_path}
                      onClick={this.scrollToTop}
                    >
                      <button className="circular ui icon button">
                        <i className="icon angle left"></i>
                      </button>
                      <div className="lesson">Prev</div>
                    </Link>
                  </div>
                  <div className="next action">
                    <Link
                      to={this.nextQuestionPath()}
                      onClick={this.scrollToTop}
                    >
                      <div className="lesson">Next</div>
                      <button className="circular ui icon button">
                        <i className="icon angle right"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <Discussion
                updateComments={this.updateComments}
                lessonId={lessonId}
                currentUser={currentUser}
              />
              <Conversations
                comments={comments}
                loaded={loaded}
                questionId={lessonId}
                currentUser={currentUser}
                enrolmentId={enrolmentId}
                updateComments={this.updateComments}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default Landing;
