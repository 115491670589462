import React, { Component } from "react";
import {
  comparer,
  IReactionDisposer,
  IReactionOptions,
  IReactionPublic,
  reaction
} from "mobx";

export interface ReactState {}
export interface BaseReactionProps<Store> {
  store: Store;
  fireImmediately?: boolean;
  debounceDelay?: number;
}

export type ReactionComparer<ObservedState> = (
  prev: ObservedState,
  current: ObservedState
) => boolean;

abstract class BaseReaction<
  Store,
  ObservedState,
  P extends BaseReactionProps<Store>
> extends Component<P, ReactState> {
  protected abstract tester: (r?: IReactionPublic) => ObservedState;
  protected abstract effect: (arg?: ObservedState, r?: IReactionPublic) => void;

  protected comparer: ReactionComparer<ObservedState> = comparer.default;
  private running?: IReactionDisposer;
  private options(): IReactionOptions | undefined {
    return {
      fireImmediately: this.props.fireImmediately,
      delay: this.props.debounceDelay,
      equals: this.comparer
    };
  }

  constructor(props: P) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.running = this.run();
  }

  componentWillUnmount() {
    if (this.running) {
      this.running();
    }
  }

  run = (): IReactionDisposer => {
    return reaction(this.tester, this.effect, this.options());
  };

  render() {
    return <span />;
  }
}
export default BaseReaction;
