import React from "react";
import { Quotation } from "../../../../../stores/CoursePreviewStore/Types";

interface Props {
  activeQuote: Quotation;
  couponCode: string;
  refCode: string;
}

const Yearly: React.FC<Props> = ({ activeQuote, couponCode, refCode }) => {
  return (
    <div className="extra content">
      {(couponCode.length > 0 || refCode.length > 0) && (
        <div className="price month discount">
          <div className="name">
            <strong>Discount:</strong>
          </div>
          <div className="discount-info">
            <span className="minus">-</span>{" "}
            <span className="symbol">{activeQuote?.currency}</span>
            <span className="amount">
              <strong>{activeQuote?.discount_amount}</strong>
            </span>{" "}
            <span className="coupon-name">({couponCode || refCode})</span>
          </div>
        </div>
      )}
      <div className="price month total">
        <div className="payment-due">
          <strong>Total:</strong>
        </div>
        <div className="payment-info">
          <span className="symbol">{activeQuote?.currency}</span>
          <span className="amount">
            <strong>{activeQuote?.formatted_amount}</strong>
          </span>
          <span className="code">{activeQuote?.currency_code}</span>
        </div>
      </div>
    </div>
  );
};

export default Yearly;
