import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { AxiosResponse, AxiosError } from "axios";
import { notification } from "../../../utils/helpers";
import { putRequest } from "../../../utils/httpRequest";

interface Props {
  userId: number;
  showPasswordModal: boolean;
  handleShowPasswordModal: (val: boolean) => void;
  handleShowSettingsModal: (val: boolean) => void;
  hideModals: () => void;
}

interface State {
  password: string;
  passwordConfirmation: string;
  updatingPassword: boolean;
  [x: string]: any;
}

class Password extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
      updatingPassword: false
    };
  }

  handleChange(key, e) {
    this.setState({ [key]: e.target.value });
  }

  updatePassword = e => {
    const {
      userId,
      handleShowPasswordModal,
      handleShowSettingsModal
    } = this.props;
    const { password, passwordConfirmation } = this.state;

    const data = {
      user: {
        password,
        password_confirmation: passwordConfirmation
      }
    };

    e.preventDefault();
    this.setState({ updatingPassword: true });

    return putRequest(`/api/users/${userId}/change_password.json`, data)
      .then((response: AxiosResponse) => {
        notification("Password was successfully updated.", "notification");
        handleShowPasswordModal(false);
        handleShowSettingsModal(true);
        this.setState({
          updatingPassword: false
        });
      })
      .catch((error: AxiosError<{ error: string }>) => {
        notification(error.response.data.error, "error");

        this.setState({ updatingPassword: false });
      });
  };

  render() {
    const {
      showPasswordModal,
      handleShowPasswordModal,
      handleShowSettingsModal,
      hideModals
    } = this.props;

    const { password, passwordConfirmation, updatingPassword } = this.state;

    return (
      <>
        <Modal
          open={showPasswordModal}
          className="profile-settings-modal password-settings-modal"
        >
          <Modal.Header>
            <div className="close">
              <a
                className="ui left button"
                id="closeNav"
                onClick={() => handleShowSettingsModal(true)}
              >
                <i className="angle left icon" />
              </a>
            </div>
            <h2>Change Password</h2>
          </Modal.Header>
          <Modal.Content>
            <div className="ui password-information">
              <div className="ui three column inputs">
                <div className="ui column password">
                  <div className="label">
                    <label>New password</label>
                  </div>
                  <div className="input">
                    <input
                      type="password"
                      value={password}
                      placeholder="New password"
                      className="general-input"
                      onChange={this.handleChange.bind(this, "password")}
                    />
                  </div>
                </div>
                <div className="ui column password">
                  <div className="label">
                    <label>Confirm new password</label>
                  </div>
                  <div className="input">
                    <input
                      type="password"
                      value={passwordConfirmation}
                      placeholder="Repeat new password"
                      className="general-input"
                      onChange={this.handleChange.bind(
                        this,
                        "passwordConfirmation"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="ui segment">
              <button
                className="ui right large button cancel"
                onClick={() => hideModals()}
              >
                Cancel
              </button>
              <button
                className={`ui right large button save ${
                  updatingPassword ? "loading" : ""
                }`}
                onClick={this.updatePassword}
              >
                Save
              </button>
            </div>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default Password;
