import {
  LOAD_TRENDING_POSTS,
  LOAD_TRENDING_POSTS_SUCCESS,
  LOAD_TRENDING_POSTS_FAILURE,
  FEATURE_POST_SUCCESS,
  TrendingPostState,
  TrendingPostActionTypes,
} from "./types";

const initialState: TrendingPostState = {
  trendingPosts: [],
  loading: false,
  created: false,
  error: "",
};

export const trendingPostReducer = (
  state = initialState,
  action: TrendingPostActionTypes,
): TrendingPostState => {
  switch (action.type) {
    case LOAD_TRENDING_POSTS:
      return { ...state, loading: true };

    case LOAD_TRENDING_POSTS_SUCCESS:
      return { ...state, loading: false, trendingPosts: action.payload };

    case LOAD_TRENDING_POSTS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case FEATURE_POST_SUCCESS:
      const isAlreadyFeatured = state.trendingPosts.some(
        (post) => post.id === action.payload.id,
      );

      return {
        ...state,
        created: true,
        trendingPosts: isAlreadyFeatured
          ? state.trendingPosts
          : [action.payload, ...state.trendingPosts],
      };

    default:
      return state;
  }
};
