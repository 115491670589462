import React, { Component } from "react";
import find from "lodash/find";
import Header from "../common/Header";
import Course from "./components/Course";
import Subscriptions from "./components/Subscriptions";
import Option from "./common/Option";
import Action from "../common/Action";
import { Priceable, PaymentType } from "../Types";
import { Quotation } from "../../../stores/CoursePreviewStore/Types";
import { scrollIntoView } from "../../../utils/helpers";

type CourseType = {
  key: string;
  value: string;
  label: string;
};

interface Props {
  slug: string;
  path: string;
  paymentType: PaymentType;
  isExpanded: boolean;
  isCourseError: boolean;
  priceable: Priceable;
  activeQuote: Quotation;
  courses: CourseType[];
  defaultCourse: string;
  couponCode: string;
  refCode: string;
  quotes: Quotation[];
  loadingSubscription: boolean;
  handleChange: (key: string, value: any) => void;
  onCourseChange: (e: any, slug: string) => void;
}

interface State {
  isExpanded: boolean;
  isComplete: boolean;
  selectedCourse: string;
  isCourseError: boolean;
}

class SelectPlan extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: true,
      selectedCourse: props.defaultCourse,
      isComplete: false,
      isCourseError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isExpanded !== prevProps.isExpanded) {
      this.setState({
        isExpanded: this.props.isExpanded,
      });
    }

    if (this.props.defaultCourse !== prevProps.defaultCourse) {
      this.setState({
        selectedCourse: this.props.defaultCourse,
      });
    }
  }

  toggleView = (e) => {
    e.preventDefault();

    this.setState({ isExpanded: !this.state.isExpanded });
  };

  onCourseChange = (e, data) => {
    this.setState({ selectedCourse: data.value });
    this.props.handleChange("selectedCourse", data.value);
    this.props.handleChange("isCourseError", false);
    this.props.onCourseChange(e, data.value);
  };

  getId = () => {
    const { priceable } = this.props;

    return priceable?.type === "course"
      ? "checkout-select-course-next"
      : "checkout-select-plan-next";
  };

  next = (e) => {
    e?.preventDefault();

    const { activeQuote, slug } = this.props;

    const { selectedCourse } = this.state;

    if (selectedCourse || ["year", "month", "gift"].includes(slug)) {
      this.setState({ isExpanded: false, isComplete: true });

      const element = document.getElementById("billing-info");

      scrollIntoView(element);

      const step = activeQuote.amount === 0 ? "payment" : "billing";

      (window as any)?.dataLayer.push({
        event: "customClick",
        elementId: this.getId(),
      });

      this.props.handleChange("step", step);
    } else {
      this.setState({ isCourseError: true });
      this.props.handleChange("isCourseError", true);
    }
  };

  subscriptionActiveQuote = () => {
    const { quotes, slug } = this.props;

    return find(quotes, (quote) => quote?.interval === "annually");
  };

  render() {
    const {
      isCourseError,
      courses,
      priceable,
      activeQuote,
      paymentType,
      slug,
      path,
      couponCode,
      refCode,
      loadingSubscription,
    } = this.props;

    const { isExpanded, selectedCourse, isComplete } = this.state;

    return (
      <div className={isExpanded ? "expanded" : "closed"}>
        {loadingSubscription && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <hr />
        <Header
          title={
            paymentType === "course" ||
            (paymentType === "gift" && priceable?.type === "course")
              ? "Step 1: Select your course"
              : "Step 1: Select your subscription"
          }
          toggleView={this.toggleView}
          isExpanded={isExpanded}
          isComplete={isComplete}
        />
        {isExpanded && (
          <>
            <>
              {paymentType === "course" && (
                <Course
                  courses={courses}
                  selectedCourse={selectedCourse}
                  onCourseChange={this.onCourseChange}
                  isError={isCourseError}
                />
              )}
              {(paymentType === "course" ||
                (paymentType === "gift" && priceable?.type === "course")) && (
                <Option
                  title="Pay per course"
                  meta="Lifetime access to one course"
                  isActive={true}
                  discountAmount={
                    couponCode || refCode
                      ? activeQuote?.original_price?.formatted_amount
                      : null
                  }
                  priceable={priceable}
                  symbol={activeQuote?.currency}
                  amount={activeQuote?.formatted_amount}
                  code={activeQuote?.currency_code}
                />
              )}
              {paymentType === "course" && (
                <div className="subscription-info">
                  <p>
                    <strong>
                      Or subscribe annually and{" "}
                      <span className="primary-blue-color">save:</span>
                    </strong>
                  </p>
                </div>
              )}
            </>
            <>
              {paymentType === "gift" && priceable?.type === "course" ? (
                <></>
              ) : (
                <Subscriptions
                  title="Pay per course"
                  slug={slug}
                  path={path}
                  couponCode={couponCode}
                  refCode={refCode}
                  priceable={priceable}
                  showLinks={priceable?.type !== "course"}
                  activeQuote={
                    priceable?.is_restricted
                      ? activeQuote
                      : this.subscriptionActiveQuote()
                  }
                  isActive={paymentType === "subscription"}
                />
              )}
            </>
            {priceable?.is_restricted && (
              <>
                <div className="subscription-info">
                  <p>
                    <strong>
                      Or subscribe anually and{" "}
                      <span className="primary-blue-color">save:</span>
                    </strong>
                  </p>
                </div>
                <Subscriptions
                  title="Pay per course"
                  slug="month"
                  path={path}
                  showLinks={false}
                  couponCode={couponCode}
                  refCode={refCode}
                  priceable={priceable}
                  activeQuote={this.subscriptionActiveQuote()}
                />
              </>
            )}
            <Action
              isDisabled={false}
              showLink={paymentType !== "gift"}
              link={
                paymentType === "course"
                  ? `/courses/${selectedCourse}/gift`
                  : "/passes/year/gift"
              }
              nextAction={this.next}
              nextButtonId={this.getId()}
              showBackButton={false}
            />
          </>
        )}
      </div>
    );
  }
}

export default SelectPlan;
