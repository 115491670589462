import { observable, decorate, action, computed } from "mobx";
import {
  ReviewsState,
  waiting,
  loading,
  ready,
  ReviewResource,
  ReviewsResource
} from "./Types";
import { noop } from "../../utils/noop";

class ReviewsStore {
  state: ReviewsState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (reviewsResource: ReviewsResource) => {
    this.state = ready(reviewsResource);
  };

  get reviews(): ReviewResource[] {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return [];
        break;
      case "ready":
        return this.state.reviewsResource.reviews;
        break;
    }
  }
}

decorate(ReviewsStore, {
  state: observable,
  loading: action,
  ready: action,
  reviews: computed
});

export default ReviewsStore;
