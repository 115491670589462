import React, { Component } from "react";
import _ from "underscore";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { AxiosResponse } from "axios";
import { required } from "../../../src/components/common/FormValidations";
import { postRequest, putRequest } from "../../../src/utils/httpRequest";
import FileUpload from "./FileUpload";
import Content from "./Content";
import { Props } from "../Types";
import "./index.scss";

interface State {
  submitting: boolean;
}

class CreatePage extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false
    };
  }

  componentDidMount() {
    new (window as any).SimpleMDE({
      element: document.getElementById("simplemde")
    });
  }

  addSection = (e, push) => {
    e.preventDefault();
    push({ name: "", email: "" });
  };

  removeSection = (e, index, remove) => {
    e.preventDefault();
    remove(index);
  };

  submit = values => {
    const { page } = this.props;

    this.setState({ submitting: true });

    const {
      id,
      title,
      active,
      seoTitle,
      seoDescription,
      pageType,
      brand,
      course,
      sections
    } = values;
    const pageSections = sections.map(section => {
      return {
        id: section.id,
        title: section.title,
        kind: section.kind,
        content: section.content,
        rank: section.rank,
        active: true,
        category_id: section.kind === "categories" ? section.category : null,
        masthead_image_url: section.masthead
      };
    });

    const sectionIds = _.pluck(this.props.sections, "id");
    const pageSectionIds = _.pluck(pageSections, "id");
    const sectionsToBeDeletedIds = _.difference(sectionIds, pageSectionIds);

    const sectionsToBeDeleted = sectionsToBeDeletedIds.map(id => {
      return {
        id,
        _destroy: true
      };
    });

    const data = {
      page: {
        id,
        title,
        active,
        type: pageType,
        seo_title: seoTitle,
        seo_description: seoDescription,
        brand_id: pageType === "Pages::Brand" ? brand : null,
        course_id: pageType === "Pages::Course" ? course : null,
        sections_attributes: page
          ? pageSections.concat(sectionsToBeDeleted)
          : pageSections
      }
    };

    if (page) {
      putRequest(`/admin/pages/${page.slug}.json`, data)
        .then((response: AxiosResponse) => {
          console.log(response.data);
          (window as any).location = `/admin/pages/${response.data}`;
        })
        .catch(error => {
          this.setState({ submitting: false });
          console.log(error?.response?.data);
        });
    } else {
      postRequest("/admin/pages.json", data)
        .then((response: AxiosResponse) => {
          console.log(response.data);
          (window as any).location = `/admin/pages/${response.data}`;
        })
        .catch(error => {
          this.setState({ submitting: false });
          console.log(error?.response?.data);
        });
    }
  };

  setSections = () => {
    const { page, sections, selectedCategory } = this.props;

    if (page) {
      return sections.map(section => {
        return {
          id: section.id,
          title: section.title,
          kind: section.kind,
          rank: section.rank,
          category: section.category_id || selectedCategory,
          content: section.content,
          masthead: section.masthead_image_url
        };
      });
    } else {
      return [
        {
          id: null,
          title: "",
          kind: "masthead",
          rank: 1,
          category: selectedCategory,
          content: "",
          masthead: ""
        }
      ];
    }
  };

  renderHeader = () => {
    const { page } = this.props;
    const { submitting } = this.state;

    if (page) {
      return (
        <div className="ui attached segment very padded shell-texture">
          <div className="ui buttons right floated">
            <a className="ui button" href={`/admin/pages/${page.slug}`}>
              Cancel
            </a>
            <div className="or"></div>
            <button
              className={`ui button primary ${submitting ? "click-busy" : ""}`}
              type="submit"
            >
              <i className="ui icon check"></i>Save
            </button>
          </div>
          <h1 className="ui header">
            <i className="icon pencil"></i>Editing '{`${page.title}`}'
          </h1>
        </div>
      );
    } else {
      return (
        <div className="ui attached segment very padded shell-texture">
          <div className="ui buttons right floated">
            <a className="ui button" href="/admin/pages">
              Cancel
            </a>
            <div className="or"></div>
            <button
              className={`ui button primary ${submitting ? "click-busy" : ""}`}
              type="submit"
            >
              <i className="ui icon check"></i>Save
            </button>
          </div>
          <h1 className="ui header">
            <i className="icon plus"></i>New Page
          </h1>
        </div>
      );
    }
  };

  render() {
    const {
      selectedBrand,
      selectedCourse,
      selectedCategory,
      brands,
      courses,
      categories,
      pageTypes,
      page,
      type
    } = this.props;

    return (
      <>
        <Formik
          initialValues={{
            id: page?.id,
            title: page?.title || "",
            active: page ? page.active : true,
            seoTitle: page?.seo_title || "",
            seoDescription: page?.seo_description || "",
            pageType: type || "Pages::Brand",
            brand: page?.brand_id || selectedBrand,
            course: page?.course_id || selectedCourse,
            sections: this.setSections()
          }}
          onSubmit={values => this.submit(values)}
        >
          {({ values, touched, handleChange, setFieldValue }) => (
            <Form className="ui form admin-uploader create-page">
              {this.renderHeader()}
              <div className="ui attached secondary segment very padded">
                <div className="fields">
                  <div className="field string required page_title ten wide">
                    <label className="string" htmlFor="page_title">
                      Url Title
                    </label>
                    <Field
                      id="title"
                      name="title"
                      validate={required}
                      className="string required"
                      onChange={handleChange}
                    />
                    <ErrorMessage name="title">
                      {msg => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="field boolean optional page_active two wide">
                    <Field
                      className="boolean"
                      type="checkbox"
                      name="active"
                      onChange={handleChange}
                    />
                    <label className="boolean" htmlFor="page_active">
                      Active
                    </label>
                  </div>
                  <div className="field select optional page_type two wide">
                    <label className="select optional" htmlFor="page_type">
                      Type
                    </label>
                    <Field component="select" id="pageType" name="pageType">
                      {pageTypes?.map((type, i) => (
                        <option key={i} value={type.value}>
                          {type.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  {values.pageType === "Pages::Brand" && (
                    <div className="field select optional page_type two wide">
                      <label className="select optional" htmlFor="page_type">
                        Brand
                      </label>
                      <Field component="select" id="brand" name="brand">
                        {brands?.map(brand => (
                          <option key={brand.id} value={brand.id}>
                            {brand.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  )}
                  {values.pageType === "Pages::Course" && (
                    <div className="field select optional page_type two wide">
                      <label className="select optional" htmlFor="page_type">
                        Courses
                      </label>
                      <Field component="select" id="course" name="course">
                        {courses?.map(course => (
                          <option key={course.id} value={course.id}>
                            {course.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  )}
                </div>
                <div className="field string page_type ten wide">
                  <label className="string" htmlFor="page_title">
                    Meta Title
                  </label>
                  <Field
                    id="seoTitle"
                    name="seoTitle"
                    className="string"
                    onChange={handleChange}
                  />
                  <ErrorMessage name="seoTitle">
                    {msg => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="field string page_type">
                  <label className="string" htmlFor="page_title">
                    Meta Description
                  </label>
                  <Content
                    name="seoDescription"
                    setFieldValue={setFieldValue}
                    id="seo_description"
                  />
                </div>
              </div>
              <div className="ui attached secondary segment very padded">
                <h4>Sections</h4>
                <FieldArray
                  name="sections"
                  render={arrayHelpers => (
                    <div>
                      {values.sections.map((section, index) => (
                        <div key={index} className="section">
                          <div className="fields">
                            {values.sections[index]?.kind === "masthead" && (
                              <div className="field string page_title ten wide">
                                <label className="string" htmlFor="page_title">
                                  Page Header
                                </label>
                                <Field
                                  id="title"
                                  name={`sections[${index}].title`}
                                  className="string"
                                  validate={required}
                                  onChange={handleChange}
                                />
                                <ErrorMessage name={`sections[${index}].title`}>
                                  {msg => <div className="error">{msg}</div>}
                                </ErrorMessage>
                              </div>
                            )}
                            <div className="field boolean optional page_active two wide">
                              <label className="string" htmlFor="page_title">
                                Kind
                              </label>
                              <Field
                                component="select"
                                id="kind"
                                name={`sections[${index}].kind`}
                              >
                                {[
                                  "masthead",
                                  "text",
                                  "trust",
                                  "how_it_works",
                                  "why_42",
                                  "categories",
                                  "blog",
                                  "pricing"
                                ].map((kind, i) => (
                                  <option key={i} value={kind}>
                                    {kind}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <div className="field boolean optional page_active two wide">
                              <label className="string" htmlFor="page_title">
                                Rank
                              </label>
                              <Field
                                component="select"
                                id="kind"
                                name={`sections[${index}].rank`}
                              >
                                {_.range(1, 21).map((rank, i) => (
                                  <option key={i} value={rank}>
                                    {rank}
                                  </option>
                                ))}
                              </Field>
                            </div>

                            {values.sections[index]?.kind === "categories" && (
                              <div className="field select optional page_type four wide">
                                <label
                                  className="select optional"
                                  htmlFor="category"
                                >
                                  Category
                                </label>
                                <Field
                                  component="select"
                                  id="course"
                                  name={`sections[${index}].category`}
                                >
                                  {categories?.map(category => (
                                    <option
                                      key={category.id}
                                      value={category.id}
                                    >
                                      {category.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            )}

                            <div className="field boolean optional page_active two wide">
                              <a
                                className="add_fields"
                                href="#"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove section
                              </a>
                            </div>
                          </div>

                          {values.sections[index]?.kind === "masthead" && (
                            <FileUpload
                              name={`sections[${index}].masthead`}
                              setFieldValue={setFieldValue}
                              fileUrl={values.sections[index]?.masthead}
                            />
                          )}
                          {values.sections[index]?.kind === "text" && (
                            <div className="field string page_title">
                              <label className="string" htmlFor="page_title">
                                Content
                              </label>
                              <Content
                                name={`sections[${index}].content`}
                                id={`section_${index}`}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          )}

                          <hr />
                        </div>
                      ))}
                      <a
                        className="add_fields"
                        href="#"
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({
                            title: "",
                            content: "",
                            kind: "masthead",
                            masthead: "",
                            category: selectedCategory,
                            id: null,
                            rank: _.last(values.sections).rank + 1
                          })
                        }
                      >
                        Add section
                      </a>
                    </div>
                  )}
                />
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default CreatePage;
