export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  reviewsResource: ReviewsResource;
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (reviewsResource: ReviewsResource): Ready => {
  return {
    kind: "ready",
    reviewsResource
  };
};

export type ReviewsState = Waiting | Loading | Ready;

export interface ReviewResource {
  id: number;
  content: string;
  profile_picture: string;
  rating: number;
  user_name: string;
  user_country: string;
  user_country_name: string;
  company_name: string;
  company_logo: string;
  created_at: string;
}

export interface ReviewsResource {
  reviews: ReviewResource[];
  total_five_star_reviews: number;
}
