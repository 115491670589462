import React from "react";

const Header = ({
  title,
  isComplete = false,
  isExpanded = false,
  toggleView
}) => {
  const renderIcon = () => {
    if (isComplete) {
      return <i className="icon check circle" />;
    }

    if (!isExpanded) {
      return <i className="caret right icon" />;
    }

    return <i className="caret down icon" />;
  };

  return (
    <div className="section-header">
      <h3>{title}</h3>
      <a href="#" onClick={e => toggleView(e)}>
        {renderIcon()}
      </a>
    </div>
  );
};

export default Header;
