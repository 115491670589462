import React from "react";
import { Post } from "../../../../stores/CommunityStore/posts/types";
import { useDispatch } from "react-redux";
import { fetchPosts } from "../../../../stores/CommunityStore/posts/actions/fetchPosts";

interface Props {
  trendingPosts: Post[];
  handleHideSidebar?: (e: any, value: boolean) => void;
  isMobile?: boolean;
}
const TrendingPosts: React.FC<Props> = ({
  trendingPosts,
  handleHideSidebar,
  isMobile = false,
}) => {
  const dispatch = useDispatch();

  const MAX_WORDS = 15;
  const trimmedContent = (content: string) => {
    return (
      content.split(" ").slice(0, MAX_WORDS).join(" ") +
      (content.split(" ").length > MAX_WORDS ? "..." : "")
    );
  };

  const onClickFeaturedPost = (e, uuid) => {
    e.preventDefault();

    dispatch(fetchPosts({ featuredPostId: uuid }));
    if (isMobile) {
      handleHideSidebar(e, false);
    }
  };

  return (
    <div className="ui community__sidebar-topics">
      <div className="community__sidebar-topics__header">
        <h4 className="font-18">Featured Posts 🔥</h4>
      </div>
      <hr />
      <div className="community__sidebar-trending-posts">
        {trendingPosts.slice(0, 3).map((post) => (
          <React.Fragment key={post.uuid}>
            <div className="community__sidebar-trending-post">
              <p
                dangerouslySetInnerHTML={{
                  __html: trimmedContent(post.formatted_content),
                }}
              />
              <div>
                <span>
                  <strong>
                    <a
                      href="#"
                      onClick={(e) => onClickFeaturedPost(e, post.uuid)}
                    >
                      View post <i className="ui icon arrow right"></i>
                    </a>
                  </strong>
                </span>
                <span className="community__sidebar-trending-post__topic">
                  # {post.topic_name}
                </span>
              </div>
            </div>
            <hr />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default TrendingPosts;
