import React, { Component } from "react";
import { observer } from "mobx-react";
import { Rating } from "semantic-ui-react";
import { ReviewResource } from "../../../../stores/ReviewsStore/Types";
import ReviewModal from "./ReviewModal";

interface Props {
  review: ReviewResource;
}

interface State {
  showModal: boolean;
}

class Review extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  onClickShowModal = (e, val) => {
    e.preventDefault();
    this.setState({ showModal: val });
  };

  render() {
    const { review } = this.props;

    const { showModal } = this.state;

    return (
      <div style={{ textAlign: "center" }}>
        <div key={review.id} className="column review">
          <div>
            <img className="profile-picture" src={review.profile_picture} />
            <div className="ratings">
              <Rating
                icon="star"
                defaultRating={review.rating}
                maxRating={5}
                disabled={true}
              />
            </div>
            <p className="content">
              {review.content.slice(0, 140)}{" "}
              {review.content.length > 140 && (
                <a onClick={e => this.onClickShowModal(e, true)} href="">
                  ...more
                </a>
              )}
            </p>
            <p className="name">{review.user_name}</p>
            {review.company_logo && (
              <div style={{ marginTop: "-15px" }}>
                <img
                  className="ui image company-logo"
                  src={review.company_logo}
                />
              </div>
            )}
          </div>
          <ReviewModal
            showModal={showModal}
            onClickShowModal={this.onClickShowModal}
            review={review}
          />
        </div>
      </div>
    );
  }
}

export default observer(Review);
