import React, { Component } from "react";
import { observer } from "mobx-react";
import SubscriptionWrapper from "./SubscriptionWrapper";
import SubscriptionStore from "../../../../stores/SubscriptionStore";
import SubscriptionReactions from "../../../../stores/SubscriptionStore/SubscriptionReactions";

class Subscription extends Component {
  subscriptionStore = new SubscriptionStore();
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.subscriptionStore.loading();
  }

  render() {
    const { state } = this.subscriptionStore;

    return (
      <div className="ui segment subscription">
        <div className="ui container">
          <SubscriptionWrapper state={state} store={this.subscriptionStore} />
        </div>
        <SubscriptionReactions store={this.subscriptionStore} />
      </div>
    );
  }
}

export default observer(Subscription);
