import React from "react";
import { Priceable, PaymentType } from "../../Types";
import { Quotation } from "../../../../stores/CoursePreviewStore/Types";
import Coupon from "./Coupon";
import Monthly from "./Subscriptions/Monthly";
import MonthlyFlexEd from "./Subscriptions/MonthlyFlexEd";
import Yearly from "./Subscriptions/Yearly";

interface Props {
  slug: string;
  path: string;
  priceable: Priceable;
  activeQuote: Quotation;
  paymentType: PaymentType;
  couponCode: string;
  refCode: string;
  amount: string;
  discountAmount: string;
  monthlySubsequentPayment: string;
  handleShowCouponModal: (e: any, val: boolean) => void;
  refreshQuoteWithoutCoupon: (e: any) => void;
}

const Subscription: React.FC<Props> = ({
  slug,
  path,
  priceable,
  activeQuote,
  couponCode,
  refCode,
  amount,
  paymentType,
  discountAmount,
  monthlySubsequentPayment,
  handleShowCouponModal,
  refreshQuoteWithoutCoupon,
}) => {
  return (
    <div className="ui card">
      <div className="content">
        <div className="content-wrapper">
          <div className="info">
            <div
              className="ui small image"
              style={{
                backgroundImage: `url(${priceable?.checkout_image})`,
                height: couponCode || refCode ? "auto" : "100px",
              }}
            ></div>
            <div className="plan-info">
              <div className="title">
                <strong>{activeQuote?.checkout_title} </strong>
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: activeQuote?.checkout_title_meta,
                }}
              ></div>
              {(couponCode?.length > 0 || refCode?.length > 0) && (
                <div className="coupon-code">
                  <div className="ui label">
                    <i className="tag icon"></i>
                    {couponCode || refCode}
                    <a onClick={(e) => refreshQuoteWithoutCoupon(e)}>
                      <i className="delete icon"></i>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {slug === "month-flex-ed" ? (
          <div className="coupon"></div>
        ) : (
          <Coupon
            couponCode={couponCode}
            refCode={refCode}
            activeQuote={activeQuote}
            paymentType={paymentType}
            handleShowCouponModal={handleShowCouponModal}
            refreshQuoteWithoutCoupon={refreshQuoteWithoutCoupon}
          />
        )}
      </div>
      {slug === "month-flex-ed" && <MonthlyFlexEd activeQuote={activeQuote} />}
      {priceable?.interval === "month" && !priceable?.is_restricted && (
        <Monthly
          priceable={priceable}
          activeQuote={activeQuote}
          couponCode={couponCode}
          refCode={refCode}
          amount={amount}
          discountAmount={discountAmount}
          monthlySubsequentPayment={monthlySubsequentPayment}
        />
      )}
      {priceable?.interval === "year" && (
        <Yearly
          activeQuote={activeQuote}
          couponCode={couponCode}
          refCode={refCode}
        />
      )}
    </div>
  );
};

export default Subscription;
