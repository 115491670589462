import React, { Component } from "react";
import Hero from "./components/Hero";
import Learners from "./components/Learners";
import CoursesView from "./components/CoursesView";

interface Props {
  match: any;
}

interface State {
  loading: boolean;
}

class Courses extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="ui facelift-landing-page facelight-courses">
        <Hero />
        <Learners />
        <CoursesView />
      </div>
    );
  }
}

export default Courses;
