import React from "react";
import { CurrencyPayload } from "../CurrencyStore/Types";
import { CoursesResource } from "../CoursesStore/Types";
import { BlogResource } from "../BlogStore/Types";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  rootPayload: RootPayload;
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (rootPayload: RootPayload): Ready => {
  return {
    kind: "ready",
    rootPayload
  };
};

export type RootState = Waiting | Loading | Ready;

export interface RootPayload {
  blog_posts: BlogResource;
}

export interface RootPayloadResponse {
  currencies_payload: CurrencyPayload;

  blog_posts: BlogResource;
}

export interface NotificationResource {
  id: number;
  href: string;
  mood: string;
  title: string;
  body: string;
  avatar: string;
  target_name: string;
  created_at: string;
}

export const RootContext = React.createContext(undefined);
