import React from "react";
import { Link } from "react-router-dom";
import CurrentUser from "../CurrentUser";
import Announcement from "../../Announcement";
import { CurrentUserResource } from "../../../stores/CurrentUserStore/Types";

interface Props {
  handleShowSideBar: () => void;
  handleShowSignInSideBar: () => void;
  handleShowLoggedInSideBar: () => void;
  onClickHeaderLogo: () => void;
  getCurrentUser: () => void;
  currentUser: CurrentUserResource;
  handleShowNotificationModal: (val: boolean) => void;
  showNotificationModal: boolean;
  userXp: number;
}

const Header: React.FC<Props> = ({
  handleShowSideBar,
  handleShowSignInSideBar,
  handleShowLoggedInSideBar,
  onClickHeaderLogo,
  currentUser,
  getCurrentUser,
  handleShowNotificationModal,
  showNotificationModal,
  userXp
}) => (
  <div className="ui grid">
    <div className="mobile only" id="mobile_nav">
      <div className="column">
        <div
          className="ui secondary menu fixed new-navbar-mobile"
          id="new-nav-mobile"
        >
          <Announcement />
          <div className="ui secondary menu three item" id="new-mobile-nav">
            <div className="left item menu">
              <a className="item left" onClick={() => handleShowSideBar()}>
                <i className="bars icon"></i>
              </a>
            </div>
            <div className="center item menu">
              {currentUser.kind === "current-user" &&
              currentUser.current_course_brand ? (
                <Link
                  to={currentUser.kind === "current-user" ? "/profile" : "/"}
                >
                  <img
                    className="ui course-brand image small"
                    src={currentUser.current_course_brand}
                    alt=""
                  />
                </Link>
              ) : (
                <Link
                  to={currentUser.kind === "current-user" ? "/profile" : "/"}
                  className="item center"
                >
                  <img
                    className="logo-mark dark"
                    src={require("../../../../../assets/images/facelift/logos/dark_blob.png")}
                    alt=""
                  />
                </Link>
              )}
            </div>
            {currentUser.kind === "current-user-absent" ? (
              <div className="right item menu">
                <div className="signInButton item">
                  <a
                    className="ui  button"
                    onClick={() => handleShowSignInSideBar()}
                  >
                    Sign In
                  </a>
                </div>
              </div>
            ) : (
              <CurrentUser
                currentUser={currentUser}
                userXp={userXp}
                getCurrentUser={getCurrentUser}
                showNotificationModal={showNotificationModal}
                handleShowLoggedInSideBar={handleShowLoggedInSideBar}
                handleShowNotificationModal={handleShowNotificationModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
