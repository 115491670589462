import React, { useEffect, useRef, useState } from "react";
import ChatMessage from "./ChatMessage";

const ChatWindow = ({ messages, isUserMessageWaiting, maxMessagesReached }) => {
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    if (messages.length > 0) {
      setAnimate(true);
    }
  }, [messages]);

  return (
    <div className="flex flex-col flex-1 justify-end z-1 ai-lesson-chat__content">
      <div className="flex flex-col space-y-2-reverse">
        <div className="p-3 text-gray-400 text-sm ml-2">
          <strong>This is a beta AI system.</strong>
          <br />
          Please take the results with a pinch of salt!
        </div>
        {messages.map((message, index) => (
          <ChatMessage
            key={index}
            message={message}
            animate={animate && index === messages.length - 1}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatWindow;
