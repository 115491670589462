import React, { Component } from "react";
import { observer } from "mobx-react";
import { getRequest, putRequest } from "../../utils/httpRequest";
import { AxiosResponse } from "axios";
import { Badge } from "../../stores/BadgeStore/Types";
import BadgeWrapper from "./BadgeWrapper";
import ReactHtmlParser from "react-html-parser";
import { Label, Modal } from "semantic-ui-react";

interface State {
  loaded: boolean;
  badges: Badge[];
  showModal: boolean;
  activeBadge: Badge;
}

interface BadgeModalProps {
  showModal: boolean;
  onClickShowModal: (e: any, val: boolean, badge: Badge) => void;
  badge: Badge;
}

const BadgeModal: React.FC<BadgeModalProps> = ({
  showModal,
  onClickShowModal,
  badge
}) => {
  return (
    <Modal size={"mini"} open={showModal} className="badge-modal">
      <Modal.Content>
        <div className="ui image">
          <img
            src={badge?.badge_url}
            className="stateful state--awarded badge--actual-image"
          />
          {badge?.xp_value && badge?.xp_value > 0 && (
            <Label>{badge?.xp_value} XP</Label>
          )}
        </div>
        <h4 className="header">{badge?.badge_name}</h4>

        {badge?.scoped && (
          <h3 className="sub-header">{badge?.enrolment?.course.name}</h3>
        )}

        {badge?.persisted && (
          <div className="description stateful state--awarded">
            {badge?.badge_awarded_message}
          </div>
        )}

        <a
          className="fl-button highlight"
          data-modal-close=""
          onClick={e => onClickShowModal(e, false, badge)}
        >
          Dismiss
        </a>
      </Modal.Content>
    </Modal>
  );
};

class Badges extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      badges: [],
      showModal: false,
      activeBadge: null
    };
  }

  onClickShowModal = (e, val, badge) => {
    e.preventDefault();
    this.setState({ showModal: val, activeBadge: badge });
    if (badge.state == "new") {
      this.markAsOpened(badge);
    }
  };

  markAsOpened = badge => {
    return putRequest(`/api/achievements/badges/${badge.id}.json`, {
      achievement: { opened_badge: true }
    }).then((response: AxiosResponse) => {
      let items = [...this.state.badges];
      items[items.indexOf(badge)] = response.data;
      this.setState({ badges: items });
    });
  };

  componentDidMount() {
    return getRequest("/api/pages/badges.json").then(
      (response: AxiosResponse) => {
        this.setState({ badges: response.data, loaded: true });
      }
    );
  }

  render() {
    const { loaded, badges, showModal, activeBadge } = this.state;

    return (
      <div className="ui pages" id="badges">
        {!loaded && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="masthead badges">
          <h1 className="ui header">Rewards</h1>
        </div>
        <div className="content">
          <div className="ui basic segment padded">
            <div className="ui container center aligned">
              <p className="ui large message">
                {/* @ts-ignore */}
                {ReactHtmlParser(badges[0]?.message)}
              </p>
              <div className="ui four cards doubling stackable">
                {badges.map((badge, i) => (
                  <BadgeWrapper
                    badge={badge}
                    key={i}
                    onClickShowModal={this.onClickShowModal}
                  />
                ))}
                <BadgeModal
                  badge={activeBadge}
                  showModal={showModal}
                  onClickShowModal={this.onClickShowModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(Badges);
