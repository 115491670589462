import React from "react";
import { Carousel } from "../../../common";
import {
  HeroesResource,
  CountriesResource,
  TeamsResource
} from "../../../../stores/LeaderboardStore/Types";
import NumberFormat from "react-number-format";
import Heroes from "./Heroes";
import Countries from "./Countries";
import Teams from "./Teams";

interface Props {
  heroes: HeroesResource;
  countries: CountriesResource;
  teams: TeamsResource;
  total_heroes: number;
  total_countries: number;
  total_teams: number;
  loading: boolean;
  prevPage: (page: number) => number;
  nextPage: (page: number, total_pages) => number;
}

const Landing: React.FC<Props> = ({
  heroes,
  countries,
  teams,
  total_heroes,
  total_countries,
  total_teams,
  loading,
  prevPage,
  nextPage
}) => (
  <div className="leaderboard-wrapper">
    <Carousel
      numberOfSlides={2}
      className="leaderboardCarousel"
      useButtons={true}
      arrows={true}
    >
      <Heroes
        heroes={heroes}
        totalHeroes={total_heroes}
        prevPage={prevPage}
        nextPage={nextPage}
      />
      <Teams
        teams={teams}
        totalTeams={total_teams}
        prevPage={prevPage}
        nextPage={nextPage}
      />
      <Countries
        countries={countries}
        totalCountries={total_countries}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </Carousel>
  </div>
);
export default Landing;
