import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../../utils/httpRequest";
import {
  ReviewResource,
  ReviewsResource
} from "../../../stores/ReviewsStore/Types";
import Review from "./Review";

interface Props {
  courseSlug: string;
}

interface State {
  loading: boolean;
  totalReviews: ReviewResource[];
  reviews: ReviewResource[];
  totalFiveStarReviews: number;
  showMore: boolean;
}

class Reviews extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      reviews: [],
      totalReviews: [],
      showMore: false,
      totalFiveStarReviews: 0,
      loading: true
    };
  }

  componentDidMount() {
    const { courseSlug } = this.props;
    return getRequest(`/api/courses/${courseSlug}/reviews.json`).then(
      (response: AxiosResponse<ReviewsResource>) => {
        const { reviews, total_five_star_reviews } = response.data;
        this.setState({
          totalReviews: reviews,
          reviews: reviews.slice(0, 8),
          showMore: reviews.length > 8,
          totalFiveStarReviews: total_five_star_reviews,
          loading: false
        });
      }
    );
  }

  showMoreReviews = e => {
    e.preventDefault();

    const { reviews, totalReviews } = this.state;
    this.setState(
      {
        reviews: totalReviews.slice(0, reviews.length + 8)
      },
      this.checkShouldShowMore
    );
  };

  checkShouldShowMore = () => {
    const { reviews, totalReviews } = this.state;

    if (totalReviews.length === reviews.length) {
      this.setState({ showMore: false });
    }
  };

  render() {
    const { loading, reviews, totalFiveStarReviews, showMore } = this.state;
    return (
      <div className="ui segment course-reviews" id="course-reviews">
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        <div className="ui">
          <div className="header">
            <h1>What our students say</h1>
          </div>
          <div className="ui reviews-container">
            <div className="ui grid four columns doubling stackable">
              {reviews.map(review => (
                <Review key={review.id} review={review} />
              ))}
            </div>

            {showMore && (
              <div className="more-courses-button desktop">
                <div className="see-more-reviews">
                  <button className="ui button" onClick={this.showMoreReviews}>
                    See more reviews
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Reviews;
