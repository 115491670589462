import { observer } from "mobx-react";
import PricingStore from ".";
import { PricingPayload, PricingState } from "./Types";
import { AxiosResponse, AxiosError } from "axios";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { Api } from "../../utils/Api";

interface State {
  pricingState: PricingState;
}

export interface Props extends BaseReactionProps<PricingStore> {
  store: PricingStore;
  params: any;
}

class PricingReactions extends BaseReaction<PricingStore, State, Props> {
  tester = (): State => ({
    pricingState: this.props.store.pricingState
  });

  effect = ({ pricingState }: State) => {
    switch (pricingState.kind) {
      case "loading":
        const client = new Api({});
        client
          .get<PricingPayload>(
            `/api/merchant/quotations.json?course_slug=${this.props.params.slug}`
          )
          .then((response: AxiosResponse<PricingPayload>) => {
            this.props.store.ready(response.data);
          })
          .catch((error: AxiosError<PricingPayload>) => {
            this.props.store.ready(error.response.data);
          });
        break;

      case "waiting":
      case "ready":
        break;
    }
  };
}

export default observer(PricingReactions);
