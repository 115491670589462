import { observable, decorate, action, computed } from "mobx";
import {
  SubscriptionState,
  waiting,
  loading,
  ready,
  subscribed,
  saving,
  readyWithErrors,
  Subscription
} from "./Types";
import { Errors } from "../../utils/helpers";
import { noop } from "../../utils/noop";

class SubscriptionStore {
  state: SubscriptionState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (subscription: Subscription) => {
    this.state = ready(subscription);
  };

  readyWithErrors = (errors: Errors) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "ready":
      case "readyWithErrors":
        noop();
        break;
      case "saving":
        const { subscription } = this.state;

        this.state = readyWithErrors(subscription, errors);
        break;
    }
  };

  saving = () => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        const { subscription } = this.state;

        this.state = saving(subscription);
        break;
    }
  };

  subscribed = () => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "ready":
      case "readyWithErrors":
        noop();
        break;
      case "saving":
        this.state = subscribed();
        break;
    }
  };

  setAttributes = (e, attribute) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.subscription[attribute] = e.target.value;
        break;
    }
  };

  get email() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.subscription.email;
        break;
    }
  }

  get username() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.subscription.username;
        break;
    }
  }

  get firstName() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.subscription.firstName;
        break;
    }
  }

  get lastName() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.subscription.lastName;
        break;
    }
  }

  get errors(): Errors {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
      case "ready":
        return [];
        break;
      case "readyWithErrors":
        this.state.errors;
        break;
    }
  }
}

decorate(SubscriptionStore, {
  state: observable,
  loading: action,
  ready: action,
  subscribed: action,
  saving: action,
  readyWithErrors: action,
  setAttributes: action,
  errors: computed,
  email: computed,
  username: computed,
  firstName: computed,
  lastName: computed
});

export default SubscriptionStore;
