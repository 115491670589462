import React, { Component } from "react";
import { observer } from "mobx-react";
import { CourseCategoryResource } from "../../stores/CourseCategoriesStore/Types";
import Course from "../Home/components/Courses/Course";
import { Carousel } from "../common";

interface Props {
  category: CourseCategoryResource;
}

class Category extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { category } = this.props;
    const blockClass = "category";

    return (
      <div
        className="ui segment courses courses-categories featured-courses"
        id="course-grid-title"
      >
        <div className="ui container">
          {category.courses.length > 0 && (
            <div className={blockClass}>
              <h2 className={`${blockClass}__name`}>{category.name}</h2>

              <p className={`${blockClass}__description`}>
                {category.description}
              </p>

              {category.courses.length > 2 ? (
                <div className="courses-container featured-courses-container ui inverted vertical segment center aligned">
                  <div
                    className="ui centered doubling stackable three column"
                    id="courses_grid"
                  >
                    <Carousel
                      noOfMobileSlides={2}
                      numberOfSlides={
                        category.courses.length < 3
                          ? category.courses.length
                          : 3
                      }
                      arrows={true}
                      slidesToScroll={3}
                      dots={true}
                    >
                      {category.courses.map((course, i) => (
                        <Course
                          courseResource={course}
                          idx={i}
                          key={course.id}
                          increaseHeight={true}
                          categories={[category]}
                        />
                      ))}
                    </Carousel>
                  </div>
                </div>
              ) : (
                <div className="courses-container ui inverted vertical segment center aligned">
                  <div
                    className="ui grid doubling stackable three column categories"
                    id="courses_grid"
                  >
                    {category.courses.map((course, i) => (
                      <Course
                        courseResource={course}
                        idx={i}
                        key={course.id}
                        increaseHeight={true}
                        categories={[category]}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Category;
