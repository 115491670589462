import React from "react";
import ChatBusyIndicator from "./ChatBusyIndicator";
import IconSend from "./IconSend";
import IconRedo from "./IconRedo";

const ChatInput = ({
  userInput,
  loading,
  handleSubmit,
  setUserInput,
  resetConversation,
  maxMessagesReached,
}) => {
  const placeholderText = maxMessagesReached
    ? "⚠️ Convo limit reached, start over"
    : "";

  return (
    <form
      onSubmit={handleSubmit}
      className="flex items-center justify-between m-4"
    >
      <input
        type="text"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        disabled={loading || maxMessagesReached}
        className="w-full px-4 py-2 mr-4 rounded-md border-2 border-teal bg-white outline-none drop-shadow"
        placeholder={placeholderText}
        autoFocus
      />
      <button
        type="submit"
        disabled={loading || maxMessagesReached}
        className="px-4 py-2 font-semibold border-none text-white bg-teal-500 rounded-md drop-shadow"
      >
        {loading ? <ChatBusyIndicator /> : <IconSend />}
      </button>
      <button
        className="px-4 py-2 font-regular border-none bg-white rounded-md ml-2 shadow"
        onClick={resetConversation}
      >
        <IconRedo />
      </button>
    </form>
  );
};

export default ChatInput;
