import React from "react";

const WhyLearn = () => (
  <div className="ui segment why-learn">
    <div className="ui container">
      <div className="header">
        <h2>Why learn with us?</h2>
      </div>
      <div className="learn-content">
        <div className="body">
          <p>
            We started this company after being fed up with how bad online
            learning was for the creative industry. We’d been to the Cannes
            Lions festival and learned from some of the world’s best creative
            minds.
          </p>
        </div>
        <div className="body">
          <p>
            It made us wonder if we could create something that helped share
            that calibre of learning worldwide on-demand.
          </p>
        </div>
        <div className="body">
          <p>
            Most online courses are too long, fail to engage and don’t offer
            real-time feedback which makes the experience about as fun as
            watching paint dry.
          </p>
        </div>
        <div className="body">
          <p>
            At 42courses, we believe learning is better when it’s enjoyable.
          </p>
        </div>
        <div className="body">
          <p>
            What’s more, research shows that enjoyable learning makes people
            more likely to complete their courses as well as retain the
            information. This helps explain why we have an 80% completion rate
            compared to the industry standard of only 7%.
          </p>
        </div>
        <div className="body">
          <p>How does 42courses ensure this?</p>
        </div>
        <div className="body">
          <p>
            We use a combination of storytelling, gamification and design to
            create our courses. We also provide real-time marking with
            one-on-one student assistance.
          </p>
        </div>
        <div className="body">
          <p>Features at a glance:</p>
          <ul>
            <li>Integrates with other LMS systems like Degreed.</li>{" "}
            <li>Automated nudges so your teams complete more learning.</li>
            <li>Analytics to track your teams performance.</li>
            <li>Leaderboards to spot the big brains in your company.</li>
            <li>Access to industry case studies.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default WhyLearn;
