import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { getRequest, postRequest } from "../../../utils/httpRequest";
import { Quotation } from "../../../stores/CoursePreviewStore/Types";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Learners from "./Learners";
import Reviews from "./Reviews";
import AccessForTeam from "./AccessForTeam";
import TeamForm from "./TeamForm";
import AboutNanoMbas from "./AboutNanoMbas";
import MoreInfo from "./MoreInfo";
import { CoursesResource } from "../../../stores/CoursesStore/Types";

interface Props {
  selectedCurrency: string;
  location: {
    pathname: string;
  };
}

interface State {
  quote: Quotation;
  courses: CoursesResource;
  customBundlePrice: number;
  overviewFile: string;
  filename: string;
  loading: boolean;
}

class TeamPlans extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      quote: null,
      courses: [],
      customBundlePrice: null,
      overviewFile: "",
      filename: "",
      loading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getTeamPayload();
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    if (
      prevProps.selectedCurrency !== "" &&
      this.props.selectedCurrency !== prevProps.selectedCurrency
    ) {
      this.getTeamPayload();
    }
  }

  getTeamPayload = () => {
    this.setState({ loading: true });

    return getRequest("/api/pages/teams.json")
      .then((response: AxiosResponse) => {
        const {
          quote,
          courses,
          custom_bundle_price,
          overview_file,
          filename
        } = response.data;

        this.setState({
          quote,
          courses,
          filename,
          customBundlePrice: custom_bundle_price,
          overviewFile: overview_file,
          loading: false
        });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      quote,
      customBundlePrice,
      courses,
      overviewFile,
      filename,
      loading
    } = this.state;

    const recaptchaRefOne = React.createRef();
    const recaptchaRefTwo = React.createRef();

    return (
      <div className="ui pages" id="team_plans">
        <Navbar />
        <Hero quote={quote} loading={loading} />
        <Learners />
        <TeamForm
          overviewFile={overviewFile}
          fileName={filename}
          recaptchaRef={recaptchaRefOne}
          pathName={this.props.location?.pathname}
        />
        <Reviews />
        <AccessForTeam />
        <AboutNanoMbas />
        <MoreInfo />
      </div>
    );
  }
}
export default TeamPlans;
