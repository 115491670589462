import * as Yup from "yup";

export const userValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  postalCode: Yup.string().required("Postal Code is required"),
  country: Yup.string().required("Country is required")
});

export const companyValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  billingName: Yup.string().required("Company name is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  postalCode: Yup.string().required("Postal Code is required"),
  country: Yup.string().required("Country is required")
});

export const getVatTypes = country => {
  let vatKinds = {};

  if (country === "GB") {
    return [
      {
        text: "United Kingdom VAT number",
        value: "gb_vat",
        key: "gb_vat"
      },
      {
        text: "European VAT number",
        value: "eu_vat",
        key: "eu_vat"
      }
    ];
  } else if (country === "ES") {
    return [
      {
        text: "Spanish CIF number",
        value: "es_cif",
        key: "es_cif"
      },
      {
        text: "European VAT number",
        value: "eu_vat",
        key: "eu_vat"
      }
    ];
  } else if (complexCountries.includes(country)) {
    return vatOptions.filter(option =>
      option.key.startsWith(country.toLowerCase())
    );
  } else {
    return [];
  }
};

export const vatOptions = [
  { key: "br_cnpj", value: "br_cnpj", text: "Brazilian CNPJ number" },
  { key: "br_cpf", value: "br_cpf", text: "Brazilian CPF number" },
  { key: "ca_bn", value: "ca_bn", text: "Canadian BN" },
  { key: "ca_gst_hst", value: "ca_gst_hst", text: "Canadian GST/HST number" },
  {
    key: "ca_pst_bc",
    value: "ca_pst_bc",
    text: "Canadian PST number (British Columbia)"
  },
  {
    key: "ca_pst_mb",
    value: "ca_pst_mb",
    text: "Canadian PST number (Manitoba)"
  },
  {
    key: "ca_pst_sk",
    value: "ca_pst_sk",
    text: "Canadian PST number (Saskatchewan)"
  },
  { key: "ca_qst", value: "ca_qst", text: "Canadian QST number" },
  {
    key: "jp_cn",
    value: "jp_cn",
    text: "Japanese Corporate Number (*Hōjin Bangō*)"
  },
  {
    key: "jp_rn",
    value: "jp_rn",
    text:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)"
  },
  { key: "my_frp", value: "my_frp", text: "Malaysian FRP number" },
  { key: "my_itn", value: "my_itn", text: "Malaysian ITN" },
  { key: "my_sst", value: "my_sst", text: "Malaysian SST number" },
  { key: "ru_inn", value: "ru_inn", text: "Russian INN" },
  { key: "ru_kpp", value: "ru_kpp", text: "Russian KPP" },
  { key: "sg_gst", value: "sg_gst", text: "Singaporean GST" },
  { key: "sg_uen", value: "sg_uen", text: "Singaporean UEN" }
];

export const complexCountries = [
  "BR",
  "CA",
  "JP",
  "MY",
  "RU",
  "SG",
  "GB",
  "ES"
];

export const getSelectedVatTypeLabel = (vatTypes, vatType) => {
  console.log(vatTypes, vatType, "vlag");
  const selected = vatTypes.find(type => type.value === vatType?.value);
  const text = selected ? selected.text : "";

  console.log("dsd", cutTextWithEllipsis(text, 25));
  return cutTextWithEllipsis(text, 25);
};

export const cutTextWithEllipsis = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + "...";
  }
};
