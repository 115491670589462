export interface PostUser {
  id: number;
  users: string[];
}

export interface UserState {
  users: PostUser[];
  loading: boolean;
  error: string;
}

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_FAILURE = "LOAD_USERS_FAILURE";

export interface LoadUsersAction {
  type: typeof LOAD_USERS;
}

export interface LoadUsersSuccessAction {
  type: typeof LOAD_USERS_SUCCESS;
  payload: PostUser[];
}

export interface LoadUsersFailureAction {
  type: typeof LOAD_USERS_FAILURE;
  payload: string;
}

export type UserActionTypes =
  | LoadUsersAction
  | LoadUsersSuccessAction
  | LoadUsersFailureAction;
