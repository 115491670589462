import React, { Component } from "react";
import _ from "lodash";
import { Search, Input } from "semantic-ui-react";
import { AdminTeam } from "../../../stores/ProfileStore/Types";

interface Props {
  teams: AdminTeam[];
}

interface State {
  loading: boolean;
  teams: AdminTeam[];
  value: string;
}

class Teams extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      teams: [],
      value: ""
    };
  }

  componentDidMount() {
    this.setState({ teams: this.props.teams });
  }

  dataSearch = team => `${team.name.toLowerCase()} ${team.id}`;

  renderTeams = () => {
    const { loading, teams, value } = this.state;

    return teams.map(team => (
      <a
        className="item"
        key={team.id}
        href={team.link}
        data-search={this.dataSearch(team)}
      >
        {team.name}
      </a>
    ));
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, value });

    const { teams } = this.state;

    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({
          teams: this.props.teams,
          loading: false
        });

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = team => re.test(`${team.name.toLowerCase()} ${team.id} `);

      const filteredTeams = _.filter(this.props.teams, isMatch);

      this.setState({
        loading: false,
        teams: filteredTeams
      });
    }, 300);
  };

  render() {
    const { loading, teams, value } = this.state;

    return (
      <>
        <div className="ui transparent inverted input search-container">
          <i className="search icon" />
          <Search
            loading={loading}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true
            })}
            results={[]}
            value={value}
            showNoResults={false}
            className="currency-search"
            input={<Input placeholder="Type to search..." autoFocus />}
          />
        </div>
        <div className="ui link list teams">{this.renderTeams()}</div>
      </>
    );
  }
}

export default Teams;
