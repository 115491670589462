import React from "react";
import { Dropdown } from "semantic-ui-react";

const Form = ({
  currencies,
  index,
  currency,
  canRemove,
  updateCurrency,
  updateAmount,
  removeCurrency,
}) => {
  const handleCurrencyChange = (value) => {
    updateCurrency(index, value);
  };

  const handleAmountChange = (e) => {
    updateAmount(index, e.target.value);
  };

  return (
    <div className="fields">
      <div className="field string required page_title four wide">
        <label className="string" htmlFor="page_title">
          Amount currency
        </label>
        <div className="field">
          <Dropdown
            placeholder="Select Currency"
            id="select-country-dropdown"
            fluid
            search
            selection
            options={currencies}
            value={currency.name}
            onChange={(e, data) => handleCurrencyChange(data.value)}
          />
        </div>
      </div>
      <div className="field string required page_title four wide">
        <label className="string" htmlFor="page_title">
          Amount
        </label>
        <div className="field">
          <input
            type="number"
            name="amount"
            id="amount"
            className="string required"
            placeholder="Enter Amount"
            value={currency.amount}
            onChange={(e) => handleAmountChange(e)}
          />
        </div>
      </div>
      {canRemove && (
        <div
          className="field string page_title four wide"
          style={{ marginTop: "2em" }}
        >
          <a href="#" className="ui" onClick={(e) => removeCurrency(e, index)}>
            Remove Currency
          </a>
        </div>
      )}
    </div>
  );
};

export default Form;
