import React, { Component } from "react";
import { AxiosResponse } from "axios";
import {
  ModalContent,
  ModalActions,
  Button,
  Header,
  Icon,
  Modal
} from "semantic-ui-react";
import ShortCourse from "../Courses/ShortCourse";
import { CourseResource } from "../../../../stores/CoursesStore/Types";
import { randomTexts } from "./helper";

interface Props {
  openModal: boolean;
  onClickCloseModal: () => void;
  courses: CourseResource[];
  loading: boolean;
  commentary: string;
  errorMessage: string;
  searchText: string;
}

interface State {
  randomText: string;
}

class CoursesModal extends Component<Props, State> {
  interval: NodeJS.Timeout | null = null;

  constructor(props) {
    super(props);

    this.state = {
      randomText: randomTexts[0]
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * randomTexts.length);
      this.setState({ randomText: randomTexts[randomIndex] });
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onCloseModal = () => {
    this.props.onClickCloseModal();
  };

  render() {
    const {
      openModal,
      onClickCloseModal,
      commentary,
      courses,
      loading,
      errorMessage,
      searchText
    } = this.props;
    const { randomText } = this.state;

    return (
      <Modal
        className="ai-course-picker-modal"
        size="large"
        closeIcon
        open={openModal}
        onClose={this.onCloseModal}
      >
        <div className="courses featured-courses">
          {loading ? (
            <ModalContent className="loading-content">
              <div className="random-text">
                {randomText}
                <br />
              </div>
              <div className="ui active inverted dimmer">
                <div className="ui medium text loader">Loading</div>
              </div>
            </ModalContent>
          ) : (
            <ModalContent scrolling={courses.length > 3}>
              <div className="courses-container featured-courses-container ui inverted vertical segment center aligned">
                <div className="search-text font-18 text-color">
                  <strong>{searchText}</strong>
                </div>
                <br />
                {courses.length > 0 ? (
                  <>
                    <div className="commentary font-18 text-color">
                      {commentary}
                    </div>
                    <div
                      className="ui centered grid doubling stackable three column desktop"
                      id="courses_grid"
                      style={{marginTop: "2em"}}
                    >
                      {courses?.map((course, i) => (
                        <ShortCourse
                          courseResource={course}
                          idx={i}
                          key={course.id}
                        />
                      ))}
                    </div>
                    <div
                      className="ui centered grid doubling stackable two column mobile"
                      id="courses_grid"
                    >
                      {courses?.map((course, i) => (
                        <ShortCourse
                          courseResource={course}
                          idx={i}
                          key={course.id}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="font-18 text-color">No results found!!!</div>
                )}
              </div>
            </ModalContent>
          )}
        </div>
      </Modal>
    );
  }
}

export default CoursesModal;
