import React, { Component } from "react";
import { Dropdown, Checkbox } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import ReactHtmlParser from "react-html-parser";
import NumberFormat from "react-number-format";
import _ from "lodash";
import { getRequest, postRequest } from "../../../utils/httpRequest";
import { notification } from "../../../utils/helpers";
import { Props, State, LeaderboardSetup, Hero, Response } from "./Types";
import TeamMember from "./TeamMember";
import MakeAdminsModal from "./modals/MakeAdminsModal";
import DeleteUsersModal from "./modals/DeleteUsersModal";
import AllocateCoursesModal from "./modals/AllocateCoursesModal";

class Leaderboard extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      courses: [],
      heroes: [],
      selectedCourse: "",
      count: 20,
      query: "",
      page: 0,
      totalPages: 0,
      allMembersSelected: false,
      loading: false,
      showDeleteUsersModal: false,
      showMakeAdminsModal: false,
      showAllocateCoursesModal: false,
      submitting: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const { slug } = this.props;

    return getRequest("/api/profile/leaderboard_setup.json").then(
      (response: AxiosResponse<LeaderboardSetup>) => {
        const { data } = response;
        const courses = [
          { text: "All Courses", value: "all", key: "all" },
        ].concat(
          data.courses.map((course) => ({
            text: course.name,
            value: course.slug,
            key: course.slug,
          })),
        );

        this.setState({
          courses,
          hasCurrentMemberShip: data.has_current_membership,
          loading: false,
        });
      },
    );
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.getHeroes(e, this.state.count);
    }
  };

  getHeroes = (e, count, newPage = 0) => {
    e.preventDefault();

    this.setState({ loading: true, count });
    const { selectedCourse, query } = this.state;

    return getRequest(
      `/api/teams/${this.props.slug}/leaderboard_search.json?course_slug=${
        selectedCourse === "all" ? "" : selectedCourse
      }&query=${query}&count=${count}&page=${newPage + 1}`,
    ).then((response: AxiosResponse<Response>) => {
      const { data } = response;

      let heroes = data.heroes.map((hero) => {
        return { ...hero, selected: false };
      });

      this.props.updateHeroes(heroes);
      this.setState({
        loading: false,
        page: newPage,
        totalPages: data.total_pages,
      });
    });
  };

  loadPrev = (event) => {
    event.preventDefault();
    const { page, count } = this.state;
    const newPage = this.prevPage(page);

    this.getHeroes(event, count, newPage);
  };

  loadNext = (event) => {
    event.preventDefault();
    const { page, count, totalPages } = this.state;
    const nextPageNum = this.nextPage(page, totalPages);
    const newPage = nextPageNum === totalPages ? totalPages - 1 : nextPageNum;

    this.getHeroes(event, count, newPage);
  };

  prevPage = (page) => {
    if (page < 1) {
      return 0;
    } else if (page + 1 === 1) {
      return 1;
    } else {
      return page - 1;
    }
  };

  nextPage = (page, totalPages) => (page > totalPages ? totalPages : page + 1);

  handleInputChange = (e) => {
    this.setState({ query: e.target.value });
  };

  handleChange(key, event, data) {
    this.setState({ [key]: data.value }, () =>
      this.getHeroes(event, this.state.count),
    );
  }

  handleAllMembersSelected = (e, data) => {
    let { heroes, updateHeroes } = this.props;

    this.setState({ allMembersSelected: data.checked });

    const updatedHeroes = heroes.map((hero) => {
      return { ...hero, selected: data.checked };
    });

    updateHeroes(updatedHeroes);
  };

  handleSelectMember = (e, value, idx, key) => {
    let heroes = [...this.props.heroes];
    heroes[idx] = {
      ...heroes[idx],
      [key]: value,
    };
    this.props.updateHeroes(heroes);
  };

  handleShowDeleteUsersModal = (e) => {
    e.preventDefault();

    const users = this.getSelectedUsers();

    if (users.length === 0) {
      alert("Please select users!");
    } else {
      this.setState({ showDeleteUsersModal: true });
    }
  };

  handleShowMakeAdminsModal = (e) => {
    e.preventDefault();

    const users = this.getSelectedUsers();

    if (users.length === 0) {
      alert("Please select users!");
    } else {
      this.setState({ showMakeAdminsModal: true });
    }
  };

  handleAllocateCoursesModal = (e) => {
    e.preventDefault();

    const users = this.getSelectedUsers();

    if (users.length === 0) {
      alert("Please select users!");
    } else {
      this.setState({ showAllocateCoursesModal: true });
    }
  };

  handleHideAllocateCoursesModal = (e) => {
    e.preventDefault();

    this.setState({ showAllocateCoursesModal: false });
  };

  handleHideDeleteUsersModal = (e) => {
    e.preventDefault();

    this.setState({ showDeleteUsersModal: false });
  };

  handleHideMakeAdminsModal = (e) => {
    e.preventDefault();

    this.setState({ showMakeAdminsModal: false });
  };

  getSelectedUsers = () => {
    const { heroes } = this.props;

    let selectedUsers = _.filter(heroes, ["selected", true]);

    return _.map(selectedUsers, "id");
  };

  deleteUsers = (e) => {
    e.preventDefault();

    const { slug } = this.props;
    this.setState({ submitting: true });

    const users = this.getSelectedUsers();

    const params = {
      situation: "remove_user",
      user_ids: users,
    };

    postRequest(`/api/teams/${slug}/manage_members.json`, params)
      .then(() => {
        this.setState({ submitting: false, showDeleteUsersModal: false });
        notification("Users removed successfully", "notification");
        this.getHeroes(e, this.state.count);
      })
      .catch(() => {
        this.setState({ submitting: false });
        notification("Error removing users", "error");
      });
  };

  makeAdmins = (e) => {
    e.preventDefault();

    const { slug } = this.props;
    this.setState({ submitting: true });

    const users = this.getSelectedUsers();

    const params = {
      situation: "manage_admin",
      user_ids: users,
    };

    postRequest(`/api/teams/${slug}/manage_members.json`, params)
      .then(() => {
        this.setState({ submitting: false, showMakeAdminsModal: false });
        notification("Users updated successfully", "notification");
        this.getHeroes(e, this.state.count);
      })
      .catch(() => {
        this.setState({ submitting: false });
        notification("Error updating users", "error");
      });
  };

  render() {
    const { heroes, slug } = this.props;

    const {
      query,
      courses,
      selectedCourse,
      count,
      page,
      allMembersSelected,
      loading,
      showDeleteUsersModal,
      showMakeAdminsModal,
      showAllocateCoursesModal,
      submitting,
      totalPages,
    } = this.state;

    const currentCourse = courses.filter(
      (courses) => courses.value === selectedCourse,
    )[0];

    return (
      <div
        className="ui segment leaderboard team-landing-leaderboard"
        id="team-leaderboard"
      >
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="ui container">
          <div className="ui header">
            <h1 className="">Leaderboard</h1>
          </div>
          <div className="filters">
            <div className="ui segment filtersLeaderboard">
              <div className="ui three column stackable grid">
                <div className="sixteen wide tablet sixteen wide computer five wide large screen five wide widescreen column">
                  <div className="ui two column stackable grid">
                    <div className="column">
                      <div className="ui action input search">
                        <input
                          id="team_leaderboard_query"
                          placeholder="I'm looking for…"
                          type="text"
                          value={query}
                          onChange={this.handleInputChange}
                        />
                        <button
                          onClick={(e) => this.getHeroes(e, count)}
                          className="ui icon button"
                          id="team_leaderboard_search_button"
                        >
                          <i className="search icon"></i>
                        </button>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field select required course-search">
                        <Dropdown
                          placeholder="Course"
                          fluid
                          search
                          selection
                          value="Course"
                          options={courses}
                          onChange={this.handleChange.bind(
                            this,
                            "selectedCourse",
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sixteen wide tablet eight wide computer six wide large screen six wide widescreen column pagination">
                  <div className="ui column grid">
                    <div className="column">
                      <span className="team_heroes_count">
                        Show
                        <span className="team_heroes_size">
                          <a
                            href="#"
                            className={count === 20 ? "option-selected" : ""}
                            onClick={(e) => this.getHeroes(e, 20)}
                          >
                            20
                          </a>
                        </span>
                        <span>|</span>
                        <span className="team_heroes_size">
                          <a
                            href="#"
                            className={count === 50 ? "option-selected" : ""}
                            onClick={(e) => this.getHeroes(e, 50)}
                          >
                            50
                          </a>
                        </span>
                        <span>|</span>
                        <span className="team_heroes_size">
                          <a
                            href="#"
                            className={count === 100 ? "option-selected" : ""}
                            onClick={(e) => this.getHeroes(e, 100)}
                          >
                            100
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="sixteen wide tablet eight wide computer five wide large screen five wide widescreen column actionsAdmin">
                  <div className="ui four column grid actionsIcons">
                    <div
                      className="column"
                      onClick={this.handleAllocateCoursesModal}
                    >
                      <span className="icon">
                        <img
                          src={require("../../../../../assets/images/team_admin/morterboard.png")}
                        />
                      </span>
                      Allocate courses
                    </div>

                    <a
                      className="column active"
                      href={`/teams/${slug}/download_usage.csv`}
                    >
                      <span className="icon">
                        <img
                          style={{ height: "25px", marginTop: "-4px" }}
                          src={require("../../../../../assets/images/team_admin/download.svg")}
                        />
                      </span>
                      Usage Data
                    </a>
                    <div
                      className="column active"
                      onClick={this.handleShowMakeAdminsModal}
                    >
                      <span className="icon">
                        <img
                          src={require("../../../../../assets/images/team_admin/makeAdmin.png")}
                        />
                      </span>
                      Make admin
                    </div>
                    <div
                      className="column active"
                      onClick={this.handleShowDeleteUsersModal}
                    >
                      <span className="icon">
                        <img
                          src={require("../../../../../assets/images/team_admin/delete.png")}
                        />
                      </span>
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ui segment">
            <div className="leaderboard-table ui">
              <div className="column">
                <div className="columnleading bottom left">
                  <div className="carouselHeroes">
                    <div aria-live="polite" className="">
                      <div className="bottom-seven">
                        <div className="small_column_classes">
                          <div className="user header">
                            <span className="checkBox">
                              <Checkbox
                                checked={allMembersSelected}
                                onChange={this.handleAllMembersSelected}
                              />
                            </span>
                            <div className="wrapper">
                              <span className="position">RANK</span>
                              <span className="heroes">
                                <span className="leaderboard-img">&nbsp;</span>
                                <span className="name">NAME</span>
                              </span>
                              <span className="courses">COURSES</span>
                              <span className="points">POINTS</span>
                              <span className="last-activity">
                                LAST ACTIVITY
                              </span>
                              <span className="dropdown-icon">&nbsp;</span>
                            </div>
                          </div>
                        </div>
                        <div className="heros-container">
                          {heroes.length === 0 ? (
                            <div className="no-heroes">No heroes found!</div>
                          ) : (
                            <>
                              {heroes.map((hero, i) => (
                                <TeamMember
                                  hero={hero}
                                  key={i}
                                  idx={i}
                                  page={page}
                                  count={count}
                                  slug={this.props.slug}
                                  handleSelectMember={this.handleSelectMember}
                                />
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {totalPages > 1 && (
                <div className="pagination-buttons">
                  <button
                    className="circular ui icon button prev"
                    onClick={this.loadPrev}
                  >
                    <i className="icon angle left"></i>
                  </button>
                  <button
                    className="circular ui icon button next"
                    onClick={this.loadNext}
                  >
                    <i className="icon angle right"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <AllocateCoursesModal
          showModal={showAllocateCoursesModal}
          hideModal={this.handleHideAllocateCoursesModal}
          courses={courses.slice(1)}
          users={this.getSelectedUsers()}
          organization_id={slug}
        />
        <MakeAdminsModal
          showMakeAdminsModal={showMakeAdminsModal}
          handleHideMakeAdminsModal={this.handleHideMakeAdminsModal}
          makeAdmins={this.makeAdmins}
          submitting={submitting}
        />
        <DeleteUsersModal
          showDeleteUsersModal={showDeleteUsersModal}
          handleHideDeleteUsersModal={this.handleHideDeleteUsersModal}
          deleteUsers={this.deleteUsers}
          submitting={submitting}
        />
      </div>
    );
  }
}

export default Leaderboard;
