import { AxiosResponse, AxiosError } from "axios";
import { Api } from "./Api";

export const getRequest = <T, U>(url) => {
  return new Promise((resolve, reject) => {
    const client = new Api({});

    client
      .get<T>(url, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response: AxiosResponse<T>) => resolve(response))
      .catch((error: AxiosError) => reject(error));
  });
};

export const postRequest = <T, U>(url, data) => {
  return new Promise((resolve, reject) => {
    const token = $('meta[name="csrf-token"]').attr("content");
    const client = new Api({});

    client
      .post<T, U>(url, data, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      })
      .then((response: AxiosResponse<T>) => resolve(response))
      .catch((error: AxiosError) => reject(error));
  });
};

export const putRequest = <T, U>(url, data) => {
  return new Promise((resolve, reject) => {
    const token = $('meta[name="csrf-token"]').attr("content");
    const client = new Api({});

    client
      .put<T, U>(url, data, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      })
      .then((response: AxiosResponse<T>) => resolve(response))
      .catch((error: AxiosError) => reject(error));
  });
};

export const patchRequest = <T, U>(url, data) => {
  return new Promise((resolve, reject) => {
    const token = $('meta[name="csrf-token"]').attr("content");
    const client = new Api({});

    client
      .patch<T, U>(url, data, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      })
      .then((response: AxiosResponse<T>) => resolve(response))
      .catch((error: AxiosError) => reject(error));
  });
};

export const deleteRequest = <T>(url) => {
  return new Promise((resolve, reject) => {
    const token = $('meta[name="csrf-token"]').attr("content");
    const client = new Api({});

    client
      .delete<T>(url, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      })
      .then((response: AxiosResponse<T>) => resolve(response))
      .catch((error: AxiosError) => reject(error));
  });
};
