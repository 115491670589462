import React, {Component} from "react";
import ModalStore from "../../../stores/ModalStore";

interface Props {
  modalStore: ModalStore;
}

const Terms: React.FC<Props> = ({modalStore}) => (
  <div className="card">
    <div className="content">
      <ul>
        <li className="first">
          <strong>30 days money back guarantee.</strong> If you don't find our
          courses incredibly helpful before you complete them, we will happily refund you up to 30 days
          from purchase.
        </li>
        <li>
          Most importantly, we politely request that you enjoy this course.
        </li>
      </ul>
      <div className="terms">
        <a
          className="terms"
          href="#"
          onClick={e => {
            e.preventDefault();
            modalStore.open();
          }}
        >
          Our Fabulous T&Cs
        </a>
      </div>
    </div>
  </div>
);

export default Terms;
