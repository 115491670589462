import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { AxiosResponse } from "axios";
import { postRequest } from "../../utils/httpRequest";
import ChatBanner from "../../../../assets/images/chat-banner.png";
import SignUpForm from "./SignUpForm";
import LoginForm from "./LoginForm";
import LinkedinIcon from "./LinkedinIcon";
import FacebookIcon from "./FacebookIcon";
import GoogleIcon from "./GoogleIcon";

interface Props {
  currentUser: { kind: string };
}

interface State {
  mode: string;
  error: string;
}

class Login extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      mode: "login",
      error: ""
    };
  }

  setMode = mode => {
    this.setState({ mode });
  };

  closeError = e => {
    e.preventDefault();

    this.setState({ error: "" });
  };

  setError = error => {
    this.setState({ error });
    setTimeout(() => {
      this.setState({ error: "" });
    }, 10000);
  };

  render() {
    const { currentUser } = this.props;

    const { mode, error } = this.state;

    return (
      <div className="ui facelift-landing-page" id="login">
        <div className="ui container desktop">
          <div className="wrapper">
            <div className="eight wide column section-two" id="column">
              <div>
                <p className="note">Please sign in to continue</p>

                <div className="segment padded special">
                  <div className="login-mobile-providers">
                    <a
                      className="ui button huge keep-case click-busy linkedin fluid icon labeled"
                      href="/oauth/linkedin"
                    >
                      <LinkedinIcon />
                      Sign in with LinkedIn
                    </a>
                    <a
                      className="ui button huge keep-case click-busy facebook fluid icon labeled"
                      href="/oauth/facebook"
                    >
                      <FacebookIcon />
                      Sign in with Facebook
                    </a>
                    <a
                      className="ui button huge keep-case click-busy google fluid icon labeled"
                      href="/oauth/google"
                    >
                      <GoogleIcon />
                      Sign in with Google
                    </a>
                  </div>
                  <br />
                  <div className="ui one column grid center aligned login-container vertically">
                    <div className="ui basic segment">
                      <div className="ui text container left aligned">
                        <p className="email-note">Or, continue with e-mail:</p>
                        <div className="forms">
                          {error && (
                            <p
                              className="ui error"
                              style={{ textAlign: "center" }}
                            >
                              {error}
                            </p>
                          )}
                          <div className="ui secondary pointing menu left aligned tabs-menu ">
                            <a
                              className={`item ${
                                mode === "signUp" ? "active" : ""
                              }`}
                              data-tab="registration"
                              onClick={() => this.setMode("signUp")}
                            >
                              New User?
                            </a>
                            <a
                              className={`item ${
                                mode === "login" ? "active" : ""
                              }`}
                              data-tab="login"
                              onClick={() => this.setMode("login")}
                            >
                              Existing User?
                            </a>
                          </div>
                          {mode === "signUp" && (
                            <SignUpForm setError={this.setError} />
                          )}
                          {mode === "login" && (
                            <LoginForm setError={this.setError} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ui medium-mobile">
          <div className="wrapper medium-wrapper">
            <div className="content">
              <p className="note">Please sign in to continue</p>

              <>
                <div className="segment padded special">
                  <div className="login-mobile-providers">
                    <a
                      className="ui button huge keep-case click-busy linkedin fluid icon labeled"
                      href="/oauth/linkedin"
                    >
                      <LinkedinIcon />
                      Sign in with LinkedIn
                    </a>
                    <a
                      className="ui button huge keep-case click-busy facebook fluid icon labeled"
                      href="/oauth/facebook"
                    >
                      <FacebookIcon />
                      Sign in with Facebook
                    </a>
                    <a
                      className="ui button huge keep-case click-busy google fluid icon labeled"
                      href="/oauth/google"
                    >
                      <GoogleIcon />
                      Sign in with Google
                    </a>
                  </div>
                  <br />
                  <div className="ui center aligned login-container vertically">
                    <div className="ui basic segment">
                      <div className="ui text container center aligned">
                        <p className="email-note">Or, continue with e-mail:</p>
                        <div className="forms">
                          {error && <p className="ui error">{error}</p>}
                          <div className="ui secondary pointing menu left aligned tabs-menu ">
                            <a
                              className={`item ${
                                mode === "signUp" ? "active" : ""
                              }`}
                              data-tab="registration"
                              onClick={() => this.setMode("signUp")}
                            >
                              <p>New User?</p>
                            </a>
                            <a
                              className={`item ${
                                mode === "login" ? "active" : ""
                              }`}
                              data-tab="login"
                              onClick={() => this.setMode("login")}
                            >
                              <p>Existing User?</p>
                            </a>
                          </div>
                          {mode === "signUp" && (
                            <SignUpForm setError={this.setError} />
                          )}
                          {mode === "login" && (
                            <LoginForm setError={this.setError} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
