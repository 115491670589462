import React from "react";
import { Button } from "semantic-ui-react";

interface Props {
  nextAction: (e: any) => void;
  name?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  showBackButton?: boolean;
  showLink?: boolean;
  link?: string;
  backButtonId?: string;
  nextButtonId?: string;
  previousAction?: (e: any) => void;
}

const Action: React.FC<Props> = ({
  name = "Next",
  isDisabled = false,
  isSubmitting = false,
  showBackButton = true,
  showLink = false,
  link,
  backButtonId,
  nextButtonId,
  nextAction,
  previousAction
}) => (
  <div className="action">
    {showBackButton && (
      <Button
        primary
        className="back"
        onClick={e => previousAction(e)}
        id={backButtonId}
      >
        Back
      </Button>
    )}
    {showLink && (
      <a className="link" href={link}>
        I'm buying this for someone else
      </a>
    )}
    <Button
      primary
      id={nextButtonId}
      disabled={isDisabled}
      className={`${isSubmitting ? "loading next" : "next"}`}
      type="submit"
      onClick={e => nextAction(e)}
    >
      {name}
    </Button>
  </div>
);

export default Action;
