import React from "react";
import { Form, Radio } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import { QuestionIcon, AnswerIcon } from "../../Learning/Icons";

interface Props {
  selectedAnswer: any;
  question: any;
  answer: any;
  updateSelectedAnswer: (val: any) => void;
  submitAnswer: (e) => void;
  submitting: boolean;
  loadingAnswer: boolean;
}

const Choice: React.FC<Props> = ({
  question,
  answer,
  selectedAnswer,
  updateSelectedAnswer,
  submitAnswer,
  submitting,
  loadingAnswer
}) => {
  const handleChange = (e, { value }) => {
    updateSelectedAnswer(value);
  };

  const renderField = (option, i, answer) => {
    if (!answer || option.id == answer?.answer_option_id) {
      return (
        <Form.Field key={i}>
          <Radio
            label={ReactHtmlParser(option.formatted_content)}
            name="radioGroup"
            value={option.id}
            checked={
              option.id == answer?.answer_option_id ||
              option.id == selectedAnswer
            }
            onChange={handleChange}
          />
        </Form.Field>
      );
    }
  };

  return (
    <Form className="assignment-form">
      <div className="form-header">
        <div className="ui image">
          <QuestionIcon />
        </div>
        <div className="ui question">
          {/* @ts-ignore */}
          <p>{ReactHtmlParser(question?.formatted_description)}</p>
        </div>
      </div>
      <div className="form-body">
        {(loadingAnswer || submitting) && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="ui image">
          <AnswerIcon />
        </div>
        <div className="content">
          {question?.answer_options.map((option, i) =>
            renderField(option, i, answer)
          )}
        </div>
      </div>
      {!answer && (
        <div className="action">
          <button
            className={`ui button ${submitting ? "loading" : ""}`}
            type="submit"
            onClick={e => submitAnswer(e)}
          >
            Submit
          </button>
        </div>
      )}
    </Form>
  );
};

export default Choice;
