import React from "react";
import ReactHtmlParser from "react-html-parser";
import { QuestionIcon, AnswerIcon } from "../../Learning/Icons";

interface Props {
  selectedAnswer: any;
  question: any;
  answer: any;
  updateSelectedAnswer: (val: any) => void;
  submitAnswer: (e) => void;
  submitting: boolean;
  loadingAnswer: boolean;
}

const TextAction: React.FC<Props> = ({
  question,
  answer,
  selectedAnswer,
  updateSelectedAnswer,
  submitAnswer,
  submitting,
  loadingAnswer
}) => {
  const handleChange = e => {
    updateSelectedAnswer(e.target.value);
  };

  return (
    <form className="ui form assignment-form">
      <div className="form-header">
        <div className="ui image">
          <QuestionIcon />
        </div>
        <div className="ui question">
          {/* @ts-ignore */}
          <p>{ReactHtmlParser(question?.formatted_description)}</p>
        </div>
      </div>
      <div className={`form-body ${answer ? "form-body-center" : ""}`}>
        {(loadingAnswer || submitting) && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="ui image">
          <AnswerIcon />
        </div>
        <div className="content">
          {answer && (
            <p style={{ whiteSpace: "break-spaces" }}>
              {/* @ts-ignore */}
              {ReactHtmlParser(answer.formatted_content || answer.content_url)}
            </p>
          )}
          {!answer && (
            <div className="field text-action">
              <textarea
                rows={2}
                placeholder="Type your answer"
                value={selectedAnswer}
                onChange={e => handleChange(e)}
              />
            </div>
          )}
        </div>
      </div>
      {!answer && (
        <div className="action">
          <button
            className={`ui button ${submitting ? "loading" : ""}`}
            type="submit"
            onClick={e => submitAnswer(e)}
          >
            Submit
          </button>
        </div>
      )}
    </form>
  );
};

export default TextAction;
