import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  CoursePreviewResource,
  QuotationResource
} from "../../stores/CoursePreviewStore/Types";
import { postRequest } from "../../utils/httpRequest";
import { AxiosError, AxiosResponse } from "axios";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";
import NumberFormat from "react-number-format";
import { Popup } from "semantic-ui-react";
import GoToCheckoutButton from "../Payments/GoToCheckoutButton";
import CreditsUsedConfirmation from "./CreditsUsedConfirmation";
import RestrictedEnrolmentConfirmation from "./RestrictedEnrolmentConfirmation";

interface Props {
  course: CoursePreviewResource;
  showSigninSidebar: boolean;
  currentUser: CurrentUserResource;
  quotationResource: QuotationResource;
  loadingQuotations: boolean;
  loadingEnrolmentInfo: boolean;
  enrolmentInfo: {
    existing_subscription: boolean;
    enrolment_id: number;
  };
  handleShowSignInSideBar: (redirectUrl?: string) => void;
}

interface State {
  enrolingUser: boolean;
  canEnrolWithRestrictedSub: boolean;
  isCreditsUsedUp: boolean;
}

class CoursePricing extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      enrolingUser: false,
      canEnrolWithRestrictedSub: false,
      isCreditsUsedUp: false
    };
  }

  toggleRestrictedConfirmation = () => {
    this.setState({
      canEnrolWithRestrictedSub: !this.state.canEnrolWithRestrictedSub
    });
  };

  toggleIsCreditsUsedUp = () => {
    this.setState({ isCreditsUsedUp: !this.state.isCreditsUsedUp });
  };

  startCourse = e => {
    const {
      plan_slug,
      merchant_quotation_id,
      new_enrolment_url
    } = this.props.quotationResource;

    e.preventDefault();

    this.setState({ enrolingUser: true });

    return postRequest(`${new_enrolment_url}.json`, {
      plan_slug,
      merchant_quotation_id
    })
      .then((response: AxiosResponse<{ url: string }>) => {
        (window as any).location = response.data.url;
      })
      .catch((error: AxiosError<{ url: string }>) => {
        this.setState({ enrolingUser: false });
      });
  };

  renderHeroPrice = () => {
    const {
      fee,
      quotations,
      existing_subscription,
      enrolment_id
    } = this.props.quotationResource;

    if (fee > 0 && !existing_subscription && !enrolment_id) {
      return (
        <>
          <span className="currency-symbol">{quotations.course.currency}</span>
          <Popup
            trigger={<span className="amount">{quotations.course.amount}</span>}
            content={quotations.course.currency_text}
          />
          <Popup
            trigger={
              <div className="currency-code">
                {quotations.course.currency_code}
              </div>
            }
            content={quotations.course.currency_name}
          />
        </>
      );
    } else if (existing_subscription || enrolment_id) {
      return <span />;
    } else {
      return <span>COMPLIMENTARY</span>;
    }
  };

  renderCoupon = () => {
    const { coupon, coupon_previous_price } = this.props.quotationResource;
    if (coupon) {
      return (
        <h3 className="hero-coupon">
          <span>{coupon.code}</span>&nbsp;&nbsp;<i className="ticket icon"></i>
          &nbsp;&nbsp;
          <span>
            <span>was &nbsp;</span>
            {coupon_previous_price}
          </span>
        </h3>
      );
    } else {
      return <span />;
    }
  };

  subscriptionOnlyButton = () => (
    <a
      className="ui traditional button fluid add-to-cart-button"
      style={{ marginTop: "1em" }}
      href="/passes/month/pay"
    >
      <i className="lock icon"></i>
      Subscriber Only
    </a>
  );

  renderActionButton = () => {
    const {
      quotationResource,
      showSigninSidebar,
      handleShowSignInSideBar,
      currentUser,
      course,
      enrolmentInfo
    } = this.props;

    const { enrolingUser } = this.state;

    if (enrolmentInfo?.enrolment_id) {
      return (
        <a
          href={`/enrolments/${enrolmentInfo.enrolment_id}`}
          className="ui traditional button fl-button highlight view-pricing click-busy"
        >
          Continue
        </a>
      );
    } else if (currentUser.kind === "current-user") {
      if (enrolmentInfo?.existing_subscription || course.sample) {
        if (course.is_restricted_subscription && course.available_credits < 1) {
          return (
            <button
              className={`ui button fl-button highlight view-pricing ${
                enrolingUser ? "loading" : ""
              }`}
              onClick={this.toggleIsCreditsUsedUp}
            >
              <i className="student icon"></i>Start now
            </button>
          );
        } else if (
          course.is_restricted_subscription &&
          course.available_credits > 0
        ) {
          return (
            <button
              className={`ui button fl-button highlight view-pricing ${
                enrolingUser ? "loading" : ""
              }`}
              onClick={this.toggleRestrictedConfirmation}
            >
              <i className="student icon"></i>Start now
            </button>
          );
        } else {
          return (
            <button
              className={`ui button fl-button highlight view-pricing ${
                enrolingUser ? "loading" : ""
              }`}
              onClick={this.startCourse}
            >
              <i className="student icon"></i>Start now
            </button>
          );
        }
      } else {
        if (course.subscription_only && !enrolmentInfo?.existing_subscription) {
          return this.subscriptionOnlyButton();
        } else {
          return (
            <a
              className="ui traditional button fl-button highlight view-pricing"
              data-smooth-scroll-to-top="#pricing-container"
              href="#"
            >
              View Pricing
            </a>
          );
        }
      }
    } else if (
      course.subscription_only &&
      !enrolmentInfo.existing_subscription
    ) {
      return this.subscriptionOnlyButton();
    } else if (course.sample) {
      return (
        <a
          href={`/courses/${course.slug}/pay`}
          className="fl-button highlight view-pricing"
        >
          Get started
        </a>
      );
    } else {
      return (
        <a
          className="fl-button highlight view-pricing"
          data-smooth-scroll-to-top="#pricing-container"
          href="#"
        >
          View Pricing
        </a>
      );
    }
  };

  render() {
    const { course, loadingQuotations, quotationResource } = this.props;

    const {
      canEnrolWithRestrictedSub,
      isCreditsUsedUp,
      enrolingUser
    } = this.state;

    return (
      <div className="ui segment course-pricing" id="">
        <RestrictedEnrolmentConfirmation
          isOpen={canEnrolWithRestrictedSub}
          onClose={this.toggleRestrictedConfirmation}
          onConfirm={this.startCourse}
          availableCredits={course.available_credits}
          enrolingUser={enrolingUser}
        />
        <CreditsUsedConfirmation
          isOpen={isCreditsUsedUp}
          onClose={this.toggleIsCreditsUsedUp}
        />
        {loadingQuotations && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        {quotationResource && (
          <div
            className="ui container"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${course?.preview_masthead_image_url})`
            }}
          >
            <div className="header main-header">
              <h1>
                Learn {course?.name}
                {course?.brand?.name && (
                  <p>And get certified by {course.brand.name}</p>
                )}
              </h1>
            </div>
            <div className="course-price">
              <div className="dimmable wrapper">
                <div className="ui image medium">
                  <img
                    src={require("../../../../assets/images/courses/preview/cert-preview.png")}
                  />
                </div>
                <div className="price-info">
                  <div className="list">
                    <img
                      className="ui image"
                      src={require("../../../../assets/images/courses/preview/approval.png")}
                      alt="approval logo"
                    />
                    <span>
                      <strong>Earn a Nano MBA from  {course.brand.name}</strong>
                    </span>
                  </div>
                  <div className="list">
                    <img
                      className="ui image"
                      src={require("../../../../assets/images/courses/preview/man-on-phone.png")}
                      alt="phone logo"
                    />
                    <span>
                      <strong>Live marking & 24/7 support</strong>
                    </span>
                  </div>
                  <div className="list">
                    <img
                      className="ui image"
                      src={require("../../../../assets/images/courses/preview/historical.png")}
                      alt="historical logo"
                    />
                    <span>
                      {course.sample ? (
                        <strong>1 hour sample course</strong>
                      ) : (
                        <strong>
                          Approx.{" "}
                          {course.learning_hours ? course.learning_hours : 9}{" "}
                          hours
                        </strong>
                      )}
                    </span>
                  </div>
                  <div className="list">
                    <img
                      className="ui image"
                      src={require("../../../../assets/images/courses/preview/future.png")}
                      alt="future logo"
                    />
                    <span>
                      <strong>On-demand</strong>
                    </span>
                  </div>
                </div>
                <div className="action">
                  {quotationResource ? (
                    <div className="action">{this.renderActionButton()}</div>
                  ) : (
                    <div className="ui active inverted dimmer">
                      <div className="ui text loader">Loading</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CoursePricing;
