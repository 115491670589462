import * as React from "react";

const LinkedIn = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="#504257" />
    <path
      d="M13.1253 12.221C13.1253 13.1717 12.3545 13.942 11.4031 13.942C10.4517 13.942 9.68088 13.1717 9.68088 12.221C9.68088 11.271 10.4517 10.5 11.4031 10.5C12.3545 10.5 13.1253 11.271 13.1253 12.221ZM13.1392 15.3188H9.66699V26.3333H13.1392V15.3188ZM18.6823 15.3188H15.2323V26.3333H18.683V20.5514C18.683 17.3366 22.8698 17.0736 22.8698 20.5514V26.3333H26.3337V19.3591C26.3337 13.9345 20.1378 14.132 18.6823 16.8024V15.3188Z"
      fill="white"
    />
  </svg>
);
export default LinkedIn;
