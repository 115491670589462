import React, { useEffect } from "react";
import AddComment from "./components/AddComment";
import Comment from "./components/Comment";
import { CurrentUser } from "../../../../../stores/CurrentUserStore/Types";
import { Post } from "../../../../../stores/CommunityStore/posts/types";

interface Props {
  currentUser: CurrentUser;
  post: Post;
  postUsers: string[];
  featuredCommentId?: string;
  featuredReplyCommentId?: string;
}

const Comments: React.FC<Props> = ({
  currentUser,
  post,
  postUsers,
  featuredCommentId,
  featuredReplyCommentId,
}) => {
  useEffect(() => {
    if (featuredCommentId) {
      const commentElement = document.getElementById(
        `comment-${featuredCommentId}`,
      );
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [featuredCommentId]);

  const scrollToComments = () => {
    const commentsElement = document.getElementById(
      `community__comments_${post.uuid}`,
    );

    if (commentsElement) {
      const lastComment = commentsElement.lastElementChild;
      lastComment.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  return (
    <div
      className="ui community__comments"
      id={`community__comments_${post.uuid}`}
    >
      <h3>{post.comments.length} Comments</h3>
      <AddComment
        currentUser={currentUser}
        postId={post.id}
        postUsers={postUsers}
        scrollToComments={scrollToComments}
      />
      {post.comments.map((comment) => (
        <Comment
          key={comment.id}
          postId={post.id}
          currentUser={currentUser}
          postUsers={postUsers}
          comment={comment}
          featuredReplyCommentId={featuredReplyCommentId}
        />
      ))}
    </div>
  );
};

export default Comments;
