import React from "react";
import { Select } from "semantic-ui-react";

type Course = {
  key: string;
  value: string;
  label: string;
};

interface Props {
  courses: Course[];
  selectedCourse: string;
  onCourseChange: (e: any, data: any) => void;
  isError: boolean;
}

const Course: React.FC<Props> = ({
  courses,
  selectedCourse,
  onCourseChange,
  isError = false
}) => {
  const className = isError ? "select-course error" : "select-course";

  return (
    <div className={className}>
      <Select
        placeholder="Select a course..."
        className="courses-dropdown"
        fluid
        search
        selection
        onChange={(e, data) => onCourseChange(e, data)}
        defaultValue={selectedCourse}
        options={courses}
      />
    </div>
  );
};

export default Course;
