import ModalStore from "../ModalStore";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  currencyPayload: CurrencyPayload;
}

export interface Saving {
  kind: "saving";
  currencyPayload: CurrencyPayload;
  isoCode: string;
  modalStore: ModalStore;
}

export interface ReadyWithErrors {
  kind: "readyWithErrors";
  currencyPayload: CurrencyPayload;
  error: string;
}

export interface OpenModal {
  kind: "openModal";
}

export interface CloseModal {
  kind: "closeModal";
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (currencyPayload: CurrencyPayload): Ready => {
  return {
    kind: "ready",
    currencyPayload
  };
};

export const saving = (
  currencyPayload: CurrencyPayload,
  isoCode: string,
  modalStore: ModalStore
): Saving => {
  return {
    kind: "saving",
    currencyPayload,
    isoCode,
    modalStore
  };
};

export const readyWithErrors = (
  currencyPayload: CurrencyPayload,
  error: string
): ReadyWithErrors => {
  return {
    kind: "readyWithErrors",
    currencyPayload,
    error
  };
};

export type CurrencyState =
  | Waiting
  | Loading
  | Ready
  | Saving
  | ReadyWithErrors;

export interface Currency {
  iso_code: string;
  name: string;
  symbol: string;
  priority: number;
}

export interface CurrencyPayload {
  currencies: Currency[];
  selectedCurrency: Currency;
}
