import React, { Component } from "react";
import Conversation from "./Conversation";
import { CommentResource } from "../../../../stores/CommentStore/Types";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";

interface Props {
  currentUser: CurrentUser;
  comments: CommentResource[];
  questionId: number;
  enrolmentId: number;
  loaded: boolean;
  updateComments: () => void;
}

class Conversations extends Component<Props, {}> {
  render() {
    const {
      currentUser,
      comments,
      loaded,
      questionId,
      enrolmentId,
      updateComments
    } = this.props;

    return (
      <div className="ui container conversations">
        <div className="conversations_body">
          {!loaded && <div className="ui text loader">Loading</div>}
          {comments.map(comment => (
            <Conversation
              key={comment.id}
              conversation={comment}
              currentUser={currentUser}
              enrolmentId={enrolmentId}
              questionId={questionId}
              updateComments={updateComments}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Conversations;
