import React, {Component} from "react";
import {observer} from "mobx-react";
import ReactHtmlParser from "react-html-parser";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {errorNotification} from "../../utils/helpers";
import {getRequest} from "../../utils/httpRequest";
import {Page} from "./Types";
import {
  Introduction,
  CourseCategories,
  Blog,
  Pricing
} from "../Home/components";
import Learners from "./Learners";
import Category from "./Category";
import PricingStore from "../../stores/PricingStore";
import {RootContext} from "../../stores/RootStore/Types";

interface Props {
  match: any;
  pricingStore: PricingStore;
}

interface State {
  page: Page;
  loading: boolean;
}

class DynamicPages extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
      loading: true
    };
  }

  componentDidMount() {
    // @ts-ignore
    const history = this.props.history;
    const pageSlug = this.props.match.params.pageSlug;

    return getRequest(`/api/dynamic_pages/${pageSlug}.json`)
      .then((response: AxiosResponse) => {
        const page = response.data;

        this.setState({
          page,
          loading: false
        });
      })
      .catch(error => {
        this.setState({loading: false});

        errorNotification(
          error.response.data.error,
          JSON.stringify(error.response.data.error)
        );
        history.goBack()
      });
  }

  renderSections = section => {
    const {pricingStore} = this.props;
    const {pricingState} = pricingStore;

    switch (section.kind) {
      case "masthead":
        return (
          <div className="hero">
            <div
              className="bg"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${section?.masthead_image_url})`
              }}
            ></div>
            <div className="course-header">
              <h1>{section?.title}</h1>
            </div>
          </div>
        );
        break;
      case "text":
        return (
          <div className="ui segment" style={{zIndex: 30}}>
            <div className="ui container current-lesson-container">
              <div className="current-lesson">
                <div className="ui text container">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: section?.content
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );

        break;
      case "trust":
        return <Learners/>;
        break;
      case "why_42":
        return <Introduction/>;
        break;
      case "categories":
        return <Category category={section?.category}/>;
        break;
      case "pricing":
        return this.renderPricing(pricingState);
        break;
      case "blog":
        return <Blog/>;
        break;
    }
  };

  renderPricing = state => {
    switch (state.kind) {
      case "ready":
        return (
          <Pricing
            handleShowSignInSideBar={() => {
            }}
            pricing={state.pricing}
            showSigninSidebar={false}
          />
        );
      case "loading":
        return (
          <div className="pricing ui vertical center aligned segment">
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          </div>
        );
      default:
        return <div/>;
    }
  };

  render() {
    const {page, loading} = this.state;

    return (
      <div className="ui dynamic-pages facelift-course-inside facelift-new-homepage course-lesson">
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        {page?.sections.map((section, i) => (
          <div key={i}>{this.renderSections(section)}</div>
        ))}
      </div>
    );
  }
}

export default observer(DynamicPages);
