interface User {
  id: number;
  name: string;
  picture_url: string;
}

export interface Reply {
  id: number;
  uuid: string;
  formatted_content: string;
  likes: number;
  liked_by_current_user: boolean;
  user: User;
}

export interface Comment {
  id: number;
  uuid: string;
  formatted_content: string;
  likes: number;
  replies: Reply[];
  liked_by_current_user: boolean;
  user: User;
}

export interface CommentState {
  comments: Comment[];
  loading: boolean;
  created: boolean;
  error: string;
}

export const LOAD_COMMENTS = "LOAD_COMMENTS";
export const LOAD_COMMENTS_SUCCESS = "LOAD_COMMENTS_SUCCESS";
export const LIKE_COMMENT = "LIKE_COMMENT";
export const UNLIKE_COMMENT = "UNLIKE_COMMENT";
export const LOAD_COMMENTS_FAILURE = "LOAD_COMMENTS_FAILURE";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "CREATE_COMMENT_FAILURE";
export const RESET_COMMENT_CREATED = "RESET_COMMENT_CREATED";
export const LIKE_COMMENT_SUCCESS = "LIKE_COMMENT_SUCCESS";
export const LIKE_COMMENT_FAILURE = "LIKE_COMMENT_FAILURE";
export const REPLY_COMMENT_SUCCESS = "REPLY_COMMENT_SUCCESS";
export const LIKE_COMMENT_REPLY = "LIKE_COMMENT_REPLY";
export const UNLIKE_COMMENT_REPLY = "UNLIKE_COMMENT_REPLY";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_REPLY_SUCCESS = "DELETE_COMMENT_REPLY_SUCCESS";

export interface LoadCommentsAction {
  type: typeof LOAD_COMMENTS;
}

export interface LoadCommentsSuccessAction {
  type: typeof LOAD_COMMENTS_SUCCESS;
  payload: Comment[];
}

export interface LikeCommentAction {
  type: typeof LIKE_COMMENT;
  postId: number;
  commentId: number;
}

export interface UnLikeCommentAction {
  type: typeof UNLIKE_COMMENT;
  postId: number;
  commentId: number;
}

export interface LikeCommentReplyAction {
  type: typeof LIKE_COMMENT_REPLY;
  postId: number;
  commentId: number;
  replyId: number;
}

export interface UnLikeCommentReplyAction {
  type: typeof UNLIKE_COMMENT_REPLY;
  postId: number;
  commentId: number;
  replyId: number;
}

export interface LoadCommentsFailureAction {
  type: typeof LOAD_COMMENTS_FAILURE;
  payload: string;
}

export interface CreateCommentSuccessAction {
  type: typeof CREATE_COMMENT_SUCCESS;
  comment: Comment;
  postId: number;
}

export interface ReplyCommentSuccessAction {
  type: typeof REPLY_COMMENT_SUCCESS;
  comment: Comment;
  postId: number;
  parentCommentId: number;
}

export interface DeleteCommentSuccessAction {
  type: typeof DELETE_COMMENT_SUCCESS;
  postId: number;
  commentId: number;
}

export interface DeleteCommentReplySuccessAction {
  type: typeof DELETE_COMMENT_REPLY_SUCCESS;
  postId: number;
  commentId: number;
  replyId: number;
}

export interface ResetCommentCreatedAction {
  type: typeof RESET_COMMENT_CREATED;
}

export interface LikeCommentSuccessAction {
  type: typeof LIKE_COMMENT_SUCCESS;
}

export interface LikeCommentFailureAction {
  type: typeof LIKE_COMMENT_FAILURE;
}

export type CommentActionTypes =
  | LoadCommentsAction
  | LoadCommentsSuccessAction
  | LikeCommentAction
  | UnLikeCommentAction
  | LoadCommentsFailureAction
  | CreateCommentSuccessAction
  | ResetCommentCreatedAction
  | LikeCommentSuccessAction
  | LikeCommentFailureAction
  | ReplyCommentSuccessAction
  | DeleteCommentSuccessAction
  | DeleteCommentReplySuccessAction;
