import React, { Component } from "react";
import { observer } from "mobx-react";
import { AxiosResponse } from "axios";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { Accordion, Icon } from 'semantic-ui-react';
import ReactHtmlParser from "react-html-parser";
import { notification } from "../../utils/helpers";

interface Referral {
   id: number;
   context: any;
   redeemed: boolean;
}
interface State {
    viewReferrals: boolean;
    referral_classes: any;
    social_buttons: any;
    threshold: number;
    achieved: number;
    referrals: Referral[];
    error: string;
    success: string;
    loaded: boolean;
    emails: string;
    message: string;
    refCode: string;
}

class Referral extends Component<{}, State> {
    constructor(props) {
        super(props);

        this.state = {
            viewReferrals: false,
            referral_classes: [],
            social_buttons: [],
            achieved: 1,
            threshold: 0,
            referrals: [],
            loaded: false,
            error: null,
            success: null,
            refCode: "",
            emails: "",
            message: "Hi, join me and fast forward your career and personal growth with 42courses. It's a brilliant e-learning site that delivers certified top career and personal growth courses in a way that's fast, fun, informative and practical. Give it a try using my personal invite link below and you'll get a discount too."
        };
    }

    fetchReferralClasses = () => {
        return getRequest("/api/referrals/defaults.json").then(
            (response: AxiosResponse) => {
                this.setState({
                    referral_classes: response.data.css,
                    threshold: response.data.threshold,
                    achieved: response.data.achieved,
                    social_buttons: response.data.social_buttons,
                    refCode: response.data.referral_code });
            }
        );
    }

    componentDidMount() {
        return getRequest("/api/referrals.json").then(
            (response: AxiosResponse) => {
                this.setState({ referrals: response.data, loaded: true });
                this.fetchReferralClasses()
            }
        );
    }

    toggleReferrals = (e, titleProps) => {
     this.setState({ viewReferrals: !this.state.viewReferrals })
    }

    copyCodeToClipboard = () => {
        let copyText = document.querySelector("#user_referral_code");
        (copyText as any).select();
        document.execCommand("copy");
        notification("Copied referrer code to clipboard!", "alert");
    }

    setAttribute = (e) => {
       e.preventDefault();
        let event_hash = {};
       event_hash[e.target.name] = e.target.value;
       this.setState(event_hash)
    }

    sendInvite = (e) => {
        e.preventDefault();
        const { emails, message } = this.state;
        let invite = { emails: emails, message: message };
        return postRequest("/api/referrals/invite.json", { referral_email: invite }).then(
            (response: AxiosResponse) => {
                this.setState(response.data);
                this.setState({emails: ""});
                const { error, success } = this.state;
                let kind = error ? "error" : (success ? "success":"alert")
                notification((error||success), kind)
            }
        );
    }

    render() {
        const { loaded, referrals, referral_classes, achieved, threshold, social_buttons, emails, message, refCode, viewReferrals } = this.state;

        const renderCircle = (circle, idx) => {
            if (circle == 'won') {
               return(<div key={`badge-${idx}`} className="circle won"/>)
            } else if (idx == 4 || idx == 9) {
               return(<div key={`badge-${idx}`} className="circle">FREE</div>)
            } else {
               return(<div key={`badge-${idx}`} className="circle"/>)
            }
        }

        const renderRemainderText = () => {
            if (achieved >= threshold && achieved < (threshold*2)) {
                return (<p>Only <strong>{Math.abs((threshold*2) - achieved)}</strong>  more to go to your FREE All access pass</p>)
            } else if (achieved < threshold) {
                return (<p>Only <strong>{Math.abs(threshold - achieved)}</strong> more to go to your FREE course</p>)
            }
        }

        const renderReferrals = () => {
            if (referrals.length > 0) {
                return (
                    <Accordion styled fluid>
                        <Accordion.Title
                            active={viewReferrals}
                            index={0}
                            onClick={this.toggleReferrals}
                        >
                            <Icon name='dropdown'/>
                            Your referrals : {referrals.length}
                        </Accordion.Title>
                        <Accordion.Content active={viewReferrals}>
                            <div className="referral-content">
                                {
                                    referrals.map((ref) => (
                                        <p key={`ref-${ref.id}`}>{ref.context.to_s}</p>
                                    ))
                                }
                            </div>
                        </Accordion.Content>
                    </Accordion>
                )
            }
        }

        return (
            <div className="ui pages" id="referrals">
                {!loaded && (
                    <div className="ui active inverted dimmer">
                        <div className="ui text loader">Loading</div>
                    </div>
                )}

                <div className="content">
                    <div className="ui vertical caret-push segment padded">
                        <div className="ui text container padded center aligned">
                            <h2 className="ui header">Refer a friend and get rewarded</h2>
                            <div className="ui stackable two column grid aligned padded">
                                <div className="column left aligned">
                                    <h3>Your friends get a 10% discount off any course, you get free courses and bonus points</h3>
                                    <p>Refer <strong>5</strong> friends and get a free course.</p>
                                    <p>Refer <strong>10</strong> friends and get a free All access pass</p>
                                </div>
                                <div className="column left aligned">
                                    <div className="appraisal">
                                        <div className="header">
                                            <h3>Your referrals: {referrals.length}</h3>
                                            <img
                                                src={require("../../../../assets/images/profile/quote.png")}
                                                alt="referrals"
                                            />
                                        </div>
                                        <p>1 x Course purchase = 1 stamp and 200XP</p>
                                        <p>1 x All Access Pass purchase = 2 stamps and 500XP</p>
                                    </div>
                                </div>
                            </div>

                            <div className="badges">
                                { referral_classes.map((c, idx) => (
                                    renderCircle(c, idx)
                                )) }
                            </div>

                            {renderReferrals()}

                            {renderRemainderText()}

                            <div className="ui stackable three column grid aligned padded">
                                <div className="column sixteen wide center aligned">
                                    <h4 className="ui horizontal divider header">SHARE 42COURSES</h4>
                                </div>
                            </div>
                            <h4>Rack up referrals by sharing your personalized referral code with others</h4>
                            <p>Get your friends to enter this voucher code when they purchase any course</p>
                            <div className="field">
                                <div className="ui action input">
                                    <input id="user_referral_code" type="text" value={refCode}/>
                                    <button className="ui icon labeled secondary button copy-btn" data-clipboard-target="#user_referral_code" onClick={() => this.copyCodeToClipboard()}>
                                        <i className="copy icon"/>
                                        <div className="bolder">COPY</div>
                                    </button>
                                </div>
                                <h4 className="ui header">OR</h4>
                                <h4 className="ui header">Share on social media</h4>
                                <div className="social-buttons">
                                    {social_buttons.map((button) => (
                                        ReactHtmlParser(button)
                                    ))}
                                </div>
                                <h4 className="ui header">OR</h4>
                                <h4 className="ui header">Share using email</h4>
                                <form className="simple_form ui form" accept-charset="UTF-8">
                                    <div className="field string required referral_email_emails with_hint">
                                        <input className="string required" value={emails} onChange={this.setAttribute} placeholder="Enter emails (Separate multiple emails by commas)" type="text" name="emails"/>
                                        <div className="hint">You can only send 10 invites each day</div>
                                    </div>
                                    <div className="field text required referral_email_message">
                                        <textarea className="text required" value={message} onChange={this.setAttribute} name="message"/>
                                    </div>
                                    <input type="submit" name="commit" value="Send Invite" onClick={this.sendInvite} className="ui button click-busy" data-disable-with="Sending..."/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default observer(Referral);
