import { Dispatch } from "redux";
import { AxiosError, AxiosResponse } from "axios";
import { AppThunk } from "../../../CommunityStore";
import { getRequest } from "../../../../utils/httpRequest";
import {
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILURE,
  LoadUsersAction,
  LoadUsersSuccessAction,
  UserActionTypes,
  PostUser,
} from "../types";

export const loadUsers = (): LoadUsersAction => ({
  type: LOAD_USERS,
});

export const loadUsersSuccess = (
  users: PostUser[],
): LoadUsersSuccessAction => ({
  type: LOAD_USERS_SUCCESS,
  payload: users,
});

export const fetchUsers =
  ({ postIds }): AppThunk =>
  async (dispatch: Dispatch<UserActionTypes>) => {
    console.log("postIds", postIds);
    dispatch({ type: LOAD_USERS });

    getRequest(`/api/community/users.json?post_ids=${postIds}`)
      .then((response: AxiosResponse) => {
        dispatch(loadUsersSuccess(response.data));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        dispatch({ type: LOAD_USERS_FAILURE, payload: error.message });
      });
  };
