import {
  LOAD_USERS,
  LOAD_USERS_FAILURE,
  LOAD_USERS_SUCCESS,
  UserActionTypes,
  UserState,
} from "./types";

const initialState: UserState = {
  users: [],
  loading: false,
  error: "",
};

export const userReducer = (
  state = initialState,
  action: UserActionTypes,
): UserState => {
  switch (action.type) {
    case LOAD_USERS:
      return { ...state, loading: true };

    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, ...action.payload], // Append new posts
      };

    case LOAD_USERS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
