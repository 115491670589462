import React from "react";
import { Link } from "react-router-dom";
import Option from "../../common/Option";
import { Quotation } from "../../../../../stores/CoursePreviewStore/Types";
import { Priceable } from "../../../Types";
import Pass from "./Pass";
import Renew from "./Renew";
import MonthlyFlexEd from "./MonthlyFlexEd";
import Lifetime from "./Lifetime";
import Upgrade from "./Upgrade";

interface Props {
  title: string;
  meta?: string;
  slug: string;
  path: string;
  couponCode: string;
  refCode: string;
  activeQuote: Quotation;
  priceable: Priceable;
  isActive?: boolean;
  showLinks?: boolean;
}

const Subscriptions: React.FC<Props> = ({
  slug,
  path,
  activeQuote,
  priceable,
  couponCode,
  refCode,
  isActive = false,
  showLinks,
}) => {
  if (activeQuote?.is_restricted) {
    return (
      <MonthlyFlexEd
        activeQuote={activeQuote}
        priceable={priceable}
        couponCode={couponCode}
        refCode={refCode}
        path={path}
        slug={slug}
      />
    );
  }

  if (slug === "lifetime") {
    return (
      <Lifetime
        activeQuote={activeQuote}
        priceable={priceable}
        couponCode={couponCode}
        refCode={refCode}
        path={path}
        slug={slug}
      />
    );
  }

  if (path === "renew") {
    return (
      <Renew
        activeQuote={activeQuote}
        priceable={priceable}
        couponCode={couponCode}
        refCode={refCode}
        path={path}
        slug={slug}
      />
    );
  }

  if (path === "upgrade") {
    return (
      <Upgrade
        activeQuote={activeQuote}
        priceable={priceable}
        couponCode={couponCode}
        refCode={refCode}
        path={path}
        slug={slug}
      />
    );
  }

  return (
    <Pass
      activeQuote={activeQuote}
      priceable={priceable}
      couponCode={couponCode}
      refCode={refCode}
      slug={slug}
      showLinks={showLinks}
      isActive={isActive}
    />
  );
};

export default Subscriptions;
