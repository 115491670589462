import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { AxiosResponse } from "axios";
import { CommentResource } from "../../../../stores/CommentStore/Types";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";
import {
  getRequest,
  postRequest,
  deleteRequest
} from "../../../../utils/httpRequest";
import { errorNotification } from "../../../../utils/helpers";
import CommentReplies from "./CommentReplies";

interface Props {
  currentUser: CurrentUser;
  comment: CommentResource;
  commentableId: number;
  commentType: string;
  enrolmentId: number;
  questionId: number;
  canReply: boolean;
  updateComments: () => void;
}

interface State {
  liking: boolean;
  liked: boolean;
  likesCount: number;
  deleting: boolean;
  showCommentForm: boolean;
  viewReplies: boolean;
  comments: CommentResource[];
  commenting: boolean;
  content: string;
  showReplyCommentForm: boolean;
}

class Comment extends Component<Props, State> {
  static defaultProps = { canReply: false };

  constructor(props) {
    super(props);

    this.state = {
      liking: false,
      liked: false,
      likesCount: 0,
      deleting: false,
      showCommentForm: true,
      content: "",
      viewReplies: false,
      comments: [],
      commenting: false,
      showReplyCommentForm: false
    };
  }

  componentDidMount() {
    const { currentUser, comment } = this.props;

    this.setState({
      liked: comment.liker_ids.includes(currentUser.id),
      likesCount: comment.likes_count,
      comments: comment.comments
    });
  }

  countryFlagImage = countryCode => {
    let img;
    try {
      img = require(`../../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
    } catch {
      img = "";
    }

    return img;
  };

  likeComment = e => {
    e.preventDefault();
    this.setState({ liking: true });

    const { comment } = this.props;

    return postRequest(`/api/likes.json?comment_id=${comment.id}`, {})
      .then((response: AxiosResponse) => {
        this.setState({
          liking: false,
          liked: true,
          likesCount: this.state.likesCount + 1
        });
      })
      .catch(error => {
        this.setState({ liking: false });

        errorNotification(
          "Error liking comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  unlikeComment = e => {
    e.preventDefault();

    const { comment } = this.props;

    return postRequest(`/api/likes/unlike.json?comment_id=${comment.id}`, {})
      .then((response: AxiosResponse) => {
        this.setState({
          liking: false,
          liked: false,
          likesCount: this.state.likesCount - 1
        });
      })
      .catch(error => {
        this.setState({ liking: false });

        errorNotification(
          "Error unliking comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  deleteComment = e => {
    e.preventDefault();
    this.setState({ deleting: true });

    const { comment, updateComments, commentType, commentableId } = this.props;

    const url =
      commentType === "answer"
        ? `/api/comments/${comment.id}.json?answer_id=${commentableId}`
        : `/api/comments/${comment.id}.json?comment_id=${commentableId}`;

    return deleteRequest(url)
      .then((response: AxiosResponse) => {
        this.setState({ deleting: false });
        updateComments();
      })
      .catch(error => {
        this.setState({ deleting: false });

        errorNotification(
          "Error deleting comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  handleContentChange = e => {
    this.setState({ content: e.target.value });
  };

  fetchComments = () => {
    let { comment } = this.props;

    return getRequest(`/api/comments.json?comment_id=${comment.id}`)
      .then((response: AxiosResponse) => {
        this.setState({
          comments: response.data,
          viewReplies: response.data.length > 0
        });
      })
      .catch(error => {
        errorNotification(
          "Error loading comments",
          JSON.stringify(error.response.data)
        );
      });
  };

  submitComment = e => {
    let { comment, enrolmentId, questionId } = this.props;
    let { content, comments } = this.state;

    e.preventDefault();
    this.setState({ commenting: true });

    return postRequest(`/api/comments.json?comment_id=${comment.id}`, {
      content,
      enrolment_id: enrolmentId,
      question_id: questionId
    })
      .then((response: AxiosResponse) => {
        comments.unshift(response.data);
        this.setState({
          comments,
          content: "",
          commenting: false,
          viewReplies: true
        });
      })
      .catch(error => {
        this.setState({ commenting: false });

        errorNotification(
          "Error commenting",
          JSON.stringify(error.response.data)
        );
      });
  };

  handleShowReplyCommentForm = e => {
    e.preventDefault();
    this.setState({ showReplyCommentForm: !this.state.showReplyCommentForm });
  };

  handleViewReplies = e => {
    e.preventDefault();
    this.setState({ viewReplies: !this.state.viewReplies });
  };

  render() {
    const {
      comment: {
        id,
        user,
        created_at,
        formatted_content,
        likes_count,
        liker_ids
      },
      comment,
      currentUser,
      enrolmentId,
      questionId,
      canReply
    } = this.props;

    const {
      liking,
      liked,
      likesCount,
      deleting,
      showCommentForm,
      content,
      comments,
      commenting,
      showReplyCommentForm,
      viewReplies
    } = this.state;

    return (
      <div
        className="ui container conversation"
        id={`comment_${id}`}
        style={{ width: "100%" }}
      >
        {deleting && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="conversation__body">
          <div className="conversation__body--avatar">
            <img src={user?.profile_picture_url} className="avatar" />
            <div className="country">
              <img src={this.countryFlagImage(user?.country)} />
            </div>
          </div>
          <div className="conversation__body--content comment__body--content">
            <div className="name-container">
              <span className="name">{user?.protected_name}</span>
              <span className="time">{created_at}</span>
            </div>
            {/* @ts-ignore */}
            <div className="content">{ReactHtmlParser(formatted_content)}</div>
            <div className="reaction">
              {canReply && (
                <a
                  className="reply space"
                  href="#"
                  onClick={this.handleShowReplyCommentForm}
                >
                  Reply
                </a>
              )}
              {user?.id === currentUser?.id && (
                <a
                  className="reply space"
                  href="#"
                  onClick={this.deleteComment}
                >
                  Delete
                </a>
              )}
              {liked ? (
                <span className="like repliable liked space">
                  <a href="#" onClick={this.unlikeComment}>
                    <i className="thumbs up icon"></i>
                  </a>
                  <span>{likesCount}</span>
                </span>
              ) : (
                <span className="like repliable space">
                  <a href="#" onClick={this.likeComment} className="reply">
                    <i className="thumbs up icon"></i>
                  </a>
                  <span>{likesCount}</span>
                </span>
              )}
              {canReply && comments?.length > 0 && (
                <a
                  className="reply view_replies"
                  href="#"
                  onClick={this.handleViewReplies}
                >
                  View replies
                </a>
              )}
            </div>
            {canReply && viewReplies && (
              <>
                {comments.map(comment => (
                  <CommentReplies
                    key={comment.id}
                    comment={comment}
                    currentUser={currentUser}
                    updateComments={this.fetchComments}
                  />
                ))}
              </>
            )}
            {canReply && showReplyCommentForm && (
              <div className="reply__body">
                <div className="reply__body--avatar">
                  <img src={currentUser?.profile_picture} className="avatar" />
                </div>
                <form className="ui form">
                  <div className="form-header">
                    <span className="name">{currentUser?.short_name} (me)</span>
                  </div>
                  <div className="field">
                    <textarea
                      value={content}
                      rows={3}
                      placeholder="Write your comment"
                      onChange={this.handleContentChange}
                      disabled={commenting}
                    />
                  </div>
                  <div className="submit">
                    <button
                      className={`ui button ${commenting ? "loading" : ""}`}
                      type="submit"
                      onClick={this.submitComment}
                    >
                      Post
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default Comment;
