import { AxiosResponse, AxiosError } from "axios";
import { Api } from "../../utils/Api";
import { CurrencyPayload } from "./Types";
import CurrencyStore from "../CurrencyStore";
import ModalStore from "../ModalStore";
import PricingStore from "../PricingStore";
import { Errors } from "../../utils/helpers";

interface Data {
  currency: string;
}

export const updateCurrency = (
  isoCode: string,
  currencyPayload: CurrencyPayload,
  modalStore: ModalStore,
  pricingStore: PricingStore,
  updateSelectedCurrency: (isoCode: string) => void,
  store: typeof CurrencyStore
) => {
  const token = $('meta[name="csrf-token"]').attr("content");

  const data = {
    currency: isoCode
  };
  const client = new Api({});
  client
    .put<CurrencyPayload, Data>(
      `/api/merchant/currency_preferences/${isoCode.toUpperCase()}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      }
    )
    .then((response: AxiosResponse<CurrencyPayload>) => {
      const { data } = response;
      modalStore.close();
      store.ready(data);
      pricingStore.loading();
      updateSelectedCurrency(data.selectedCurrency.iso_code);
    })
    .catch((error: AxiosError<{ errors: string }>) => {
      store.readyWithErrors(currencyPayload, error.response.data.errors);
    });
};
