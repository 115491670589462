import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Header from "../common/Header";
import UserForm from "./components/UserForm";
import CompanyForm from "./components/CompanyForm";
import { Props, State } from "./Types";
import { getVatTypes } from "./helpers";
import { scrollIntoView } from "../../../utils/helpers";

class BillingInfo extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      isComplete: false,
      type: "user",
      userDetails: {
        name: "",
        email: "",
        address: "",
        city: "",
        postalCode: "",
        country: ""
      },
      companyDetails: {
        name: "",
        email: "",
        billingName: "",
        address: "",
        city: "",
        postalCode: "",
        vatNumber: "",
        vatType: "",
        country: ""
      },
      vatTypes: []
    };
  }

  componentDidMount() {
    this.setState({
      userDetails: this.props.userDetails,
      companyDetails: this.props.companyDetails
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isExpanded !== prevProps.isExpanded) {
      this.setState({
        isExpanded: this.props.isExpanded
      });
    }

    if (this.props.country !== prevProps.country) {
      this.setState({
        userDetails: this.props.userDetails,
        companyDetails: this.props.companyDetails
      });
    }
  }

  onChangeType = (e, type) => {
    e.preventDefault();

    this.setState({ type });
    this.props.handleChange("billingType", type);
  };

  updateUserDetails = (key, value) => {
    const { userDetails } = this.state;

    const updatedUserDetails = { ...userDetails, [key]: value };

    this.setState({ userDetails: updatedUserDetails });
    this.props.handleChange("userDetails", updatedUserDetails);
  };

  updateCompanyDetails = (key, value) => {
    let { companyDetails } = this.state;

    const updatedCompanyDetails = {
      ...companyDetails,
      [key]: value
    };

    this.setState({ companyDetails: updatedCompanyDetails });

    this.props.handleChange("companyDetails", updatedCompanyDetails);
  };

  updateCompanyCountryDetails = (key, value) => {
    let { companyDetails } = this.state;

    const vatTypes = getVatTypes(value);

    const updatedCompanyDetails = {
      ...companyDetails,
      [key]: value,
      vatType: vatTypes[0]?.value
    };

    this.setState({ companyDetails: updatedCompanyDetails, vatTypes });

    this.props.handleChange("companyDetails", updatedCompanyDetails);
  };

  next = values => {
    this.setState({ isExpanded: false, isComplete: true });

    const element = document.getElementById("card-details");
    scrollIntoView(element);

    (window as any)?.dataLayer.push({
      event: "customClick",
      elementId: "checkout-billing-next"
    });

    this.props.handleChange("step", "payment");
  };

  back = e => {
    e.preventDefault();

    this.setState({ isExpanded: false });

    const element = document.getElementById("select-plan");
    scrollIntoView(element);

    (window as any)?.dataLayer.push({
      event: "customClick",
      elementId: "checkout-billing-back"
    });

    this.props.handleChange("step", "plan");
  };

  toggleView = e => {
    e.preventDefault();

    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { countries, country, isCurrentUserPresent } = this.props;
    const {
      isExpanded,
      type,
      userDetails,
      companyDetails,
      vatTypes,
      isComplete
    } = this.state;

    return (
      <div className={isExpanded ? "expanded" : "closed"}>
        <hr />
        {type === "user" && (
          <Header
            title="Step 2: Cardholder’s billing info"
            toggleView={this.toggleView}
            isExpanded={isExpanded}
            isComplete={isComplete}
          />
        )}
        {type === "company" && (
          <Header
            title="Step 2: Company’s billing info"
            toggleView={this.toggleView}
            isExpanded={isExpanded}
            isComplete={isComplete}
          />
        )}
        {isExpanded && (
          <>
            <div className="paying">
              {type === "company" ? (
                <a href="#" onClick={e => this.onChangeType(e, "user")}>
                  I'm paying!
                </a>
              ) : (
                <a href="#" onClick={e => this.onChangeType(e, "company")}>
                  My company is paying!
                </a>
              )}
            </div>

            <>
              {type === "user" && (
                <UserForm
                  isCurrentUserPresent={isCurrentUserPresent}
                  userDetails={userDetails}
                  countries={countries}
                  nextAction={this.next}
                  previousAction={this.back}
                  updateUserDetails={this.updateUserDetails}
                  updateCompanyCountryDetails={this.updateCompanyCountryDetails}
                />
              )}
              {type === "company" && (
                <CompanyForm
                  isCurrentUserPresent={isCurrentUserPresent}
                  userDetails={userDetails}
                  companyDetails={companyDetails}
                  vatTypes={vatTypes}
                  updateUserDetails={this.updateUserDetails}
                  updateCompanyDetails={this.updateCompanyDetails}
                  updateCompanyCountryDetails={this.updateCompanyCountryDetails}
                  countries={countries}
                  nextAction={this.next}
                  previousAction={this.back}
                />
              )}
            </>
          </>
        )}
      </div>
    );
  }
}

export default BillingInfo;
