import React, { Component } from "react";
import ContentModal from "./ContentModal";

interface State {
  videoLink: string;
  imageLink: string;
  showModal: boolean;
}

class EmbeddedContent extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      videoLink: "",
      imageLink: "",
      showModal: false
    };
  }

  componentDidMount() {
    const that = this;

    $(document).on("click", "[data-video-source]", function(e) {
      const videoLink = this.getAttribute("data-video-source");

      if (videoLink) {
        that.setState({ videoLink, imageLink: "" }, () =>
          that.handleShowModal(e)
        );
      }
    });

    $(document).on("click", "[data-image-source]", function(e) {
      const imageLink = this.getAttribute("data-image-source");

      if (imageLink) {
        that.setState({ imageLink, videoLink: "" }, () =>
          that.handleShowModal(e)
        );
      }
    });
  }

  handleShowModal = e => {
    e.preventDefault;

    this.setState({ showModal: true });
  };

  handleHideModal = e => {
    e.preventDefault;

    this.setState({ showModal: false });
  };

  render() {
    const { videoLink, imageLink, showModal } = this.state;

    return (
      <div>
        <ContentModal
          videoLink={videoLink}
          imageLink={imageLink}
          showModal={showModal}
          handleHideModal={this.handleHideModal}
        />
      </div>
    );
  }
}

export default EmbeddedContent;
