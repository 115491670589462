import React from "react";
import { Confirm } from "semantic-ui-react";

const RestrictedEnrolmentConfirmation = ({
  isOpen,
  onClose,
  onConfirm,
  availableCredits,
  enrolingUser
}) => (
  <Confirm
    className="enrolment-confirmation"
    size="tiny"
    open={isOpen}
    onCancel={onClose}
    onConfirm={onConfirm}
    confirmButton={enrolingUser ? "Enroling..." : "Start now"}
    content={
      <div className="ui content">
        <p>Hello you delightful fellow,</p>
        <p>
          When you enrol in this course, you’ll use 1 course credit and then
          have {availableCredits - 1} remaining till your next renewal
        </p>
        <p>Are you sure you want to proceed?</p>
      </div>
    }
  />
);

export default RestrictedEnrolmentConfirmation;
