import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

interface Props {
  isButton?: boolean;
  hasLegacyJsTrigger?: boolean;
  handleShowSignInSideBar?: (redirectUrl?: string) => void;
  checkoutUrl: string;
  label?: string;
}

class GoToCheckoutButton extends Component<Props, {}> {
  render() {
    // add-to-cart OR add-to-cart-button
    const addToCartCssClass = `add-to-cart${
      this.props.isButton ? "-button" : ""
    }`;

    const legacyJsTriggerClass = this.props.hasLegacyJsTrigger
      ? "sign_in_sidebar_trigger"
      : "";

    const css = `ui traditional button fluid ${addToCartCssClass} ${legacyJsTriggerClass}`;
    const label = this.props.label || "Add to cart";

    if (false && this.props.handleShowSignInSideBar) {
      //this is hack never require signing on checkout button
      //TODO: remove handleShowSignInSideBar prop from this component and app
      return (
        <Link
          className={css}
          onClick={e =>
            this.props.handleShowSignInSideBar(this.props.checkoutUrl)
          }
        >
          {label}
        </Link>
      );
    } else {
      return (
        <Link className={css} to={this.props.checkoutUrl}>
          {label}
        </Link>
      );
    }
  }
}

export default observer(GoToCheckoutButton);
