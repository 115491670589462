import React, { Component } from "react";
import { observer } from "mobx-react";
import { Field } from "formik";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import { notification, randomNumber } from "../../../utils/helpers";
import GiftStore from "../../../stores/GiftStore";

interface Props {
  store: GiftStore;
  values: any;
  setFieldValue: any;
  handleChange: (e: any, key: string) => void;
}

interface State {
  uploadingImage: boolean;
  uploadProgress: number;
}

class CreateTeam extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { uploadingImage: false, uploadProgress: 0 };

    (this as any).uppy = Uppy({
      autoProceed: true
    });

    (this as any).uppy
      .use(Transloadit, {
        params: {
          auth: {
            key: process.env.TRANSLOADIT_AUTH_KEY,
            secret: process.env.TRANSLOADIT_AUTH_SECRET,
            duration: 1800
          },
          template_id: process.env.TRANSLOADIT_PROFILE_PIC_TEMPLATE_ID
        },
        waitForEncoding: true
      })
      .on("error", error => {
        if (error.assembly) {
          console.log(`Assembly ID ${error.assembly.assembly_id} failed!`);
          console.log(error.assembly);
          notification("Error uploading image. Please try again", "error");
        }
      })
      .on("transloadit:result", (stepName, result) => {
        this.uploadFinished(result.url);
      });
  }

  addFile(file) {
    const descriptor = {
      id: "FileInput",
      name: randomNumber() + file.name,
      type: file.type,
      data: file
    };

    try {
      (this as any).uppy.addFile(descriptor);
    } catch (err) {
      this.setState({ uploadingImage: false });
      console.log(err);

      (this as any).uppy.log(err);

      notification("Error uploading image. Please try again", "error");
      return;
    }
  }

  uploadFile = e => {
    (this as any).uppy.log("[FileInput] Something selected through input...");
    const file = e.target.files[0];
    this.setState({ uploadingImage: true, uploadProgress: 50 });
    this.addFile(file);

    e.target.value = null;
  };

  uploadFinished = url => {
    this.props.store.setDirectAttribute("team_logo_url", url);
    this.setState({ uploadProgress: 100 });
    setTimeout(() => {
      this.setState({ uploadingImage: false });
    }, 500);
  };

  handleCheckboxChange = e => {
    e.persist();

    this.props.setFieldValue("create_team", e.target.checked);

    if (!e.target.checked) {
      this.props.store.resetAttribute("team_logo_url");
      this.props.store.resetAttribute("team_name");
      this.props.setFieldValue("team_name", "");
    }
  };

  render() {
    const { store, values } = this.props;
    const { uploadingImage, uploadProgress } = this.state;

    return (
      <div className="create-team">
        <h4>Create a team?</h4>
        <p>
          The feature allows you to learn as a team and gives you a branded
          leaderboard so you can see who is the smartest.
        </p>

        <div className="ui toggle checkbox">
          <Field
            type="checkbox"
            name="create_team"
            onChange={e => this.handleCheckboxChange(e)}
          />
          <label></label>
        </div>

        {values.create_team && (
          <div className="ui image-container">
            <div className="image-holder">
              <input
                id="file-upload"
                type="file"
                onChange={this.uploadFile}
                accept="image/*"
              />
              <label htmlFor="file-upload">
                <div className="ui image circular avatar dimmable dimmed">
                  <img
                    className="ui preview"
                    src={
                      store.teamLogoUrl
                        ? store.teamLogoUrl
                        : process.env.TRANSLOADIT_IMAGE_PLACEHOLDER
                    }
                  />
                  <div
                    className="ui dimmer visible active"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="image-content">
                      <div className="center">
                        <div
                          className="ui inverted icon header"
                          id="upload-image-icon"
                        >
                          <div className="upload-file" id="upload-file">
                            <i className="camera icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
              {uploadingImage && (
                <div className="ui progress image-upload-progress">
                  <div className="bar" style={{ width: `${uploadProgress}%` }}>
                    <div className="progress">{uploadProgress}%</div>
                  </div>
                </div>
              )}
            </div>
            <Field
              className="team-name"
              placeholder="Team's name"
              value={store.teamName}
              name="team_name"
              onChange={e => this.props.handleChange(e, "team_name")}
            />
          </div>
        )}
      </div>
    );
  }
}

export default observer(CreateTeam);
