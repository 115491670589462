import React, { Component } from "react";
import { observer } from "mobx-react";
import ContactImage from "../../../../assets/images/mastheads/responsive/contact.jpg";
import ContactImageMobile from "../../../../assets/images/mastheads/responsive/contact-mobile.jpg";


class Contact extends Component {

    render() {
        const contactItems = [
            { title: "Partner", icon_name: "contact-partner",  email_subject: "Becoming a partner" },
            { title: "Join", icon_name: "contact-join",  email_subject: "Joining the team" },
            { title: "Suggestion", icon_name: "contact-suggestion",  email_subject: "I have a suggestion" },
            { title: "Query", icon_name: "contact-query",  email_subject: "I have a query" }
        ]

        return (
            <div className="ui pages" id="contact">
                <div className="responsive-masthead contact">
                    <div
                        className="backdrop desktop"
                        style={{
                            backgroundImage: `url(
                ${ContactImage}
                )`
                        }}
                    ></div>
                    <div
                        className="backdrop mobile"
                        style={{
                            backgroundImage: `url(
                ${ContactImageMobile}
                )`
                        }}
                    ></div>
                </div>

                <div className="ui aligned padded" id="contact_text">
                    <div className="ui container">
                        <h2>Have a question, Suggestion, Or Just want to know the answer to life, the universe and everything?</h2>
                    </div>
                </div>

                <div className="ui aligned padded" id="contact_options">
                    <div className="ui container">
                        <div className="ui grid">
                            {contactItems.map((item, i) => (
                                <div className="four wide computer four wide tablet eight wide mobile column center aligned" key={i}>
                                    <a href={`mailto:chris@42courses.com?subject=${item.email_subject}`}>
                                        <img src={require(`../../../../assets/images/icons/new/${item.icon_name}.png`)}/>
                                        <h3>{item.title}</h3>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="ui vertical segment" id="contact_flags">
                    <div className="ui container center aligned">
                        <img src={require("../../../../assets/images/icons/new/contact-flags.png")} className="ui spaced image small"/>
                        <h3>Made with love</h3>
                    </div>
                </div>
            </div>
        );
    }
}
export default observer(Contact);
