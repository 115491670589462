export const formatCourses = courses =>
  courses.map(course => ({
    text: course.name,
    value: course.slug,
    key: course.slug
  }));

export const formatCountries = countries =>
  countries.map(country => ({
    text: country.country,
    value: country.code,
    key: country.code
  }));
