import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { Carousel } from "../../common";
import { getRequest } from "../../../utils/httpRequest";
import { ThoughtLeader } from "../../../stores/CoursePreviewStore/Types";
import Leader from "./Leader";

interface Props {
  courseSlug: string;
}

interface State {
  loading: boolean;
  thoughtLeaders: ThoughtLeader[];
}

class ThoughtLeaders extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      thoughtLeaders: [],
      loading: true
    };
  }

  componentDidMount() {
    const { courseSlug } = this.props;
    return getRequest(`/api/courses/${courseSlug}/collaborators.json`).then(
      (response: AxiosResponse) => {
        this.setState({ thoughtLeaders: response.data, loading: false });
      }
    );
  }

  render() {
    const { loading, thoughtLeaders } = this.state;
    return (
      <div className="ui segment thought-leaders" id="course_collaborators">
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        {thoughtLeaders.length > 0 && (
          <>
            {thoughtLeaders.length > 2 ? (
              <div className="ui container">
                <div className="header">
                  <h2>Meet your</h2>
                  <h1>Thought leaders</h1>
                </div>
                <div className="ui three column">
                  <Carousel
                    arrows={true}
                    numberOfSlides={
                      thoughtLeaders.length < 3 ? thoughtLeaders.length : 3
                    }
                  >
                    {thoughtLeaders.map(leader => (
                      <Leader leader={leader} key={leader.id} />
                    ))}
                  </Carousel>
                </div>
              </div>
            ) : (
              <div className="ui container">
                <div className="header">
                  <h2>Meet your</h2>
                  <h1>Thought leaders</h1>
                </div>
                <div className="ui grid doubling stackable three column centered">
                  {thoughtLeaders.map(leader => (
                    <Leader leader={leader} key={leader.id} />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default ThoughtLeaders;
