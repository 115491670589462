import React, { Component } from "react";
import { observer } from "mobx-react";
import { Carousel } from "../../../common";
import Review from "./Review";
import { ReviewsState } from "../../../../stores/ReviewsStore/Types";

interface Props {
  reviewsState: ReviewsState;
}

class ReviewsWrapper extends Component<Props, {}> {
  renderReviews = () => {
    const { reviewsState } = this.props;

    switch (reviewsState.kind) {
      case "loading":
        return (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        );
      case "ready":
        return (
          <>
            <h2 className="header">What people are saying</h2>
            <div className="ui stackable three column">
              <Carousel className="reviews-carousel" arrows={true}>
                {reviewsState.reviewsResource.reviews.map(review => (
                  <Review key={review.id} review={review} />
                ))}
              </Carousel>
            </div>
          </>
        );
        break;

      default:
        return <div />;
        break;
    }
  };

  render() {
    return <div>{this.renderReviews()}</div>;
  }
}

export default observer(ReviewsWrapper);
