import React from "react";
import ReactHtmlParser from "react-html-parser";
import { AnswerIcon, QuestionIcon } from "../../Learning/Icons";
import { Link } from "react-router-dom";
import FileAction from "./FileAction";
import Comments from "./Comments";
import { CommentResource } from "../../../../stores/CommentStore/Types";

interface Props {
  selectedAnswer: any;
  question: any;
  answer: any;
  updateSelectedAnswer: (val: any) => void;
  submitAnswer: (e) => void;
  submitting: boolean;
  loadingAnswer: boolean;
  comments: CommentResource[];
  currentUser: any;
  mode: string;
  search: string;
  enrolmentId: number;
}

const Opinion: React.FC<Props> = ({
  question,
  answer,
  selectedAnswer,
  updateSelectedAnswer,
  submitAnswer,
  submitting,
  loadingAnswer,
  comments,
  currentUser,
  mode,
  search,
  enrolmentId
}) => {
  const handleChange = e => {
    updateSelectedAnswer(e.target.value);
  };

  return (
    <>
      {question.uploader_type ? (
        <FileAction
          question={question}
          answer={answer}
          submitAnswer={submitAnswer}
          selectedAnswer={selectedAnswer}
          submitting={submitting}
          loadingAnswer={loadingAnswer}
          updateSelectedAnswer={updateSelectedAnswer}
          mode={mode}
          comments={comments}
          search={search}
          currentUser={currentUser}
          enrolmentId={enrolmentId}
        />
      ) : (
        <form className="ui form assignment-form">
          <div className="form-header">
            <div className="ui image">
              <QuestionIcon />
            </div>
            <div className="ui question">
              {/* @ts-ignore */}
              <p>{ReactHtmlParser(question?.formatted_description)}</p>
            </div>
          </div>
          <div className={`form-body ${answer ? "form-body-center" : ""}`}>
            {(loadingAnswer || submitting) && (
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Loading</div>
              </div>
            )}
            <div className="ui image">
              <AnswerIcon />
            </div>
            {/* @ts-ignore */}
            <div className="content">
              {/* @ts-ignore */}
              {answer && ReactHtmlParser(answer?.formatted_content)}
              {!answer && (
                <div className="field text-action">
                  <textarea
                    rows={2}
                    placeholder="Type your answer"
                    value={selectedAnswer}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
          </div>
          {!answer && (
            <div className="action">
              <button
                className={`ui button ${submitting ? "loading" : ""}`}
                type="submit"
                onClick={e => submitAnswer(e)}
              >
                Submit
              </button>
            </div>
          )}

          {answer && answer.state == "pending" && (
            <div className="action">
              <Link
                to={`/enrolments/${enrolmentId}/questions/${question.id}/answers/${answer.id}/votes`}
                className={`ui button ${submitting ? "loading" : ""}`}
              >
                Continue to votes
              </Link>
            </div>
          )}
          {answer && answer.state === "passed" && (
            <Comments
              comments={comments}
              answer={answer}
              currentUser={currentUser}
              search={search}
              enrolmentId={enrolmentId}
              questionId={question?.id}
            />
          )}
        </form>
      )}
    </>
  );
};

export default Opinion;
