import { observable, decorate, action, computed } from "mobx";
import {
  TeamState,
  waiting,
  loading,
  ready,
  TeamResource,
  Subscription,
  CurrentTeam
} from "./Types";
import { noop } from "../../utils/noop";

class TeamStore {
  state: TeamState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (teamResource: TeamResource) => {
    this.state = ready(teamResource);
  };

  setTeamResource = (teamResource: CurrentTeam) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        noop();
        break;
      case "ready":
        this.state.teamResource = teamResource;
        break;
    }
  };

  get teamResource() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return {};
        break;
      case "ready":
        return this.state.teamResource;
        break;
    }
  }
}

decorate(TeamStore, {
  state: observable,
  loading: action,
  ready: action,
  setTeamResource: action,
  teamResource: computed
});

export default TeamStore;
