import React, { Component } from "react";
import { AxiosResponse } from "axios";
import Menu from "./Menu";
import Chapters from "./Chapters";
import Resources from "./Resources";
import Lives from "./Lives";
import { Answer, Enrolment } from "../../../stores/EnrolmentStore/Types";
import { getRequest } from "../../../utils/httpRequest";
import { errorNotification } from "../../../utils/helpers";
import { TopicPreview } from "../../../stores/CoursePreviewStore/Types";

interface Props {
  enrolment: Enrolment;
  mode: string;
}

interface State {
  activeItem: string;
  chapters: TopicPreview[];
  answers: Answer[];
  loadingChapters: boolean;
}

class Learning extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: props.mode || "chapters",
      chapters: [],
      answers: [],
      loadingChapters: true
    };
  }

  componentDidMount() {
    const { enrolment } = this.props;

    return getRequest(`/api/courses/${enrolment?.course.slug}/topics.json`)
      .then((response: AxiosResponse) => {
        this.setState({ chapters: response.data, loadingChapters: false });

        this.loadAnswers(enrolment);
      })
      .catch(error => {
        this.setState({ loadingChapters: false });

        errorNotification(
          "Error loading chapters",
          JSON.stringify(error.response.data)
        );
      });
  }

  onItemClick = (e, data) => {
    this.setState({ activeItem: data.name });
  };

  loadAnswers = enrolment => {
    return getRequest(`/api/answers.json?enrolment_id=${enrolment?.id}`)
      .then((response: AxiosResponse) => {
        this.setState({ answers: response.data });
      })
      .catch(error => {
        errorNotification(
          "Error loading chapters",
          JSON.stringify(error?.response?.data)
        );
      });
  };

  render() {
    const { activeItem, chapters, answers, loadingChapters } = this.state;
    const { enrolment } = this.props;

    return (
      <div className="ui segment">
        <div className="ui container learning" id="learning">
          {loadingChapters && (
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          )}
          <Menu
            livesCount={enrolment?.lives_count}
            activeItem={activeItem}
            handleItemClick={this.onItemClick}
          />
          {activeItem === "chapters" && (
            <Chapters
              enrolment={enrolment}
              chapters={chapters}
              answers={answers}
            />
          )}
          {activeItem === "resources" && enrolment && (
            <Resources enrolment={enrolment} />
          )}
          {activeItem === "lives" && (
            <Lives livesCount={enrolment?.lives_count} />
          )}
        </div>
      </div>
    );
  }
}

export default Learning;
