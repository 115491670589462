import React, { Component } from "react";
import { observer } from "mobx-react";
import LoginStore from "../../../../stores/LoginStore";
import { buttonCss, buttonText } from "../../../../stores/LoginStore/Types";
import EmailSuggestions from "../../../../components/Checkout/BillingInfo/components/EmailSuggestions";

interface Props {
  store: LoginStore;
  loginUser: (e: React.MouseEvent<HTMLElement>) => void;
}

interface State {
  isDropdownVisible: boolean;
}

class SignupForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownVisible: false,
    };
  }

  handleEmailSelection = (selectedEmail: string, setFieldValue) => {
    let e = { target: { value: selectedEmail } };
    this.props.store.setEmail(e);
    this.toggleDropdownVisibility(false);
  };

  toggleDropdownVisibility = (isVisible: boolean) => {
    this.setState({ isDropdownVisible: isVisible });
  };

  handleEmailInputChange = (e) => {
    const emailInput = e.target.value;
    this.props.store.setEmail(e);

    this.toggleDropdownVisibility(emailInput.includes("@"));
  };

  toggleDropdown = () => {
    this.state.isDropdownVisible
      ? this.toggleDropdownVisibility(false)
      : this.toggleDropdownVisibility(true);
  };

  render() {
    const { store, loginUser } = this.props;
    return (
      <form noValidate={true} className="ui form">
        <div className="field">
          <div className="ui left icon action input">
            <input
              className="string email optional fluid input-fields"
              placeholder="Your email"
              type="email"
              name="session[email]"
              id="session_email"
              autoComplete="off"
              value={store.email}
              onChange={this.handleEmailInputChange}
              onClick={this.toggleDropdown}
            />
          </div>
            <EmailSuggestions
              email={store.email}
              isDropdownVisible={this.state.isDropdownVisible}
              onSelect={this.handleEmailSelection}
              style={{ top: "-1em"}}
            />
          <div className="ui left icon action input spc_username">
            <input
              type="text"
              placeholder="Your username"
              id="session_username"
              className="username optional fluid input-fields"
              name="session[username]"
              autoComplete="off"
              value={store.username}
              onChange={(e) => store.setUsername(e)}
            />
          </div>
          <div className="ui left icon action input">
            <input
              className="password optional fluid input-fields"
              placeholder="Your password"
              type="password"
              name="session[password]"
              id="session_password"
              autoComplete="off"
              value={store.password}
              onChange={(e) => store.setPassword(e)}
            />
          </div>
          <div className="ui left icon action input">
            <input
              className="password optional fluid input-fields"
              placeholder="Confirm your password"
              type="password"
              name="session[password_confirmation]"
              id="session_password_confirmation"
              autoComplete="off"
              value={store.passwordConfirmation}
              onChange={(e) => store.setPasswordConfirmation(e)}
            />
          </div>
          <div className="action">
            <button
              className={buttonCss(store.state.kind)}
              onClick={(e) => loginUser(e)}
            >
              {buttonText(store.state.kind)}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default observer(SignupForm);
