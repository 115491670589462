import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { errorNotification } from "../../../../utils/helpers";
import { getRequest } from "../../../../utils/httpRequest";
import { Carousel } from "../../../common";
import { BlogResource } from "../../../../stores/BlogStore/Types";
import BlogPost from "./BlogPost";

interface State {
  blogPosts: BlogResource;
  loading: boolean;
}

class Blog extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      blogPosts: [],
      loading: true
    };
  }

  componentDidMount() {
    return getRequest("/api/blogs.json")
      .then((response: AxiosResponse) => {
        this.setState({
          blogPosts: response.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });

        errorNotification(
          "Error loading data",
          JSON.stringify(error?.response?.data)
        );
      });
  }

  render() {
    const { blogPosts } = this.state;

    return (
      <div className="ui segment blog">
        <div className="ui container">
          <h2 className="ui header center aligned blog-header">Our Blog</h2>
          <h3 className="ui header center aligned blog-header-sub">
            Weird and wonderful things we've written about
          </h3>
          <Carousel noOfMobileSlides={2} numberOfSlides={4}>
            {(blogPosts || []).map((post, i) => (
              <BlogPost post={post} idx={i} key={post.guid} />
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Blog;
