import React from "react";

const MoreInfo = () => (
  <div className="ui segment more-info">
    <div className="ui container">
      <div className="wrapper">
        <div className="images mobile">
          <img
            className="ui image small chat"
            src={require("../../../../../assets/images/teams/more_info/chat.png")}
          />
        </div>
        <div className="text">
          <h2>Need more convincing?</h2>
          <div className="list">
            <img
              src={require("../../../../../assets/images/teams/more_info/face.svg")}
            />
            <span>
              <strong>
                Flexible free seat swapping for new joins & leavers
              </strong>
            </span>
          </div>
          <div className="list">
            <img
              src={require("../../../../../assets/images/teams/more_info/ai.svg")}
            />
            <span>
              <strong>AI-powered mentors to compliment training</strong>
            </span>
          </div>
          <div className="list">
            <img
              src={require("../../../../../assets/images/teams/more_info/game.svg")}
            />
            <span>
              <strong>Compatible with all modern LMS platforms</strong>
            </span>
          </div>
          <div className="list">
            <img
              src={require("../../../../../assets/images/teams/more_info/chart.svg")}
            />
            <span>
              <strong>
                Monthly creativity and innovation trend reports for all learners
              </strong>
            </span>
          </div>
          <div className="list">
            <img
              src={require("../../../../../assets/images/teams/more_info/star.svg")}
            />
            <span>
              <strong>
                Access to quarterly live thought leader Q&A sessions
              </strong>
            </span>
          </div>
        </div>
        <div className="images desktop">
          <img
            className="ui image medium chat"
            src={require("../../../../../assets/images/teams/more_info/chat.png")}
          />
          <img
            className="ui image medium cert"
            src={require("../../../../../assets/images/teams/more_info/cert.png")}
          />
        </div>
      </div>
    </div>
  </div>
);

export default MoreInfo;
