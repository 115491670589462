import { observer } from "mobx-react";
import LoginStore from ".";
import { AxiosResponse, AxiosError } from "axios";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { LoginState, Saving } from "./Types";
import { Api } from "../../utils/Api";

interface State {
  state: LoginState;
}

interface LoginData {
  email: string;
  password: string;
  login: boolean;
  react_session: boolean;
}

interface SignUpData {
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
  registration: boolean;
  react_session: boolean;
}

interface DataBody {
  session: LoginData | SignUpData;
}

interface Response {
  url: string;
  signup_datalayer: any;
}

export interface Props extends BaseReactionProps<LoginStore> {
  store: LoginStore;
}

class LoginReactions extends BaseReaction<LoginStore, State, Props> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    switch (state.kind) {
      case "waiting":
      case "ready":
        break;
      case "readyWithErrors":
        {
          $("#new-login-errors span").html(state.errors);

          $("#new-login-errors").removeClass("forceHide");
          const hideAlert = () => {
            $("#new-login-errors span" as any).html("");
            $("#new-login-errors" as any).addClass("forceHide");
          };

          (window as any).setTimeout(() => {
            hideAlert();
          }, 6000);
        }

        break;
      case "loading":
        {
          const mode = "login";
          const username = "";
          const email = "";
          const password = "";
          const passwordConfirmation = "";
          this.props.store.ready(
            mode,
            username,
            email,
            password,
            passwordConfirmation
          );
        }
        break;
      case "saving":
        {
          switch (state.mode) {
            case "login":
              {
                const data = {
                  session: {
                    email: state.email,
                    password: state.password,
                    login: true,
                    react_session: true
                  }
                };

                this.post(data, state);
              }

              break;

            case "signUp":
              {
                const data = {
                  session: {
                    username: state.username,
                    email: state.email,
                    password: state.password,
                    password_confirmation: state.passwordConfirmation,
                    registration: true,
                    react_session: true
                  }
                };
                this.post(data, state);
              }
              break;
          }
        }
        break;
    }
  };

  private post(data: DataBody, state: Saving) {
    const token = $('meta[name="csrf-token"]').attr("content");
    const sessionUrl =
      data.session["username"]?.length > 0
        ? "/api/spam.json"
        : "/session/email.json";
    const client = new Api({});
    client
      .post<Response, DataBody>(sessionUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      })
      .then((response: AxiosResponse<Response>) => {
        const { data } = response;

        if (data?.signup_datalayer?.new_user_tag) {
          (window as any)?.dataLayer.push(data.signup_datalayer.new_user_tag);
        }
        if (state.redirectUrl) {
          (window as any).location = state.redirectUrl;
        } else {
          (window as any).location = data.url || "/";
        }
      })
      .catch((error: AxiosError<{ errors: string }>) => {
        this.props.store.readyWithErrors(
          state.mode,
          state.username,
          state.email,
          state.password,
          state.passwordConfirmation,
          error.response.data.errors
        );
      });
  }
}

export default observer(LoginReactions);
