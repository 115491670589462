import React, { Component } from "react";
import { observer } from "mobx-react";
import urlHelpers from "../../utils/urlHelpers";
import { putRequest } from "../../utils/httpRequest";
import { AxiosError, AxiosResponse } from "axios";
import { Errors } from "../../utils/helpers";

interface Props {
  activationToken: string;
  email: string;
  userActivatedCallback: () => void;
}

interface State {
  email: string;
  password: string;
  passwordConfirmation: string;
  isLoading: boolean;
  errors: Array<string>;
}

class UserActivationForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: props.email,
      password: "",
      passwordConfirmation: "",
      isLoading: false,
      errors: []
    };
  }

  attemptActivation(e: React.MouseEvent<HTMLElement>): void {
    e.preventDefault();
    const url = urlHelpers.api.userActivations.updateUserActivationsPath(
      this.props.activationToken
    );

    this.setState({
      errors: [],
      isLoading: true
    });

    const { password, passwordConfirmation, email } = this.state;

    const requestData = {
      user: {
        password,
        password_confirmation: passwordConfirmation,
        email
      }
    };

    putRequest(url, requestData)
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          //lets let our parent know all is good
          this.props.userActivatedCallback();
        }
      })
      .catch((error: AxiosError<{ errors: string[] }>) => {
        this.setState({
          isLoading: false,
          errors: error.response?.data?.errors
        });
      });
  }

  renderFormErrors() {
    if (this.state.errors && this.state.errors.length > 0) {
      return (
        <div className="login-error">
          <i className="warning icon util-v-top"></i>
          <div className="util-inline-block">
            {this.state.errors.map((msg, index) => {
              if (
                msg.toLocaleLowerCase() === "your activation token has expired"
              ) {
                return (
                  <div key={index}>
                    {msg}. Please contact{" "}
                    <a href="mailto:groundcontrol@42courses.com?subject=Can't activate my account, help!">
                      groundcontrol@42courses.com
                    </a>{" "}
                    for help.
                  </div>
                );
              } else {
                return <div key={index}>{msg}</div>;
              }
            })}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="sign_in_sidebar">
        <div className="sign_in">
          <div className="login-container">
            <div className="forms">
              {this.renderFormErrors()}
              <form noValidate={true} className="ui form">
                <div className="field">
                  <div className="ui left icon action input">
                    <input
                      className="string email optional fluid input-fields"
                      placeholder="Your email"
                      type="email"
                      name="user_activation[email]"
                      id="user_activation_email"
                      autoComplete="off"
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </div>
                  <div className="ui left icon action input">
                    <input
                      className="password optional fluid input-fields"
                      placeholder="Your password"
                      type="password"
                      name="user_activation[password]"
                      id="user_activation_password"
                      autoComplete="off"
                      value={this.state.password}
                      onChange={e =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </div>
                  <div className="ui left icon action input">
                    <input
                      className="password optional fluid input-fields"
                      placeholder="Confirm your password"
                      type="password"
                      name="user_activation[password_confirmation]"
                      id="user_activation_password_confirmation"
                      autoComplete="off"
                      value={this.state.passwordConfirmation}
                      onChange={e =>
                        this.setState({ passwordConfirmation: e.target.value })
                      }
                    />
                  </div>
                  <div className="action">
                    <button
                      className={`ui session-email-button button login ${
                        this.state.isLoading ? "loading" : ""
                      }`}
                      onClick={e => this.attemptActivation(e)}
                    >
                      {this.state.isLoading ? "" : "Activate your account"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(UserActivationForm);
