import * as React from "react";

const Facebook = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="#504257" />
    <path
      d="M19.305 26.75V18.768H21.8837L22.2706 15.6564H19.305V13.67C19.305 12.7694 19.5448 12.1557 20.7897 12.1557L22.375 12.155V9.37183C22.1009 9.33483 21.1598 9.25 20.0645 9.25C17.7774 9.25 16.2116 10.6998 16.2116 13.3619V15.6564H13.625V18.768H16.2116V26.75H19.305Z"
      fill="white"
    />
  </svg>
);
export default Facebook;
