import { observable, decorate, action, computed } from "mobx";
import {
  ProfileState,
  waiting,
  loading,
  ready,
  ProfileResource,
  Subscription,
  Profile
} from "./Types";
import { noop } from "../../utils/noop";

class ProfileStore {
  state: ProfileState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (profileResource: ProfileResource) => {
    this.state = ready(profileResource);
  };

  setSubscription = (subscription: Subscription) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        noop();
        break;
      case "ready":
        if (this.state.profileResource.kind === "current-user") {
          this.state.profileResource.subscription = subscription;
        } else {
          noop();
        }

        break;
    }
  };

  setProfileResource = (profileResource: Profile) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        noop();
        break;
      case "ready":
        if (this.state.profileResource.kind === "current-user") {
          this.state.profileResource = profileResource;
        } else {
          noop();
        }

        break;
    }
  };

  get profileResource() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return {};
        break;
      case "ready":
        return this.state.profileResource;
        break;
    }
  }
}

decorate(ProfileStore, {
  state: observable,
  loading: action,
  ready: action,
  setSubscription: action,
  setProfileResource: action,
  profileResource: computed
});

export default ProfileStore;
