import React, { Component } from "react";
import { Checkbox, Icon, Progress } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import { postRequest } from "../../../utils/httpRequest";
import { Hero } from "./Types";

interface Props {
  hero: Hero;
  idx: number;
  slug: string;
  page: number;
  count: number;
  handleSelectMember: (
    e: any,
    value: boolean,
    idx: number,
    key: string
  ) => void;
}

interface State {
  visible: boolean;
}

class TeamMember extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  toggleVisible = e => {
    e.preventDefault();

    this.setState({ visible: !this.state.visible });
  };

  setAdmin = (e, data) => {
    const { idx, hero, handleSelectMember, slug } = this.props;
    const previousValue = hero.admin;

    handleSelectMember(e, data.checked, idx, "admin");

    const params = {
      situation: "manage_admin",
      user_ids: [hero.id]
    };

    postRequest(`/api/teams/${slug}/manage_members.json`, params)
      .then(() => {})
      .catch(() => {
        handleSelectMember(e, previousValue, idx, "admin");
      });
  };

  setShowOnBoard = (e, data) => {
    const { idx, hero, handleSelectMember, slug } = this.props;
    const previousValue = hero.show_on_board;

    handleSelectMember(e, data.checked, idx, "show_on_board");

    const params = {
      situation: "manage_leaderboard",
      user_ids: [hero.id]
    };
    postRequest(`/api/teams/${slug}/manage_members.json`, params)
      .then(() => {})
      .catch(() => {
        handleSelectMember(e, previousValue, idx, "show_on_board");
      });
  };

  render() {
    const { hero, handleSelectMember, idx, page, count } = this.props;

    const { visible } = this.state;

    return (
      <>
        <div className="small_column_classes">
          <div className="user">
            <span className="checkBox">
              <Checkbox
                checked={hero.selected}
                onChange={(e, data) =>
                  handleSelectMember(e, data.checked, idx, "selected")
                }
              />
            </span>
            <a className="wrapper" href="#" onClick={this.toggleVisible}>
              <span className="position">{idx + 1 + page * count}</span>
              <span className="heroes">
                <span className="leaderboard-img">
                  <img src={hero.profile_picture_url} alt="profile picture" />
                </span>
                <span className="name">{hero.protected_name}</span>
              </span>
              <span className="courses">{hero?.enrolments?.length}</span>
              <span className="points">{hero.xp} XP</span>
              <span className="last-activity">{hero.last_activity}</span>
              <span className="dropdown-icon">
                {visible ? (
                  <Icon name="caret up" />
                ) : (
                  <Icon name="caret down" />
                )}
              </span>
            </a>
          </div>
        </div>
        {visible && (
          <div className="content active">
            <div className="hero_enrolments transition visible">
              {hero.enrolments.map((enrolment, i) => (
                <div key={i} className="courseInfo">
                  <span className="courseTitle">{enrolment.course_name}</span>
                  <span className="courseProgress">
                    <Progress
                      percent={enrolment.percentage_completed}
                      size="tiny"
                      className={`${
                        enrolment.percentage_completed === 0
                          ? "zero-progress"
                          : ""
                      }`}
                    />
                  </span>
                  <span className="coursePercentage">
                    {enrolment.percentage_completed}%
                  </span>
                </div>
              ))}
            </div>
            <div className="actions">
              <div className="inline field">
                <Checkbox
                  toggle
                  label="Admin?"
                  checked={hero.admin}
                  onChange={this.setAdmin}
                />
              </div>
              <div className="inline field show-on-board">
                <Checkbox
                  toggle
                  label="Show on board?"
                  checked={hero.show_on_board}
                  onChange={this.setShowOnBoard}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default TeamMember;
