import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentUser } from "../../../../../../stores/CurrentUserStore/Types";
import { createComment } from "../../../../../../stores/CommunityStore/comments/actions/createComment";
import { AppState } from "../../../../../../stores/CommunityStore";
import MentionInput from "./MentionInput";

interface Props {
  currentUser: CurrentUser;
  postId: number;
  postUsers: string[];
  scrollToComments: () => void;
}

const AddComment: React.FC<Props> = ({
  currentUser,
  postId,
  postUsers,
  scrollToComments,
}) => {
  const dispatch = useDispatch();
  const created = useSelector((state: AppState) => state.comments.created);

  const [text, setText] = useState("");
  const [formattedText, setFormattedText] = useState(""); // State for formatted text
  const [rows, setRows] = useState(1);

  useEffect(() => {
    if (created) {
      setText("");
      setFormattedText("");
      dispatch({ type: "RESET_COMMENT_CREATED" });
      scrollToComments();
    }
  }, [postId, created, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      createComment({
        postId,
        content: formattedText || text,
      }),
    );
  };

  return (
    <div className="ui community__add-comment">
      <div className="ui form">
        <div className="field">
          <div className="profile">
            <img
              className="ui avatar image"
              src={currentUser.profile_picture}
              alt="profile picture"
            />
          </div>
          <MentionInput
            users={postUsers}
            value={text}
            rows={rows}
            setText={setText}
            setRows={setRows}
            setFormattedText={setFormattedText}
          />
        </div>
        <div className="actions">
          <button className="ui button" onClick={onSubmit}>
            Comment
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddComment;
