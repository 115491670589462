import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Popup } from "semantic-ui-react";
import { MonthlyCoursePricing } from "../../../../stores/PricingStore/Types";
import GoToCheckoutButton from "../../../Payments/GoToCheckoutButton";

interface Props {
  handleShowSignInSideBar: (redirectUrl?: string) => void;
  monthlyPricing: MonthlyCoursePricing;
  showSigninSidebar: boolean;
  courseCount?: number;
  coursesValue?: string;
}

const Price = ({ monthlyPricing }) => {
  return (
    <>
      <span className="currency-symbol">{monthlyPricing.currency_symbol}</span>

      <span className="amount">{monthlyPricing.amount}</span>

      <Popup
        trigger={
          <div className="currency-code">{monthlyPricing.currency_code}</div>
        }
        content={monthlyPricing.currency_name}
      />
    </>
  );
};

class MonthlyPricing extends Component<Props, {}> {
  render() {
    const {
      handleShowSignInSideBar,
      monthlyPricing,
      showSigninSidebar,
      courseCount,
      coursesValue
    } = this.props;

    return (
      <>
        {courseCount && (
          <div className="title yearly">
            <strong>Subscriptions</strong>
            <p className="courses-count">
              (Access to{" "}
              <Link to="/courses" target="_blank">
                <span>{courseCount} courses</span>
              </Link>{" "}
              valued at <strong>{coursesValue}</strong>)
            </p>
          </div>
        )}
        <div className="pricing-card month">
          <div className="banner year-banner" style={{ background: "#F2D192" }}>
            Best Starter!
          </div>
          <div className="ui fluid card yearly-payment">
            <div className="header">
              Pay As You Learn 
              <br /> Monthly
            </div>
            <div className="content">
              <div className="hero-price">
                <Price monthlyPricing={monthlyPricing} />
              </div>
              <div className="description">(Cancel anytime)</div>
              <hr />
            </div>
            <div className="extra content">
              <div className="value">
                <ul className="list">
                  <li>
                    <i className="check icon"></i>
                    <span>
                      <strong>Limited to 1 new course per month</strong>
                    </span>
                  </li>
                  <li>
                    <i className="check icon"></i>
                    <span>Live marking & 24/7 support</span>
                  </li>
                  <li>
                    <i className="check icon"></i>
                    <span>Custom trained GPT-4 mentor</span>
                  </li>
                  <li>
                    <i className="star icon"></i>
                    <span>30 day money back guarantee</span>
                  </li>
                  <li style={{ color: "transparent" }}>
                    <span />
                  </li>
                </ul>
              </div>
              <div className="hero-actions">
                <div className="buttons">
                  <GoToCheckoutButton
                    isButton={true}
                    hasLegacyJsTrigger={true}
                    checkoutUrl={monthlyPricing.checkout_url}
                    handleShowSignInSideBar={
                      showSigninSidebar ? handleShowSignInSideBar : null
                    }
                    label="Get Started"
                  />
                </div>
                <div
                  className="gift-this-course"
                  style={{ height: "20px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default observer(MonthlyPricing);
