import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ModalHeader,
  ModalContent,
  Modal,
  ModalActions,
} from "semantic-ui-react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { createPost } from "../../../../stores/CommunityStore/posts/actions/createPost";
import { AppState } from "../../../../stores/CommunityStore";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";
import { CloseIcon } from "../../Icon";

interface Props {
  currentUser: CurrentUser;
  isModalOpen: boolean;
  onToggleModal: (e: any, value: boolean) => void;
}

const AddPostModal: React.FC<Props> = ({
  currentUser,
  isModalOpen,
  onToggleModal,
}) => {
  const dispatch = useDispatch();
  const created = useSelector((state: AppState) => state.posts.created);
  const selectedTopicUuid = useSelector(
    (state: AppState) => state.topics.selectedTopicUuid,
  );

  const validationSchema = Yup.object({
    content: Yup.string().required("Content is required"),
  });

  useEffect(() => {
    if (created) {
      onToggleModal(null, false);
      dispatch({ type: "RESET_POST_CREATED" });
    }
  }, [created]); // Triggered when 'success' changes

  // Define the initial form values
  const initialValues = {
    content: "",
  };

  const onSubmit = (values) => {
    dispatch(createPost(values.content, selectedTopicUuid));
  };

  return (
    <Modal
      open={isModalOpen}
      className="community__add-post__modal"
      style={{ borderRadius: "20px" }}
    >
      <ModalHeader>
        <div className="profile">
          <img
            className="ui avatar image"
            src={currentUser.profile_picture}
            alt="profile picture"
          />
          <span>{currentUser.name}</span>
          <span className="close">
            <a href="#" onClick={(e) => onToggleModal(e, false)}>
              <CloseIcon />
            </a>
          </span>
        </div>
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ values }) => (
          <>
            <ModalContent>
              <Form className="ui form">
                <div className="form-group">
                  <Field
                    name="content"
                    as="textarea"
                    rows="6"
                    cols="50"
                    placeholder="Write detail about your post here."
                    className="ui form-control body"
                  />
                </div>
              </Form>
            </ModalContent>
            <ModalActions style={{ border: "none", background: "#fff" }}>
              <button
                type="submit"
                className="ui button highlight"
                disabled={values.content.length === 0}
                onClick={(e) => onSubmit(values)}
              >
                Post
              </button>
            </ModalActions>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AddPostModal;
