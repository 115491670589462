import React from "react";
import { Modal } from "semantic-ui-react";

interface Props {
  showMakeAdminsModal: boolean;
  submitting: boolean;
  handleHideMakeAdminsModal: (e: any) => void;
  makeAdmins: (e: any) => void;
}

const MakeAdminsModal: React.FC<Props> = ({
  showMakeAdminsModal,
  submitting,
  handleHideMakeAdminsModal,
  makeAdmins
}) => (
  <Modal
    closeIcon
    open={showMakeAdminsModal}
    onClose={e => handleHideMakeAdminsModal(e)}
    className="actions-modal"
    size="small"
  >
    <div className="ui internally celled stackable grid">
      <div className="column row">
        <div className="column center aligned">
          <div className="ui image">
            <img
              src={require("../../../../../../assets/images/team_admin/makeAdmin.png")}
            />
          </div>
          <h1>Almost There!</h1>
          <p>Are you sure you want to make these team members admins?</p>
          <div className="ui actions">
            <button
              className="ui right large button cancel"
              onClick={e => handleHideMakeAdminsModal(e)}
            >
              Cancel
            </button>
            <button
              className={`ui right large button save ${
                submitting ? "loading" : ""
              }`}
              onClick={e => makeAdmins(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default MakeAdminsModal;
