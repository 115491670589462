import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import { Progress } from "semantic-ui-react";
import { QuestionIcon, AnswerIcon } from "../../Learning/Icons";
import { notification, randomNumber } from "../../../../utils/helpers";
import TextAction from "./TextAction";
import Comments from "./Comments";
import { CommentResource } from "../../../../stores/CommentStore/Types";

interface Props {
  selectedAnswer: any;
  question: any;
  answer: any;
  updateSelectedAnswer: (val: any) => void;
  submitAnswer: (e) => void;
  submitting: boolean;
  loadingAnswer: boolean;
  mode: string;
  comments: CommentResource[];
  currentUser: any;
  search: string;
  enrolmentId: number;
}

interface State {
  uploadNewImage: boolean;
  fileUrl: string;
  progress: number;
  uploadingImage: boolean;
}

class FileAction extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      fileUrl: "",
      uploadNewImage: false,
      progress: 0,
      uploadingImage: false
    };

    (this as any).uppy = Uppy({ debug: true, autoProceed: true });

    const templateId =
      props.question.uploader_type === "image"
        ? process.env.TRANSLOADIT_IMAGE_TEMPLATE_ID
        : process.env.TRANSLOADIT_FILE_TEMPLATE_ID;

    (this as any).uppy
      .use(Transloadit, {
        params: {
          auth: {
            key: process.env.TRANSLOADIT_AUTH_KEY,
            secret: process.env.TRANSLOADIT_AUTH_SECRET,
            duration: 1800
          },
          template_id: templateId
        },
        waitForEncoding: true
      })
      .on("error", error => {
        if (error.assembly) {
          console.log(`Assembly ID ${error.assembly.assembly_id} failed!`);
          console.log(error.assembly);
          notification("Error uploading image. Please try again", "error");
        }
      })
      .on("transloadit:complete", assembly => {
        this.finalizeUpload(assembly);
      })
      .on("progress", progress => {
        this.setState({ progress: progress });
      });
  }

  addFile(file) {
    const descriptor = {
      id: "FileInput",
      name: randomNumber() + file.name,
      type: file.type,
      data: file
    };

    try {
      (this as any).uppy.addFile(descriptor);
    } catch (err) {
      this.setState({ uploadingImage: false });
      console.log(err);

      (this as any).uppy.log(err);

      notification("Error uploading image. Please try again", "error");
      return;
    }
  }

  uploadFile = e => {
    (this as any).uppy.log("[FileInput] Something selected through input...");
    const file = e.target.files[0];
    this.setState({ uploadingImage: true, progress: 50 });
    this.addFile(file);

    e.target.value = null;
  };

  finalizeUpload = assembly => {
    const { question } = this.props;
    let assemblyResult;

    const resultKey =
      question.uploader_type === "image" ? "resize" : ":original";

    const heic = assembly.uploads[0].ext == "heic";

    if (question.uploader_type == "image" && heic) {
      assemblyResult = assembly.results["resize_heic"][0];
    } else {
      assemblyResult = assembly.results[resultKey][0];
    }

    const resultSslUrl = assemblyResult.ssl_url;
    this.setState({ fileUrl: resultSslUrl, uploadingImage: false });
    this.props.updateSelectedAnswer(resultSslUrl);
  };

  displayFile = url => {
    const { question } = this.props;

    if (question.uploader_type == "image") {
      return (
        <a href={url} target="_blank">
          <img src={url} className="ui small image" />
        </a>
      );
    } else {
      return (
        <p>
          <a href={url} target="_blank">
            <i className="icon attach" />

            {url && <>{url.substring(url.lastIndexOf("/") + 1)}</>}
          </a>
        </p>
      );
    }
  };

  undoFileUpload = () => {
    this.setState({ fileUrl: "", progress: 0 });
  };

  render() {
    const {
      submitAnswer,
      question,
      answer,
      submitting,
      loadingAnswer,
      selectedAnswer,
      updateSelectedAnswer,
      comments,
      currentUser,
      mode,
      search,
      enrolmentId
    } = this.props;

    const { fileUrl, uploadingImage, progress } = this.state;

    return (
      <>
        {question.uploader_type ? (
          <div className="ui form assignment-form">
            <div className="form-header">
              <div className="ui image">
                <QuestionIcon />
              </div>
              <div className="ui question">
                {/* @ts-ignore */}
                <p>{ReactHtmlParser(question?.formatted_description)}</p>
                {question.uploader_type === "image" && (
                  <span>
                    <i className="info circle icon"></i>
                    <i>Only upload images (.jpeg, .jpg, .png, .gif)</i>
                  </span>
                )}
              </div>
            </div>
            <div className={`form-body ${answer ? "form-body-center" : ""}`}>
              {(loadingAnswer || submitting) && (
                <div className="ui active inverted dimmer">
                  <div className="ui text loader">Loading</div>
                </div>
              )}
              <div className="ui image">
                <AnswerIcon />
              </div>
              <div className="content">
                {answer && (
                  <div className="file-container">
                    {this.displayFile(answer.content_url)}
                  </div>
                )}

                {answer || fileUrl ? (
                  <></>
                ) : (
                  <div className="upload-file">
                    {!uploadingImage && (
                      <div className="field file optional uploader_transloadit">
                        <form id="upload-form">
                          <label
                            className="file optional"
                            htmlFor="uploader_transloadit"
                          >
                            <a className="ui button large">
                              <i className="upload icon"></i> Upload{" "}
                              {question.uploader_type}
                            </a>
                          </label>
                          {question.uploader_type === "image" ? (
                            <input
                              className="file optional"
                              type="file"
                              name="uploader[transloadit]"
                              id="uploader_transloadit"
                              onChange={this.uploadFile}
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                          ) : (
                            <input
                              className="file optional"
                              type="file"
                              name="uploader[transloadit]"
                              id="uploader_transloadit"
                              onChange={this.uploadFile}
                              style={{ display: "none" }}
                            />
                          )}
                        </form>
                      </div>
                    )}
                    {uploadingImage && (
                      <Progress
                        percent={progress}
                        progress="percent"
                        size="small"
                        indicating
                        active
                      >
                        Uploading...
                      </Progress>
                    )}
                  </div>
                )}
                {fileUrl && !answer && (
                  <div className="file-container">
                    {this.displayFile(fileUrl)}
                  </div>
                )}
              </div>
            </div>
            {fileUrl && !answer && (
              <div className="action">
                <button
                  className="ui button undo"
                  type="submit"
                  onClick={this.undoFileUpload}
                >
                  Undo
                </button>
                <button
                  className={`ui button ${submitting ? "loading" : ""}`}
                  type="submit"
                  onClick={submitAnswer}
                >
                  Submit
                </button>
              </div>
            )}

            {mode === "Opinion" && answer && answer.state === "passed" && (
              <Comments
                comments={comments}
                answer={answer}
                currentUser={currentUser}
                search={search}
                enrolmentId={enrolmentId}
                questionId={question?.id}
              />
            )}
          </div>
        ) : (
          <TextAction
            question={question}
            answer={answer}
            submitAnswer={submitAnswer}
            selectedAnswer={selectedAnswer}
            submitting={submitting}
            loadingAnswer={loadingAnswer}
            updateSelectedAnswer={updateSelectedAnswer}
          />
        )}
      </>
    );
  }
}

export default FileAction;
