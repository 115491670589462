import React, { Component } from "react";
import { Question, Topic } from "../../../stores/CoursePreviewStore/Types";
import { getRequest } from "../../../utils/httpRequest";
import { AxiosResponse } from "axios";

interface Props {
  topic: Topic;
  courseSlug: string;
}

interface State {
  expandTile: boolean;
  questions: Question[];
}

class Chapter extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      expandTile: false,
      questions: []
    };
  }

  toggleExpandTile = e => {
    e.preventDefault();
    this.setState({ expandTile: !this.state.expandTile });
  };

  componentDidMount() {
    const { courseSlug, topic } = this.props;
    return getRequest(
      `/api/courses/${courseSlug}/topics/${topic.id}/questions.json`
    ).then((response: AxiosResponse) => {
      this.setState({ questions: response.data });
    });
  }

  render() {
    const { topic } = this.props;
    const { expandTile, questions } = this.state;

    const renderQuestions = () => {
      return questions.map((question, idx) => (
        <p className="question" key={idx}>
          <span className="ui white circular label">{idx + 1}</span>
          {question.name}
        </p>
      ));
    };

    return (
      <>
        <li>
          <h4>
            {topic.name}
            <a href="#" onClick={this.toggleExpandTile}>
              {expandTile ? (
                <i className="angle up icon" />
              ) : (
                <i className="angle down icon" />
              )}
            </a>
          </h4>
          <p>{topic.description}</p>
          {expandTile && renderQuestions()}
        </li>
        <hr />
      </>
    );
  }
}

export default Chapter;
