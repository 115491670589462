import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import ReactHtmlParser from "react-html-parser";
import NumberFormat from "react-number-format";
import { getRequest } from "../../../utils/httpRequest";
import { Props, State, LeaderboardSetup, LeaderboardResource } from "./Types";

class Leaderboard extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      courses: [],
      countries: [],
      teams: [],
      heroes: [],
      topThreeHeroes: [],
      totalHeroes: 0,
      selectedCourse: "",
      selectedCountry: "",
      selectedTeam: "",
      hasCurrentMemberShip: false,
      page: 0,
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    return getRequest("/api/profile/leaderboard_setup.json").then(
      (response: AxiosResponse<LeaderboardSetup>) => {
        const { data } = response;
        const courses = [
          { text: "All Courses", value: "all", key: "all" }
        ].concat(
          data.courses.map(course => ({
            text: course.name,
            value: course.slug,
            key: course.slug
          }))
        );

        const countries = [
          { text: "All Countries", value: "all", key: "all" }
        ].concat(
          data.countries.map(countryResource => {
            return {
              text: countryResource.country,
              value: countryResource.code,
              key: countryResource.code
            };
          })
        );

        const teams = data.teams.map(team => ({
          text: team.name,
          value: team.slug,
          key: team.slug
        }));

        this.setState(
          {
            courses,
            countries,
            teams,
            selectedTeam: data.team?.slug,
            hasCurrentMemberShip: data.has_current_membership
          },
          () => {
            const url = data.team?.slug
              ? `/api/leaderboards/profile_search.json?organization_slug=${data.team.slug}`
              : "/api/leaderboards/profile_search.json";

            return getRequest(url).then(
              (response: AxiosResponse<LeaderboardResource>) => {
                const { data } = response;
                this.setState({
                  heroes: data.heroes.slice(3, 10),
                  topThreeHeroes: data.heroes.slice(0, 3),
                  totalHeroes: data.total_heroes,
                  loading: false
                });
              }
            );
          }
        );
      }
    );
  }

  handleChange(key, event, data) {
    this.setState({ [key]: data.value }, () => this.getHeroes());
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.getHeroes();
    }
  };

  getHeroes = () => {
    const { selectedTeam, selectedCourse, selectedCountry } = this.state;

    return getRequest(
      `/api/leaderboards/profile_search.json?course_slug=${
        selectedCourse === "all" ? "" : selectedCourse
      }&organization_slug=${selectedTeam}&country=${
        selectedCountry === "all" ? "" : selectedCountry
      }`
    ).then((response: AxiosResponse<LeaderboardResource>) => {
      const { data } = response;
      this.setState({
        topThreeHeroes: data.heroes.slice(0, 3),
        heroes: data.heroes.slice(3, 10),
        page: 0,
        loading: false
      });
    });
  };

  getHeroesPagination = (newPage: number) => {
    const { selectedTeam, selectedCourse, selectedCountry } = this.state;

    return getRequest(
      `/api/leaderboards/profile_search.json?course_slug=${
        selectedCourse === "all" ? "" : selectedCourse
      }&organization_slug=${selectedTeam}&country=${
        selectedCountry === "all" ? "" : selectedCountry
      }&page=${newPage + 1}`
    ).then((response: AxiosResponse<LeaderboardResource>) => {
      const { data } = response;
      this.setState({
        heroes: data.heroes,
        page: newPage,
        loading: false
      });
    });
  };

  resetFilters = () => {
    this.setState(
      { selectedCourse: "", selectedCountry: "", selectedTeam: "" },
      () => this.getHeroes()
    );
  };

  loadPrev = event => {
    event.preventDefault();
    const { page } = this.state;
    const newPage = this.prevPage(page);

    this.getHeroesPagination(newPage);
  };

  loadNext = event => {
    event.preventDefault();
    const { page, totalHeroes } = this.state;
    const nextPageNum = this.nextPage(page, totalHeroes);
    const newPage = nextPageNum === totalHeroes ? totalHeroes - 1 : nextPageNum;

    this.getHeroesPagination(newPage);
  };

  prevPage = page => {
    if (page < 1) {
      return 0;
    } else if (page + 1 === 1) {
      return 1;
    } else {
      return page - 1;
    }
  };

  nextPage = (page, total_pages) =>
    page > total_pages ? total_pages : page + 1;

  render() {
    const {
      topThreeHeroes,
      heroes,
      courses,
      countries,
      teams,
      selectedCourse,
      selectedCountry,
      selectedTeam,
      page,
      loading
    } = this.state;

    const currentCourse = courses.filter(
      courses => courses.value === selectedCourse
    )[0];

    const currentCountry = countries.filter(
      country => country.value === selectedCountry
    )[0];

    const currentTeam = teams.filter(team => team.value === selectedTeam)[0];

    const currentTeamName =
      currentTeam && currentTeam.text.length > 15
        ? currentTeam.text.slice(0, 10) + "..."
        : "";

    return (
      <div
        className="ui segment leaderboard profile-landing-leaderboard"
        id="profile-leaderboard"
      >
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="ui container">
          <div className="ui header">
            <h1 className="">Leaderboard</h1>
          </div>
          <div className="filters">
            <div className="ui segment filtersLeaderboard">
              <div className="filterHeaders">
                <div className="labelSortBy">
                  <span>Sort by</span>
                </div>
                <div className="filter">
                  <Dropdown
                    placeholder="Course"
                    fluid
                    search
                    selection
                    value="Course"
                    options={courses}
                    onChange={this.handleChange.bind(this, "selectedCourse")}
                  />
                </div>
                <div className="filter">
                  <Dropdown
                    placeholder="Country"
                    fluid
                    search
                    selection
                    value="Country"
                    options={countries}
                    onChange={this.handleChange.bind(this, "selectedCountry")}
                  />
                </div>
                {teams.length > 0 ? (
                  <>
                    {selectedTeam ? (
                      <div className="filter">
                        <Dropdown
                          fluid
                          search
                          selection
                          labeled={true}
                          text={currentTeamName}
                          value={selectedTeam}
                          options={teams}
                          defaultValue={selectedTeam}
                          onChange={this.handleChange.bind(
                            this,
                            "selectedTeam"
                          )}
                        />
                      </div>
                    ) : (
                      <div className="filter">
                        <Dropdown
                          placeholder="Teams"
                          fluid
                          search
                          selection
                          text={currentTeamName}
                          value={selectedTeam}
                          options={teams}
                          onChange={this.handleChange.bind(
                            this,
                            "selectedTeam"
                          )}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          <div className="ui segment">
            <div className="leaderboard-table ui">
              <div>
                <h2>
                  <span>
                    <span id="coursesBc">
                      {currentCourse ? currentCourse.text : "All courses"}
                    </span>
                    <span>&nbsp;/&nbsp;</span>
                  </span>
                  <span>
                    <span id="countriesBc">
                      {currentCountry ? currentCountry.text : "All countries"}
                    </span>
                    <span>&nbsp;/&nbsp;</span>
                  </span>
                  <span>
                    <span id="groupsBc">
                      {currentTeam ? currentTeam.text : "All teams"}
                    </span>
                  </span>
                </h2>
              </div>
              <div className="column">
                <div className="columnleading bottom left">
                  <div className="carouselHeroes">
                    <div aria-live="polite" className="">
                      <div className="top-three heroes">
                        {topThreeHeroes.map((hero, idx) => (
                          <div
                            className={`large_column_classes leading-position-${idx}`}
                            key={idx}
                          >
                            <div className="user">
                              <div className="leaderboard-img-wrapper">
                                <div className="leaderboard-img">
                                  <div className="image-wrap">
                                    <img src={hero.profile_picture_url} />
                                  </div>
                                </div>
                                <div className="position">
                                  <span className={`position-${idx}`}>
                                    {idx + 1}
                                  </span>
                                </div>
                              </div>
                              <div className="name">{hero.protected_name}</div>
                              <div className="score">
                                <NumberFormat
                                  value={hero.xp}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                <span className="score-label">XP</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="bottom-seven">
                        <div className="small_column_classes">
                          <div className="user header">
                            <div className="left">
                              <span className="position">RANK</span>
                              <span className="heroes">
                                <span className="name">NAME</span>
                              </span>
                            </div>
                            <div className="right">
                              <span className="score">XP</span>
                              <span className="country">COUNTRY</span>
                            </div>
                          </div>
                        </div>
                        <div className="heros-container">
                          {heroes.length === 0 ? (
                            <div className="no-heroes">No heroes found!</div>
                          ) : (
                            <>
                              {heroes.map((hero, i) => (
                                <div className="small_column_classes" key={i}>
                                  <div className="user">
                                    <div className="left">
                                      <span className="position">
                                        {i + 4 + page * 7}
                                      </span>
                                      <span className="heroes">
                                        <span className="leaderboard-img">
                                          <img
                                            src={hero.profile_picture_url}
                                            alt="profile picture"
                                          />
                                        </span>
                                        <span className="name">
                                          {hero.protected_name}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="right">
                                      <span className="score">
                                        <span className="value">{hero.xp}</span>
                                        <span className="score-label">
                                          {" "}
                                          &nbsp;XP
                                        </span>
                                      </span>
                                      <span className="country">
                                        <div className="leaderboard-img">
                                          <div className="image-wrap">
                                            {/* @ts-ignore */}
                                            {ReactHtmlParser(
                                              hero.country_flag_image
                                            )}
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagination-buttons">
                <button
                  className="circular ui icon button prev"
                  onClick={this.loadPrev}
                >
                  <i className="icon angle left"></i>
                </button>
                <button
                  className="circular ui icon button next"
                  onClick={this.loadNext}
                >
                  <i className="icon angle right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Leaderboard;
