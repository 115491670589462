import React from "react";
import { LivesPresentIcon, LivesAbsentIcon } from "../Icons";

const Lives = ({ livesCount }) => (
  <div className="lives">
    {livesCount > 0 ? (
      <div>
        <LivesPresentIcon />
        <div className="text">
          <h4>You have {livesCount} lives left</h4>
          <p>
            You can use a life to re-take a lesson if you get it wrong. If you run out of lives you can still pass but you will no longer be able to re-take lessons.
          </p>
        </div>
      </div>
    ) : (
      <div>
        <LivesAbsentIcon />
        <div className="text">
          <h4>You've run out of lives</h4>
          <p>
            You can’t re-take a lesson, but you still can pass the course. You've got this 💪
          </p>
        </div>
      </div>
    )}
  </div>
);

export default Lives;
