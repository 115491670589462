import React, { Component } from "react";
import { observer } from "mobx-react";
import CourseCategoriesStore from "../../../../stores/CourseCategoriesStore";
import CourseCategoriesReactions from "../../../../stores/CourseCategoriesStore/CourseCategoriesReactions";
import Wrapper from "./Wrapper";

interface Props {
  headerTitle?: string;
}

class CourseCategories extends Component<Props, {}> {
  courseCategoriesStore = new CourseCategoriesStore();
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.courseCategoriesStore.loading();
  }

  renderCourseCategories = state => {
    const { headerTitle } = this.props;

    switch (state.kind) {
      case "ready":
        return (
          <>
            {this.courseCategoriesStore.courses.length > 0 && (
              <>
                {!headerTitle && (
                  <div className="view-courses">
                    <a className="ui large fl-button highlight" href="/courses">
                      View all courses
                    </a>
                  </div>
                )}
                <Wrapper
                  courseCategories={state.courseCategories}
                  headerTitle={this.props.headerTitle}
                />
                <div className="view-courses">
                  <a className="ui large fl-button highlight" href="/courses">
                    View all courses
                  </a>
                </div>
              </>
            )}
          </>
        );
        break;
      case "loading":
        return (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        );
      default:
        return <div />;
        break;
    }
  };

  render() {
    const { state } = this.courseCategoriesStore;
    const { headerTitle } = this.props;

    return (
      <div
        className="ui segment courses courses-categories featured-courses"
        id="course-grid-title"
        style={{ paddingTop: headerTitle ? "40px" : "0" }}
      >
        {this.renderCourseCategories(state)}

        <CourseCategoriesReactions store={this.courseCategoriesStore} />
      </div>
    );
  }
}

export default observer(CourseCategories);
