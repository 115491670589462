import React from "react";
import ReactHtmlParser from "react-html-parser";
import { AnswerIcon, QuestionIcon } from "../../Learning/Icons";
import {
  Form,
  Radio,
  Progress,
  Segment,
  SemanticCOLORS
} from "semantic-ui-react";

interface Props {
  selectedAnswer: any;
  question: any;
  answer: any;
  updateSelectedAnswer: (val: any) => void;
  submitAnswer: (e) => void;
  submitting: boolean;
  loadingAnswer: boolean;
  pollAnswerOptions: any;
  pollAnswerOptionsWithPercent: any;
}

const Poll: React.FC<Props> = ({
  question,
  answer,
  pollAnswerOptions,
  pollAnswerOptionsWithPercent,
  selectedAnswer,
  updateSelectedAnswer,
  submitAnswer,
  submitting,
  loadingAnswer
}) => {
  const handleChange = (e, { value }) => {
    updateSelectedAnswer(value);
  };

  const renderAnswer = (opt, idx) => {
    let selectedClass =
      opt[0]["id"] == answer.answer_option_id ? "selected-answer" : "";
    return (
      <div className="item" key={idx}>
        <Progress
          percent={opt[1]}
          className={`${opt[1] === 0 ? "zero-progress" : ""} ${selectedClass}`}
          color="blue"
          size="medium"
          progress
        />
        <div
          dangerouslySetInnerHTML={{ __html: opt[0]["formatted_content"] }}
        />
      </div>
    );
  };

  return (
    <form className="ui form assignment-form">
      <div className="form-header">
        <div className="ui image">
          <QuestionIcon />
        </div>
        <div className="ui question">
          {/* @ts-ignore */}
          <p>{ReactHtmlParser(question?.formatted_description)}</p>
        </div>
      </div>
      <div className="form-body">
        {(loadingAnswer || submitting) && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="ui image">
          <AnswerIcon />
        </div>
        <div className="content poll-content">
          {answer && (
            <>
              {pollAnswerOptionsWithPercent.map((opt, idx) =>
                renderAnswer(opt, idx)
              )}
            </>
          )}
          {!answer &&
            pollAnswerOptions.map((option, i) => (
              <Form.Field key={i}>
                <Radio
                  label={
                    <label
                      dangerouslySetInnerHTML={{
                        __html: option.formatted_content
                      }}
                    ></label>
                  }
                  name="radioGroup"
                  value={option.id}
                  checked={
                    option.id == answer?.answer_option_id ||
                    option.id == selectedAnswer
                  }
                  onChange={handleChange}
                />
              </Form.Field>
            ))}
        </div>
      </div>
      {!answer && (
        <div className="action">
          <button
            className={`ui button ${submitting ? "loading" : ""}`}
            type="submit"
            onClick={e => submitAnswer(e)}
          >
            Submit
          </button>
        </div>
      )}
    </form>
  );
};

export default Poll;
