import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import ReactPlayer from "react-player";
import { CoursePreviewResource } from "../../stores/CoursePreviewStore/Types";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";
import { postRequest } from "../../utils/httpRequest";

interface Props {
  course: CoursePreviewResource;
}

interface State {
  downloadedGuide: boolean;
}

class Description extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      downloadedGuide: props.course?.downloaded_guide
    };
  }

  renderCourseGuide = () => {
    const { course } = this.props;
    const { downloadedGuide } = this.state;

    if (course.overview_file_url) {
      return (
        <div className="course-guide">
          <a
            href="#"
            className={`ui small button ${
              downloadedGuide ? "overview-downloaded" : ""
            } brochure-button`}
            onClick={this.downloadOverviewGuide}
          >
            {downloadedGuide ? (
              <>
                <i className="check icon" /> Course guide
              </>
            ) : (
              <>
                <i className="cloud download icon" /> Course guide
              </>
            )}
          </a>
        </div>
      );
    }
  };

  downloadOverviewGuide = e => {
    const { course } = this.props;
    e.preventDefault();

    fetch(course.overview_file_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", course.overview_filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .then(() =>
        postRequest(
          `/api/courses/${course.slug}/download_overview.json`,
          {}
        ).then(() => this.setState({ downloadedGuide: true }))
      );
  };

  render() {
    const { course } = this.props;

    return (
      <div className="ui segment description" style={{ background: "#fff" }}>
        <div className="ui container">
          <div className="header">
            <h1 className="">{course.preview_mini_description}</h1>
          </div>
          {course.description_video_url && (
            <div className="course-intro-video">
              <ReactPlayer
                className="react-player"
                url={course.description_video_url}
                controls={true}
              />
            </div>
          )}
          <div className="ui text content">
            <div className="left">
              {/* @ts-ignore */}
              {ReactHtmlParser(course.formatted_description)}
            </div>
            <div className="right">
              <div className="key-skills-learned">
                <h2>What you will learn</h2>

                <ul>
                  {course.course_skills &&
                    course.course_skills
                      .slice(0, 5)
                      .map((skill, idx) => <li key={idx}>{skill.name}</li>)}
                </ul>
              </div>
              <div className="who-is-this-for">
                <h2> Who is this for?</h2>

                <ul>
                  {course.course_targets &&
                    course.course_targets
                      .slice(0, 5)
                      .map((target, idx) => <li key={idx}>{target.name}</li>)}
                </ul>
              </div>
              {this.renderCourseGuide()}
              <br />
              <br />
              <div className="cpd">
                <img
                  className="ui medium image"
                  src={require("../../../../assets/images/courses/CPD.png")}
                />
                <h2>
                  This activity equates <br /> to {course.cpd_hours} hours of
                  cpd
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Description;
