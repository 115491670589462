import { observable, decorate, action, computed, toJS } from "mobx";
import {
  CourseCategoryState,
  waiting,
  loading,
  ready,
  CourseCategoryResource,
  CourseCategoriesResource
} from "./Types";
import { CourseResource } from "../CoursesStore/Types";
import { noop } from "../../utils/noop";

class CourseCategoriesStore {
  state: CourseCategoryState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (courseCategories: CourseCategoriesResource) => {
    this.state = ready(courseCategories);
  };

  get courseCategories(): CourseCategoriesResource {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return [];
        break;
      case "ready":
        return this.state.courseCategories || [];
        break;
    }
  }

  get courses(): CourseResource[] {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return [];
        break;
      case "ready":
        const coursesArray = this.state.courseCategories.map(
          category => category.courses
        );

        let courses = [];

        return courses.concat(...coursesArray.map(i => i.map(j => j.slug)));

        break;
    }
  }
}

decorate(CourseCategoriesStore, {
  state: observable,
  loading: action,
  ready: action,
  courseCategories: computed,
  courses: computed
});

export default CourseCategoriesStore;
