import { observer } from "mobx-react";
import { AxiosResponse, AxiosError } from "axios";
import CourseCategoriesStore from ".";
import { CourseCategoriesResource, CourseCategoryState } from "./Types";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { Api } from "../../utils/Api";

interface State {
  state: CourseCategoryState;
}

export interface Props extends BaseReactionProps<CourseCategoriesStore> {
  store: CourseCategoriesStore;
}

class CourseCategoriesReactions extends BaseReaction<
  CourseCategoriesStore,
  State,
  Props
> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    const { store } = this.props;

    const url = "/api/course_categories.json";

    const client = new Api({});
    switch (state.kind) {
      case "loading":
        client
          .get<CourseCategoriesResource>(url)
          .then((response: AxiosResponse<CourseCategoriesResource>) => {
            const { data } = response;

            this.props.store.ready(data);
          })
          .catch((error: AxiosError) => {
            this.props.store.ready([]);
          });

        break;

      case "waiting":
      case "ready":
        break;
    }
  };
}

export default observer(CourseCategoriesReactions);
