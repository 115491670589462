import { Dispatch } from "redux";
import { AppThunk } from "../../../CommunityStore";
import { Post } from "../../posts/types";
import {
  LOAD_TRENDING_POSTS,
  LOAD_TRENDING_POSTS_SUCCESS,
  LOAD_TRENDING_POSTS_FAILURE,
  LoadTrendingPostsAction,
  LoadTrendingPostsSuccessAction,
  LoadTrendingPostsFailureAction,
  TrendingPostActionTypes,
} from "../types";
import { getRequest } from "../../../../utils/httpRequest";
import { AxiosError, AxiosResponse } from "axios";

export const loadTrendingPosts = (): LoadTrendingPostsAction => ({
  type: LOAD_TRENDING_POSTS,
});

export const loadTrendingPostsSuccess = (
  posts: Post[],
): LoadTrendingPostsSuccessAction => ({
  type: LOAD_TRENDING_POSTS_SUCCESS,
  payload: posts,
});

// Thunk action creator
export const fetchTrendingPosts =
  (): AppThunk => async (dispatch: Dispatch<TrendingPostActionTypes>) => {
    dispatch({ type: LOAD_TRENDING_POSTS });
    getRequest(`/api/community/posts/trending.json`)
      .then((response: AxiosResponse) => {
        dispatch(loadTrendingPostsSuccess(response.data));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        dispatch({ type: LOAD_TRENDING_POSTS_FAILURE, error: error.message });
      });
  };
