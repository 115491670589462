import { observable, decorate, action, computed } from "mobx";
import { RootState, RootPayload, waiting, loading, ready } from "./Types";

class RootStore {
  state: RootState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (rootPayload: RootPayload) => {
    this.state = ready(rootPayload);
  };
}

decorate(RootStore, {
  state: observable,
  loading: action,
  ready: action
});

export default RootStore;
