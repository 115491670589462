import React from "react";
import { Menu as _Menu } from "semantic-ui-react";
import {
  ChaptersActiveIcon,
  ChaptersInactiveIcon,
  ResourcesActiveIcon,
  ResourcesInactiveIcon,
  LivesActiveIcon,
  LivesInactiveIcon
} from "./Icons";

const Menu = ({ livesCount, activeItem, handleItemClick }) => (
  <div className="course-menu" id="course-menu">
    <_Menu widths={3}>
      <_Menu.Item
        name="chapters"
        active={activeItem === "chapters"}
        onClick={(e, data) => handleItemClick(e, data)}
      >
        {activeItem === "chapters" ? (
          <ChaptersActiveIcon />
        ) : (
          <ChaptersInactiveIcon />
        )}
        <p>Chapters</p>
      </_Menu.Item>

      <_Menu.Item
        name="resources"
        id="resources-target"
        active={activeItem === "resources"}
        onClick={(e, data) => handleItemClick(e, data)}
      >
        {activeItem === "resources" ? (
          <ResourcesActiveIcon />
        ) : (
          <ResourcesInactiveIcon />
        )}
        <p>Resources</p>
      </_Menu.Item>

      <_Menu.Item
        name="lives"
        active={activeItem === "lives"}
        onClick={(e, data) => handleItemClick(e, data)}
        id="lives-target"
      >
        {activeItem === "lives" ? <LivesActiveIcon /> : <LivesInactiveIcon />}
        <p>{livesCount} Lives</p>
      </_Menu.Item>
    </_Menu>
  </div>
);

export default Menu;
