import Noty from "noty";

export const formErrors = (errors: Errors) => {
  const errorObj = {} as any;

  errors.map(err => {
    errorObj[err.attribute] = err.error;
  });

  return errorObj;
};

export interface Error {
  title: string;
  attribute: string;
  detail: string;
  error: string;
}

export type Errors = Error[];

export const notification = (message, type) => {
  new Noty({
    type,
    layout: "topLeft",
    text: message,
    theme: "relax",
    timeout: 5000,
    animation: {
      open: "animated bounceInLeft",
      close: "animated fadeOutLeft"
    }
  }).show();
};

export const errorNotification = (message, data = "") => {
  notification(message, "error");
};

export const randomNumber = () => Math.floor(Math.random() * 1000000 + 1);

export const scrollIntoView = element => {
  if (element) {
    const offsetTop = element.offsetTop;

    window.scrollTo({
      top: 50,
      behavior: "smooth"
    });
  }
};
