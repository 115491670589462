import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Dropdown } from "semantic-ui-react";
import { freeValidationSchema } from "../helpers";

interface Props {
  userDetails: {
    name: string;
    email: string;
  };
  error: string;
  isSubmitting: boolean;
  setError: (value: string) => void;
  previousAction: (e: any) => void;
  updateUserDetails: (key: string, value: string) => void;
  handleSubmit: (e?: any) => void;
  isCurrentUserPresent: boolean;
}

class FreeForm extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  handleChange = (e, key, setFieldValue) => {
    e.persist();

    const value = e.target.value;

    setFieldValue(key, value);

    this.props.updateUserDetails(key, value);
  };

  render() {
    const {
      userDetails,
      error,
      setError,
      isSubmitting,
      isCurrentUserPresent
    } = this.props;

    return (
      <div style={{ marginTop: "1em" }}>
        <Formik
          initialValues={userDetails}
          validationSchema={freeValidationSchema}
          onSubmit={() => this.props.handleSubmit()}
        >
          {({ setFieldValue }) => (
            <Form className="ui form">
              {!isCurrentUserPresent && (
                <div className="field">
                  <Field
                    id="name"
                    name="name"
                    validate={true}
                    className="string required"
                    placeholder="Eg. Sherlock Holmes"
                    onChange={e => this.handleChange(e, "name", setFieldValue)}
                  />
                  <label>Full name</label>
                  <ErrorMessage name="name">
                    {msg => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
              )}
              {!isCurrentUserPresent && (
                <div className="field">
                  <Field
                    id="email"
                    name="email"
                    validate={true}
                    className="string required"
                    placeholder="Eg. sherlock@42courses.com"
                    onChange={e => this.handleChange(e, "email", setFieldValue)}
                  />
                  <label>Email</label>
                  <ErrorMessage name="email">
                    {msg => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
              )}
              {error && (
                <div id="card-errors" role="alert">
                  <i
                    className="close icon util-clickable"
                    onClick={() => setError("")}
                  />
                  <span>{error}</span>
                </div>
              )}
              <div className="action">
                <button
                  id="checkout-payment-back"
                  className="ui primary button back"
                  onClick={e => this.props.previousAction(e)}
                >
                  Back
                </button>
                <button
                  id="checkout-payment-submit"
                  className={`ui primary button next ${
                    isSubmitting ? "loading" : ""
                  }`}
                  type="submit"
                >
                  Get started!
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default FreeForm;
