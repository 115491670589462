import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";

interface Props {
  currentUser: CurrentUserResource;
}

class PaymentNavbar extends Component<Props, {}> {
  render() {
    const { currentUser } = this.props;

    return (
      <div className="newNavs ui menu">
        <div className="ui grid">
          <div className="desktop only row">
            <div className="column">
              <div className="ui secondary menu fixed new-navbar-desktop" id="">
                <a
                  href={currentUser.kind === "current-user" ? "/profile" : "/"}
                  className="item"
                  style={{ textAlign: "center", margin: "0 auto" }}
                >
                  <div className="logo">
                    <img
                      className="dark"
                      src={require("../../../../assets/images/facelift/logos/dark_rectangle.png")}
                      alt=""
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="mobile only" id="mobile_nav">
            <div className="column">
              <div className="ui secondary menu fixed new-navbar-mobile">
                <Link
                  to={currentUser.kind === "current-user" ? "/profile" : "/"}
                  className="item"
                  style={{ textAlign: "center", margin: "0 auto" }}
                >
                  <img
                    className="ui middle aligned small image logo-mark dark"
                    style={{ textAlign: "center", margin: "0 auto" }}
                    src={require("../../../../assets/images/facelift/logos/dark_rectangle.png")}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentNavbar;
