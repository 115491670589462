import React, { useEffect } from "react";
import { Field } from "formik";

interface Props {
  name: string;
  id: string;
  setFieldValue: any;
}

const Content: React.FC<Props> = ({ name, id, setFieldValue }) => {
  useEffect(() => {
    let simplemde = new (window as any).SimpleMDE({
      element: document.getElementById(id)
    });

    simplemde.codemirror.on("blur", function() {
      setFieldValue(name, simplemde.value());
    });
  }, [id]);

  return (
    <Field component="textarea" id={id} name={name} className="simplemde" />
  );
};

export default Content;
