import React, { Component } from "react";
import { observer } from "mobx-react";
import LinkedinIcon from "./Icons/LinkedinIcon";
import FacebookIcon from "./Icons/FacebookIcon";
import GoogleIcon from "./Icons/GoogleIcon";
import Forms from "./Forms";
import TermsAndConditions from "../../../components/TermsAndConditions";
import ModalStore from "../../../stores/ModalStore";
import LoginStore from "../../../stores/LoginStore";

interface SignInFormContainerProps {
  redirectUrl: string;
  loginUser: (e: React.MouseEvent<HTMLElement>) => void;
  modalStore: ModalStore;
  loginStore: LoginStore;
}

const greetings = [
  "You're marvellous!",
  "You're wonderful!",
  "Hello amazing human",
  "Hello there!",
  "Hi curious brain",
  "Hello Einstein",
  "Ahoy!",
  "Ahoy there!",
  "What’s the craic?",
  "Greetings",
  "Howdy partner",
  "How’s tricks?",
  "Welcome",
  "Well, look at you!"
];

const random = Math.floor(Math.random() * greetings.length);

class SignInFormContainer extends Component<SignInFormContainerProps, {}> {
  handleShowTermsModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.modalStore.open();
  };

  render() {
    const { redirectUrl, modalStore, loginStore, loginUser } = this.props;

    const greeting = greetings[random];

    return (
      <div id="sign_in">
        <div className="ui vertical segment">
          <div className="ui container center aligned padded">
            {redirectUrl ? (
              <div id="main_header">
                <h2>PLEASE SIGN IN TO CONTINUE</h2>
                <p>(Step 1 of 2)</p>
              </div>
            ) : (
              <div id="main_header">
                <h1>{greeting}</h1>
              </div>
            )}
            <div className="segment padded special">
              <div id="login_mobile_providers">
                <a
                  className="ui button huge keep-case click-busy linkedin fluid icon labeled"
                  href="/oauth/linkedin"
                >
                  <LinkedinIcon />
                  Sign in with LinkedIn
                </a>
                <a
                  className="ui button huge keep-case click-busy facebook fluid icon labeled"
                  href="/oauth/facebook"
                >
                  <FacebookIcon />
                  Sign in with Facebook
                </a>
                <a
                  className="ui button huge keep-case click-busy google fluid icon labeled"
                  href="/oauth/google"
                >
                  <GoogleIcon />
                  Sign in with Google
                </a>
              </div>
              <div className="ui stackable three column grid aligned padded vertically">
                <div className="column four wide center aligned computer only tablet only"></div>
                <div className="column eight wide center aligned">
                  <h4 className="ui horizontal divider">OR</h4>
                </div>
                <div className="column four wide center aligned computer only tablet only"></div>
              </div>
              <div className="ui one column grid center aligned login-container vertically">
                <div className="ui basic segment">
                  <div className="ui text container center aligned">
                    <h2 className="ui" id="login_headline">
                      Anti-social?
                    </h2>
                    <p>Continue with email below</p>

                    <Forms loginStore={loginStore} loginUser={loginUser} />
                  </div>
                </div>
              </div>
              <div className="ui one column grid center aligned">
                <TermsAndConditions modalStore={modalStore} />
                <p id="login_terms">
                  <a
                    href="/terms/full"
                    onClick={e => this.handleShowTermsModal(e)}
                  >
                    Terms and Conditions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(SignInFormContainer);
