import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { AxiosResponse } from "axios";
import qs from "qs";
import {
  getRequest,
  postRequest,
  deleteRequest
} from "../../../../utils/httpRequest";
import { errorNotification } from "../../../../utils/helpers";
import { CommentResource } from "../../../../stores/CommentStore/Types";
import Comment from "../Conversations/Comment";
import { Answer } from "../../../../stores/EnrolmentStore/Types";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";

interface Props {
  comments: CommentResource[];
  answer: Answer;
  enrolmentId: number;
  idx: number;
  lessonId: number;
  answerId: number;
  updateUpvoteComments: (idx: number, answer: Answer) => void;
  currentUser: CurrentUser;
  search: string;
}

interface State {
  comment: string;
  showCommentForm: boolean;
  liking: boolean;
  viewReplies: boolean;
  comments: CommentResource[];
  commenting: boolean;
  search: "";
}

class Comments extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      comment: "",
      showCommentForm: false,
      liking: false,
      viewReplies: false,
      comments: [],
      commenting: false,
      search: ""
    };
  }

  componentDidUpdate(props) {
    const { search, answer } = props;

    if (props.search !== this.state.search) {
      const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

      if (answer.id === Number(queryParams?.target_answer_id)) {
        this.setState({
          viewReplies: true
        });
      }

      this.setState({ search });

      this.scrollToComment();
    }
  }

  scrollToComment = () => {
    const { search } = this.props;

    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

    if (queryParams?.scroll_to) {
      document
        .getElementById(`${queryParams?.scroll_to}`)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  handleShowCommentForm = e => {
    e.preventDefault();
    this.setState({ showCommentForm: !this.state.showCommentForm });
  };

  handleViewReplies = e => {
    e.preventDefault();
    this.setState({ viewReplies: !this.state.viewReplies });
  };

  setComment = e => {
    this.setState({ comment: e.target.value });
  };

  fetchComments = () => {
    let { answer, idx, updateUpvoteComments } = this.props;

    return getRequest(`/api/comments.json?answer_id=${answer.id}`)
      .then((response: AxiosResponse) => {
        answer.comments = response.data;
        updateUpvoteComments(idx, answer);
      })
      .catch(error => {
        errorNotification(
          "Error loading comments",
          JSON.stringify(error.response.data)
        );
      });
  };

  submitComment = e => {
    let { answer, idx, enrolmentId, lessonId } = this.props;

    e.preventDefault();
    this.setState({ commenting: true });

    return postRequest(`/api/comments.json?answer_id=${answer.id}`, {
      content: this.state.comment,
      enrolment_id: enrolmentId,
      question_id: lessonId
    })
      .then((response: AxiosResponse) => {
        this.setState({ comment: "", commenting: false, viewReplies: true });
        answer.comments.unshift(response.data);
        this.props.updateUpvoteComments(idx, answer);
      })
      .catch(error => {
        this.setState({ commenting: false });

        errorNotification(
          "Error commenting",
          JSON.stringify(error.response.data)
        );
      });
  };

  render() {
    const { answer, enrolmentId, lessonId, currentUser, comments } = this.props;

    const {
      commenting,
      comment,
      showCommentForm,
      liking,
      viewReplies
    } = this.state;

    return (
      <div className="conversation">
        <div className="conversation__body--content">
          <div className="reaction">
            <a
              className="reply space"
              href="#"
              onClick={this.handleShowCommentForm}
            >
              Reply
            </a>
            {comments.length > 0 && (
              <a
                className="reply view_replies"
                href="#"
                onClick={this.handleViewReplies}
              >
                View replies
              </a>
            )}
          </div>
          {viewReplies && (
            <>
              {comments.map(comment => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  currentUser={currentUser}
                  enrolmentId={enrolmentId}
                  updateComments={this.fetchComments}
                  commentType="answer"
                  commentableId={answer.id}
                  questionId={lessonId}
                  canReply={true}
                />
              ))}
            </>
          )}
          {showCommentForm && (
            <div className="reply__body">
              <div className="reply__body--avatar">
                <img src={currentUser?.profile_picture} className="avatar" />
              </div>
              <form className="ui form">
                <div className="form-header">
                  <span className="name">{currentUser?.short_name} (me)</span>
                </div>
                <div className="field">
                  <textarea
                    value={comment}
                    rows={3}
                    placeholder="Write your comment"
                    onChange={this.setComment}
                    disabled={commenting}
                  />
                </div>
                <div className="submit">
                  <button
                    className={`ui button ${commenting ? "loading" : ""}`}
                    type="submit"
                    onClick={this.submitComment}
                  >
                    Post
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Comments;
