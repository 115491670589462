import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

interface Props {
  match: any;
}

class PassSuccess extends Component<Props, {}> {
  render() {
    const slug = this.props.match.params.slug;

    return (
      <div className="ui pages" id="pass_success">
        <div className="masthead">
          <h1 className="ui header">Subscription success!</h1>
        </div>

        <div className="content">
          <div className="ui vertical segment padded">
            <div className="ui text container padded">
              <p>
                To enrol in a new course, simply make sure you're logged in,
                then select a course you want to start, press the green "Start
                now" button and enjoy the learning.
              </p>
              {slug !== "lifetime" && (
                <p>
                  As a thank you, your membership will remain at the same price
                  for as long as you stay a member regardless of any price
                  increases.
                </p>
              )}
              <Link to={"/profile"} className="fl-button basic">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(PassSuccess);
