import { observer } from "mobx-react";
import TeamStore from ".";
import { TeamState } from "./Types";
import { TeamResource } from "./Types";
import { AxiosResponse, AxiosError } from "axios";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { getRequest } from "../../utils/httpRequest";

interface State {
  state: TeamState;
}

export interface Props extends BaseReactionProps<TeamStore> {
  slug: string;
  store: TeamStore;
}

class TeamReactions extends BaseReaction<TeamStore, State, Props> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    const teamSlug = this.props.slug;

    switch (state.kind) {
      case "loading":
        getRequest(`/api/teams/${teamSlug}.json`)
          .then((response: AxiosResponse<TeamResource>) => {
            const { data } = response;
            console.log(data);

            this.props.store.ready(data);
          })
          .catch((error: AxiosError) => {
            console.log(error.response?.statusText);
          });
        break;

      case "waiting":
      case "ready":
        break;
    }
  };
}

export default observer(TeamReactions);
