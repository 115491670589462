import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { AxiosResponse, AxiosError } from "axios";
import { postRequest } from "../../../../utils/httpRequest";
import { Card } from "./Types";

interface Props {
  updateCards: (card: Card) => void;
}

const stripeStyles = {
  base: {
    fontSize: "16px",
    color: "#32325d",
    lineHeight: "40px",
    fontFamily: '"Averta CY", sans-serif',
    fontSmoothing: "antialiased",
    fontWeight: 400,
    "::placeholder": {
      color: "#CFD7E0"
    }
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};

const AddPaymentMethod: React.FC<Props> = ({ updateCards }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const labelClicked = e => {
    $(e.target as any)
      .parents(".field")
      .find("input")
      .trigger("focus");
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitting(true);

    if (!stripe || !elements) {
      setSubmitting(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    handleResultsOfStripePaymentMethod(payload);
  };

  const handleResultsOfStripePaymentMethod = payload => {
    if (payload.error) {
      setSubmitting(false);
      setError(payload.error.message);
    } else {
      console.log(payload);
      handleSetPaymentMethod(payload.paymentMethod);
      attachPaymentMethodToCustomer(payload.paymentMethod);
    }
  };

  const handleSetPaymentMethod = pm => {
    setError("");
    setPaymentMethod(pm);
  };

  const attachPaymentMethodToCustomer = paymentMethod => {
    const data = {
      payment_method_id: paymentMethod.id
    };

    postRequest("/api/payment_methods.json", data)
      .then((response: AxiosResponse) => {
        updateCards(response.data);
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        setSubmitting(false);
      })
      .catch((error: AxiosError<{ error: string }>) => {
        setError(error?.response?.data?.error);
        setSubmitting(false);
      });
  };

  return (
    <form className="ui form">
      <h4>Add payment method</h4>
      <div className="group pay-info">
        <div className="field">
          <label id="card-label" onClick={labelClicked}>
            Card number
          </label>
          <div id="card-element">
            <CardNumberElement
              options={{ style: stripeStyles }}
              onChange={() => setError("")}
            />
          </div>
        </div>
      </div>
      <div className="two fields">
        <div className="field">
          <label id="card-label" onClick={labelClicked}>
            Expiry
          </label>
          <div id="expiry-element">
            <CardExpiryElement
              options={{ style: stripeStyles }}
              onChange={() => setError("")}
            />
          </div>
        </div>
        <div className="field">
          <label id="card-label" onClick={labelClicked}>
            CVC
          </label>
          <div id="cvc-element">
            <CardCvcElement
              options={{ style: stripeStyles }}
              onChange={() => setError("")}
            />
          </div>
        </div>
      </div>
      {error && (
        <div id="card-errors" role="alert">
          <i
            className="close icon util-clickable"
            onClick={() => setError("")}
          />
          <span>{error}</span>
        </div>
      )}
      <div className="form-actions">
        <button
          className={`ui right large button submit   ${
            submitting ? "loading" : ""
          }
            ${error ? "disabled" : ""}`}
          disabled={!stripe}
          onClick={e => handleSubmit(e)}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default AddPaymentMethod;
