import React from "react";
import { observer } from "mobx-react";
import GiftStore from "../../../stores/GiftStore";

interface Props {
  store: GiftStore;
}

const templates = [
  "whale",
  "valentines",
  "single",
  "purrfect",
  "birthday",
  "oprah",
  "christmas"
];

const Templates: React.FC<Props> = ({ store }) => (
  <div className="ui component voucher">
    <h4>Choose your voucher</h4>
    <div className="ui three cards">
      {templates.map((template, i) => (
        <div
          className={`ui card ${store.template === template ? "selected" : ""}`}
          key={i}
        >
          <a
            className="image"
            href="#"
            onClick={e => store.setSelectedTemplate(e, template)}
          >
            <img
              className="ui template image"
              src={`https://s3.amazonaws.com/42courses-user/coupons/${template}.png`}
            />
          </a>
        </div>
      ))}
    </div>
  </div>
);

export default observer(Templates);
