import React from "react";

const Header = ({ handleShowAiChat }) => {
  return (
    <header
      className="sticky inset-x-0 top-0 z-10 text-black text-center py-5 bg-white w-full relative"
      style={{ paddingBottom: "26px" }}
    >
      <div className="mx-auto max-w-full">
        <span className="text-lg text-gray-600 font-bold">Deep Thought AI</span>
      </div>
      <div className="inline-flex items-center mt-2">
        <div className="h-3 w-3 rounded-full bg-teal-500 mr-2 animate-pulse"></div>
        <span className="text-[#4B3A55]">Online</span>
      </div>
      <button
        onClick={(e) => handleShowAiChat(e, false)}
        className="
          absolute 
          top-4 
          right-6 
          text-gray-600 
          text-xl 
          font-semibold
          bg-white
          border-none
        "
      >
        X
      </button>
    </header>
  );
};

export default Header;
