import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Popup } from "semantic-ui-react";
import { YearlyCoursePricing } from "../../../../stores/PricingStore/Types";
import GoToCheckoutButton from "../../../Payments/GoToCheckoutButton";

interface Props {
  handleShowSignInSideBar: (redirectUrl?: string) => void;
  lifetimePricing: YearlyCoursePricing;
  showSigninSidebar: boolean;
  isRestrictedPricingDisabled?: boolean;
}

class LifetimePricing extends Component<Props, {}> {
  render() {
    const {
      handleShowSignInSideBar,
      lifetimePricing,
      showSigninSidebar,
      isRestrictedPricingDisabled,
    } = this.props;

    return (
      <div
        className={`pricing-card lifetime ${isRestrictedPricingDisabled ? "restricted" : "not-restricted"}`}
      >
        <div className="banner month-banner">Lifelong Learning!</div>
        <div
          className={`ui fluid card yearly-payment ${
            lifetimePricing?.full_plan_quotation_present ? "discounted" : ""
          }`}
        >
          <div className="header">
            Lifetime Access To <br />
             All Courses
          </div>
          <div className="content middle">
            <div className="hero-price">
              <span className="currency-symbol">
                {lifetimePricing.currency_symbol}
              </span>
              <span className="amount">{lifetimePricing.amount}</span>

              <Popup
                trigger={
                  <div className="currency-code">
                    {lifetimePricing.currency_code}
                  </div>
                }
                content={lifetimePricing.currency_name}
              />
            </div>
            <div className="description">
              (One payment, access to everything)
            </div>
            <hr />
          </div>
          <div className="extra content">
            <div className="value">
              <ul className="list">
                <li>
                  <span>Everything in Yearly Access +</span>
                </li>
                <li>
                  <i className="check icon"></i>
                  <span>Lifetime access to all courses</span>
                </li>
                <li>
                  <i className="check icon"></i>
                  <span>One-one onboarding (Optional)</span>
                </li>
                <li>
                  <i className="check icon"></i>
                  <span>Priority support</span>
                </li>
                <li>
                  <i className="star icon"></i>
                  <span>30 day money back guarantee</span>
                </li>
              </ul>
            </div>
            <div className="hero-actions">
              <div className="buttons">
                <GoToCheckoutButton
                  isButton={true}
                  hasLegacyJsTrigger={true}
                  checkoutUrl={lifetimePricing.checkout_url}
                  handleShowSignInSideBar={
                    showSigninSidebar ? handleShowSignInSideBar : null
                  }
                  label="Apply Now"
                />
              </div>
              <div className="gift-this-course">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M14.4863 3.61184H11.6507C11.9178 3.48872 12.1849 3.32456 12.411 3.13989C13.1301 2.44221 13.1507 1.27258 12.4315 0.554387C11.7123 -0.163808 10.5616 -0.184328 9.84247 0.533867L9.82192 0.554387C9.32877 1.04686 8.73288 2.72949 8.44521 3.61184H6.67808C6.39041 2.72949 5.79452 1.02634 5.30137 0.554387C4.60274 -0.163808 3.43151 -0.184328 2.71233 0.533867C1.99315 1.25206 1.9726 2.40117 2.69178 3.11937L2.71233 3.13989C2.93836 3.34508 3.20548 3.48872 3.4726 3.61184H0.513699C0.226027 3.61184 0 3.83756 0 4.12484V6.95658C0 7.24385 0.226027 7.46957 0.513699 7.46957H1.27397V14.4874C1.27397 14.7746 1.5 15.0004 1.78767 15.0004H13.3356C13.6233 15.0004 13.8493 14.7746 13.8493 14.4874V7.46957H14.4863C14.774 7.46957 15 7.24385 15 6.95658V4.12484C15 3.83756 14.774 3.61184 14.4863 3.61184ZM10.5411 1.27258C10.8493 0.964783 11.363 0.964783 11.6712 1.27258C11.9795 1.58038 11.9795 2.09337 11.6712 2.40117C11.4658 2.60637 10.5822 2.97573 9.61644 3.30405C9.96575 2.36013 10.3356 1.47778 10.5411 1.27258ZM8.30137 4.63783V6.44358H6.82192V4.63783H8.30137ZM3.20548 1.84714C3.20548 1.3957 3.55479 1.04686 4.00685 1.04686C4.21233 1.04686 4.41781 1.12894 4.58219 1.2931C4.78767 1.4983 5.15753 2.38065 5.4863 3.34508C4.52055 3.01677 3.63699 2.62689 3.43151 2.44221C3.28767 2.27805 3.20548 2.05233 3.20548 1.84714ZM1.0274 4.63783H5.79452V6.44358H1.0274V4.63783ZM2.30137 7.46957H5.79452V13.9949H2.30137V7.46957ZM6.82192 13.9744V7.46957H8.30137V13.9744H6.82192ZM12.8219 13.9744H9.32877V7.46957H12.8219V13.9744ZM13.9726 6.44358H9.32877V4.63783H13.9726V6.44358Z"
                      fill="#AEB5C7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="15" height="15" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <Link className="ui landing" to={lifetimePricing.gift_url}>
                  Gift this
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(LifetimePricing);
