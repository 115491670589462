import React, { Component } from "react";
import { observer } from "mobx-react";
import { AxiosResponse } from "axios";
import { Helmet } from "react-helmet";
import {
  CoursePreviewResource,
  QuotationResource
} from "../../stores/CoursePreviewStore/Types";
import Wrapper from "./Wrapper";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";
import PricingStore from "../../stores/PricingStore";
import { getRequest } from "../../utils/httpRequest";

interface Props {
  match: any;
  mode?: string;
  handleShowSignInSideBar: () => void;
  currentUser: CurrentUserResource;
  selectedCurrency: string;
  pricingStore: PricingStore;
}

interface State {
  course: CoursePreviewResource;
  loading: boolean;
  loadingQuotations: boolean;
  loadingEnrolmentInfo: boolean;
  quotationResource: QuotationResource;
  enrolmentInfo: {
    existing_subscription: boolean;
    enrolment_id: number;
  };
}

class CoursePreview extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingQuotations: true,
      course: null,
      quotationResource: null,
      loadingEnrolmentInfo: true,
      enrolmentInfo: null
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const courseSlug = this.props.match.params.slug;

    await this.fetchCourse(courseSlug);

    if (this.props.mode !== "upcoming") {
      await this.getCourseQuotation(courseSlug);
    }

    this.props.pricingStore.loading();
  }

  fetchCourse = courseSlug => {
    getRequest(`/api/v2/courses/${courseSlug}.json`).then(
      (response: AxiosResponse) => {
        this.setState({
          course: response.data.course,
          enrolmentInfo: response.data.enrolment_info,
          loading: false
        });
      }
    );
  };

  getCourseQuotation = courseSlug => {
    this.setState({ loadingQuotations: true });

    return getRequest(`/api/courses/${courseSlug}/quotations.json`).then(
      (response: AxiosResponse<QuotationResource>) => {
        this.setState({
          quotationResource: response.data,
          loadingQuotations: false
        });
      }
    );
  };

  async componentDidUpdate(prevProps) {
    const courseSlug = this.props.match.params.slug;

    if (
      prevProps.selectedCurrency !== "" &&
      this.props.selectedCurrency !== prevProps.selectedCurrency &&
      this.props.mode !== "upcoming"
    ) {
      await this.getCourseQuotation(courseSlug);
    }
  }

  render() {
    const {
      loading,
      course,
      loadingQuotations,
      quotationResource,
      loadingEnrolmentInfo,
      enrolmentInfo
    } = this.state;
    const {
      currentUser,
      handleShowSignInSideBar,
      pricingStore,
      mode
    } = this.props;

    return (
      <>
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        {course && (
          <>
            <Wrapper
              mode={mode}
              refresh={this.fetchCourse}
              course={course}
              currentUser={currentUser}
              pricingStore={pricingStore}
              loadingQuotations={loadingQuotations}
              quotationResource={quotationResource}
              loadingEnrolmentInfo={loadingEnrolmentInfo}
              enrolmentInfo={enrolmentInfo}
              handleShowSignInSideBar={handleShowSignInSideBar}
            />
          </>
        )}
      </>
    );
  }
}

export default observer(CoursePreview);
