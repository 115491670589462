import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AxiosResponse, AxiosError } from "axios";
import { postRequest } from "../../utils/httpRequest";
import {
  CoursePreviewResource,
  QuotationResource
} from "../../stores/CoursePreviewStore/Types";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";
import GoToCheckoutButton from "../Payments/GoToCheckoutButton";

interface Props {
  course: CoursePreviewResource;
  currentUser: CurrentUserResource;
  quotationResource: QuotationResource;
  showSigninSidebar: boolean;
  loadingQuotations: boolean;
  loadingEnrolmentInfo: boolean;
  enrolmentInfo: {
    existing_subscription: boolean;
    enrolment_id: number;
  };
  handleShowSignInSideBar: (redirectUrl?: string) => void;
}

const PurchaseNavbar: React.FC<Props> = ({
  course,
  currentUser,
  quotationResource,
  showSigninSidebar,
  loadingQuotations,
  handleShowSignInSideBar,
  enrolmentInfo
}) => {
  const {
    quotations,
    show_purchase_navbar,
    checkout_url,
    gift_url,
    merchant_quotation_id,
    plan_slug,
    new_enrolment_url
  } = quotationResource || {};

  const [enrolingUser, setEnrolingUser] = useState(false);

  const startCourse = e => {
    e.preventDefault();

    setEnrolingUser(true);

    return postRequest(`${new_enrolment_url}.json`, {
      plan_slug,
      merchant_quotation_id
    })
      .then((response: AxiosResponse<{ url: string }>) => {
        (window as any).location = response.data.url;
      })
      .catch((error: AxiosError<{ url: string }>) => {
        setEnrolingUser(false);

        (window as any).location = error.response.data.url;
      });
  };

  const renderActionButton = () => {
    if (enrolmentInfo?.enrolment_id) {
      return (
        <a
          href={`/enrolments/${enrolmentInfo.enrolment_id}`}
          className="ui traditional button fluid click-busy"
        >
          Continue
        </a>
      );
    } else if (currentUser.kind === "current-user") {
      if (enrolmentInfo?.existing_subscription || course.sample) {
        return (
          <a
            href="#"
            className={`ui button fl-button highlight view-pricing click-busy ${
              enrolingUser ? "loading" : ""
            }`}
            onClick={e => startCourse(e)}
          >
            <i className="student icon"></i>Start now
          </a>
        );
      }
    } else {
      return (
        <a
          href={`/courses/${course.slug}/pay`}
          className="ui traditional button fluid add-to-cart click-busy"
        >
          Get started
        </a>
      );
    }
  };

  return (
    <>
      {show_purchase_navbar && (
        <div className="ui menu dimmable forceHide" id="purchase_bar">
          <div className="center menu large only" id="mini-mobile">
            <>
              <div className="item">
                {course.sample ? (
                  <>{renderActionButton()}</>
                ) : (
                  <a
                    className="ui traditional button fluid add-to-cart"
                    data-smooth-scroll-to-top="#pricing-container"
                    href="#"
                  >
                    View Pricing
                  </a>
                )}
              </div>
              <div className="item add-to-cart-button">
                {!course.subscription_only && (
                  <Link
                    className="ui traditional button fluid gift-this-course"
                    to={gift_url}
                  >
                    Gift
                  </Link>
                )}
              </div>
            </>
          </div>

          <div className="item" id="mobile">
            <h3 className="header course-name">{course.name}</h3>
          </div>

          <div className="right menu large only" id="mobile">
            <>
              <div className="item">
                {!course.subscription_only && (
                  <Link
                    className="ui traditional button fluid gift-this-course"
                    to={gift_url}
                  >
                    Gift
                  </Link>
                )}
              </div>
              <div className="item add-to-cart-button">
                {course.sample ? (
                  <>{renderActionButton()}</>
                ) : (
                  <a
                    className="ui traditional button fluid add-to-cart"
                    data-smooth-scroll-to-top="#pricing-container"
                    href="#"
                  >
                    View Pricing
                  </a>
                )}
              </div>
            </>
            )}
          </div>

          <div className="left menu large only desktop">
            <div className="ui column">
              <h3 className="ui header course-name">{course.name}</h3>
              <div className="purchase-ratings">
                <div className="star-ratings-css">
                  <div
                    className="star-ratings-css-top"
                    style={{ width: `${course.rating_percentage}%` }}
                  >
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                  </div>
                  <div className="star-ratings-css-bottom">
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                  </div>
                </div>
                <span className="rating">{course.rating.toFixed(1)}</span>
              </div>
            </div>
          </div>
          <div className="right menu large only desktop">
            <div className="ui two column middle aligned stackable grid buttons">
              <div className="column">
                {!course.subscription_only && (
                  <Link
                    className="ui traditional button fluid gift-this-course"
                    to={gift_url}
                  >
                    Gift this course
                  </Link>
                )}
              </div>
              <div className="column add-to-cart-button">
                {course.sample ? (
                  <>{renderActionButton()}</>
                ) : (
                  <a
                    className="ui traditional button fluid add-to-cart"
                    data-smooth-scroll-to-top="#pricing-container"
                    href="#"
                  >
                    View Pricing
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchaseNavbar;
