import * as React from "react";

const FirstSection = () => (
  <div className="certification-wrapper">
    <div className="mobile">
      <h2 className="header">Enjoyable learning</h2>
      <span className="sub-header">Because life is serious enough already</span>
      <div className="images">
        <img
          className="laptop"
          src={require("../../../../../../assets/images/landings/certifications/laptop-and-phone.webp")}
          alt="laptop"
        />
      </div>
    </div>

    <div className="content">
      <div className="desktop">
        <h2 className="header">Enjoyable learning</h2>
        <span className="sub-header">
          Because life is serious enough already
        </span>
      </div>
      <p className="description">
        We believe learning should be something people look forward to, not
        something they have to be beaten into submission to do. So we designed
        all of our courses to be enjoyable. If we don’t make you smile while
        you’re learning, we’ll even give you your money back… seriously.
      </p>

      <a className="fl-button basic" data-smooth-scroll-to="#pricing-container">
        Get started
      </a>
    </div>

    <div className="images desktop">
      <img
        className="image laptop"
        src={require("../../../../../../assets/images/landings/certifications/laptop-and-phone.webp")}
        alt="laptop"
      />
    </div>
  </div>
);

export default FirstSection;
