import React, { Component } from "react";
import { observer } from "mobx-react";
import { AxiosError, AxiosResponse } from "axios";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { Checkbox, Dropdown, Form } from "semantic-ui-react";
import { notification } from "../../utils/helpers";
import { Redirect } from "react-router-dom";

interface State {
  firstName: string;
  lastName: string;
  selectedCountry: string;
  recieveNewsletter: boolean;
  redirectUrl: string;
  countries: any;
  loaded: boolean;
  submitting: boolean;
}

class UserSetup extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      selectedCountry: "",
      recieveNewsletter: true,
      redirectUrl: null,
      countries: [],
      loaded: false,
      submitting: false
    };
  }

  fetchCountries = () => {
    return getRequest("/api/countries.json").then(
      (response: AxiosResponse<any>) => {
        const countries = response.data.map(countryResource => {
          return {
            text: countryResource.country,
            value: countryResource.code,
            flag: countryResource.code.toLowerCase(),
            key: countryResource.code
          };
        });
        this.setState({ countries: countries, loaded: true });
      }
    );
  };

  componentDidMount() {
    return getRequest("/api/current_user.json").then(
      (response: AxiosResponse<any>) => {
        this.setState({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          selectedCountry: response.data.country
        });
        this.fetchCountries();
      }
    );
  }

  setAttribute = e => {
    e.preventDefault();
    let event_hash = {};
    event_hash[e.target.name] = e.target.value;
    this.setState(event_hash);
  };

  handleCountryChange = (event, data) => {
    this.setState({ selectedCountry: data.value });
  };

  handleNewsletter = event => {
    this.setState({ recieveNewsletter: !this.state.recieveNewsletter });
  };

  onSubmit = e => {
    const {
      firstName,
      lastName,
      selectedCountry,
      recieveNewsletter
    } = this.state;

    this.setState({ submitting: true });

    const userData = {
      user: {
        first_name: firstName ? firstName : "",
        last_name: lastName ? lastName : "",
        name: firstName && lastName ? `${firstName} ${lastName}` : "",
        country: selectedCountry,
        receive_newsletter: recieveNewsletter,
        name_required: true
      }
    };

    return postRequest("/api/profile/setup.json", userData)
      .then((response: AxiosResponse<{ url: string; success: string }>) => {
        notification(response.data.success, "notification");
        (window as any).location = response.data.url;
      })
      .catch((error: AxiosError<{ error: string }>) => {
        notification(error.response.data.error, "error");
        this.setState({ submitting: false });
      });
  };

  render() {
    const {
      loaded,
      countries,
      firstName,
      lastName,
      selectedCountry,
      recieveNewsletter,
      redirectUrl,
      submitting
    } = this.state;

    return (
      <div className="ui pages" id="user_setup">
        {!loaded && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        <div className="content">
          <div className="ui basic vertical segment caret-push padded">
            <div className="ui text container">
              {redirectUrl && <Redirect to={{ pathname: redirectUrl }} />}
              <h1 className="ui header center aligned">Nice to meet you.</h1>

              <Form>
                <div className="two fields">
                  <div className="field string optional">
                    <label className="string optional">First name</label>
                    <input
                      className="string optional"
                      type="text"
                      value={firstName}
                      onChange={this.setAttribute}
                      name="firstName"
                    />
                  </div>
                  <div className="field string optional">
                    <label className="string optional">Last name</label>
                    <input
                      className="string optional"
                      type="text"
                      value={lastName}
                      onChange={this.setAttribute}
                      name="lastName"
                    />
                  </div>
                </div>
                <p>
                  <em>Your name appears on your course certificates.</em>
                </p>
                <div className="field country optional">
                  <label className="country optional">Country</label>
                  <Dropdown
                    fluid
                    search
                    selection
                    options={countries}
                    value={selectedCountry}
                    placeholder="Select Country"
                    onChange={this.handleCountryChange}
                  />
                </div>
                <p>
                  <em>Help your country climb the smartypants leaderboard.</em>
                </p>
                <div className="fields">
                  <Checkbox
                    label="Keep me updated about new courses"
                    onChange={this.handleNewsletter}
                    checked={recieveNewsletter}
                  />
                </div>
                <button
                  className={`ui large button fluid ${
                    submitting ? "loading" : ""
                  }`}
                  onClick={this.onSubmit}
                >
                  Continue
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(UserSetup);
