import React, { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../../../utils/httpRequest";
import CoursesModal from "./CoursesModal";
import { CourseResource } from "../../../../stores/CoursesStore/Types";
import { defaultSearch } from "./helper";

interface Props {}

interface State {
  search: string;
  openModal: boolean;
  courses: CourseResource[];
  loading: boolean;
  commentary: string;
  errorMessage: string;
}

const AiCoursePicker: React.FC<Props> = () => {
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [commentary, setCommentary] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const onClickDefaultText = (e, text) => {
    e.preventDefault();

    setSearch(text);
    onSubmit(e, text);
  };

  const onSubmit = (e, text) => {
    e.preventDefault();

    if (text.trim().length < 3) {
      alert("Please enter a valid search term");
      return;
    }

    setLoading(true);
    setOpenModal(true);

    getRequest(`/api/ai/courses/recommender?goal=${text}`)
      .then((response: AxiosResponse) => {
        setCommentary(response.data.commentary);
        setCourses(response.data.courses);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error.response?.statusText);

        setLoading(false);
        setCommentary("");
        setCourses([]);
      });
  };

  const onClickCloseModal = () => {
    setOpenModal(false);
    setSearch("");
    setErrorMessage("");
    setCourses([]);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit(e, search);
    }
  };

  return (
    <div className="ui segment courses featured-courses ai-course-picker">
      <div className="ui container">
        <h2 className="ui header aligned course-header featured-course-header">
          Find the right course for you
        </h2>
        <div className="content">
          <p className="info">I want to...</p>
          <div className="action">
            <div className="ui input">
              <input
                type="text"
                value={search}
                placeholder="What's your goal?"
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleKeyPress(e)}
              />
            </div>
            <button
              className="ui button highlight"
              id="fl-button"
              onClick={(e) => onSubmit(e, search)}
              disabled={search.trim().length < 3}
            >
              Recommend
            </button>
          </div>
          <div className="default-searches">
            {defaultSearch.map((s, index) => (
              <>
                <span key={index} className={`search-index-${index}`}>
                  <a href="#" onClick={(e) => onClickDefaultText(e, s)}>
                    {s}
                  </a>
                </span>
              </>
            ))}
          </div>
        </div>
      </div>
      <CoursesModal
        openModal={openModal}
        onClickCloseModal={onClickCloseModal}
        commentary={commentary}
        courses={courses}
        errorMessage={errorMessage}
        loading={loading}
        searchText={search}
      />
    </div>
  );
};

export default AiCoursePicker;
