import React, { useState } from "react";
import find from "lodash/find";
import Course from "./components/Course";
import Subscription from "./components/Subscription";
import { Priceable, PaymentType } from "../Types";
import { Quotation } from "../../../stores/CoursePreviewStore/Types";
import CouponModal from "../../Gifts/CouponModal";

interface Props {
  isCourseError: boolean;
  course: string;
  paymentType: PaymentType;
  slug: string;
  path: string;
  category: string;
  couponCode: string;
  refCode: string;
  monthlySubsequentPayment: string;
  priceable: Priceable;
  activeQuote: Quotation;
  quotes: Quotation[];
  loading: boolean;
  loadingSubscription: boolean;
  handleChange: (key: string, value: any) => void;
  refreshQuote: () => void;
  refreshQuoteWithoutCoupon: (e: any) => void;
}

const PlanInfo: React.FC<Props> = ({
  isCourseError = false,
  course,
  priceable,
  activeQuote,
  paymentType,
  slug,
  path,
  quotes,
  couponCode,
  refCode,
  loading,
  category,
  loadingSubscription,
  handleChange,
  refreshQuote,
  refreshQuoteWithoutCoupon,
  monthlySubsequentPayment,
}) => {
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [removeCoupon, setRemoveCoupon] = useState(false);

  const handleShowCouponModal = (e, val) => {
    e.preventDefault();

    setShowCouponModal(val);
    setRemoveCoupon(false);
  };

  const honorCouponOrReferral = (code, ref) => {
    handleChange("couponCode", code);
    handleChange("refCode", ref);

    refreshQuote();
  };

  const subscriptionActiveQuote = () =>
    find(quotes, (quote) => quote?.interval === "annually");

  return (
    <>
      {(loadingSubscription || loading) && (
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      )}
      {(paymentType === "course" ||
        (path === "gift" && priceable?.type === "course")) && (
        <Course
          isCourseError={isCourseError}
          course={course}
          priceable={priceable}
          activeQuote={activeQuote}
          couponCode={couponCode}
          refCode={refCode}
          paymentType={paymentType}
          handleShowCouponModal={handleShowCouponModal}
          refreshQuoteWithoutCoupon={refreshQuoteWithoutCoupon}
        />
      )}
      {(paymentType === "subscription" ||
        (path === "gift" && priceable?.type === "pass")) && (
        <Subscription
          priceable={priceable}
          activeQuote={activeQuote}
          slug={slug}
          path={path}
          couponCode={couponCode}
          refCode={refCode}
          monthlySubsequentPayment={monthlySubsequentPayment}
          amount={subscriptionActiveQuote()?.formatted_amount}
          discountAmount={subscriptionActiveQuote()?.discount_amount}
          paymentType={paymentType}
          handleShowCouponModal={handleShowCouponModal}
          refreshQuoteWithoutCoupon={refreshQuoteWithoutCoupon}
        />
      )}
      <CouponModal
        showCouponModal={showCouponModal}
        removeCoupon={removeCoupon}
        handleShowCouponModal={handleShowCouponModal}
        honorCouponOrReferral={honorCouponOrReferral}
        priceable={{ kind: category, id: priceable?.id }}
      />
    </>
  );
};

export default PlanInfo;
