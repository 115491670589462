import { observable, decorate, action, computed } from "mobx";
import {
  CurrencyState,
  CurrencyPayload,
  Currency,
  waiting,
  loading,
  ready,
  saving,
  readyWithErrors
} from "./Types";
import { noop } from "../../utils/noop";
import ModalStore from "../ModalStore";

class CurrencyStore {
  state: CurrencyState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (currencyPayload: CurrencyPayload) => {
    this.state = ready(currencyPayload);
  };

  readyWithErrors = (currencyPayload: CurrencyPayload, error: string) => {
    this.state = readyWithErrors(currencyPayload, error);
  };

  saving = (isoCode: string, modalStore: ModalStore) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;

      case "ready":
      case "readyWithErrors":
        {
          const { currencyPayload } = this.state;
          this.state = saving(currencyPayload, isoCode, modalStore);
        }

        break;
    }
  };

  get currencies(): Currency[] {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return [];
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.currencyPayload.currencies;
        break;
    }
  }

  get isoCode(): string {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "USD";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.currencyPayload.selectedCurrency.iso_code;
        break;
    }
  }

  get symbol(): string {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "$";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
        return this.state.currencyPayload.selectedCurrency.symbol;
        break;
    }
  }
}

decorate(CurrencyStore, {
  state: observable,
  loading: action,
  ready: action,
  saving: action,
  readyWithErrors: action,
  currencies: computed,
  isoCode: computed,
  symbol: computed
});

const instance = new CurrencyStore();
Object.freeze(instance);

export default instance;
