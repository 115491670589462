import { Errors } from "../../utils/helpers";
import { Quotation } from "../CoursePreviewStore/Types";
import { MiniCourseResource } from "../CoursesStore/Types";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  gift: Gift;
  defaultsUrl: string;
  errors: Errors;
  courses: MiniCourseResource[];
}

export interface ReadyWithErrors {
  kind: "readyWithErrors";
  gift: Gift;
  defaultsUrl: string;
  errors: Errors;
  courses: MiniCourseResource[];
}

export interface Saving {
  kind: "saving";
  gift: Gift;
  defaultsUrl: string;
  errors: Errors;
  courses: MiniCourseResource[];
}

export interface Created {
  kind: "created";
  gift: Gift;
}

export interface ChangingCourse {
  kind: "changingCourse";
  gift: Gift;
  defaultsUrl: string;
  courseSlug: string;
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (
  defaultsUrl: string,
  gift: Gift,
  courses: MiniCourseResource[]
): Ready => {
  return {
    kind: "ready",
    defaultsUrl,
    gift,
    courses,
    errors: []
  };
};

export const saving = (
  defaultsUrl: string,
  gift: Gift,
  courses: MiniCourseResource[]
): Saving => {
  return {
    kind: "saving",
    defaultsUrl,
    gift,
    courses,
    errors: []
  };
};

export const readyWithErrors = (
  defaultsUrl: string,
  gift: Gift,
  courses: MiniCourseResource[],
  errors: Errors
): ReadyWithErrors => {
  return {
    kind: "readyWithErrors",
    defaultsUrl,
    gift,
    courses,
    errors
  };
};

export const created = (gift: Gift): Created => {
  return {
    kind: "created",
    gift
  };
};

export const changingCourse = (
  defaultsUrl: string,
  gift: Gift,
  courseSlug: string
): ChangingCourse => {
  return {
    kind: "changingCourse",
    defaultsUrl,
    gift,
    courseSlug
  };
};

export type GiftState =
  | Waiting
  | Loading
  | Ready
  | ReadyWithErrors
  | Saving
  | Created
  | ChangingCourse;

export interface Recipient {
  id: number;
  name: string;
  email: string;
}

export interface Gift {
  id: number;
  token: string;
  state: string;
  sender_name: string;
  sender_email: string;
  team_name: string;
  team_logo_url: string;
  message: string;
  deliver_at: string;
  template: string;
  recipients: Recipient[];
  quotation: Quotation;
  priceable: any;
  category: string;
  timezone: string;
}
