import { CurrentUser, CurrentUserAbsent } from "../CurrentUserStore/Types";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  profileResource: ProfileResource;
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting",
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading",
  };
};

export const ready = (profileResource: ProfileResource): Ready => {
  return {
    kind: "ready",
    profileResource,
  };
};

export type ProfileState = Waiting | Loading | Ready;
interface Badge {
  eligible: number;
  awarded: number;
}

interface Enrolment {
  finished: number;
  ongoing: number;
}

export interface OngoingEnrolment {
  id: number;
  name: string;
  percentage_completed: number;
}

export interface AdminTeam {
  id: number;
  name: string;
  link: string;
}

export interface Profile extends CurrentUser {
  email: string;
  first_name: string;
  last_name: string;
  picture_url: string;
  team_name: string;
  date_joined: string;
  subscription_url: string;
  providers: string[];
  subscription: Subscription;
  enrolments: Enrolment;
  country_iso_code: string;
  quaderno_billing_url: string;
  badge: Badge;
  global_leaderboard: ProfileLeaderboard;
  team_leaderboard: ProfileLeaderboard;
  zen_pool: string;
  rank_with_ordinal: string;
  rank: number;
  admin: boolean;
  qa: boolean;
  is_lifetime_subscription: boolean;
  super_user: boolean;
  can_upgrade: boolean;
  admin_teams: AdminTeam[];
  countries: CountryResource[];
}

export type ProfileResource = CurrentUserAbsent | Profile;

export interface ProfileLeaderboard {
  pool: Leaderboard[];
  user_xp_total: number;
  rank: string;
  user_rank: number;
  section_above_user: Leaderboard[];
  section_below_user: Leaderboard[];
}

export interface Leaderboard {
  name: string;
  xp: number;
  rank: string;
  country: string;
  profile_picture: string;
}

export interface CountryResource {
  country: string;
  code: string;
  iso_code: string;
}

export interface Subscription {
  id: number;
  name: string;
  renew: boolean;
  valid_till: string;
  plan: string;
  interval: string;
  active: boolean;
  number_of_months_left: number;
  is_restricted: boolean;
  available_credits: number;
  expiry_date: string;
}
