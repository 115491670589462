import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { AxiosResponse, AxiosError } from "axios";
import { patchRequest } from "../../../utils/httpRequest";
import ProfileStore from "../../../stores/ProfileStore";

interface Props {
  subscriptionId: number;
  showCancelAutoRenewModal: boolean;
  handleShowCancelAutoRenewModal: (e: any, val: boolean) => void;
  handleShowSettingsModal: (val: boolean) => void;
  profileStore: ProfileStore;
}

interface State {
  saving: boolean;
  canceled: boolean;
}

class CancelAutoRenew extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      canceled: false
    };
  }

  cancelAutoRenewSubscription = e => {
    const { subscriptionId } = this.props;

    e.preventDefault();
    this.setState({ saving: true });

    return patchRequest(
      `/api/subscriptions/${subscriptionId}/toggle_status.json`,
      {}
    )
      .then((response: AxiosResponse) => {
        this.props.profileStore.setSubscription(response.data);

        this.setState({
          saving: false,
          canceled: true
        });
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  };

  doNotCancel = e => {
    this.setState({ saving: false, canceled: false });
    this.props.handleShowCancelAutoRenewModal(e, false);
    this.props.handleShowSettingsModal(true);
  };

  render() {
    const {
      showCancelAutoRenewModal,
      handleShowCancelAutoRenewModal
    } = this.props;

    const { saving, canceled } = this.state;

    const buttonCss = saving
      ? "ui button general-button click-busy loading"
      : "ui button general-button click-busy";

    const buttonText = saving ? "Submitting" : "Yes";

    return (
      <>
        <Modal
          open={showCancelAutoRenewModal}
          size="small"
          className="profile-settings-modal cancel-auto-renew-modal"
        >
          <Modal.Header>
            <div className="close">
              <a
                className="ui left button"
                id="closeNav"
                onClick={e => handleShowCancelAutoRenewModal(e, false)}
              >
                <i className="close icon" />
              </a>
            </div>
            {canceled ? (
              <h1>Auto-renew turned off successfully</h1>
            ) : (
              <h1>Are you sure?</h1>
            )}
          </Modal.Header>
          <Modal.Content>
            <div className="column center aligned">
              <p>
                By cancelling auto-renew, you'll miss out on learning once your
                subscription expires.
              </p>
              <div className="ui image">
                <img
                  src={require("../../../../../assets/images/team_admin/endSubscription.gif")}
                  alt="end subscription"
                />
              </div>
              {canceled ? (
                <>
                  <h2 className="ui header">
                    You have now successfully turned off your renewal.
                  </h2>
                  <div className="ui column stackable grid yesnoButtons">
                    <button
                      className="ui button general-button"
                      onClick={this.doNotCancel}
                    >
                      Continue
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="ui header"> Cancel Auto Renewal</h2>
                  <div className="ui two column stackable grid yesnoButtons">
                    <div className="column">
                      <button
                        className={buttonCss}
                        onClick={this.cancelAutoRenewSubscription}
                      >
                        {buttonText}
                      </button>
                    </div>
                    <div className="column">
                      <button
                        className="ui options button general-button"
                        onClick={this.doNotCancel}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export default CancelAutoRenew;
