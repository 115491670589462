import { observable, decorate, action, computed } from "mobx";
import { AxiosResponse } from "axios";
import {
  GiftState,
  waiting,
  loading,
  ready,
  created,
  saving,
  readyWithErrors,
  changingCourse,
  Gift,
  Recipient
} from "./Types";
import { Errors } from "../../utils/helpers";
import { noop } from "../../utils/noop";
import { Quotation } from "../CoursePreviewStore/Types";
import { postRequest } from "../../utils/httpRequest";
import { MiniCourseResource } from "../CoursesStore/Types";

class GiftStore {
  state: GiftState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (defaultsUrl: string, gift: Gift, courses: MiniCourseResource[]) => {
    this.state = ready(defaultsUrl, gift, courses);
  };

  changingCourse = (courseSlug: string) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
      case "created":
      case "changingCourse":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        const { defaultsUrl, gift } = this.state;
        this.state = changingCourse(defaultsUrl, gift, courseSlug);
        break;
    }
  };

  fetchGift = () => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "created":
      case "ready":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift;
        break;
    }
  };

  readyWithErrors = (errors: Errors) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "ready":
      case "readyWithErrors":
      case "changingCourse":
        noop();
        break;
      case "saving":
        const { defaultsUrl, gift, courses } = this.state;
        this.state = readyWithErrors(defaultsUrl, gift, courses, errors);
        break;
    }
  };

  saving = () => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
      case "changingCourse":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        const { defaultsUrl, gift, courses } = this.state;
        this.state = saving(defaultsUrl, gift, courses);
        break;
    }
  };

  created = (gift: Gift) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "ready":
      case "readyWithErrors":
      case "changingCourse":
        noop();
        break;
      case "saving":
        this.state = created(gift);
        break;
    }
  };

  setQuotation = quotation => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.gift.quotation = quotation;
        break;
    }
  };

  setDirectAttribute = (attribute, value) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.gift[attribute] = value;
        this.preserveDetails(this.state.defaultsUrl, this.state.gift);
        break;
    }
  };

  setSelectAttribute = (name, value) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.gift[name] = value;
        this.preserveDetails(this.state.defaultsUrl, this.state.gift);
        break;
    }
  };

  setSelectedTemplate = (e, template) => {
    e.preventDefault();

    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.gift.template = template;
        this.preserveDetails(this.state.defaultsUrl, this.state.gift);
        break;
    }
  };

  resetAttribute = attribute => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.gift[attribute] = "";
        this.preserveDetails(this.state.defaultsUrl, this.state.gift);
        break;
    }
  };

  setAttributes = (e, attribute) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.gift[attribute] = e.target.value;
        this.preserveDetails(this.state.defaultsUrl, this.state.gift);
        break;
    }
  };

  setCollectionAttributes = (val, idx, attribute) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.gift.recipients[idx][attribute] = val;
        this.preserveDetails(this.state.defaultsUrl, this.state.gift);
        break;
    }
  };

  addRecipients = () => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        noop();
        break;
      case "ready":
      case "readyWithErrors":
        this.state.gift.recipients.push({ id: null, name: "", email: "" });

        break;
    }
  };

  preserveDetails = (url, gift) => {
    return postRequest(url, gift).then((response: AxiosResponse) => {});
  };

  get template() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "whale";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.template;
        break;
    }
  }

  get senderName() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.sender_name;
        break;
    }
  }

  get senderEmail() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.sender_email;
        break;
    }
  }

  get deliverAt() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.deliver_at;
        break;
    }
  }

  get teamName() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.team_name;
        break;
    }
  }

  get teamLogoUrl() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.team_logo_url;
        break;
    }
  }

  get message() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "";
        break;
      case "ready":
      case "saving":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.message;
        break;
    }
  }

  get priceable() {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
      case "ready":
      case "changingCourse":
        return [];
        break;
      case "readyWithErrors":
        return this.state.gift.priceable;
        break;
    }
  }

  get quotation(): Quotation {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return null;
        break;
      case "saving":
      case "ready":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.quotation;
        break;
    }
  }

  get recipients(): Recipient[] {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
        return [];
        break;
      case "ready":
      case "readyWithErrors":
      case "changingCourse":
        return this.state.gift.recipients;
        break;
    }
  }

  get courses(): MiniCourseResource[] {
    switch (this.state.kind) {
      case "saving":
      case "ready":
      case "readyWithErrors":
        return this.state.courses;
        break;
      case "waiting":
      case "loading":
      case "changingCourse":
        return [];
        break;
    }
  }

  get errors(): Errors {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "saving":
      case "ready":
      case "changingCourse":
        return [];
        break;
      case "readyWithErrors":
        return this.state.errors;
        break;
    }
  }
}

decorate(GiftStore, {
  state: observable,
  loading: action,
  ready: action,
  created: action,
  saving: action,
  readyWithErrors: action,
  setAttributes: action,
  setQuotation: action,
  addRecipients: action,
  setSelectedTemplate: action,
  errors: computed,
  senderEmail: computed,
  senderName: computed,
  teamName: computed,
  teamLogoUrl: computed,
  message: computed,
  deliverAt: computed,
  template: computed,
  recipients: computed,
  priceable: computed,
  quotation: computed,
  courses: computed
});

export default GiftStore;
