import { observable, decorate, action, computed } from "mobx";
import {
  CoursesState,
  waiting,
  loading,
  ready,
  sorting,
  SelectedFilter,
  CoursesResource,
  CourseResource
} from "./Types";
import { noop } from "../../utils/noop";

class CoursesStore {
  state: CoursesState;

  constructor() {
    this.state = waiting();
  }

  loading = () => {
    this.state = loading();
  };

  ready = (courses: CoursesResource, mode: string) => {
    this.state = ready(courses, mode);
  };

  sorting = (mode: string, filters: SelectedFilter[]) => {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
      case "sorting":
        noop();
        break;
      case "ready":
        {
          const { courses } = this.state;
          this.state = sorting(courses, mode, filters);
        }
        break;
    }
  };

  get courses(): CourseResource[] {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return [];
        break;
      case "ready":
      case "sorting":
        return this.state.courses || [];
        break;
    }
  }

  get mode(): string {
    switch (this.state.kind) {
      case "waiting":
      case "loading":
        return "default";
        break;
      case "ready":
      case "sorting":
        return this.state.mode;
        break;
    }
  }
}

decorate(CoursesStore, {
  state: observable,
  loading: action,
  ready: action,
  sorting: action,
  courses: computed,
  mode: computed
});

export default CoursesStore;
