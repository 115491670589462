import React from "react";
import { Quotation } from "../../../../../stores/CoursePreviewStore/Types";

interface Props {
  activeQuote: Quotation;
}

const MonthlyFlexEd: React.FC<Props> = ({ activeQuote }) => {
  return (
    <div className="extra content">
      <div className="price month total">
        <div className="payment-due">
          <strong>Total:</strong>
        </div>
        <div className="payment-info">
          <span className="symbol">{activeQuote?.currency}</span>
          <span className="amount">
            <strong>{activeQuote?.formatted_amount}</strong>
          </span>
          <span className="code">{activeQuote?.currency_code}</span>
        </div>
      </div>
    </div>
  );
};

export default MonthlyFlexEd;
