import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AxiosResponse, AxiosError } from "axios";
import { postRequest } from "../../utils/httpRequest";
import { notification } from "../../utils/helpers";
import { required, email } from "../common/FormValidations";

interface Props {
  courseSlug: string;
  refresh: (slug: string) => void;
}

const FeatureInterest: React.FC<Props> = ({ courseSlug, refresh }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const submit = values => {
    setSubmitting(true);
    postRequest(`/api/feature_interests`, {
      course_slug: courseSlug,
      first_name: values.first_name,
      email: values.email
    })
      .then((response: AxiosResponse) => {
        if (response.data?.datalayer?.tag_success) {
          (window as any)?.dataLayer.push(response.data.datalayer.tag_success);
        }
        refresh(courseSlug);
        setSubmitted(true);
      })
      .catch(error => {
        setSubmitting(false);
        notification(error.response.data.errors, "error");
      });
  };

  return (
    <div className={`dimmable wrapper ${submitted ? "requested" : ""}`}>
      {!submitted && (
        <div className="hero-learners upcoming">
          Sign up for course launch news
        </div>
      )}
      <div className="pricing ui vertical center aligned segment feature-request">
        {submitted && (
          <>
            <p>Bravo for signing up!</p>
            <p>We’ll now keep you updated on the latest course launch news.</p>
          </>
        )}{" "}
        {!submitted && (
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              first_name: "",
              email: ""
            }}
            onSubmit={values => submit(values)}
          >
            {({ values, touched, handleChange, validateForm }) => (
              <Form className="ui form">
                <div className="field">
                  <div className="ui input">
                    <Field
                      id="first_name"
                      name="first_name"
                      validate={required}
                      className="string required"
                      placeholder="Your first name"
                      onChange={handleChange}
                    />
                  </div>
                  <ErrorMessage name="first_name">
                    {msg => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="field">
                  <div className="ui input">
                    <Field
                      id="email"
                      name="email"
                      validate={email}
                      className="string required"
                      placeholder="Your email address"
                      onChange={handleChange}
                    />
                  </div>
                  <ErrorMessage name="email">
                    {msg => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
                <button
                  className={`ui large traditional fluid button ${
                    submitting ? "loading" : ""
                  }`}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default FeatureInterest;
