import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../../../utils/httpRequest";
import { errorNotification } from "../../../../utils/helpers";
import { Answer, Enrolment } from "../../../../stores/EnrolmentStore/Types";
import { CourseReadingResource } from "../../../../stores/CoursesStore/Types";

interface Props {
  enrolment: Enrolment;
}

interface State {
  resources: CourseReadingResource[];
  loading: boolean;
  sortBy: string;
}

class Resources extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      resources: [],
      sortBy: "popular",
      loading: true
    };
  }

  componentDidMount() {
    this.loadResources(this.state.sortBy);
  }

  loadResources = sortBy => {
    const { enrolment } = this.props;

    return getRequest(
      `/api/resources.json?enrolment_id=${enrolment?.id}&sort_by=${sortBy}`
    )
      .then((response: AxiosResponse) => {
        this.setState({ resources: response.data, loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });

        errorNotification(
          "Error loading resources",
          JSON.stringify(error.response.data)
        );
      });
  };

  onClickSortOption = (e, option) => {
    e.preventDefault();

    const { sortBy } = this.state;

    if (sortBy === "option") {
      return;
    }

    this.setState({ sortBy: option, loading: true });

    this.loadResources(option);
  };

  render() {
    const { resources, loading } = this.state;

    return (
      <div className="resources">
        <div className="ui stackable grid vertically resources-container">
          {loading && (
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          )}
          {resources.map((resource, i) => (
            <div className="ui two column row resource" key={i}>
              <div className="ui column three wide image">
                <img src={resource.image_url} />
              </div>
              <div className="ui column thirteen wide content">
                <div className="link desktop">
                  <a
                    target="_blank"
                    className="ui huge secondary button"
                    href={resource.target_url}
                  >
                    Go to link
                  </a>
                </div>
                <div className="ui fluid text">
                  <h2>{resource.name}</h2>

                  <div className="kramdown">
                    <p>{resource.description}</p>
                  </div>
                </div>
                <div className="link mobile">
                  <a
                    target="_blank"
                    className="ui huge secondary button"
                    href={resource.target_url}
                  >
                    Go to link
                  </a>
                </div>
              </div>
            </div>
          ))}
          <div style={{ margin: "1em 0" }}>
            42courses is a participant in the Amazon Services LLC Associates
            Program, an affiliate advertising program designed to provide a
            means for sites to earn advertising fees by advertising and linking
            to{" "}
            <a href="amazon.com" target="_blank">
              Amazon.com.
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Resources;
