import React from "react";

const Navbar = () => (
  <div className="ui menu dimmable forceHide team-nav" id="purchase_bar">
    <div className="center menu large only">
      <div className="item">
        <a
          className="fl-button highlight"
          data-smooth-scroll-to="#team-form"
          href="#"
        >
          Unlock teams access now!
        </a>
      </div>
    </div>
  </div>
);

export default Navbar;
