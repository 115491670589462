import React from "react";
import { Progress, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";

const chapterClass = chapter => {
  if (chapter.completed) {
    return "completed";
  } else {
    return "in-progress";
  }
};

const questionPath = (enrolmentId, question, answers) => {
  let answerIdx = answers.findIndex(v => v.question_id == question?.id);
  const answer = answers[answerIdx];

  if (
    answer &&
    question.kind === "Opinion" &&
    ["pending", "passed"].includes(answer["state"])
  ) {
    return `/enrolments/${enrolmentId}/questions/${question.id}/answers/${answer.id}/votes`;
  } else {
    return `/enrolments/${enrolmentId}/questions/${question.id}/answer`;
  }
};

const questionClass = (enrolment, question, answers) => {
  let answerIdx = answers.findIndex(v => v.question_id == question?.id);
  if (
    answerIdx == -1 &&
    (enrolment.locked || (enrolment.preview && !question?.preview))
  ) {
    return "locked";
  } else if (
    enrolment?.next_question_path ==
    questionPath(enrolment?.id, question, answers)
  ) {
    return "current";
  } else if (answerIdx == -1) {
    return "not-started";
  } else {
    return answers[answerIdx]["state"];
  }
};

const renderQuestionState = (
  enrolment,
  nextQuestionPath,
  question,
  i,
  answers
) => {
  if (
    enrolment?.next_question_path ==
    questionPath(enrolment?.id, question, answers)
  ) {
    return <img src={enrolment?.user?.profile_picture_url} />;
  }

  let questionState = questionClass(enrolment, question, answers);
  switch (questionState) {
    case "not-started":
      return i + 1;
    case "pending":
    case "submitted":
      return <i className="ellipsis horizontal icon"></i>;
    case "failed":
      return <i className="close icon"></i>;
    case "passed":
      return <i className="check icon"></i>;
    case "locked":
      return <i className="lock icon"></i>;
    default:
      return i + 1;
  }
};

const mobileProgressClass = chapter => {
  if (chapter.questions.length > 6) {
    if (chapter.completed) {
      return "mobile-completed-progress";
    } else {
      return "mobile-long-progress";
    }
  } else if (!chapter.completed) {
    return "mobile-incomplete-progress";
  } else {
    return "";
  }
};

const renderProgressBar = (chapterSize, chapter, i) => {
  let finalChapter = i == chapterSize - 1;
  const progressClass = finalChapter ? "no-progress" : "progress";

  return (
    <>
      <div
        className={`desktop ${progressClass} ${
          chapter.questions.length > 7 ? "desktop-long-progress" : ""
        }`}
      />
      <div
        className={`mobile-small ${progressClass} ${mobileProgressClass(
          chapter
        )}`}
      />
    </>
  );
};

const Topic = ({ question, i, additionalNum, answers, enrolment, chapter }) => (
  <Link
    className={`topic ${
      questionClass(enrolment, question, answers) == "locked"
        ? "click-disabled"
        : ""
    }`}
    to={questionPath(enrolment?.id, question, answers)}
  >
    <div
      className={`${questionClass(enrolment, question, answers)} ${
        i == 0 ? "" : "ruler"
      }`}
    >
      <div className="divider" />
      <Popup
        basic
        key={question.id}
        style={{
          color: "white",
          backgroundColor: "#392d40"
        }}
        trigger={
          <div className="circle">
            <div className="text">
              {renderQuestionState(
                enrolment,
                chapter.next_question_path,
                question,

                additionalNum ? additionalNum + i : i,
                answers
              )}
            </div>
          </div>
        }
        wide
      >
        <Popup.Content>
          {question.name} - {question.length_minutes} minutes
        </Popup.Content>
      </Popup>
    </div>
  </Link>
);

const Chapters = ({ enrolment, chapters, answers }) => (
  <div className="chapters">
    {chapters.map((chapter, chapIdx) => (
      <div
        key={chapIdx}
        className={`chapter ${chapterClass(chapter)} ${
          chapIdx > 0 ? "chapter-target" : ""
        }`}
      >
        <aside className="top section-one">
          <div className="number">
            <div className="text">
              <p>{String(chapIdx + 1).padStart(2, "0")}</p>
            </div>
            {chapter.completed && (
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14" cy="14" r="14" fill="#36CBBD" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.2559 8.41107C21.5814 8.73651 21.5814 9.26414 21.2559 9.58958L12.0893 18.7562C11.7638 19.0817 11.2362 19.0817 10.9107 18.7562L6.74408 14.5896C6.41864 14.2641 6.41864 13.7365 6.74408 13.4111C7.06951 13.0856 7.59715 13.0856 7.92259 13.4111L11.5 16.9885L20.0774 8.41107C20.4028 8.08563 20.9305 8.08563 21.2559 8.41107Z"
                  fill="white"
                />
              </svg>
            )}
          </div>

          {renderProgressBar(chapters.length, chapter, chapIdx)}
        </aside>
        <div className="section-two">
          <div className="ui chapter-header">
            <div className="left">
              <h3>{chapter.name}</h3>
              <div>
                <span className="ui label">
                  {chapter.answer_count}/{chapter.question_count} completed
                </span>
              </div>
            </div>
            {!chapter.completed && (
              <div>
                <Link
                  to={chapter.next_question_path + "/answer"}
                  className={`ui traditional button ${
                    chapter.started ? "continue" : "start"
                  } ${enrolment?.locked ? "click-disabled" : ""}`}
                >
                  {chapter.started ? "Continue" : "Start"}
                </Link>
              </div>
            )}
          </div>
          <div className="description">{chapter.description} </div>
          <div className="desktop">
            <div className="topics">
              {chapter.questions.map((question, i) => (
                <Topic
                  key={i}
                  question={question}
                  i={i}
                  additionalNum={0}
                  chapter={chapter}
                  answers={answers}
                  enrolment={enrolment}
                />
              ))}
            </div>
          </div>
          <div className="mobile-small">
            <div className="topics">
              {chapter.questions.slice(0, 6).map((question, i) => (
                <Topic
                  key={i}
                  question={question}
                  i={i}
                  additionalNum={0}
                  chapter={chapter}
                  answers={answers}
                  enrolment={enrolment}
                />
              ))}
            </div>
            <div className="topics" style={{ marginTop: "15px" }}>
              {chapter.questions
                .slice(6, chapter.questions.length)
                .map((question, i) => (
                  <Topic
                    key={i}
                    question={question}
                    i={i}
                    additionalNum={6}
                    chapter={chapter}
                    answers={answers}
                    enrolment={enrolment}
                  />
                ))}
            </div>
          </div>
          {chapIdx === 0 && <div className="topic-tour" />}
          <hr className="chapter-divider" />
        </div>
      </div>
    ))}
  </div>
);

export default Chapters;
