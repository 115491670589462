export interface Topic {
  id: number;
  uuid: string;
  name: string;
}

export interface TopicState {
  topics: Topic[];
  loading: boolean;
  selectedTopicUuid: string;
  error: string;
}

export const LOAD_TOPICS = "LOAD_TOPICS";
export const LOAD_TOPICS_SUCCESS = "LOAD_TOPICS_SUCCESS";
export const LOAD_TOPICS_FAILURE = "LOAD_TOPICS_FAILURE";
export const SET_TOPIC = "SET_TOPIC";

export interface LoadTopicsAction {
  type: typeof LOAD_TOPICS;
}

export interface LoadTopicsSuccessAction {
  type: typeof LOAD_TOPICS_SUCCESS;
  payload: Topic[];
}

export interface LoadTopicsFailureAction {
  type: typeof LOAD_TOPICS_FAILURE;
  payload: string;
}

export interface SetTopicAction {
  type: typeof SET_TOPIC;
  topicUuid: string;
}

export type TopicActionTypes =
  | LoadTopicsAction
  | LoadTopicsSuccessAction
  | LoadTopicsFailureAction
  | SetTopicAction;
