import React from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, Progress, Popup } from "semantic-ui-react";
import { PieChart } from "react-minimal-pie-chart";
import { QuestionPreview } from "../../../stores/CoursePreviewStore/Types";
import { Enrolment } from "../../../stores/EnrolmentStore/Types";

interface Props {
  showSidebar: boolean;
  handleHideSidebar: () => void;
  lesson: QuestionPreview;
  enrolment: Enrolment;
  chapters: any;
  answers: any;
}

const chapterClass = chapter => {
  if (chapter.completed) {
    return "completed";
  } else {
    return "in-progress";
  }
};

const questionPath = (enrolmentId, question, answers) => {
  let answerIdx = answers.findIndex(v => v.question_id == question?.id);
  const answer = answers[answerIdx];

  if (
    answer &&
    question.kind === "Opinion" &&
    ["pending", "passed"].includes(answer["state"])
  ) {
    return `/enrolments/${enrolmentId}/questions/${question.id}/answers/${answer.id}/votes`;
  } else {
    return `/enrolments/${enrolmentId}/questions/${question.id}/answer`;
  }
};

const questionClass = (enrolment, question, answers, currentLessonId) => {
  let answerIdx = answers.findIndex(v => v.question_id == question?.id);

  if (
    answerIdx == -1 &&
    (enrolment.locked || (enrolment.preview && !question?.preview))
  ) {
    return "locked";
  } else if (question?.id == currentLessonId) {
    return "current";
  } else if (answerIdx == -1) {
    return "not-started";
  } else {
    return answers[answerIdx]["state"];
  }
};

const renderQuestionState = (
  enrolment,
  nextQuestionPath,
  question,
  currentLesson,
  i,
  answers
) => {
  if (question?.id == currentLesson?.id) {
    return <img src={enrolment?.user?.profile_picture_url} />;
  }

  let questionState = questionClass(
    enrolment,
    question,
    answers,
    currentLesson?.id
  );

  switch (questionState) {
    case "not-started":
      return i + 1;
    case "pending":
    case "submitted":
      return <i className="ellipsis horizontal icon"></i>;
    case "failed":
      return <i className="close icon"></i>;
    case "passed":
      return <i className="check icon"></i>;
    case "locked":
      return <i className="lock icon"></i>;
    default:
      return i + 1;
  }
};

const renderProgressBar = (chapterSize, chapter, i) => {
  let finalChapter = i == chapterSize - 1;
  const progressClass = finalChapter ? "no-progress" : "progress";
  return (
    <>
      <div
        className={`desktop ${progressClass} ${
          chapter.questions.length > 7 ? "desktop-long-progress" : ""
        }`}
      />
      <div
        className={`mobile ${progressClass} ${
          chapter.questions.length > 5 ? "mobile-long-progress" : ""
        }`}
      />
    </>
  );
};

const Topic = ({
  currentLesson,
  question,
  i,
  additionalNum,
  answers,
  enrolment,
  chapter,
  handleHideSidebar
}) => (
  <Link
    className={`${
      questionClass(enrolment, question, answers, currentLesson?.id) == "locked"
        ? "click-disabled"
        : ""
    }`}
    to={questionPath(enrolment?.id, question, answers)}
    onClick={() => handleHideSidebar()}
  >
    <div
      className={`${questionClass(
        enrolment,
        question,
        answers,
        currentLesson?.id
      )} ${i == 0 ? "" : "ruler"}`}
    >
      <div className="divider" />
      <Popup
        basic
        key={question.id}
        style={{
          color: "white",
          backgroundColor: "#392d40"
        }}
        trigger={
          <div className="circle">
            <div className="text">
              {renderQuestionState(
                enrolment,
                chapter.next_question_path,
                question,
                currentLesson,
                additionalNum ? additionalNum + i : i,
                answers
              )}
            </div>
          </div>
        }
        wide
      >
        <Popup.Content>
          {question.name} - {question.length_minutes} minutes
        </Popup.Content>
      </Popup>
    </div>
  </Link>
);

const LessonSidebar: React.FC<Props> = ({
  lesson,
  enrolment,
  chapters,
  answers,
  showSidebar,
  handleHideSidebar
}) => {
  return (
    <Sidebar
      className="lesson-sidebar"
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      onHide={() => handleHideSidebar()}
      vertical
      visible={showSidebar}
      width="thin"
    >
      <div className="ui one column stackable grid container">
        <div className="header menu">
          <div className="close">
            <a
              className="ui left button"
              id="closeNav"
              onClick={() => handleHideSidebar()}
            >
              <i className="close icon"></i>
            </a>
          </div>
        </div>
        <div className="ui two column stackable grid container menu-container">
          <div className="menu progress">
            <PieChart
              data={[
                { value: enrolment?.percentage_completed, color: "#36CBBD" },
                {
                  label: "",
                  value: 100 - enrolment?.percentage_completed,
                  isEmpty: true,
                  color: "#EFF0F4"
                }
              ]}
              totalValue={100}
              lineWidth={20}
              label={() => `${enrolment?.percentage_completed}%`}
              labelStyle={{
                fontSize: "25px",
                fill: "#40444E"
              }}
              labelPosition={0}
              startAngle={270}
            />
            <div className="next">
              <div className="course-title">{enrolment?.course.name}</div>
              <div className="home-link">
                <Link
                  to={{ pathname: `/enrolments/${enrolment?.id}` }}
                  onClick={() => handleHideSidebar()}
                >
                  <button className="button">
                    <span>Course</span>
                    <i className="home icon" />
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="menu course-chapters">
            {chapters.map((chapter, i) => (
              <div className={`chapter ${chapterClass(chapter)}`} key={i}>
                <aside className="top section-one">
                  <div className="number">
                    <div className="text">
                      <p>{String(i + 1).padStart(2, "0")}</p>
                    </div>
                    {chapter.completed && (
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="14" cy="14" r="14" fill="#36CBBD" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M21.2559 8.41107C21.5814 8.73651 21.5814 9.26414 21.2559 9.58958L12.0893 18.7562C11.7638 19.0817 11.2362 19.0817 10.9107 18.7562L6.74408 14.5896C6.41864 14.2641 6.41864 13.7365 6.74408 13.4111C7.06951 13.0856 7.59715 13.0856 7.92259 13.4111L11.5 16.9885L20.0774 8.41107C20.4028 8.08563 20.9305 8.08563 21.2559 8.41107Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </div>

                  {renderProgressBar(chapters.length, chapter, i)}
                </aside>

                <div className="section-two">
                  <div className="ui chapter-header">
                    <div className="left">
                      <h3>{chapter.name}</h3>
                    </div>
                  </div>
                  <div className="desktop">
                    {chapter.questions.length <= 7 ? (
                      <div className="topics">
                        {chapter.questions.map((question, i) => (
                          <Topic
                            key={i}
                            question={question}
                            currentLesson={lesson}
                            i={i}
                            additionalNum={0}
                            chapter={chapter}
                            answers={answers}
                            enrolment={enrolment}
                            handleHideSidebar={handleHideSidebar}
                          />
                        ))}
                      </div>
                    ) : (
                      <>
                        <div className="topics">
                          {chapter.questions.slice(0, 6).map((question, i) => (
                            <Topic
                              key={i}
                              question={question}
                              currentLesson={lesson}
                              i={i}
                              additionalNum={0}
                              chapter={chapter}
                              answers={answers}
                              enrolment={enrolment}
                              handleHideSidebar={handleHideSidebar}
                            />
                          ))}
                        </div>
                        <div className="topics" style={{ marginTop: "15px" }}>
                          {chapter.questions
                            .slice(6, chapter.questions.length)
                            .map((question, i) => (
                              <Topic
                                key={i}
                                question={question}
                                currentLesson={lesson}
                                i={i}
                                additionalNum={6}
                                chapter={chapter}
                                answers={answers}
                                enrolment={enrolment}
                                handleHideSidebar={handleHideSidebar}
                              />
                            ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="mobile">
                    <div className="topics">
                      {chapter.questions.slice(0, 5).map((question, i) => (
                        <Topic
                          key={i}
                          question={question}
                          currentLesson={lesson}
                          i={i}
                          additionalNum={0}
                          chapter={chapter}
                          answers={answers}
                          enrolment={enrolment}
                          handleHideSidebar={handleHideSidebar}
                        />
                      ))}
                    </div>
                    <div className="topics" style={{ marginTop: "15px" }}>
                      {chapter.questions
                        .slice(5, chapter.questions.length)
                        .map((question, i) => (
                          <Topic
                            key={i}
                            question={question}
                            currentLesson={lesson}
                            i={i}
                            additionalNum={5}
                            chapter={chapter}
                            answers={answers}
                            enrolment={enrolment}
                            handleHideSidebar={handleHideSidebar}
                          />
                        ))}
                    </div>
                  </div>
                  <hr className="chapter-divider" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default LessonSidebar;
