import React, { Component } from "react";
import { observer } from "mobx-react";
import { Carousel } from "../common";
import Course from "./Course";
import { getRequest } from "../../utils/httpRequest";
import { AxiosResponse } from "axios";
import { EnrolmentsResource } from "../../stores/EnrolmentStore/Types";

interface State {
  completedCourses: EnrolmentsResource;
  loaded: boolean;
}

class CompletedCourses extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      completedCourses: [],
      loaded: false
    };
  }

  componentDidMount() {
    return getRequest("/api/profile/completed.json").then(
      (response: AxiosResponse<EnrolmentsResource>) => {
        this.setState({ completedCourses: response.data, loaded: true });
      }
    );
  }

  render() {
    const { loaded, completedCourses } = this.state;

    return (
      <>
        {!loaded && (
          <div className="courses featured-courses">
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          </div>
        )}

        {completedCourses.length > 0 && (
          <>
            {completedCourses.length > 2 ? (
              <div
                className="ui segment courses featured-courses"
                style={{ paddingTop: "40px" }}
              >
                <div className="ui container">
                  <h1 className="ui header aligned course-header featured-course-header">
                    <span className="title">Completed courses</span>
                  </h1>

                  <div className="courses-container featured-courses-container ui inverted vertical segment center aligned">
                    <div
                      className="ui  centered doubling stackable three column"
                      id="courses_grid"
                    >
                      <Carousel
                        noOfMobileSlides={2}
                        dots={true}
                        slidesToScroll={3}
                        numberOfSlides={
                          completedCourses.length < 3
                            ? completedCourses.length
                            : 3
                        }
                        arrows={true}
                      >
                        {completedCourses.map((enrolment, i) => (
                          <Course
                            enrolment={enrolment}
                            idx={i}
                            key={enrolment.course.id}
                          />
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="ui segment courses">
                <div className="ui container">
                  <h1 className="ui header aligned course-header">
                    <span className="title">Completed courses</span>
                  </h1>

                  <div className="courses-container featured-courses-container ui inverted vertical segment center aligned">
                    <div
                      className="ui grid doubling stackable three column"
                      id="courses_grid"
                    >
                      {completedCourses.map((enrolment, i) => (
                        <Course
                          enrolment={enrolment}
                          idx={i}
                          key={enrolment.course.id}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
export default observer(CompletedCourses);
