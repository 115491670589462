import React, { Component } from "react";
import { AxiosResponse } from "axios";
import Landing from "./Landing";
import Learning from "./Learning";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { errorNotification } from "../../utils/helpers";
import { OngoingCourse } from "../../stores/EnrolmentStore/Types";
import NewReviewModal from "./NewReviewModal";
import PersonalizationModal from "./PersonalizationModal";

interface Props {
  match: any;
  mode: string;
}

interface State {
  enrolment: OngoingCourse;
  loading: boolean;
  showReviewModal: boolean;
}

class Enrolment extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      enrolment: null,
      loading: true,
      showReviewModal: false
    };
  }

  componentDidMount() {
    const enrolmentId = this.props.match.params.enrolmentId;

    return getRequest(`/api/enrolments/${enrolmentId}.json`)
      .then((response: AxiosResponse) => {
        if (response.data.state === "passed") {
          this.handleShowReviewModal();
        } else if (response.data.embedded_host_url) {
          window.location = response.data.embedded_host_url;
        }

        this.setState({ enrolment: response.data, loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });

        errorNotification(
          "Error loading enrolment data",
          JSON.stringify(error?.response?.data)
        );
      });
  }

  handleShowReviewModal = () => {
    setTimeout(() => {
      this.setState({ showReviewModal: true });
    }, 5000);
  };

  handleHideNewReviewModal = () => {
    this.setState({ showReviewModal: false });
  };

  render() {
    const { enrolment, loading, showReviewModal } = this.state;


    return (
      <div className="ui facelift-new-homepage facelift-course-inside">
        <Landing enrolment={enrolment} loading={loading} />
        <div className="enrolment">
          {enrolment && (
            <>
              <Learning enrolment={enrolment} mode={this.props.mode} />
              <NewReviewModal
                enrolmentId={enrolment.id}
                courseName={enrolment.course.name}
                showModal={showReviewModal}
                handleHideNewReviewModal={this.handleHideNewReviewModal}
                refreshCertificate={() => {}}
              />
              {enrolment.personalization_state != 'complete' && <PersonalizationModal enrolment={enrolment} />}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Enrolment;
