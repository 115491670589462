import React from "react";

interface Props {
    email: string,
    isDropdownVisible: boolean;
    onSelect: any;
    setFieldValue?: (field: string, value: string) => void;
    style?: React.CSSProperties
}

const EmailSuggestions: React.FC<Props> = ({
  email,
  setFieldValue,
  isDropdownVisible,
  onSelect,
  style
}) => {
  const domainOptions = ["gmail.com", "hotmail.com", "yahoo.com"];

  if (!email.includes("@") || !isDropdownVisible || email.startsWith("@")) {
    return null;
  }

  const emailPrefix = email.split("@")[0];
  const typedDomain = email.split("@")[1] || "";

  const filteredDomains = domainOptions.filter(domain => domain.startsWith(typedDomain))

  return (
    <div className="suggestion_box">
      <ul
        className={`suggestion_box-dropdown ${isDropdownVisible ? "suggestion_box-dropdown--after-open" : "suggestion_box-dropdown--before-close"}`}
        style={style}
      >
        {filteredDomains.map((domain) => (
          <li
            key={domain}
            onClick={() => onSelect(`${emailPrefix}@${domain}`, setFieldValue)}
          >
            {emailPrefix}@{domain}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EmailSuggestions;
