import React, { Component } from "react";
import { observer } from "mobx-react";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import LoginStore from "../../../../stores/LoginStore";

interface Props {
  loginStore: LoginStore;
  loginUser: (e: React.MouseEvent<HTMLElement>) => void;
}

class Forms extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  renderForms = () => {
    const { loginStore, loginUser } = this.props;
    const { state } = loginStore;
    switch (state.kind) {
      case "ready":
      case "saving":
      case "readyWithErrors": {
        switch (state.mode) {
          case "login":
            return <LoginForm store={loginStore} loginUser={loginUser} />;

          case "signUp":
            return <SignupForm store={loginStore} loginUser={loginUser} />;
        }
      }

      default:
        return <div />;
    }
  };

  render() {
    const { loginStore } = this.props;
    return (
      <div className="forms">
        <div className="forceHide login-error" id="new-login-errors">
          <i className="warning icon"></i>
          <span></span>
        </div>
        <div className="ui secondary pointing menu center aligned tabs-menu ">
          <a
            className={`item ${loginStore.loginState ? "active" : ""}`}
            data-tab="login"
            onClick={() => loginStore.login()}
          >
            Existing User?
          </a>
          <a
            className={`item ${loginStore.signUpState ? "active" : ""}`}
            data-tab="registration"
            onClick={() => loginStore.signUp()}
          >
            New User?
          </a>
        </div>
        {this.renderForms()}
      </div>
    );
  }
}

export default observer(Forms);
