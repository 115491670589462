import React from "react";
import { Priceable } from "../../../Types";
import { Quotation } from "../../../../../stores/CoursePreviewStore/Types";
import Coupon from "../Coupon";

interface Props {
  priceable: Priceable;
  activeQuote: Quotation;
  couponCode: string;
  refCode: string;
  amount: string;
  discountAmount: string;
  monthlySubsequentPayment: string;
}

const Monthly: React.FC<Props> = ({
  priceable,
  activeQuote,
  couponCode,
  refCode,
  amount,
  discountAmount,
  monthlySubsequentPayment
}) => {
  return (
    <div className="extra content">
      <>
        {(couponCode?.length > 0 || refCode?.length > 0) && (
          <div className="price month discount">
            <div className="name">
              <strong>Discount:</strong>
            </div>
            <div className="discount-info">
              <span className="minus">-</span>{" "}
              <span className="symbol">{activeQuote?.currency}</span>
              <span className="amount">
                <strong>{discountAmount}</strong>
              </span>{" "}
              <span className="coupon-name">({couponCode || refCode})</span>
            </div>
          </div>
        )}
        {activeQuote?.amount > 0 && (
          <>
            <div className="price month">
              <div className="payment-due">
                <strong>First payment due:</strong>
              </div>
              <div className="payment-info">
                <span className="symbol">{activeQuote?.currency}</span>
                <span className="amount">
                  <strong>{activeQuote?.formatted_amount}</strong>
                </span>
                <span className="code">{activeQuote?.currency_code}</span>
              </div>
            </div>
            <div className="price month instalment">
              <div className="payment-due-instalment">
                <strong>Followed by:</strong>
              </div>
              <div className="payment-info-instalment">
                <span>11 monthly instalments of</span>{" "}
                <span className="symbol">{activeQuote?.currency}</span>
                <span className="amount">{monthlySubsequentPayment}</span>
              </div>
            </div>
          </>
        )}
        <hr
          style={{
            background: "#dadada",
            marginTop: "1em"
          }}
        />
        <div className="price month total">
          <div className="payment-due">
            <strong>Total:</strong>
          </div>
          <div className="payment-info">
            <span className="symbol">{activeQuote?.currency}</span>
            <span className="amount">
              <strong>{amount}</strong>
            </span>
            <span className="code">{activeQuote?.currency_code}</span>
          </div>
        </div>
      </>
    </div>
  );
};

export default Monthly;
