import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { AxiosResponse, AxiosError } from "axios";
import { CSVReader } from "react-papaparse";
import { notification } from "../../../utils/helpers";
import { postRequest } from "../../../utils/httpRequest";
import { CurrentTeam, TeamResource } from "../../../stores/TeamStore/Types";

interface Props {
  teamResource: TeamResource;
  showAllocateUserModal: boolean;
  handleShowAllocateUserModal: (val: boolean) => void;
  handleShowSettingsModal: (val: boolean) => void;
  hideModals: () => void;
  getHeroes: () => void;
}

interface User {
  name: string;
  email: string;
}

interface State {
  emails: string;
  importedUsers: User[];
  importing: boolean;
  [x: string]: any;
}

export interface FileData {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export interface IRawUploadedData {
  data: [string, string];
}

class AllocateUsers extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      emails: "",
      importedUsers: [],
      importing: false
    };
  }

  handleChange(key, e) {
    this.setState({ [key]: e.target.value });
  }

  fileExtension = (fileName: string) => {
    return fileName
      ?.split(".")
      ?.pop()
      ?.toLowerCase();
  };

  isCSVFile = (fileName: string): boolean =>
    this.fileExtension(fileName) === "csv";

  private handleOnDrop = (data, file) => {
    if (this.isCSVFile(file.name)) {
      data.shift();
      const importedUsers = data.map(d => {
        return {
          name: d.data[0],
          email: d.data[1]
        };
      });

      this.setState({ importedUsers });
    } else {
      this.setState({
        importError:
          "Looks like this is not a valid CSV file. Please make sure to save your file as a CSV"
      });
    }
  };

  cancel = () => {
    const { handleShowAllocateUserModal, handleShowSettingsModal } = this.props;

    this.setState({ emails: "", importedUsers: [], importing: false });
    handleShowAllocateUserModal(false);
    handleShowSettingsModal(true);
  };

  submit = e => {
    const {
      teamResource,
      handleShowAllocateUserModal,
      handleShowSettingsModal,
      getHeroes
    } = this.props;
    const { emails, importedUsers } = this.state;

    const data = {
      import_members: {
        emails,
        users: importedUsers
      }
    };

    e.preventDefault();
    this.setState({ importing: true });

    return postRequest(
      `/api/teams/${teamResource.slug}/import_members.json`,
      data
    )
      .then((response: AxiosResponse) => {
        getHeroes();
        notification("User import successful!", "notification");
        handleShowAllocateUserModal(false);
        handleShowSettingsModal(true);
        this.setState({
          importing: false,
          emails: "",
          users: []
        });
      })
      .catch((error: AxiosError<{ error: string }>) => {
        notification(error.response.data.error, "error");

        this.setState({ importing: false });
      });
  };

  render() {
    const {
      showAllocateUserModal,
      handleShowAllocateUserModal,
      handleShowSettingsModal,
      hideModals
    } = this.props;

    const { emails, importedUsers, importing } = this.state;

    return (
      <>
        <Modal
          open={showAllocateUserModal}
          className="profile-settings-modal password-settings-modal"
          id="allocate-users-modal"
        >
          <Modal.Header>
            <div className="close">
              <a
                className="ui left button"
                id="closeNav"
                onClick={() => handleShowSettingsModal(true)}
              >
                <i className="angle left icon" />
              </a>
            </div>
            <h2>It’s time to add some users</h2>
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="ui allocate-users">
              <form className="simple_form ui form">
                <div className="label">Add user emails (comma separated)</div>
                <div className="field ui">
                  <div className="field text required import_members_emails">
                    <textarea
                      className="text required"
                      value={emails}
                      onChange={this.handleChange.bind(this, "emails")}
                    ></textarea>
                  </div>
                </div>
                <p>Or Upload Via CSV</p>
                <div className="field uploadButton">
                  <div className="field file required import_members_csv_file">
                    <CSVReader
                      onDrop={this.handleOnDrop}
                      style={{}}
                      config={{
                        header: false
                      }}
                      addRemoveButton
                    >
                      <span>Drop CSV file here or click to upload.</span>
                    </CSVReader>
                  </div>
                </div>
                {importedUsers.length > 0 && (
                  <div className="sample_csv">
                    <table className="ui very basic collapsing celled structured table">
                      <thead>
                        <tr>
                          <th colSpan={2}>Users</th>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {importedUsers.map(user => (
                          <tr>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="sample_csv">
                  <table className="ui very basic collapsing celled structured table">
                    <thead>
                      <tr>
                        <th colSpan={2}>Sample csv formatting</th>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>John Doe</td>
                        <td>john@example.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <br />
              </form>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="ui segment">
              <button
                className="ui right large button cancel"
                onClick={this.cancel}
              >
                Cancel
              </button>
              <button
                className={`ui right large button save ${
                  importing ? "loading" : ""
                }`}
                onClick={this.submit}
              >
                Save
              </button>
            </div>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default AllocateUsers;
