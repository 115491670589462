import React, { Component } from "react";
import { observer } from "mobx-react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import SignInSidebar from "./SignInSidebar";
import LoggedInSidebar from "./LoggedInSidebar";
import ModalStore from "../../stores/ModalStore";
import LoginStore from "../../stores/LoginStore";
import ProfileStore from "../../stores/ProfileStore";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";

interface Props {
  showSignInSidebar: boolean;
  showLoggedInSidebar: boolean;
  redirectUrl: string;
  handleShowSignInSideBar: () => void;
  handleHideSignInSideBar: () => void;
  handleShowLoggedInSideBar: () => void;
  handleHideLoggedInSideBar: () => void;
  getCurrentUser: () => void;
  onClickHeaderLogo: () => void;
  loginUser: (e: React.MouseEvent<HTMLElement>) => void;
  loginStore: LoginStore;
  currentUser: CurrentUserResource;
  profileStore: ProfileStore;
  handleShowNotificationModal: (val: boolean) => void;
  showNotificationModal: boolean;
  layout: string;
  userXp: number;
}

class DefaultNavbar extends Component<Props, {}> {
  modalStore = new ModalStore();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.modalStore.ready();
  }

  render() {
    const {
      showSignInSidebar,
      showLoggedInSidebar,
      redirectUrl,
      handleShowSignInSideBar,
      handleHideSignInSideBar,
      handleShowLoggedInSideBar,
      handleHideLoggedInSideBar,
      loginStore,
      loginUser,
      onClickHeaderLogo,
      currentUser,
      profileStore,
      getCurrentUser,
      handleShowNotificationModal,
      showNotificationModal,
      layout,
      userXp
    } = this.props;

    return (
      <div className="newNavs ui menu">
        <DesktopNav
          handleShowSignInSideBar={handleShowSignInSideBar}
          handleShowLoggedInSideBar={handleShowLoggedInSideBar}
          onClickHeaderLogo={onClickHeaderLogo}
          currentUser={currentUser}
          layout={layout}
          getCurrentUser={getCurrentUser}
          handleShowNotificationModal={handleShowNotificationModal}
          showNotificationModal={showNotificationModal}
          userXp={userXp}
        />
        <MobileNav
          handleShowSignInSideBar={handleShowSignInSideBar}
          handleHideSignInSideBar={handleHideSignInSideBar}
          handleShowLoggedInSideBar={handleShowLoggedInSideBar}
          handleHideLoggedInSideBar={handleHideLoggedInSideBar}
          onClickHeaderLogo={onClickHeaderLogo}
          currentUser={currentUser}
          getCurrentUser={getCurrentUser}
          handleShowNotificationModal={handleShowNotificationModal}
          showNotificationModal={showNotificationModal}
          userXp={userXp}
        />
        <SignInSidebar
          showSignInSidebar={showSignInSidebar}
          redirectUrl={redirectUrl}
          handleHideSignInSideBar={handleHideSignInSideBar}
          modalStore={this.modalStore}
          loginStore={loginStore}
          loginUser={loginUser}
        />
        <LoggedInSidebar
          showLoggedInSidebar={showLoggedInSidebar}
          handleHideLoggedInSideBar={handleHideLoggedInSideBar}
          profileStore={profileStore}
          getCurrentUser={getCurrentUser}
          currentUser={currentUser}
          userXp={userXp}
        />
      </div>
    );
  }
}

export default observer(DefaultNavbar);
