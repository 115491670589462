import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { AxiosResponse } from "axios";
import {
  getRequest,
  postRequest,
  deleteRequest
} from "../../../../utils/httpRequest";
import { errorNotification } from "../../../../utils/helpers";
import { CommentResource } from "../../../../stores/CommentStore/Types";
import { Answer, Enrolment } from "../../../../stores/EnrolmentStore/Types";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";
import Comments from "./Comments";
import Comment from "../Conversations/Comment";

interface Props {
  answer: Answer;
  originalAnswer: Answer;
  enrolmentId: number;
  answerUpvoteCount?: number;
  idx: number;
  lessonId: number;
  answerId: number;
  answerState: string;
  answerUpvoteEnrolmentIds: number[];
  updateAnswerState: (val: string) => void;
  updateUpvoteCount: (
    idx: number,
    count: number,
    newEnrolmentIds: number[]
  ) => void;
  updateEnrolment: (enrolment: Enrolment) => void;
  updateUpvoteComments: (idx: number, answer: Answer) => void;
  currentUser: CurrentUser;
  search: string;
}

interface State {
  voting: boolean;
  selected: boolean;
  count: number;
  upVoteId: string;
}

class AnswerUpvote extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      voting: false,
      selected: false,
      count: props.answerUpvoteCount,
      upVoteId: ""
    };
  }

  upvote = e => {
    e.preventDefault();

    const {
      answerId,
      enrolmentId,
      lessonId,
      updateAnswerState,
      answer,
      idx,
      updateUpvoteCount,
      updateEnrolment
    } = this.props;

    let { answerUpvoteCount, answerUpvoteEnrolmentIds } = this.props;

    this.setState({ voting: true });

    const data = {
      answer_upvote: {
        answer_id: answer.id
      }
    };

    return postRequest(
      `/api/enrolments/${enrolmentId}/questions/${lessonId}/answer/answer_upvotes?answer_id=${answerId}`,
      data
    )
      .then((response: AxiosResponse) => {
        let newCount = answerUpvoteCount + 1;
        let newEnrolmentIds = answerUpvoteEnrolmentIds.concat(
          Number(enrolmentId)
        );

        this.setState({
          count: newCount,
          voting: false,
          selected: true,
          upVoteId: response.data.id
        });
        updateAnswerState(response.data.answer_state);
        updateEnrolment(response.data.enrolment);
        updateUpvoteCount(idx, newCount, newEnrolmentIds);
      })
      .catch(error => {
        this.setState({ selected: false, voting: false });

        errorNotification(
          "Error upvoting",
          JSON.stringify(error?.response?.data)
        );
      });
  };

  downvote = e => {
    e.preventDefault();

    const {
      enrolmentId,
      lessonId,
      updateAnswerState,
      idx,
      updateUpvoteCount
    } = this.props;

    let { answerUpvoteCount, answerUpvoteEnrolmentIds } = this.props;

    this.setState({ voting: true });

    return deleteRequest(
      `/api/enrolments/${enrolmentId}/questions/${lessonId}/answer/answer_upvotes/${this.state.upVoteId}`
    )
      .then((response: AxiosResponse) => {
        let newCount = answerUpvoteCount === 0 ? 0 : answerUpvoteCount - 1;
        let newEnrolmentIds = answerUpvoteEnrolmentIds.filter(
          id => id !== Number(enrolmentId)
        );

        this.setState({
          count: newCount,
          voting: false,
          selected: false
        });
        updateAnswerState(response.data.answer_state);
        updateUpvoteCount(idx, newCount, newEnrolmentIds);
      })
      .catch(error => {
        this.setState({ selected: false, voting: false });
        console.log(error);
        errorNotification(
          "Error downvoting",
          JSON.stringify(error?.response?.data)
        );
      });
  };

  render() {
    const {
      idx,
      answerId,
      answer,
      answerState,
      originalAnswer,
      answerUpvoteCount,
      answerUpvoteEnrolmentIds,
      enrolmentId,
      lessonId,
      currentUser,
      search,
      updateUpvoteComments,
      answer: { comments }
    } = this.props;

    const { voting, count } = this.state;

    const selected = answerUpvoteEnrolmentIds.includes(Number(enrolmentId));

    return (
      <div className="ui container answer">
        <div className="answer__body-wrapper">
          <div className="answer__body">
            <div className="answer__body--avatar"></div>
            <div className="answer__body--content">
              <div className="name-container">
                <span className="name">{answer.user_name}</span>
                <span className="time">{answer.created_at}</span>
              </div>
              {/* @ts-ignore */}
              <div className="content">
                {/* @ts-ignore */}
                {answer.content_url ? (
                  <div className="answer-upvote-image">
                    <a
                      target="_blank"
                      href={answer.content_url}
                      style={{ margin: "20px 0", display: "block" }}
                    >
                      <i className="icon attach"></i>
                      {answer.attachment_file_name}
                    </a>
                    <img src={answer.content_url} className="ui image large" />
                  </div>
                ) : (
                  ReactHtmlParser(answer.formatted_content)
                )}
              </div>
            </div>
          </div>
          {answerState === "passed" || originalAnswer?.state === "passed" ? (
            <div className="action">
              <button className="ui button" disabled={true}>
                <i className="thumbs up icon"></i>

                <span className="count">{answerUpvoteCount}</span>
              </button>
            </div>
          ) : (
            <div className="action">
              {selected ? (
                <button
                  className={`ui button ${selected ? "selected" : ""} ${
                    voting ? "loading" : ""
                  }`}
                  onClick={this.downvote}
                >
                  <i className="icon undo"></i>

                  <span className="count">{answerUpvoteCount}</span>
                </button>
              ) : (
                <button
                  className={`ui button ${selected ? "selected" : ""} ${
                    voting ? "loading" : ""
                  }`}
                  onClick={this.upvote}
                >
                  <i className="thumbs up icon"></i>
                  <span className="count">{answerUpvoteCount}</span>
                </button>
              )}
            </div>
          )}
        </div>
        <Comments
          idx={idx}
          answer={answer}
          enrolmentId={enrolmentId}
          answerId={answerId}
          lessonId={lessonId}
          currentUser={currentUser}
          comments={comments}
          search={search}
          updateUpvoteComments={updateUpvoteComments}
        />
      </div>
    );
  }
}

export default AnswerUpvote;
