import React, { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { putRequest } from "../../utils/httpRequest";

interface Props {
  match: any;
}

interface State {
  password: string;
  passwordConfirmation: string;
  message: string;
  errorMessage: string;
  saving: boolean;
  passwordError: string;
  passwordConfirmationError: string;
  [x: number]: any;
}

class ResetPassword extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
      errorMessage: "",
      message: "",
      saving: false,
      passwordError: "",
      passwordConfirmationError: ""
    };
  }

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    this.setState({
      errorMessage: "",
      passwordError: "",
      passwordConfirmationError: ""
    });

    const id = this.props.match.params.id;

    const { password, passwordConfirmation } = this.state;

    if (!password) {
      this.setState({ passwordError: "Password required" });

      return;
    }

    if (!passwordConfirmation) {
      this.setState({
        passwordConfirmationError: "Password confirmation required"
      });

      return;
    }

    const data = {
      session: {
        password,
        password_confirmation: passwordConfirmation
      }
    };

    this.setState({ saving: true });

    putRequest(`/session/password_resets/${id}`, data)
      .then((response: AxiosResponse) => {
        this.setState({ saving: false });

        let message;

        if (response.data.status === "token_expired") {
          message = "Password reset token expired!";
        }

        if (response.data.status === "success") {
          message = "Password was successfully updated.";
        }

        this.setState({ message, password: "", passwordConfirmation: "" });
        setTimeout(() => {
          (window as any).location = "/";
        }, 3000);
      })
      .catch((error: AxiosError<{ error: string }>) => {
        this.setState({
          saving: false,
          errorMessage: error?.response?.data?.error
        });
        console.log(error);
      });
  };

  onClose = () => {
    (window as any).location = "/";
  };

  onCloseErrorMessage = e => {
    e.preventDefault();

    this.setState({ errorMessage: "" });
  };

  render() {
    const {
      password,
      passwordConfirmation,
      message,
      errorMessage,
      passwordError,
      passwordConfirmationError,
      saving
    } = this.state;

    return (
      <div className="facelift-new-homepage forgot-password-page">
        <div className="ui basic segment">
          <div className="ui text container center aligned">
            {message && (
              <div className="ui info message">
                <i className="close icon" onClick={this.onClose}></i>

                <div className="header">{message}</div>
              </div>
            )}
            <form className="ui form">
              <p className="instructions">
                Almost there. Enter your new password
              </p>
              {errorMessage && (
                <div className="ui negative message">
                  <i
                    className="close icon"
                    onClick={this.onCloseErrorMessage}
                  ></i>

                  <div className="header">{errorMessage}</div>
                </div>
              )}
              <div className="field">
                <div className="ui left icon action input">
                  <input
                    type="password"
                    placeholder="Your new password"
                    value={password}
                    onChange={e => this.handleChange(e, "password")}
                  />
                </div>
                {passwordError && (
                  <div className="ui red pointing above label error">
                    {passwordError}
                  </div>
                )}
              </div>
              <div className="field">
                <div className="ui left icon action input">
                  <input
                    type="password"
                    value={passwordConfirmation}
                    placeholder="Confirm your new password"
                    onChange={e => this.handleChange(e, "passwordConfirmation")}
                  />
                </div>
                {passwordConfirmationError && (
                  <div className="ui red pointing above label error">
                    {passwordConfirmationError}
                  </div>
                )}
              </div>
              <div className="action">
                <br />
                <button
                  className={`ui button ${saving ? "loading" : ""}`}
                  onClick={this.onSubmit}
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
