import React, { Component } from "react";
import { observer } from "mobx-react";
import Checkout from "../Checkout";

interface Props {
  match: any;
  selectedCurrency: string;
  location: {
    state: {
      couponCode: string;
      refCode: string;
    };
  };
  currentUser: {
    country: string;
    kind: string;
  };
}

class SubscriptionPay extends Component<Props, {}> {
  render() {
    const slug = this.props.match.params.slug;
    const params = new URLSearchParams(window.location.search);
    const couponCode = params.get("coupon_code");
    const refCode = this.props.location.state?.refCode;

    return (
      <div className="facelift-checkout">
        <Checkout
          slug={slug}
          paymentType="subscription"
          paymentUrl={`/api/passes/${slug}/pay?code=${couponCode}&ref=${refCode}`}
          refCode={refCode}
          couponCode={couponCode}
          selectedCurrency={this.props.selectedCurrency}
          isCurrentUserPresent={this.props.currentUser.kind === "current-user"}
        />
      </div>
    );
  }
}

export default SubscriptionPay;
