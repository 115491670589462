import { observer } from "mobx-react";
import CurrencyStore from ".";
import { CurrencyState, CurrencyPayload } from "./Types";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { updateCurrency } from "./service";
import PricingStore from "../PricingStore";

interface State {
  state: CurrencyState;
}

export interface Props extends BaseReactionProps<typeof CurrencyStore> {
  store: typeof CurrencyStore;
  pricingStore: PricingStore;
  updateSelectedCurrency: (isoCode: string) => void;
}

class CurrencyReactions extends BaseReaction<
  typeof CurrencyStore,
  State,
  Props
> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case "waiting":
      case "loading":
      case "ready":
        break;
      case "saving":
        updateCurrency(
          state.isoCode,
          state.currencyPayload,
          state.modalStore,
          this.props.pricingStore,
          this.props.updateSelectedCurrency,
          store
        );

        break;
    }
  };
}

export default observer(CurrencyReactions);
