import React from "react";
import { Modal } from "semantic-ui-react";
import { notification } from "../../../utils/helpers";

const LinkedInModal = ({
  showModal,
  linkedInShareUrl,
  certificationUrl,
  handleHideLinkedInModal
}) => {
  const copyUrlToClipboard = e => {
    e.preventDefault();
    let copyText = document.querySelector("#linkedin-certification-url");
    (copyText as any).select();
    document.execCommand("copy");
    notification("Copied certificate url to clipboard!", "alert");
  };

  return (
    <Modal
      closeIcon
      open={showModal}
      onClose={e => handleHideLinkedInModal(e)}
      size="tiny"
      className="linkedin-certificate-modal"
    >
      <Modal.Header>
        <h3 className="ui header">Show the world how smart you are!</h3>
      </Modal.Header>
      <Modal.Content>
        <h4 className="ui header explanation">
          LinkedIn doesn't let us auto fill this info for you so you'll need to
          fill in these few things:
        </h4>
        <p>
          <strong>Name</strong>&nbsp;-&nbsp;Enter the course name
        </p>
        <p>
          <strong>Issuing Organisation</strong>&nbsp;-&nbsp;Enter course brand
          name
        </p>
        <p>
          (Tick the "this certification does not expire" box then enter today's
          date)
        </p>
        <p>
          <strong>Credential ID</strong>&nbsp;-&nbsp;Leave blank
        </p>
        <p>
          <strong>Credential url</strong>&nbsp;-&nbsp;Copy the link below
        </p>
        <div className="field">
          <label></label>
          <div className="ui fluid action input">
            <input
              id="linkedin-certification-url"
              readOnly
              type="text"
              value={certificationUrl}
            />
            <button
              className="ui icon labeled  button copy-linkedin-certification-url"
              data-clipboard-target="#linkedin-certification-url"
              onClick={e => copyUrlToClipboard(e)}
            >
              <i className="copy icon"></i>Copy
            </button>
          </div>
        </div>
        <div className="congrats">
          <h4 className="ui header">Bravo and well done again on passing 🎉</h4>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <a
          target="_blank"
          className="ui icon labeled button finish-linkedin-add-certification"
          href={linkedInShareUrl}
        >
          <i className="linkedin square icon"></i>Continue to LinkedIn
        </a>
      </Modal.Actions>
    </Modal>
  );
};

export default LinkedInModal;
