import { observer } from "mobx-react";
import SubscriptionStore from ".";
import { SubscriptionState } from "./Types";
import { AxiosResponse, AxiosError } from "axios";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { postRequest } from "../../utils/httpRequest";
import { formErrors, Errors } from "../../utils/helpers";

interface State {
  state: SubscriptionState;
}

export interface Props extends BaseReactionProps<SubscriptionStore> {
  store: SubscriptionStore;
}

class SubscriptionReactions extends BaseReaction<
  SubscriptionStore,
  State,
  Props
> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    switch (state.kind) {
      case "loading":
        this.props.store.ready({
          username: "",
          email: "",
          firstName: "",
          lastName: ""
        });
        break;
      case "saving":
        const subcriptionBody = {
          subscription: {
            username: state.subscription.username,
            first_name: state.subscription.firstName,
            last_name: state.subscription.lastName,
            email: state.subscription.email
          }
        };
        const subUrl =
          state.subscription.username.length > 0
            ? "/api/spam.json"
            : "/api/subscriptions.json";
        return postRequest(subUrl, subcriptionBody)
          .then((response: AxiosResponse) => {
            this.props.store.subscribed();
          })
          .catch((error: AxiosError<{ errors: Errors }>) => {
            this.props.store.readyWithErrors(
              formErrors(error.response.data.errors)
            );
          });
        break;
      case "waiting":
      case "ready":
      case "readyWithErrors":
      case "subscribed":
        break;
    }
  };
}

export default observer(SubscriptionReactions);
