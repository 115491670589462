import { observer } from "mobx-react";
import ReviewsStore from ".";
import { ReviewsResource, ReviewsState } from "./Types";
import { AxiosResponse, AxiosError } from "axios";
import BaseReaction, { BaseReactionProps } from "../../BaseReaction";
import { Api } from "../../utils/Api";

interface State {
  state: ReviewsState;
}

export interface Props extends BaseReactionProps<ReviewsStore> {
  store: ReviewsStore;
}

class ReviewsReactions extends BaseReaction<ReviewsStore, State, Props> {
  tester = (): State => ({
    state: this.props.store.state
  });

  effect = ({ state }: State) => {
    switch (state.kind) {
      case "loading":
        const client = new Api({});
        client
          .get<ReviewsResource>(`/api/review_highlights.json`)
          .then((response: AxiosResponse<ReviewsResource>) => {
            const { data } = response;

            this.props.store.ready(data);
          })
          .catch((error: AxiosError) => {
            console.log(error.response?.statusText);
          });
        break;

      case "waiting":
      case "ready":
        break;
    }
  };
}

export default observer(ReviewsReactions);
