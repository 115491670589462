import React from "react";

const Learners = () => (
  <div className="learners">
    <div className="ui container">
      <p>
        <strong>Join the ranks</strong> of brands that are already{" "}
        <strong>supercharging their teams’ creativity:</strong>
      </p>
      <div className="images">
        <img
          className="ui tiny image"
          src={require("../../../../../assets/images/teams/logos/google.png")}
          alt="google"
        />
        <img
          className="ui tiny image accenture"
          style={{ marginTop: "-10px" }}
          src={require("../../../../../assets/images/teams/logos/accenture.png")}
          alt="accenture"
        />
        <img
          className="ui small image ideo"
          src={require("../../../../../assets/images/teams/logos/ideo.png")}
          alt="ideo"
        />
        <img
          className="ui tiny image fcb"
          src={require("../../../../../assets/images/teams/logos/fcb.png")}
          alt="fcb"
        />
        <img
          className="ui tiny image ogilvy"
          src={require("../../../../../assets/images/teams/logos/ogilvy.png")}
          alt="ogilvy"
        />
        <img
          className="ui tiny image"
          src={require("../../../../../assets/images/teams/logos/abinbev.png")}
          alt="abinbev"
        />
        <img
          className="ui tiny image"
          src={require("../../../../../assets/images/teams/logos/kroger.png")}
          alt="kroger"
        />
        <img
          className="ui tiny image"
          src={require("../../../../../assets/images/teams/logos/hsbc.png")}
          alt="hsbc"
        />
      </div>
    </div>
  </div>
);

export default Learners;
