import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import Courses from "./Courses";
import AdminLinks from "./AdminLinks";
import ProfileStore from "../../../stores/ProfileStore";
import { ProfileState } from "../../../stores/ProfileStore/Types";
import Settings from "../../../components/Profile/Settings";

interface Props {
  showLoggedInSidebar: boolean;
  handleHideLoggedInSideBar: () => void;
  getCurrentUser: () => void;
  profileStore: ProfileStore;
  currentUser: any;
  userXp: number;
}

interface State {
  showSettingsModal: boolean;
}

class LoggedInSidebar extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showSettingsModal: false
    };
  }

  handleShowSettingsModal = val => this.setState({ showSettingsModal: val });

  countryFlagImage = countryCode => {
    let img;
    try {
      img = require(`../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
    } catch {
      img = "";
    }

    return img;
  };

  onClickProfile = () => {
    if (location.pathname === "/profile") {
      this.props.handleHideLoggedInSideBar();
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      (window as any).location = "/profile";
    }
  };

  renderContent = (profileState: ProfileState) => {
    const { currentUser, userXp } = this.props;
    switch (profileState.kind) {
      case "ready":
        if (profileState.profileResource.kind === "current-user-absent") {
          return (
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          );
        } else {
          const { profileResource } = profileState;
          const { handleHideLoggedInSideBar } = this.props;
          return (
            <>
              <div className="ui one column stackable grid container">
                <div className="header menu">
                  <div className="close">
                    <a
                      className="ui left button"
                      id="closeNav"
                      onClick={() => handleHideLoggedInSideBar()}
                    >
                      <i className="close icon"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="ui content-container">
                <div className="profile">
                  <div className="bio">
                    <div className="profile-pic">
                      <img
                        src={
                          currentUser.kind === "current-user"
                            ? currentUser.profile_picture
                            : ""
                        }
                        className="user-image"
                      />
                      <div className="country">
                        <img
                          src={this.countryFlagImage(profileResource.country)}
                        />
                      </div>
                    </div>
                    <div className="ranking">
                      <div className="rank-and-xp">
                        <div className="rank">
                          <span className="intro">your rank</span>
                          <h2 className="value">
                            {profileResource.rank_with_ordinal}
                          </h2>
                        </div>
                        <div className="xp">
                          <span className="intro">your XP</span>
                          <h2 className="value">{userXp}</h2>
                        </div>
                      </div>
                      <div className="home">
                        <button
                          className="button"
                          onClick={this.onClickProfile}
                        >
                          <span>Profile</span>
                          <i className="home icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <AdminLinks
                  adminUser={profileResource.admin}
                  qaUser={profileResource.qa}
                  superUser={profileResource.super_user}
                />
                <Courses />

                <div className="refer courses-container">
                  <Link
                    to="/referrals"
                    onClick={() => handleHideLoggedInSideBar()}
                  >
                    <div className="header">
                      <h3>Refer a friend</h3>
                      <p>
                        <i className="icon angle right" />
                      </p>
                    </div>
                    <div className="content">
                      <p>
                        Your friends get a 10% discount off any course, you get
                        free courses and bonus XP
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="logout">
                  <a
                    className="ui button"
                    rel="nofollow"
                    data-method="delete"
                    href="/session"
                  >
                    <i className="sign-out icon" />
                    <span>Sign out</span>
                  </a>
                </div>
              </div>
              <Settings
                showSettingsModal={this.state.showSettingsModal}
                handleShowSettingsModal={this.handleShowSettingsModal}
                profileResource={profileResource}
                profileStore={this.props.profileStore}
                getCurrentUser={this.props.getCurrentUser}
                profilePicture={
                  currentUser.kind === "current-user"
                    ? currentUser.profile_picture
                    : ""
                }
              />
            </>
          );
        }
        break;
      case "loading":
        return (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        );

      default:
        break;
    }
  };

  render() {
    const {
      showLoggedInSidebar,

      profileStore
    } = this.props;

    const sideBarClass = showLoggedInSidebar
      ? "ui sidebar vertical menu right overlay uncover visible new-side-bar logged-in-side-bar"
      : "ui sidebar vertical menu right overlay animating new-side-bar logged-in-side-bar";

    return (
      <div className="ui">
        <div className={sideBarClass} id="sign_in_sidebar">
          {this.renderContent(profileStore.state)}
        </div>
      </div>
    );
  }
}

export default observer(LoggedInSidebar);
