import React, { useState } from "react";
import AddPostModal from "./AddPostModal";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";

interface Props {
  currentUser: CurrentUser;
}

const AddPost: React.FC<Props> = ({ currentUser }) => {
  const [openModal, setOpenModal] = React.useState(false);

  const toggleModal = (e, value) => {
    e?.preventDefault();

    setOpenModal(value);
  };

  return (
    <div className="ui form community__add-post">
      <div className="ui form">
        <div className="field">
          <div className="profile">
            <img
              className="ui avatar image"
              src={currentUser.profile_picture}
              alt="profile picture"
            />
          </div>
          <div className="ui input">
            <input
              readOnly={true}
              type="text"
              placeholder="Share your wisdom or thoughts here"
              onClick={(e) => toggleModal(e, true)}
            />
            <button
              className="ui button desktop"
              onClick={(e) => toggleModal(e, true)}
            >
              Post
            </button>
          </div>
        </div>
      </div>
      <AddPostModal
        isModalOpen={openModal}
        onToggleModal={toggleModal}
        currentUser={currentUser}
      />
    </div>
  );
};

export default AddPost;
