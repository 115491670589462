export type Mode = "login" | "signUp";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  mode: Mode;
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export interface ReadyWithErrors {
  kind: "readyWithErrors";
  mode: Mode;
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  errors: string;
}

export interface Saving {
  kind: "saving";
  mode: Mode;
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  redirectUrl: string;
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (
  mode: Mode,
  username: string,
  email: string,
  password: string,
  passwordConfirmation: string
): Ready => {
  return {
    kind: "ready",
    mode,
    username,
    email,
    password,
    passwordConfirmation
  };
};

export const saving = (
  mode: Mode,
  username: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  redirectUrl: string
): Saving => {
  return {
    kind: "saving",
    mode,
    username,
    email,
    password,
    passwordConfirmation,
    redirectUrl
  };
};

export const readyWithErrors = (
  mode: Mode,
  username: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  errors: string
): ReadyWithErrors => {
  return {
    kind: "readyWithErrors",
    mode,
    username,
    email,
    password,
    passwordConfirmation,
    errors
  };
};

export type LoginState = Waiting | Loading | Ready | ReadyWithErrors | Saving;

export const buttonCss = (kind: string) => {
  switch (kind) {
    case "saving":
      return "ui session-email-button button login loading";
      break;

    default:
      return "ui session-email-button button login";
  }
};

export const buttonText = (kind: string) => {
  switch (kind) {
    case "saving":
      return "";
      break;

    default:
      return "Continue";
  }
};
