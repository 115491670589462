import React, { Component } from "react";
import { observer } from "mobx-react";
import PricingWrapper from "./PricingWrapper";
import { PricingPayload } from "../../../../stores/PricingStore/Types";

interface Props {
  handleShowSignInSideBar: (redirectUrl?: string) => void;
  pricing: PricingPayload;
  showSigninSidebar: boolean;
  courseSlug?: string;
  singleCourseHeading?: string;
}

class Pricing extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      pricing,
      courseSlug,
      handleShowSignInSideBar,
      showSigninSidebar,
      singleCourseHeading
    } = this.props;
    return (
      <PricingWrapper
        pricing={pricing}
        courseSlug={courseSlug}
        singleCourseHeading={singleCourseHeading}
        handleShowSignInSideBar={handleShowSignInSideBar}
        showSigninSidebar={showSigninSidebar}
      />
    );
  }
}

export default observer(Pricing);
