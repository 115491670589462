import React, { Component } from "react";
import { observer } from "mobx-react";
import CoursesStore from "../../../stores/CoursesStore";
import CoursesReactions from "../../../stores/CoursesStore/CoursesReactions";
import Wrapper from "./Wrapper";

interface Props {
  kind?: string;
  headerTitle?: string;
}

class CoursesView extends Component<Props, {}> {
  coursesStore = new CoursesStore();
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.coursesStore.loading();
  }

  renderCourses = state => {
    switch (state.kind) {
      case "ready":
      case "sorting":
        return (
          <Wrapper
            courses={state.courses}
            store={this.coursesStore}
            headerTitle={this.props.headerTitle}
          />
        );
        break;
      case "loading":
        return (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        );
      default:
        return <div />;
        break;
    }
  };

  render() {
    const { state } = this.coursesStore;

    return (
      <div className="ui segment courses" id="courses">
        {this.renderCourses(state)}
        <CoursesReactions store={this.coursesStore} kind={this.props.kind} />
      </div>
    );
  }
}

export default observer(CoursesView);
