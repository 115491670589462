import React, { Component } from "react";
import { observer } from "mobx-react";
import CourseCategoriesStore from "../../../../stores/CourseCategoriesStore";
import { CourseCategoriesResource } from "../../../../stores/CourseCategoriesStore/Types";
import Course from "../Courses/Course";
import { Carousel } from "../../../common";

interface Props {
  headerTitle?: string;
  courseCategories: CourseCategoriesResource;
}

interface State {}

class Wrapper extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  categories = (courseId, category) => {
    let allCategories = this.props.courseCategories.filter(cat => {
      const courseIds = cat.courses.map(course => course.id);

      return courseIds.includes(courseId);
    });

    const filteredCategories = allCategories.filter(
      cat => cat.id !== category.id
    );

    filteredCategories.unshift(category);
    return filteredCategories;
  };

  render() {
    const { courseCategories, headerTitle } = this.props;
    const blockClass = "category";

    return (
      <div className="ui container">
        {headerTitle && (
          <h2 className={`${blockClass}__name`}>{headerTitle}</h2>
        )}
        {courseCategories.map((category, i) => (
          <div key={i}>
            {category.courses.length > 0 && (
              <div className={blockClass} key={i}>
                {headerTitle ? (
                  <h3 className={`${blockClass}__name_small`}>
                    {category.name}
                  </h3>
                ) : (
                  <h2 className={`${blockClass}__name`}>{category.name}</h2>
                )}
                <p className={`${blockClass}__description`}>
                  {category.description}
                </p>

                {category.courses.length > 2 ? (
                  <div className="courses-container featured-courses-container ui inverted vertical segment center aligned">
                    <div
                      className="ui centered doubling stackable three column"
                      id="courses_grid"
                    >
                      <Carousel
                        noOfMobileSlides={2}
                        numberOfSlides={
                          category.courses.length < 3
                            ? category.courses.length
                            : 3
                        }
                        arrows={true}
                        slidesToScroll={3}
                        dots={true}
                      >
                        {category.courses.map((course, i) => (
                          <Course
                            courseResource={course}
                            idx={i}
                            key={course.id}
                            increaseHeight={true}
                            categories={this.categories(course.id, category)}
                          />
                        ))}
                      </Carousel>
                    </div>
                  </div>
                ) : (
                  <div className="courses-container ui inverted vertical segment center aligned">
                    <div
                      className="ui grid doubling stackable three column categories"
                      id="courses_grid"
                    >
                      {category.courses.map((course, i) => (
                        <Course
                          courseResource={course}
                          idx={i}
                          key={course.id}
                          increaseHeight={true}
                          categories={this.categories(course.id, category)}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default observer(Wrapper);
