export const randomTexts = [
  "Initiating the Infinite Improbability Drive…",
  "Fetching the Ultimate Answer to Life, the Universe, and Everything...yup, still 42",
  "Summoning the spirit of Steve Jobs for insights…",
  "Brewing a cup of tea for inspiration…",
  "Navigating through the hyperspace of knowledge…",
  "Scanning your brainwaves for telepathic answers…",
  "Ensuring our towel supply is sufficient…",
  "Tuning the Sub-Etha for intergalactic insights…",
  "Lining up all the planets for your answer…",
  "Conjuring wisdom from the depths of the internet…",
  "Brewing a potent potion of knowledge just for you…",
  "Decoding the secrets of the educational cosmos…",
  "Summoning the ancient scholars for a quick consultation…",
  "Sifting through the sands of time for your perfect curriculum…",
  "Calibrating our crystal ball for peak educational foresight…",
  "Mixing the perfect blend of insight and enlightenment…",
  "Having a quick brainstorm with Bill Bernbach…",
  "Checking in with Lee Clow…"
];

export const defaultSearch = [
  "Get a promotion",
  "Become more creative",
  "Be more productive"
];
