import { Errors } from "../../utils/helpers";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  subscription: Subscription;
}

export interface ReadyWithErrors {
  kind: "readyWithErrors";
  subscription: Subscription;
  errors: Errors;
}

export interface Saving {
  kind: "saving";
  subscription: Subscription;
}

export interface Subscribed {
  kind: "subscribed";
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (subscription: Subscription): Ready => {
  return {
    kind: "ready",
    subscription
  };
};

export const saving = (subscription: Subscription): Saving => {
  return {
    kind: "saving",
    subscription
  };
};

export const readyWithErrors = (
  subscription: Subscription,
  errors: Errors
): ReadyWithErrors => {
  return {
    kind: "readyWithErrors",
    subscription,
    errors
  };
};

export const subscribed = (): Subscribed => {
  return {
    kind: "subscribed"
  };
};

export type SubscriptionState =
  | Waiting
  | Loading
  | Ready
  | ReadyWithErrors
  | Saving
  | Subscribed;

export interface Subscription {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
}
