import React, { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import NumberFormat from "react-number-format";
import { TeamsResource } from "../../../../stores/LeaderboardStore/Types";
import { Api } from "../../../../utils/Api";

interface Props {
  teams: TeamsResource;

  totalTeams: number;
  prevPage: (page: number) => number;
  nextPage: (page: number, total_pages) => number;
}

interface State {
  teams: TeamsResource;
  topThreeTeams: TeamsResource;
  totalTeams: number;
  page: number;
  loading: boolean;
}

class Teams extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      teams: [],
      topThreeTeams: [],
      totalTeams: 0,
      page: 0,
      loading: false
    };
  }

  componentDidMount() {
    const { teams, totalTeams } = this.props;

    this.setState({
      teams: teams.slice(3, 10),
      topThreeTeams: teams.slice(0, 3),
      totalTeams
    });
  }

  loadPrev = event => {
    const { page } = this.state;
    const newPage = this.props.prevPage(page);

    this.getTeams(newPage);
  };

  loadNext = event => {
    const { page, totalTeams } = this.state;
    const nextPageNum = this.props.nextPage(page, totalTeams);
    const newPage = nextPageNum === totalTeams ? totalTeams - 1 : nextPageNum;

    this.getTeams(newPage);
  };

  getTeams = (newPage: number) => {
    const { page, totalTeams } = this.state;

    const client = new Api({});

    if (totalTeams > page) {
      this.setState({ loading: true });
      client
        .get<any>(`/api/leaderboards/teams.json?page=${newPage + 1}`)
        .then((response: AxiosResponse<any>) => {
          const { data } = response;
          this.setState({
            teams: data.teams,
            page: newPage,
            loading: false
          });
        })
        .catch((error: AxiosError) => {
          console.error(error.response?.statusText);
        });
    }
  };

  render() {
    const { teams, topThreeTeams, page, loading } = this.state;
    return (
      <div className="leaderboard-card brands">
        <h3 className="card-header">Top Companies</h3>
        <div className="top-three heroes">
          {loading && <div className="ui big active loader" />}
          {topThreeTeams.map((team, idx) => (
            <div
              className={`large_column_classes leading-position-${idx}`}
              key={idx}
            >
              <div className="user">
                <div className="leaderboard-img-wrapper">
                  <div className="leaderboard-img">
                    <div className="image-wrap">
                      <img src={team.logo_url} />
                    </div>
                  </div>
                  <div className="position">
                    <span className={`position-${idx}`}>{idx + 1}</span>
                  </div>
                </div>
                <div className="name">{team.code}</div>
                <div className="score">
                  <NumberFormat
                    value={team.score}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  <span className="score-label">XP</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="bottom-seven">
          {teams.map((team, idx) => (
            <div className="small_column_classes" key={idx}>
              <div className="user">
                <span className="position">{idx + 4 + page * 7}.</span>
                <span className="leaderboard-img">
                  <img src={team.logo_url} />
                </span>
                <span className="name">{team.code}</span>
                <span className="score">
                  <NumberFormat
                    value={team.score}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  <span className="score-label">XP</span>
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-buttons">
          <button
            className="circular ui icon button next"
            onClick={e => this.loadPrev(e)}
          >
            <i className="icon angle left"></i>
          </button>
          <button
            className="circular ui icon button next"
            onClick={e => this.loadNext(e)}
          >
            <i className="icon angle right"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default Teams;
