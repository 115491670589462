import * as React from "react";
import ReactPlayer from "react-player";

const Introduction = () => (
  <div className="ui segment introduction">
    <div className="ui container">
      <h1 className="ui header aligned">
        <span className="title">Why learn with us?</span>
      </h1>

      <div className="ui two column stackable grid scrollme content-container">
        <div className="column mobile">
          <div className="columnContent">
            <ReactPlayer
              className="react-player"
              url="https://player.vimeo.com/video/448103322?color=ffffff&byline=0"
              width="100%"
              controls={true}
            />
          </div>
        </div>
        <div className="column left">
          <div className="columnContent">
            <div className="ui list">
              <div className="item">
                <div className="content">
                  <div className="image">
                    <img
                      className="ui avatar image"
                      src={require("../../../../../assets/images/landings/introduction/Learn_Icon.svg")}
                      alt="time"
                    />
                  </div>
                  <div className="description">
                    Learn from top industry leaders in a fun and fast way
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="image time">
                    <img
                      className="ui avatar image"
                      src={require("../../../../../assets/images/landings/introduction/Cert_Icon.svg")}
                      alt="time"
                    />
                  </div>
                  <div className="description">
                    Gain certifications to advance your career
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="image">
                    <img
                      className="ui avatar image"
                      src={require("../../../../../assets/images/landings/introduction/Marking_icon.svg")}
                      alt="learn"
                    />
                  </div>
                  <div className="description">
                    Manually marked with personalised feedback
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="image">
                    <img
                      className="ui avatar image"
                      src={require("../../../../../assets/images/landings/introduction/AI_Icon.svg")}
                      alt="certificate"
                    />
                  </div>
                  <div className="description">
                    Custom trained GPT-4 mentor included
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="column right desktop">
          <div className="columnContent">
            <ReactPlayer
              className="react-player"
              url="https://player.vimeo.com/video/448103322?color=ffffff&byline=0"
              width="100%"
              controls={true}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Introduction;
