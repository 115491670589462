import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import PreviewImage from "../../Home/components/Courses/Course/PreviewImage";
import StatusImage from "../../Home/components/Courses/Course/StatusImage";
import { OngoingCourse } from "../../../stores/EnrolmentStore/Types";

interface Props {
  enrolment: OngoingCourse;
  idx: number;
}

class Course extends Component<Props, {}> {
  render() {
    const { enrolment, idx } = this.props;

    return (
      <div
        className="column animateme three"
        data-from={0.45 + 0.07 * idx}
        data-opacity="0"
        data-to={idx * 0.07}
        data-translatey="180"
        data-when="enter"
      >
        <div className="ui text loader">Loading</div>

        <a
          className="content"
          href={
            enrolment.embedded_host_url
              ? enrolment.embedded_host_url
              : `/enrolments/${enrolment.id}`
          }
        >
          <div className="copyWrap">
            <div className="bg">
              <div className="course-status">
                <StatusImage courseResource={enrolment.course} />
              </div>
              <div className="course-headshot">
                <PreviewImage courseResource={enrolment.course} />
                {enrolment.course.brand_logo_url && (
                  <div className="course-logo">
                    <img src={enrolment.course.brand_logo_url} alt="brand" />
                  </div>
                )}
              </div>
            </div>
            <div className="main">
              <div className="title">{enrolment.course.name}</div>
              <p className="teaser">
                {enrolment.course.preview_mini_description}
              </p>
            </div>
            <div className="course_footer ongoing">
              <div className="progress">
                <PieChart
                  data={[
                    { value: enrolment.percentage_completed, color: "#36CBBD" },
                    {
                      label: "",
                      value: 100 - enrolment.percentage_completed,
                      isEmpty: true,
                      color: "#dbdee4",
                    },
                  ]}
                  totalValue={100}
                  lineWidth={20}
                  label={() => `${enrolment.percentage_completed}%`}
                  labelStyle={{
                    fontSize: "30px",
                    fill: "392D40",
                  }}
                  labelPosition={0}
                  startAngle={270}
                />
                <div className="fl-button highlight">
                  {enrolment.percentage_completed === 100
                    ? "View Again"
                    : "Continue"}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default observer(Course);
