import React, { useState } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import { CurrentUserResource } from "../../../stores/CurrentUserStore/Types";

interface Props {
  handleShowSignInSideBar: () => void;
  handleHideSignInSideBar: () => void;
  handleShowLoggedInSideBar: () => void;
  handleHideLoggedInSideBar: () => void;
  onClickHeaderLogo: () => void;
  getCurrentUser: () => void;
  currentUser: CurrentUserResource;
  handleShowNotificationModal: (val: boolean) => void;
  showNotificationModal: boolean;
  userXp: number;
}

const MobileNav: React.FC<Props> = ({
  handleShowSignInSideBar,
  handleHideSignInSideBar,
  handleShowLoggedInSideBar,
  handleHideLoggedInSideBar,
  onClickHeaderLogo,
  currentUser,
  getCurrentUser,
  handleShowNotificationModal,
  showNotificationModal,
  userXp
}) => {
  const [showSidebar, setShowSideBar] = useState(false);

  const sideBarClass = showSidebar
    ? "ui sidebar vertical menu left overlay uncover visible new-side-bar"
    : "ui sidebar vertical menu left overlay animating new-side-bar";

  const handleShowSideBar = () => {
    handleHideSignInSideBar();
    handleHideLoggedInSideBar();
    setShowSideBar(true);
    $(".pusher").addClass("dimmed");
  };

  const handleHideSideBar = () => {
    setShowSideBar(false);
    $(".pusher").removeClass("dimmed");
  };

  return (
    <>
      <Header
        handleShowSideBar={handleShowSideBar}
        handleShowSignInSideBar={handleShowSignInSideBar}
        handleShowLoggedInSideBar={handleShowLoggedInSideBar}
        onClickHeaderLogo={onClickHeaderLogo}
        currentUser={currentUser}
        getCurrentUser={getCurrentUser}
        handleShowNotificationModal={handleShowNotificationModal}
        showNotificationModal={showNotificationModal}
        userXp={userXp}
      />
      <SideBar
        currentUser={currentUser}
        sideBarClass={sideBarClass}
        handleHideSideBar={handleHideSideBar}
        onClickHeaderLogo={onClickHeaderLogo}
      />
    </>
  );
};

export default MobileNav;
