import React from "react";
import styled from "styled-components";
import { CoursesResource } from "../../stores/CoursesStore/Types";
import { Brand } from "./Types";

interface Props {
  brand: Brand;
  courses: CoursesResource;
}

const isEven = n => {
  return n % 2 == 0;
};

const Courses: React.FC<Props> = ({ brand, courses }) => {
  const H1 = styled.h1`
    &:before {
      border-bottom: 0.2em solid ${props => props.tile_color};
    }
  `;

  return (
    <div className="ui segment brand-courses">
      {courses.map((course, idx) => (
        <div
          key={idx}
          className={`ui stackable equal width grid ${
            isEven(idx) ? "" : "tablet reversed computer reversed"
          }`}
        >
          <div className="column course-info">
            <div className="ui text">
              <H1 className="ui header title" tile_color={course.tile_color}>
                {course.name}
              </H1>
              <div
                className="desc"
                dangerouslySetInnerHTML={{
                  __html: course.tile_description
                }}
              />
              <div className="masthead-buttom-plan">
                <div
                  className="elipse"
                  style={{ background: course.tile_color }}
                ></div>
                <div className="menu-button">
                  <a
                    className="ui button masthead-link"
                    href={course.course_path}
                  >
                    <span className="menu-button-copy">More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="column course-video"
            style={{
              background: course.tile_color,
              opacity: course.content_live ? 1 : 0.5
            }}
          >
            <img src={brand.tile_image_url} />

            {course.content_live && course.tile_video_url ? (
              <a data-video-source={course.tile_video_url}>
                <i
                  className="icon play circle"
                  style={{ color: course.tile_icon_color }}
                ></i>
              </a>
            ) : (
              <h1 style={{ color: course.tile_icon_color }}>Coming Soon</h1>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Courses;
