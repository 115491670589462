import React, { useEffect, useRef, useState } from "react";

interface MentionInputProps {
  users: string[];
  value: string;
  rows: number;
  onChange?: (text: string, formattedText: string) => void;
  setRows: (rows: number) => void;
  setText: (text: string) => void;
  setFormattedText: (formattedText: string) => void;
}

const MentionInput: React.FC<MentionInputProps> = ({
  users,
  value,
  rows,
  setRows,
  setText,
  setFormattedText,
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const suggestionsRef = useRef<HTMLUListElement | null>(null);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const lineBreaks = (value.match(/\n/g) || []).length + 1;
    setRows(lineBreaks);
    updateFormattedText(value);
  }, [value]);

  const updateFormattedText = (inputText: string) => {
    const formatted = formatTextWithMentions(inputText);
    setFormattedText(formatted);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentText = event.target.value;
    setText(currentText);

    // Calculate the number of rows needed
    const lineBreaks = (currentText.match(/\n/g) || []).length + 1;
    setRows(lineBreaks);

    // Get the cursor position
    const position = event.target.selectionStart; // Get the current cursor position
    setCursorPosition(position); // Store cursor position

    // Check if mentioning
    const mentionStart = currentText.lastIndexOf("@", position - 1);
    if (mentionStart !== -1) {
      const searchTerm = currentText
        .slice(mentionStart + 1, position)
        .toLowerCase();
      const filteredUsers = users.filter((user) =>
        user.toLowerCase().includes(searchTerm),
      );

      setSuggestions(filteredUsers);
      setShowSuggestions(filteredUsers.length > 0); // Show if there are matching suggestions
    } else {
      setShowSuggestions(false);
      setSuggestions([]); // Clear suggestions if not mentioning
    }
  };

  const handleSuggestionClick = (user) => {
    // Replace text based on cursor position
    if (cursorPosition !== null) {
      const mentionStart = value.lastIndexOf("@", cursorPosition);
      const beforeMention = value.slice(0, mentionStart); // Text before the '@'
      const afterMention = value.slice(cursorPosition); // Everything after the mention

      // Construct the new text and formatted text
      const newText = `${beforeMention}@${user} ${afterMention.trim()}`;
      const newFormattedText = formatTextWithMentions(newText);

      setText(newText);
      setFormattedText(newFormattedText); // Update formatted text
      setShowSuggestions(false); // Hide suggestions after selection

      // Move cursor to end of inserted username
      setTimeout(() => {
        const cursorPos = beforeMention.length + `@${user} `.length; // Account for '@' and space
        inputRef.current!.selectionStart = cursorPos;
        inputRef.current!.selectionEnd = cursorPos;
        inputRef.current!.focus(); // Ensure the textarea is focused
      }, 0);
    }
  };

  const formatTextWithMentions = (text) => {
    // Match all mentions in the format '@User Name'
    const mentions = text.match(/@(\w+\s\w+)/g) || [];
    return mentions.reduce((acc, mention) => {
      return acc.replace(mention, `<span class="mention">${mention}</span>`);
    }, text);
  };

  return (
    <>
      <div className="ui input">
        <textarea
          ref={inputRef}
          className="form-control"
          placeholder="Write your comment here."
          value={value}
          onChange={handleChange}
          rows={rows}
          style={{ resize: "none", overflow: "hidden" }}
        />
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <ul
          className="community__feed-mentions-suggestions"
          ref={suggestionsRef}
          style={{}}
        >
          {suggestions.map((user, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(user)}
              style={{
                padding: "10px",
                cursor: "pointer",
                borderBottom: "1px solid #ddd",
                backgroundColor: "#f9f9f9",
              }}
            >
              {user}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default MentionInput;
