import * as React from "react";

const firstSection = [
  "kpmg",
  "cannes",
  "forbes",
  "google",
  "ddb",
  "microsoft",
  "sbank"
];

const secondSection = ["pmedia", "wpp", "ogilvy", "lowe", "airbnb", "ideo"];

const thirdSection = ["amazon", "facebook", "omg", "salesforce", "quartz"];

const Alumni = () => {
  return (
    <div className="ui segment alumni">
      <div className="ui">
        <h2 className="header">Where our students work</h2>
        <div className="ui companies section-one">
          {firstSection.map(coy => (
            <div className="" key={coy}>
              <img
                className="ui small image"
                src={`https://42courses-course-material.s3-us-west-1.amazonaws.com/alumni-companies/${coy}.png`}
              />
            </div>
          ))}
        </div>
        <div className="ui companies section-two">
          {secondSection.map(coy => (
            <div className="" key={coy}>
              <img
                className="ui small image"
                src={`https://42courses-course-material.s3-us-west-1.amazonaws.com/alumni-companies/${coy}.png`}
              />
            </div>
          ))}
        </div>
        <div className="ui companies section-three">
          {thirdSection.map(coy => (
            <div className="" key={coy}>
              <img
                className="ui small image"
                src={`https://42courses-course-material.s3-us-west-1.amazonaws.com/alumni-companies/${coy}.png`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Alumni;
