import { CurrentUser } from "../CurrentUserStore/Types";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  teamResource: TeamResource;
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (teamResource: TeamResource): Ready => {
  return {
    kind: "ready",
    teamResource
  };
};

export type TeamState = Waiting | Loading | Ready;

export interface OngoingEnrolment {
  id: number;
  name: string;
  percentage_completed: number;
}

export interface Membership {
  id: number;
  user: CurrentUser;
  active: boolean;
  admin: boolean;
  show_on_board: boolean;
}

export interface CurrentTeam {
  id: number;
  name: string;
  slug: string;
  catch_phrase: string;
  formatted_created_at: string;
  logo_url: string;
  active: boolean;
  user_count: number;
  certificates_count: number;
  learning_time: number;
  manager: CurrentUser;
  memberships: Membership;
  subscription: Subscription;
  zen_pool: string;
}

export type TeamResource = CurrentTeam;

export interface TeamLeaderboard {
  pool: Leaderboard[];
}

export interface Leaderboard {
  name: string;
  xp: number;
  rank: string;
  country: string;
  profile_picture: string;
}

export interface CountryResource {
  country: string;
  code: string;
  iso_code: string;
}

export interface Subscription {
  id: number;
  name: string;
  renew: boolean;
  valid_till: string;
  plan: string;
  interval: string;
  active: boolean;
}
