import React, { Component } from "react";
import { getRequest } from "../../../utils/httpRequest";
import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";
import {
  EnrolmentCertificate,
  OngoingCourse
} from "../../../stores/EnrolmentStore/Types";
import FeaturedCourses from "../../CoursePreview/FeaturedCourses";
import {
  Facebook,
  CopyLink,
  LinkedIn,
  Twitter,
  Download
} from "../../../containers/Footer/Icons";
import { notification } from "../../../utils/helpers";
import NewReviewModal from "../NewReviewModal";
import LinkedInModal from "./LinkedInModal";
import { Popup } from "semantic-ui-react";

interface Props {
  enrolmentId: any;
  enrolment: OngoingCourse;
}

interface State {
  certificate: EnrolmentCertificate;
  showReviewModal: boolean;
  loading: boolean;
  showLinkedInModal: boolean;
}

const brandIdMapping = {
  "cannes-lions": 436863,
  parsons: 301086,
  ogilvy: 1673,
  "happiness-research-institute": 2947845
};

class Certificate extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      certificate: null,
      loading: true,
      showReviewModal: false,
      showLinkedInModal: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchCertificate();
  }

  fetchCertificate = () => {
    const { enrolmentId } = this.props;

    return getRequest(`/api/enrolments/${enrolmentId}/certificate.json`)
      .then((response: AxiosResponse) => {
        this.setState({ certificate: response.data, loading: false });
      })
      .catch(error => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
      });
  };

  toggleNewReviewModal = e => {
    e.preventDefault();
    this.setState({ showReviewModal: !this.state.showReviewModal });
  };

  toggleLinkedInModal = e => {
    e.preventDefault();
    this.setState({ showLinkedInModal: !this.state.showLinkedInModal });
  };

  copyUrlToClipboard = e => {
    e.preventDefault();
    let copyText = document.querySelector("#cert-share-url");
    (copyText as any).select();
    document.execCommand("copy");
    notification("Copied certificate url to clipboard!", "alert");
  };

  getBrandId = () => {
    const organizationId = brandIdMapping[this.state.certificate?.brand_slug];

    if (!organizationId) {
      return 10274769; // 42courses brand_id
    }

    return organizationId;
  };

  render() {
    const {
      loading,
      certificate,
      showReviewModal,
      showLinkedInModal
    } = this.state;
    const { enrolment, enrolmentId } = this.props;
    const { course } = enrolment || {};

    const linkedInShare = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
      certificate?.course_name
    }&organizationId=${this.getBrandId()}&issueYear=${
      certificate?.enrolment?.completed_at_year
    }&issueMonth=${certificate?.enrolment?.completed_at_month}&certUrl=${
      certificate?.share_urls?.platform
    }`.replace(/['"]+/g, "");

    return (
      <>
        {loading ? (
          <>
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          </>
        ) : (
          <>
            <div
              className="ui segment wrapper"
              style={{
                backgroundImage: `url(${require("../../../../../assets/images/banners/confetti.png")})`
              }}
            >
              <div className="ui container current-chapter-container">
                <div className="course-header">
                  <h1>Congratulations!</h1>
                </div>
                <div className="ui container center aligned share-container">
                  <h2>Download and share your certificate</h2>
                  <input
                    id="cert-share-url"
                    value={certificate?.share_urls?.platform}
                    readOnly
                  />
                  <div className="ui buttons">
                    <div className="icons">
                      <Popup
                        key="download"
                        style={{
                          color: "white",
                          backgroundColor: "#392d40"
                        }}
                        trigger={
                          <div className="share-icon">
                            <a
                              target="_blank"
                              href={`/enrolments/${enrolmentId}/certificate/download`}
                            >
                              <Download />
                            </a>
                          </div>
                        }
                        wide
                      >
                        <Popup.Content>Download</Popup.Content>
                      </Popup>

                      <Popup
                        key="facebook"
                        style={{
                          color: "white",
                          backgroundColor: "#392d40"
                        }}
                        trigger={
                          <div className="share-icon">
                            <a
                              target="_blank"
                              href={certificate?.share_urls?.facebook}
                            >
                              <Facebook />
                            </a>
                          </div>
                        }
                        wide
                      >
                        <Popup.Content>Share on Facebook</Popup.Content>
                      </Popup>

                      <Popup
                        key="twitter"
                        style={{
                          color: "white",
                          backgroundColor: "#392d40"
                        }}
                        trigger={
                          <div className="share-icon">
                            <a
                              target="_blank"
                              href={certificate?.share_urls?.twitter}
                            >
                              <Twitter />
                            </a>
                          </div>
                        }
                        wide
                      >
                        <Popup.Content>Share on Twitter</Popup.Content>
                      </Popup>

                      <Popup
                        key="linkedin"
                        style={{
                          color: "white",
                          backgroundColor: "#392d40"
                        }}
                        trigger={
                          <div className="share-icon">
                            <a href={linkedInShare} target="_blank">
                              <LinkedIn />
                            </a>
                          </div>
                        }
                        wide
                      >
                        <Popup.Content>Share on LinkedIn</Popup.Content>
                      </Popup>

                      <Popup
                        key="copy-link"
                        style={{
                          color: "white",
                          backgroundColor: "#392d40"
                        }}
                        trigger={
                          <div className="share-icon">
                            <a href="#" onClick={this.copyUrlToClipboard}>
                              <CopyLink />
                            </a>
                          </div>
                        }
                        wide
                      >
                        <Popup.Content>Sharable link</Popup.Content>
                      </Popup>
                    </div>
                  </div>
                </div>
                {certificate?.landscape_url && (
                  <div className="certificate-image-container landscape">
                    <a
                      target="_blank"
                      href={`/enrolments/${enrolmentId}/certificate/download`}
                    >
                      <img src={certificate?.landscape_url} />
                    </a>
                  </div>
                )}
                {certificate?.portrait_url && (
                  <div className="certificate-image-container portrait">
                    <a
                      target="_blank"
                      href={`/enrolments/${enrolmentId}/certificate/download`}
                    >
                      <img src={certificate?.portrait_url} />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="ui segment">
              <div className="ui container center aligned points-container">
                <div className="score">
                  <div className="icon">
                    <img
                      src={require("../../../../../assets/images/profile/score-trophy.png")}
                      alt="badges"
                    />
                  </div>
                  <div className="desc">
                    <h3>{enrolment?.score}%</h3>
                    <span>Score</span>
                  </div>
                </div>
                <div className="rank">
                  <div className="icon">
                    <img
                      src={require("../../../../../assets/images/profile/cert.png")}
                      alt="badges"
                    />
                  </div>
                  <div className="desc">
                    <h3>{enrolment?.rank}</h3>
                    <span>Course rank</span>
                  </div>
                </div>
                <div className="xp">
                  <div className="icon">
                    <img
                      src={require("../../../../../assets/images/profile/xp-trophy.png")}
                      alt="badges"
                    />
                  </div>
                  <div className="desc">
                    <h3>{enrolment?.xp}</h3>
                    <span>XP gained</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ui segment">
              <div className="ui container download-and-review center aligned">
                <div className="ui buttons">
                  {!certificate?.reviewed && (
                    <button
                      className="ui traditional button review"
                      onClick={this.toggleNewReviewModal}
                    >
                      <i className="icon comment" /> Leave a review
                    </button>
                  )}
                  <Link to={{ pathname: `/enrolments/${enrolment?.id}` }}>
                    <button className="ui traditional button home">
                      <span>Course</span>
                      <i className="home icon" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            {course?.slug && (
              <FeaturedCourses
                courseSlug={course?.slug}
                sectionTitle={"Things to learn next"}
              />
            )}
            <LinkedInModal
              showModal={showLinkedInModal}
              linkedInShareUrl={certificate?.share_urls?.linked_in}
              handleHideLinkedInModal={this.toggleLinkedInModal}
              certificationUrl={certificate?.share_urls?.platform}
            />
            <NewReviewModal
              enrolmentId={enrolmentId}
              courseName={course?.name}
              showModal={showReviewModal}
              handleHideNewReviewModal={this.toggleNewReviewModal}
              refreshCertificate={this.fetchCertificate}
            />
          </>
        )}
      </>
    );
  }
}

export default Certificate;
