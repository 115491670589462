import React, { Component } from "react";
import { Form, Input, Popup } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { AxiosResponse, AxiosError } from "axios";
import { Link } from "react-router-dom";
import {
  CoursePreviewResource,
  QuotationResource
} from "../../stores/CoursePreviewStore/Types";
import { CurrentUserResource } from "../../stores/CurrentUserStore/Types";
import { postRequest } from "../../utils/httpRequest";
import { notification } from "../../utils/helpers";
import GoToCheckoutButton from "../Payments/GoToCheckoutButton";
import FeatureInterest from "./FeatureInterest";
import CreditsUsedConfirmation from "./CreditsUsedConfirmation";
import RestrictedEnrolmentConfirmation from "./RestrictedEnrolmentConfirmation";

interface Props {
  mode?: string;
  refresh: (slug: string) => void;
  course: CoursePreviewResource;
  showSigninSidebar: boolean;
  loadingQuotations: boolean;
  currentUser: CurrentUserResource;
  quotationResource: QuotationResource;
  loadingEnrolmentInfo: boolean;
  enrolmentInfo: {
    existing_subscription: boolean;
    enrolment_id: number;
  };
  handleShowSignInSideBar: (redirectUrl?: string) => void;
}

interface State {
  enrolingUser: boolean;
  first_name: string;
  email: string;
  canEnrolWithRestrictedSub: boolean;
  isCreditsUsedUp: boolean;
}

class Landing extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      enrolingUser: false,
      first_name: props?.currentUser?.name,
      email: props?.currentUser?.email,
      canEnrolWithRestrictedSub: false,
      isCreditsUsedUp: false
    };
  }

  subscriptionOnlyButton = () => (
    <button
      className="ui traditional button fluid add-to-cart-button"
      style={{ marginTop: "1em" }}
    >
      <i className="lock icon"></i>
      Subscriber Only
    </button>
  );

  toggleRestrictedConfirmation = () => {
    this.setState({
      canEnrolWithRestrictedSub: !this.state.canEnrolWithRestrictedSub
    });
  };

  toggleIsCreditsUsedUp = () => {
    this.setState({ isCreditsUsedUp: !this.state.isCreditsUsedUp });
  };

  startCourse = e => {
    const {
      plan_slug,
      merchant_quotation_id,
      new_enrolment_url
    } = this.props.quotationResource;

    const { is_restricted_subscription, available_credits } = this.props.course;

    e.preventDefault();

    this.setState({ enrolingUser: true });

    return postRequest(`${new_enrolment_url}.json`, {
      plan_slug,
      merchant_quotation_id
    })
      .then((response: AxiosResponse<{ url: string }>) => {
        (window as any).location = response.data.url;
      })
      .catch((error: AxiosError<{ url: string }>) => {
        this.setState({ enrolingUser: false });
      });
  };

  renderHeroPrice = () => {
    const {
      fee,
      quotations,
      existing_subscription,
      enrolment_id
    } = this.props.quotationResource;

    if (fee > 0 && !existing_subscription && !enrolment_id) {
      return (
        <>
          <span className="currency-symbol">{quotations.course.currency}</span>
          <Popup
            trigger={<span className="amount">{quotations.course.amount}</span>}
            content={quotations.course.currency_text}
          />
          <Popup
            trigger={
              <div className="currency-code">
                {quotations.course.currency_code}
              </div>
            }
            content={quotations.course.currency_name}
          />
        </>
      );
    } else if (existing_subscription || enrolment_id) {
      return <span />;
    } else {
      return <span>COMPLIMENTARY</span>;
    }
  };

  renderCoupon = () => {
    const { quotationResource } = this.props;

    if (quotationResource?.coupon) {
      return (
        <h3 className="hero-coupon">
          <span>{quotationResource?.coupon.code}</span>&nbsp;&nbsp;
          <i className="ticket icon"></i>
          &nbsp;&nbsp;
          <span>
            <span>was &nbsp;</span>
            {quotationResource?.coupon_previous_price}
          </span>
        </h3>
      );
    } else {
      return <span />;
    }
  };

  renderActionButton = () => {
    const {
      quotationResource,
      showSigninSidebar,
      handleShowSignInSideBar,
      currentUser,
      course,
      enrolmentInfo
    } = this.props;

    const { enrolingUser } = this.state;

    if (enrolmentInfo?.enrolment_id) {
      return (
        <a
          href={`/enrolments/${enrolmentInfo.enrolment_id}`}
          className="ui button fl-button highlight view-pricing click-busy "
        >
          Continue
        </a>
      );
    } else if (currentUser.kind === "current-user") {
      if (enrolmentInfo?.existing_subscription || course.sample) {
        if (course.is_restricted_subscription && course.available_credits < 1) {
          return (
            <button
              className={`ui button fl-button highlight view-pricing ${
                enrolingUser ? "loading" : ""
              }`}
              onClick={this.toggleIsCreditsUsedUp}
            >
              <i className="student icon"></i>Start now
            </button>
          );
        } else if (
          course.is_restricted_subscription &&
          course.available_credits > 0
        ) {
          return (
            <button
              className={`ui button fl-button highlight view-pricing ${
                enrolingUser ? "loading" : ""
              }`}
              onClick={this.toggleRestrictedConfirmation}
            >
              <i className="student icon"></i>Start now
            </button>
          );
        } else {
          return (
            <button
              className={`ui button fl-button highlight view-pricing ${
                enrolingUser ? "loading" : ""
              }`}
              onClick={this.startCourse}
            >
              <i className="student icon"></i>Start now
            </button>
          );
        }
      } else {
        if (course.subscription_only && !enrolmentInfo?.existing_subscription) {
          return this.subscriptionOnlyButton();
        } else {
          return (
            <a
              className="fl-button highlight view-pricing"
              data-smooth-scroll-to-top="#pricing-container"
              href="#"
            >
              View Pricing
            </a>
          );
        }
      }
    } else if (
      course.subscription_only &&
      !enrolmentInfo.existing_subscription
    ) {
      return this.subscriptionOnlyButton();
    } else if (course.sample) {
      return (
        <a
          href={`/courses/${course.slug}/pay`}
          className="fl-button highlight view-pricing"
        >
          Get started
        </a>
      );
    } else {
      return (
        <a
          className="fl-button highlight view-pricing"
          data-smooth-scroll-to-top="#pricing-container"
          href="#"
        >
          View Pricing
        </a>
      );
    }
  };

  setFormValue = e => {
    e.preventDefault();
    let event_hash = {};
    event_hash[e.target.name] = e.target.value;
    this.setState(event_hash);
  };

  render() {
    const {
      mode,
      course,
      loadingQuotations,
      quotationResource,
      enrolmentInfo
    } = this.props;

    const {
      canEnrolWithRestrictedSub,
      isCreditsUsedUp,
      enrolingUser
    } = this.state;

    return (
      <>
        <RestrictedEnrolmentConfirmation
          isOpen={canEnrolWithRestrictedSub}
          onClose={this.toggleRestrictedConfirmation}
          onConfirm={this.startCourse}
          availableCredits={course.available_credits}
          enrolingUser={enrolingUser}
        />
        <CreditsUsedConfirmation
          isOpen={isCreditsUsedUp}
          onClose={this.toggleIsCreditsUsedUp}
        />
        <div className="ui component hero">
          <div
            className="bg"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${course?.preview_masthead_image_url})`
            }}
          ></div>
          {course.subscription_only && !course.has_subscription && (
            <div id="overlay">
              <a href="/passes/month/pay" target="_blank">
                <div id="text">
                  <i className="lock icon"></i>

                  <p>Subscriber Only</p>
                </div>
              </a>
            </div>
          )}
          <div className="hero-container" id="course-grid-title">
            <div className="course-info">
              <div className="logo">
                <a data-smooth-scroll-to="#course_collaborators" href="#">
                  <img src={course?.preview_brand_logo_url} alt="" />
                </a>
              </div>
              <div className="course_name">{course?.name}</div>
              <br />
              <a data-smooth-scroll-to="#course_collaborators" href="#">
                <div className="thought-leader">
                  With {course?.thought_leader_name}
                </div>
              </a>
              <br />
              <br />
              <div className="join-learners">
                <p>Join learners from</p>
                <img
                  className="ui image"
                  src={require("../../../../assets/images/courses/preview/student-brands.png")}
                  alt=""
                />
              </div>
              <br />
              {mode !== "upcoming" && (
                <div className="reviews">
                  <div id="course_overview">
                    <div id="course_overview_chapters">
                      <a data-smooth-scroll-to="#course-reviews" href="#">
                        <h1 className="ui header">
                          <div className="star-ratings-css">
                            <div
                              className="star-ratings-css-top"
                              style={{ width: `${course?.rating_percentage}%` }}
                            >
                              <span>★</span>
                              <span>★</span>
                              <span>★</span>
                              <span>★</span>
                              <span>★</span>
                            </div>
                            <div className="star-ratings-css-bottom">
                              <span>★</span>
                              <span>★</span>
                              <span>★</span>
                              <span>★</span>
                              <span>★</span>
                            </div>
                          </div>
                        </h1>
                        <div className="average-text">
                          Avg. Rating {course?.rating?.toFixed(1)}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="course-price">
              {mode == "upcoming" && (
                <FeatureInterest
                  courseSlug={this.props.course?.slug}
                  refresh={this.props.refresh}
                />
              )}
              {mode !== "upcoming" && (
                <div className="dimmable wrapper">
                  <div className="ui image medium">
                    <img
                      src={require("../../../../assets/images/courses/preview/cert-preview.png")}
                    />
                  </div>
                  <div className="price-info">
                    <div className="list">
                      <img
                        className="ui image"
                        src={require("../../../../assets/images/courses/preview/approval.png")}
                        alt="approval logo"
                      />
                      <span>
                        <strong>
                          Earn a Nano MBA from  {course.brand.name}
                        </strong>
                      </span>
                    </div>
                    <div className="list">
                      <img
                        className="ui image"
                        src={require("../../../../assets/images/courses/preview/man-on-phone.png")}
                        alt="phone logo"
                      />
                      <span>
                        <strong>Live marking & 24/7 support</strong>
                      </span>
                    </div>
                    <div className="list">
                      <img
                        className="ui image"
                        src={require("../../../../assets/images/courses/preview/historical.png")}
                        alt="historical logo"
                      />
                      <span>
                        {course.sample ? (
                          <strong>1 hour sample course</strong>
                        ) : (
                          <strong>
                            Approx.{" "}
                            {course.learning_hours ? course.learning_hours : 9}{" "}
                            hours
                          </strong>
                        )}
                      </span>
                    </div>
                    <div className="list">
                      <img
                        className="ui image"
                        src={require("../../../../assets/images/courses/preview/future.png")}
                        alt="future logo"
                      />
                      <span>
                        <strong>On-demand</strong>
                      </span>
                    </div>
                  </div>
                  <div className="action">{this.renderActionButton()}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="ui segment hero-mobile">
          {mode == "upcoming" && (
            <FeatureInterest
              courseSlug={this.props.course?.slug}
              refresh={this.props.refresh}
            />
          )}
          {mode !== "upcoming" && (
            <div className="dimmable wrapper">
              <div className="ui image medium">
                <img
                  src={require("../../../../assets/images/courses/preview/cert-preview.png")}
                />
              </div>
              <div className="price-info">
                <div className="list">
                  <img
                    className="ui image"
                    src={require("../../../../assets/images/courses/preview/approval.png")}
                    alt="approval logo"
                  />
                  <span>
                    <strong>Earn a Nano MBA from  {course.brand.name}</strong>
                  </span>
                </div>
                <div className="list">
                  <img
                    className="ui image"
                    src={require("../../../../assets/images/courses/preview/historical.png")}
                    alt="historical logo"
                  />
                  <span>
                    {course.sample ? (
                      <strong>1 hour sample course</strong>
                    ) : (
                      <strong>
                        Approx.{" "}
                        {course.learning_hours ? course.learning_hours : 9}{" "}
                        hours
                      </strong>
                    )}
                  </span>
                </div>
                <div className="list">
                  <img
                    className="ui image"
                    src={require("../../../../assets/images/courses/preview/future.png")}
                    alt="future logo"
                  />
                  <span>
                    <strong>On-demand learning</strong>
                  </span>
                </div>
                <div className="list">
                  <img
                    className="ui image"
                    src={require("../../../../assets/images/courses/preview/man-on-phone.png")}
                    alt="phone logo"
                  />
                  <span>
                    <strong>24/7 support & live marking</strong>
                  </span>
                </div>
              </div>
              <div className="action">
                <div className="action">{this.renderActionButton()}</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Landing;
