import React, { Component } from "react";
import { observer } from "mobx-react";
import qs from 'qs';
import UserActivationsForm from "./UserActivationsForm"

interface PaymentSuccessfulProps {
  match: any,
  handleShowSignInSideBar: (redirectUrl?: string) => void;
  location: any
}

class PaymentSuccessful extends Component<PaymentSuccessfulProps, PaymentSuccessfulState> {

  constructor(props) {
    super(props);

    this.state = {
      userActivationStatus: null,
      redirectUrl: null,
      userEmail: null,
      activationToken: null
    };
  }

  componentDidMount() {
    const queryStringValues = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.setState({
      userActivationStatus: this.props.match.params.userActivationStatus,
      redirectUrl: queryStringValues.redirectUrl,
      userEmail: queryStringValues.userEmail,
      activationToken: queryStringValues.activationToken
    })
  }

  renderForDeactiveStatus() {
    if(this.state.activationToken) {

      const handleUserActivationSuccess = () => {
        window.location.href = this.state.redirectUrl
      }

      return (
        <UserActivationsForm
          activationToken={this.state.activationToken}
          email={this.state.userEmail}
          userActivatedCallback={handleUserActivationSuccess}
        />
      )
    } else {
      return (
        <div>
          <p>
            Check your <strong>{this.state.userEmail}</strong> email for the activation link we sent you.
          </p>
          <p>
            <small>Contact <a href={`mailto:groundcontrol@42courses.com?subject=Can't activate my account for ${this.state.userEmail}, help!`}>groundcontrol@42courses.com</a> if you need help.</small>
          </p>
        </div>
      )
    }
  }

  renderForActivationStatus() {
    switch (this.state.userActivationStatus) {
      case 'activated':
        return (
          <div>
            <p>
              Looks like you already have an account with us under <strong>{this.state.userEmail}</strong>
            </p>
            <div className="cta">
              <a
                className="fl-button large highlight util-clickable"
                onClick={() => this.props.handleShowSignInSideBar(this.state.redirectUrl)}
              >
                Log in to continue
              </a>
            </div>
          </div>
        )
      case 'deactivated':
        return this.renderForDeactiveStatus()
    }
  }

  render() {
    return (
      <div className="ui pages">
        <div className="content">
          <div className="ui vertical segment caret-push padded">
            <div className="ui text container padded util-text-center">
              <div>
                <h3>Bravo, your payment was successful 🎉</h3>
                {this.renderForActivationStatus()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(PaymentSuccessful);
