import React, { Component } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import { Button, Header, Image, Modal, Search, Input } from "semantic-ui-react";
import currencyStore from "../../../stores/CurrencyStore";
import CurrencyReactions from "../../../stores/CurrencyStore/CurrencyReactions";
import ModalStore from "../../../stores/ModalStore";
import { Currency } from "../../../stores/CurrencyStore/Types";
import PricingStore from "../../../stores/PricingStore";

interface Props {
  modalStore: ModalStore;
  currencies: Currency[];
  pricingStore: PricingStore;
  updateSelectedCurrency: (isoCode: string) => void;
}

interface State {
  loading: boolean;
  currencies: Currency[];
  value: string;
  hideLowPriorityCurrencies: boolean;
}

class Currencies extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      currencies: [],
      value: "",
      hideLowPriorityCurrencies: true
    };
  }

  componentDidMount() {
    this.setState({ currencies: this.props.currencies });
  }

  shouldHideDefault = currency => currency.priority < 100;

  dataSearch = currency =>
    `${currency.name.toLowerCase()} ${currency.iso_code.toLowerCase()} ${
      currency.symbol
    }`;

  setCurrency = (e, currency) => {
    $(".currency-option").css("opacity", 0.4);
    $(`.currency-option[data-search*='${this.dataSearch(currency)}']`).css(
      "opacity",
      1
    );

    currencyStore.saving(currency.iso_code, this.props.modalStore);
  };

  renderCurrencies = () => {
    const {
      loading,
      currencies,
      value,
      hideLowPriorityCurrencies
    } = this.state;

    const shouldHideDefault = currency => {
      if (hideLowPriorityCurrencies) {
        return currency.priority < 100;
      } else {
        return true;
      }
    };

    return currencies.map(currency => (
      <a
        key={currency.iso_code}
        onClick={e => this.setCurrency(e, currency)}
        className={`ui card currency-option ${
          shouldHideDefault(currency) ? "" : "forceHide"
        }`}
        data-search={this.dataSearch(currency)}
      >
        <div className="content">
          <div className="header">
            <h1>{currency.symbol}</h1>
            <div
              className={`currency-flag currency-flag-${currency.iso_code.toLowerCase()}`}
            ></div>
            {currency.iso_code.toUpperCase()}
          </div>
          <div className="meta">{currency.name}</div>
        </div>
      </a>
    ));
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, value });

    const { currencies } = this.state;

    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({
          currencies: this.props.currencies,
          hideLowPriorityCurrencies: true,
          loading: false
        });

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = currency =>
        re.test(
          `${currency.name.toLowerCase()} ${currency.iso_code.toLowerCase()} ${
            currency.symbol
          }`
        );

      const filteredCurrencies = _.filter(this.props.currencies, isMatch);

      this.setState({
        loading: false,
        currencies: filteredCurrencies,
        hideLowPriorityCurrencies: false
      });
    }, 300);
  };

  render() {
    const { loading, currencies, value } = this.state;
    const { modalStore } = this.props;
    return (
      <Modal
        basic
        dimmer="blurring"
        open={modalStore.isOpen}
        onClose={() => modalStore.close()}
        closeIcon
        className="currency-modal"
      >
        <Modal.Header className="modal-header">
          {currencyStore.state.kind === "saving" && (
            <div className="ui inline small loader" />
          )}
          {currencyStore.state.kind !== "saving" && (
            <h1>Choose your currency</h1>
          )}
        </Modal.Header>
        <Modal.Content>
          <div className="ui transparent inverted input search-container">
            <i className="search icon" />
            <Search
              loading={loading}
              onSearchChange={_.debounce(this.handleSearchChange, 500, {
                leading: true
              })}
              results={[]}
              value={value}
              className="currency-search"
              input={<Input placeholder="Type to search..." autoFocus />}
            />
          </div>
          <div className="ui four doubling stackable cards currency-options">
            {this.renderCurrencies()}
          </div>
        </Modal.Content>
        <CurrencyReactions
          store={currencyStore}
          pricingStore={this.props.pricingStore}
          updateSelectedCurrency={this.props.updateSelectedCurrency}
        />
      </Modal>
    );
  }
}

export default observer(Currencies);
