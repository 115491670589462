import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../../utils/httpRequest";
import Leaderboard from "./Leaderboard";
import Courses from "./Courses";
import {
  Profile,
  ProfileLeaderboard
} from "../../../stores/ProfileStore/Types";
import { OngoingEnrolment } from "../../../stores/ProfileStore/Types";

interface Props {
  profileResource: Profile;
}

interface State {
  mode: string;
  globalLeaderboard: ProfileLeaderboard;
  teamLeaderboard: ProfileLeaderboard;
  countryLeaderboard: ProfileLeaderboard;
  loading: boolean;
  ongoingEnrolments: OngoingEnrolment[];
  teamName: string;
  teamRank: string;
}

class ProgressAndPosition extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.profileResource.team_name
        ? "teamLeaderboard"
        : "globalLeaderboard",
      globalLeaderboard: null,
      teamLeaderboard: null,
      countryLeaderboard: null,
      ongoingEnrolments: [],
      teamName: "",
      teamRank: "",
      loading: true
    };
  }

  componentDidMount() {
    return getRequest("/api/users/leaderboards.json").then(
      (response: AxiosResponse<any>) => {
        this.setState(
          {
            globalLeaderboard: response.data.global_leaderboard,
            teamLeaderboard: response.data.team_leaderboard,
            countryLeaderboard: response.data.country_leaderboard,
            teamName: response.data.team_name,
            teamRank: response.data.team_rank,
            mode: this.getMode(response.data)
          },
          () => {
            getRequest("/api/users/enrolments.json").then(
              (response: AxiosResponse<OngoingEnrolment[]>) => {
                this.setState({
                  ongoingEnrolments: response.data,
                  loading: false
                });
              }
            );
          }
        );
      }
    );
  }

  getMode(data) {
    const { profileResource } = this.props;

    if (profileResource.team_name) {
      return "teamLeaderboard";
    } else if (data.global_leaderboard.user_rank > 100) {
      return "countryLeaderboard";
    } else {
      return "globalLeaderboard";
    }
  }

  onChangeMode = e => {
    e.preventDefault();

    const { mode, teamName, globalLeaderboard } = this.state;

    let newMode;

    if (mode === "teamLeaderboard") {
      newMode =
        globalLeaderboard.user_rank > 100
          ? "countryLeaderboard"
          : "globalLeaderboard";
    } else if (mode === "globalLeaderboard" || mode === "countryLeaderboard") {
      newMode = "progress";
    } else if (mode === "progress") {
      if (teamName) {
        newMode = "teamLeaderboard";
      } else {
        newMode =
          globalLeaderboard.user_rank > 100
            ? "countryLeaderboard"
            : "globalLeaderboard";
      }
    }

    this.setState({ mode: newMode });
  };

  header = () => {
    const { mode, teamName } = this.state;

    if (teamName) {
      switch (mode) {
        case "teamLeaderboard":
          return <h2>{teamName}</h2>;
          break;
        case "globalLeaderboard":
          return <h2>Your Global Ranking</h2>;
          break;
        case "countryLeaderboard":
          return <h2>Your Country Ranking</h2>;
          break;
        case "progress":
          return <h2>Your active courses</h2>;
          break;
      }
    } else {
      switch (mode) {
        case "globalLeaderboard":
          return <h2>Your Global Ranking</h2>;
          break;
        case "countryLeaderboard":
          return <h2>Your Country Ranking</h2>;
          break;
        default:
          return <h2>Your active courses</h2>;
          break;
      }
    }
  };

  anchors = () => {
    return (
      <div className="anchors">
        <a href="#" onClick={this.onChangeMode}>
          <i className="icon angle left" />
        </a>
        <a href="#" onClick={this.onChangeMode}>
          <i className="icon angle right" />
        </a>
      </div>
    );
  };

  carousel = () => {
    const {
      mode,
      teamLeaderboard,
      globalLeaderboard,
      countryLeaderboard,
      ongoingEnrolments,
      teamRank
    } = this.state;
    const { profileResource } = this.props;

    switch (mode) {
      case "teamLeaderboard":
        return (
          <Leaderboard
            leaderboard={teamLeaderboard}
            profileResource={profileResource}
            rank={teamRank}
          />
        );
        break;

      case "globalLeaderboard":
        return (
          <Leaderboard
            leaderboard={globalLeaderboard}
            profileResource={profileResource}
            rank={profileResource.rank_with_ordinal}
          />
        );
      case "countryLeaderboard":
        return (
          <Leaderboard
            leaderboard={countryLeaderboard}
            profileResource={profileResource}
            rank={profileResource.rank_with_ordinal}
          />
        );

      case "progress":
        return <Courses ongoingEnrolments={ongoingEnrolments} />;
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="ui column right profile-leaderboard">
        {loading && (
          <div className="ui active inverted dimmer" style={{ zIndex: 1 }}>
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="profile-leaderboard-container">
          <div className="header">
            {this.header()}
            {this.anchors()}
          </div>
          {this.carousel()}
        </div>
      </div>
    );
  }
}

export default ProgressAndPosition;
