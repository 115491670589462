import * as React from "react";

const Download = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="#504257" />
    <path
      d="M25.5003 19.667C25.2793 19.667 25.0674 19.7548 24.9111 19.9111C24.7548 20.0674 24.667 20.2793 24.667 20.5003V23.8337C24.667 24.0547 24.5792 24.2666 24.4229 24.4229C24.2666 24.5792 24.0547 24.667 23.8337 24.667H12.167C11.946 24.667 11.734 24.5792 11.5777 24.4229C11.4215 24.2666 11.3337 24.0547 11.3337 23.8337V20.5003C11.3337 20.2793 11.2459 20.0674 11.0896 19.9111C10.9333 19.7548 10.7213 19.667 10.5003 19.667C10.2793 19.667 10.0674 19.7548 9.91107 19.9111C9.75479 20.0674 9.66699 20.2793 9.66699 20.5003V23.8337C9.66699 24.4967 9.93038 25.1326 10.3992 25.6014C10.8681 26.0703 11.504 26.3337 12.167 26.3337H23.8337C24.4967 26.3337 25.1326 26.0703 25.6014 25.6014C26.0703 25.1326 26.3337 24.4967 26.3337 23.8337V20.5003C26.3337 20.2793 26.2459 20.0674 26.0896 19.9111C25.9333 19.7548 25.7213 19.667 25.5003 19.667ZM17.4087 21.092C17.4879 21.1679 17.5814 21.2273 17.6837 21.267C17.7834 21.3111 17.8913 21.3339 18.0003 21.3339C18.1094 21.3339 18.2172 21.3111 18.317 21.267C18.4193 21.2273 18.5127 21.1679 18.592 21.092L21.9253 17.7587C22.0822 17.6017 22.1704 17.3889 22.1704 17.167C22.1704 16.9451 22.0822 16.7322 21.9253 16.5753C21.7684 16.4184 21.5556 16.3302 21.3337 16.3302C21.1117 16.3302 20.8989 16.4184 20.742 16.5753L18.8337 18.492V10.5003C18.8337 10.2793 18.7459 10.0674 18.5896 9.91107C18.4333 9.75479 18.2213 9.66699 18.0003 9.66699C17.7793 9.66699 17.5674 9.75479 17.4111 9.91107C17.2548 10.0674 17.167 10.2793 17.167 10.5003V18.492L15.2587 16.5753C15.181 16.4976 15.0887 16.436 14.9872 16.3939C14.8857 16.3519 14.7769 16.3302 14.667 16.3302C14.5571 16.3302 14.4483 16.3519 14.3468 16.3939C14.2453 16.436 14.153 16.4976 14.0753 16.5753C13.9976 16.653 13.936 16.7453 13.8939 16.8468C13.8519 16.9483 13.8302 17.0571 13.8302 17.167C13.8302 17.2769 13.8519 17.3857 13.8939 17.4872C13.936 17.5887 13.9976 17.681 14.0753 17.7587L17.4087 21.092Z"
      fill="white"
    />
  </svg>
);
export default Download;
