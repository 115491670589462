import React, { Component } from "react";
import CreatePage from "./CreatePage";

class Admin extends Component {
  render() {
    return <div></div>;
  }
}

export default Admin;
