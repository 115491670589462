import { observable, decorate, action, computed } from "mobx";
import { ModalState, waiting, ready, open, close } from "./Types";

class ModalStore {
  state: ModalState;

  constructor() {
    this.state = waiting();
  }

  ready = () => {
    this.state = ready();
  };

  open = () => {
    this.state = open();
  };

  close = () => {
    this.state = close();
  };

  get isOpen() {
    switch (this.state.kind) {
      case "open":
        return true;
        break;
      case "waiting":
      case "close":
        return false;
        break;
      default:
        return false;
    }
  }
}

decorate(ModalStore, {
  state: observable,
  ready: action,
  open: action,
  close: action,
  isOpen: computed
});

export default ModalStore;
