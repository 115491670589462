import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../utils/httpRequest";
import { errorNotification } from "../../utils/helpers";
import { CoursesResource } from "../../stores/CoursesStore/Types";
import { Quotation } from "../../stores/CoursePreviewStore/Types";
import Hero from "./Hero";
import Courses from "./Courses";
import { Brand } from "./Types";
import Learners from "../Pages/TeamPlans/Learners";
import Plans from "../Pages/TeamPlans/Plans";
import Reviews from "../Pages/TeamPlans/Reviews";
import TeamForm from "../Pages/TeamPlans/TeamForm";
import How from "../Pages/TeamPlans/How";
import WhyLearn from "../Pages/TeamPlans/WhyLearn";

interface Props {
  match: any;
  location: {
    pathname: string;
  };
}

interface State {
  loading: boolean;
  courses: CoursesResource;
  brand: Brand;
  quotation: Quotation;
  customBundlePrice: number;
  overviewFile: string;
  filename: string;
}

class Brands extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      brand: null,
      courses: [],
      quotation: null,
      customBundlePrice: null,
      overviewFile: "",
      filename: ""
    };
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;

    return getRequest(`/api/brands/${slug}.json`)
      .then((response: AxiosResponse) => {
        const {
          brand,
          courses,
          quotation,
          filename,
          overview_file,
          custom_bundle_price
        } = response.data;

        this.setState({
          brand,
          courses,
          quotation,
          filename,
          customBundlePrice: custom_bundle_price,
          overviewFile: overview_file,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });

        errorNotification(
          "Error loading data",
          JSON.stringify(error.response.data)
        );
      });
  }

  render() {
    const {
      brand,
      courses,
      quotation,
      customBundlePrice,
      overviewFile,
      filename,
      loading
    } = this.state;

    return (
      <div className="ui pages brands" id="team_plans">
        {loading ? (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        ) : (
          <>
            <Hero brand={brand} />
            <Courses brand={brand} courses={courses} />
            <Learners />
            <Plans
              quote={quotation}
              loading={loading}
              customBundlePrice={customBundlePrice}
            />
            <Reviews />
            <TeamForm
              overviewFile={overviewFile}
              fileName={filename}
              pathName={this.props.location?.pathname}
            />
            <How />
            <WhyLearn />
          </>
        )}
      </div>
    );
  }
}

export default Brands;
