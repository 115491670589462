import React, { Component } from "react";
import { Modal, Tab, Menu, Label } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import ReactHtmlParser from "react-html-parser";
import { getRequest, putRequest } from "../../../utils/httpRequest";
import { CurrentUser } from "../../../stores/CurrentUserStore/Types";

interface Notification {
  id: number;
  href: string;
  mood: string;
  title: string;
  body: string;
  avatar: string;
  target_name: string;
  created_at: string;
}

interface AchievementResponse {
  page: Achievement[];
  total_count: number;
}
interface Achievement {
  id: number;
  html: string;
  xp: number;
  created_at: string;
  opened_xp: boolean;
  created_at_timestamp: string;
}

interface Props {
  getCurrentUser: () => void;
  currentUser: CurrentUser;
  handleShowNotificationModal: (val: boolean) => void;
  showNotificationModal: boolean;
}

interface State {
  notifications: Notification[];
  xp_alerts: Achievement[];
  xpAlertsTotalCount: number;
  loading: boolean;
}

class Notifications extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      xp_alerts: [],
      loading: false,
      xpAlertsTotalCount: 0
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    return getRequest("/api/notifications.json").then(
      (response: AxiosResponse<Notification[]>) => {
        this.setState({ notifications: response.data });
        this.fetchXpAlerts();
      }
    );
  }

  computeXpAlertsLastDateTimeAsUrlEncodedString = (): string => {
    const xpAlertsLength = this.state.xp_alerts.length;
    const lastDatetimeString =
      (xpAlertsLength > 0 &&
        this.state.xp_alerts[xpAlertsLength - 1].created_at_timestamp) ||
      "";
    return encodeURIComponent(lastDatetimeString);
  };

  fetchXpAlerts = () => {
    this.setState({ loading: true });

    return getRequest(
      `/api/achievements/xp/recent.json?last_datetime=${this.computeXpAlertsLastDateTimeAsUrlEncodedString()}`
    ).then((response: AxiosResponse<AchievementResponse>) => {
      const newXpAlerts = this.state.xp_alerts.concat(response.data.page);
      this.setState({
        xp_alerts: newXpAlerts,
        loading: false,
        xpAlertsTotalCount: response.data.total_count
      });
    });
  };

  updateOpenNotifications = () => {
    return putRequest("/api/notifications/update_all.json", {}).then(() => {
      putRequest(
        `/api/achievements/xp/mark_as_opened.json?last_datetime=${this.computeXpAlertsLastDateTimeAsUrlEncodedString()}`,
        {}
      );
      this.props.getCurrentUser();
      this.props.handleShowNotificationModal(false);
    });
  };

  handleCloseNotificationModal = () => {
    this.updateOpenNotifications();
  };

  render() {
    const {
      notifications,
      loading,
      xp_alerts,
      xpAlertsTotalCount
    } = this.state;
    const { showNotificationModal, currentUser } = this.props;

    const panes = [
      {
        menuItem: "Notifications",
        render: () => (
          <Tab.Pane attached={false}>{renderNotifications()}</Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item key="xps">
            {currentUser.short_name_split[0]}`s XP
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}>{renderXpAlerts()}</Tab.Pane>
      }
    ];

    const renderNotifications = () => {
      if (notifications.length > 0) {
        return (
          <>
            {notifications.map(notification => (
              <div className="item" key={notification.id}>
                <a
                  className={`toast ${!notification.href ? "no-href" : ""}`}
                  href={notification.href || "javascript:void(0)"}
                >
                  {notification.avatar ? (
                    <div
                      className="toast-avatar ui circular image"
                      style={{
                        backgroundImage: `url(${notification.avatar})`
                      }}
                    />
                  ) : (
                    <div className={`toast-avatar ${notification.mood}`} />
                  )}
                  <div className="toast-content">
                    <span className="toast-created-at">
                      {/* @ts-ignore */}
                      {ReactHtmlParser(notification.created_at)}
                    </span>
                    <div className="toast-title">
                      {/* @ts-ignore */}
                      {ReactHtmlParser(notification.title)}
                    </div>
                    <div className="toast-body">
                      {/* @ts-ignore */}
                      {ReactHtmlParser(notification.body)}
                    </div>
                    <div className="toast-meta">
                      {notification.target_name && (
                        <span className="target">
                          {/* @ts-ignore */}
                          {ReactHtmlParser(notification.target_name)}
                        </span>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </>
        );
      } else {
        return (
          <>
            {!loading && (
              <div className="no-notifications">
                <h3>* crickets chirping *</h3>
                <p>You have no notifications.</p>
              </div>
            )}
          </>
        );
      }
    };

    const renderMoreXpAlertsButton = () => {
      if (xpAlertsTotalCount > xp_alerts.length) {
        return (
          <div className="cta util-text-center util-mt-1">
            <a
              className="fl-button large highlight util-clickable"
              onClick={() => this.fetchXpAlerts()}
            >
              Gimme more!
            </a>
          </div>
        );
      }
    };

    const renderXpAlerts = () => {
      if (xp_alerts.length > 0) {
        return (
          <>
            {/* @ts-ignore */}
            {xp_alerts.map(alert => (
              <div className="item" key={alert.id}>
                <div className={`toast`}>
                  <div className="toast-content xp">
                    <span className="toast-created-at">
                      {/* @ts-ignore */}
                      {ReactHtmlParser(alert.created_at)}
                    </span>
                    {/* @ts-ignore */}
                    <div className="toast-body xp">
                      {!alert.opened_xp && (
                        <div className="ui label green">New!</div>
                      )}{" "}
                      <Label color="blue">+ {alert.xp}</Label>{" "}
                      {ReactHtmlParser(alert.html)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {renderMoreXpAlertsButton()}
          </>
        );
      } else {
        return (
          <>
            {!loading && (
              <div className="no-notifications">
                <h3>* crickets chirping *</h3>
                <p>You haven't earned any XP yet!</p>
              </div>
            )}
          </>
        );
      }
    };

    return (
      <Modal
        open={showNotificationModal}
        className="notifications-modal"
        closeIcon
        size="small"
        onClose={() => this.handleCloseNotificationModal()}
      >
        <Modal.Content scrolling>
          <h1 className="ui header">Your alerts</h1>
          {loading && (
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
          )}
          <div className="ui list">
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default Notifications;
