import React from "react";

const Learners = () => (
  <div className="learners">
    <div className="ui container">
      <p>Trusted by learners at</p>
      <div className="images">
        <img
          className="ui tiny image"
          src={require("../../../../assets/images/teams/logos/google.png")}
          alt="google"
        />
        <img
          className="ui small image kroger"
          src={require("../../../../assets/images/teams/logos/kroger.png")}
          alt="kroger"
        />
        <img
          className="ui tiny image"
          src={require("../../../../assets/images/teams/logos/forbes.png")}
          alt="forbes"
        />
        <img
          className="ui tiny image facebook"
          src={require("../../../../assets/images/teams/logos/facebook.png")}
          alt="facebook"
        />
        <img
          className="ui tiny image"
          src={require("../../../../assets/images/teams/logos/amazon.png")}
          alt="amazon"
        />
        <img
          className="ui tiny image"
          src={require("../../../../assets/images/teams/logos/ideo.png")}
          alt="ideo"
        />
      </div>
    </div>
  </div>
);

export default Learners;
