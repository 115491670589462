import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal, Transition, Dropdown, Checkbox } from "semantic-ui-react";
import { AxiosResponse, AxiosError } from "axios";
import { Link } from "react-router-dom";
import {
  getRequest,
  patchRequest,
  putRequest,
  deleteRequest,
} from "../../../utils/httpRequest";
import {
  formErrors,
  notification,
  randomNumber,
  Errors,
} from "../../../utils/helpers";
import Password from "./Password";
import CancelAutoRenew from "./CancelAutoRenew";
import PaymentMethods from "./PaymentMethods";
import ProfileStore from "../../../stores/ProfileStore";
import { Profile } from "../../../stores/ProfileStore/Types";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import BillingArea from "./BillingArea";

interface Props {
  showSettingsModal: boolean;
  handleShowSettingsModal: (val: boolean) => void;
  profileResource: Profile;
  profileStore: ProfileStore;
  getCurrentUser: () => void;
  profilePicture: string;
}

interface State {
  showPasswordModal: boolean;
  showCancelAutoRenewModal: boolean;
  selectedCountry: string;
  countries: any;
  firstName: string;
  lastName: string;
  email: string;
  [x: string]: any;
  savingAutoRenewSubscription: boolean;
  savingUser: boolean;
  userErrors: any;
  showDeleteMessage: boolean;
  deletingUser: boolean;
  uploadNewImage: boolean;
  pictureUrl: string;
  progress: number;
  uploadingImage: boolean;
  showPaymentMethodsModal: boolean;
  showCannotDeleteMessage: boolean;
}

class Settings extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showPasswordModal: false,
      showCancelAutoRenewModal: false,
      selectedCountry: "",
      countries: [],
      firstName: "",
      lastName: "",
      email: "",
      pictureUrl: "",
      savingAutoRenewSubscription: false,
      savingUser: false,
      userErrors: {},
      showDeleteMessage: false,
      deletingUser: false,
      uploadNewImage: false,
      progress: 0,
      uploadingImage: false,
      showPaymentMethodsModal: false,
      showCannotDeleteMessage: false,
    };

    (this as any).uppy = Uppy({
      autoProceed: true,
    });

    (this as any).uppy
      .use(Transloadit, {
        params: {
          auth: {
            key: process.env.TRANSLOADIT_AUTH_KEY,
            secret: process.env.TRANSLOADIT_AUTH_SECRET,
            duration: 1800,
          },
          templates: {
            s3_store: process.env.TRANSLOADIT_FILE_TEMPLATE_ID,
          },
          template_id: process.env.TRANSLOADIT_PROFILE_PIC_TEMPLATE_ID,

          // It’s more secure to use a template_id and enable
          // Signature Authentication
          steps: {
            resize: {
              robot: "/image/resize",
              width: 250,
              height: 250,
              resize_strategy: "fit",
              format: "webp",
            },
          },
        },
        waitForEncoding: true,
      })
      .on("error", (error) => {
        this.setState({ uploadingImage: false });
        notification("Error uploading image. Please try again", "error");
        console.error(error);
        if (error.assembly) {
          console.error(`Assembly ID ${error.assembly.assembly_id} failed!`);
          console.error(error.assembly);
        }
        (window as any).honeybadger?.notify(
          `Error uploading profile picture ${error}`,
        );
      })
      .on("upload-error", (file, error, response) => {
        console.error("error with file:", file.id);
        console.error("error message:", error);
        console.error("error response:", response);
        this.setState({ uploadingImage: false });
        notification("Error uploading image. Please try again", "error");
        (window as any).honeybadger?.notify(
          `Error uploading profile picture ${error}`,
        );
      })
      .on("transloadit:result", (stepName, result) => {
        this.uploadFinished(result.url);
      });
  }

  uploadFinished = (url) => {
    this.setState({ pictureUrl: url, progress: 100 });
    setTimeout(() => {
      this.setState({ uploadingImage: false });
    }, 500);
  };

  componentDidMount() {
    const { profileResource } = this.props;
    this.initialState();

    return getRequest("/api/countries.json").then(
      (response: AxiosResponse<any>) => {
        const countries = response.data.map((countryResource) => {
          return {
            text: countryResource.country,
            value: countryResource.code,
            flag: countryResource.code.toLowerCase(),
            key: countryResource.code,
          };
        });

        this.setState({
          countries,
        });
      },
    );
  }

  initialState = () => {
    const { profileResource } = this.props;

    this.setState({
      selectedCountry: profileResource.country,
      firstName: profileResource.first_name,
      lastName: profileResource.last_name,
      email: profileResource.email,
      userErrors: {},
      showDeleteMessage: false,
      pictureUrl: profileResource.picture_url,
      uploadNewImage: false,
    });
  };

  handleShowPasswordModal = (val) => {
    this.setState({ showPasswordModal: val });

    if (val) {
      this.props.handleShowSettingsModal(false);
    }
  };

  handleShowCancelAutoRenewModal = (e, val) => {
    e.preventDefault();
    this.setState({ showCancelAutoRenewModal: val });

    if (val) {
      this.props.handleShowSettingsModal(false);
    }
  };

  handleShowPaymentMethodsModal = (e, val) => {
    e.preventDefault();
    this.setState({ showPaymentMethodsModal: val });

    if (val) {
      this.props.handleShowSettingsModal(false);
    }
  };

  hideModals = () => {
    this.props.handleShowSettingsModal(false);
    this.handleShowPasswordModal(false);
    this.initialState();
  };

  handleCountryChange = (event, data) => {
    this.setState({ selectedCountry: data.value });
  };

  //Add your search logic here.
  customFilter(option, searchText) {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleChange(key, e) {
    this.setState({ [key]: e.target.value });
  }

  activateAutoRenewSubscription = (e) => {
    const { profileResource } = this.props;

    e.preventDefault();
    this.setState({ savingAutoRenewSubscription: true });

    return patchRequest(
      `/api/subscriptions/${profileResource.subscription.id}/toggle_status.json`,
      {},
    )
      .then((response: AxiosResponse) => {
        this.props.profileStore.setSubscription(response.data);
        this.setState({
          savingAutoRenewSubscription: false,
        });
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  };

  updateUser = (e) => {
    const { profileResource, handleShowSettingsModal } = this.props;
    const { firstName, lastName, email, pictureUrl, selectedCountry } =
      this.state;

    const data = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        picture_url: pictureUrl,
        country: selectedCountry,
        name_required: true,
      },
    };

    e.preventDefault();
    this.setState({ savingUser: true });

    return putRequest(`/api/users/${profileResource.id}.json`, data)
      .then((response: AxiosResponse) => {
        this.props.profileStore.setProfileResource(response.data);
        handleShowSettingsModal(false);
        this.props.getCurrentUser();
        notification("Profile was successfully updated.", "notification");
        this.setState({
          savingUser: false,
          uploadingImage: false,
          uploadNewImage: false,
          userErrors: {},
        });
      })
      .catch((error: AxiosError<{ errors: Errors }>) => {
        const userErrors = formErrors(error.response.data.errors);
        this.setState({ userErrors, savingUser: false });
      });
  };

  handleShowDeleteMessage = (e) => {
    const { profileResource } = this.props;
    e.preventDefault();
    if (
      profileResource?.subscription?.active &&
      profileResource?.subscription?.number_of_months_left > 0
    ) {
      this.setState(
        { showCannotDeleteMessage: !this.state.showCannotDeleteMessage },
        () => {
          if (this.state.showCannotDeleteMessage) {
            document.getElementById("delete-container").scrollIntoView();
          }
        },
      );
    } else {
      this.setState(
        { showDeleteMessage: !this.state.showDeleteMessage },
        () => {
          if (this.state.showDeleteMessage) {
            document.getElementById("delete-container").scrollIntoView();
          }
        },
      );
    }
  };

  deleteUser = (e) => {
    e.preventDefault();
    const { profileResource } = this.props;
    this.setState({ deletingUser: true });

    return deleteRequest(`/api/users/${profileResource.id}.json`)
      .then((response: AxiosResponse) => {
        (window as any).location = "/";
      })
      .catch((error: AxiosError) => {
        notification("Unable to delete account.", "error");
      });
  };

  disableUploadingImage = () => {
    this.setState({ uploadingImage: false });
  };

  addFile(file) {
    const that = this;

    const descriptor = {
      id: "FileInput",
      name: randomNumber() + file.name,
      type: file.type,
      data: file,
    };

    try {
      (this as any).uppy.addFile(descriptor);
    } catch (err) {
      this.disableUploadingImage();
      console.log(err);

      (this as any).uppy.log(err);

      notification("Error uploading image. Please try again", "error");
      return;
    }
  }

  toArray(list) {
    return Array.prototype.slice.call(list || [], 0);
  }

  handleUploadNewImage = (e) => {
    e.preventDefault();

    this.setState({ uploadNewImage: true });
  };

  uploadFile = (e) => {
    (this as any).uppy.log("[FileInput] Something selected through input...");
    const file = e.target.files[0];
    this.setState({ uploadingImage: true, progress: 50 });
    this.addFile(file);

    e.target.value = null;
  };

  toggleAutoRenew = (e, data) => {
    data.checked
      ? this.activateAutoRenewSubscription(e)
      : this.handleShowCancelAutoRenewModal(e, true);
  };

  render() {
    const {
      showSettingsModal,
      handleShowSettingsModal,
      profileResource,
      profileStore,
    } = this.props;

    const {
      showPasswordModal,
      showCancelAutoRenewModal,
      selectedCountry,
      countries,
      firstName,
      lastName,
      email,
      savingUser,
      showDeleteMessage,
      uploadNewImage,
      progress,
      uploadingImage,
      pictureUrl,
      showPaymentMethodsModal,
      showCannotDeleteMessage,
    } = this.state;

    const getErrorMessageFor = (type) => {
      return this.state.userErrors[type];
    };

    const firstNameError = getErrorMessageFor("first_name");
    const lastNameError = getErrorMessageFor("last_name");
    const emailError = getErrorMessageFor("email");
    const countryError = getErrorMessageFor("country");
    const providers = profileResource.providers || [];

    const currentCountry = countries.filter(
      (country) => country.key === selectedCountry,
    )[0];
    const currentCountryName =
      currentCountry && currentCountry.text.length > 20
        ? currentCountry.text.slice(0, 20) + "..."
        : "";

    return (
      <>
        <Modal open={showSettingsModal} className="profile-settings-modal">
          <Modal.Header>
            <div className="close">
              <a
                className="ui left button"
                id="closeNav"
                onClick={() => this.hideModals()}
              >
                <i className="close icon" />
              </a>
            </div>
            <h2>Account Settings</h2>
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="ui segment account-information">
              <h4>Your account information</h4>
              <div className="information">
                {uploadNewImage ? (
                  <div className="image-holder">
                    <input
                      id="file-upload"
                      type="file"
                      onChange={this.uploadFile}
                      accept="image/*"
                    />
                    <label htmlFor="file-upload">
                      <div className="ui image circular avatar dimmable dimmed">
                        <img
                          className="ui preview"
                          src={
                            pictureUrl
                              ? pictureUrl
                              : process.env.TRANSLOADIT_IMAGE_PLACEHOLDER
                          }
                        />
                        <div
                          className="ui dimmer visible active"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <div className="imageContent">
                            <div className="center">
                              <div
                                className="ui inverted icon header"
                                id="uploadImageIcon"
                              >
                                <div
                                  className="imageContent upload-file"
                                  id="uploadFile"
                                >
                                  <i className="camera icon"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                    {uploadingImage && (
                      <div className="ui progress image-upload-progress">
                        <div className="bar" style={{ width: `${progress}%` }}>
                          <div className="progress">{progress}%</div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="profile-picture">
                    <img
                      src={this.props.profilePicture}
                      className="user-image"
                    />
                    <a href="#" onClick={this.handleUploadNewImage}>
                      <p>
                        <i className="upload icon" />
                        Change image
                      </p>
                    </a>
                  </div>
                )}

                <div>
                  <div className="ui two column grid stackable inputs">
                    <div className="row">
                      <div className="ui column input">
                        <input
                          type="text"
                          placeholder="First name"
                          className="general-input account-input"
                          value={firstName}
                          onChange={this.handleChange.bind(this, "firstName")}
                        />
                        {firstNameError && (
                          <div className="ui red pointing above label error">
                            {firstNameError}
                          </div>
                        )}
                      </div>

                      <div className="ui column input">
                        <input
                          type="text"
                          placeholder="Last name"
                          className="general-input account-input"
                          value={lastName}
                          onChange={this.handleChange.bind(this, "lastName")}
                        />
                        {lastNameError && (
                          <div className="ui red pointing above label error">
                            {lastNameError}
                          </div>
                        )}
                      </div>
                      <div className="ui column input country">
                        <Dropdown
                          fluid
                          search
                          selection
                          text={currentCountryName}
                          options={countries}
                          defaultValue={selectedCountry}
                          placeholder="Select Country"
                          onChange={this.handleCountryChange}
                        />

                        {countryError && (
                          <div className="ui red pointing above label error">
                            {countryError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="member">
                    <span style={{ color: "#7e7981" }}>Member since:</span>
                    <span className="time" style={{ paddingLeft: "8px" }}>
                      <strong>{profileResource.date_joined}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {profileResource.quaderno_billing_url && (
              <BillingArea
                quadernoBillingUrl={profileResource.quaderno_billing_url}
              />
            )}
            <div className="ui segment subscription-info">
              <h4>Your subscription status</h4>
              <div className="ui two column grid stackable">
                {profileResource.subscription ? (
                  <>
                    {profileResource.subscription.active ? (
                      <div className="ui column pass">
                        {profileResource.is_lifetime_subscription ? (
                          <h2>Lifetime</h2>
                        ) : (
                          <h2>{profileResource.subscription.plan}</h2>
                        )}
                        {profileResource.is_lifetime_subscription ? (
                          <p>Your subscription is for lifetime access</p>
                        ) : (
                          <p>
                            {profileResource.subscription.is_restricted && (
                              <p>
                                You have{" "}
                                <strong>
                                  {
                                    profileResource.subscription
                                      .available_credits
                                  }
                                </strong>{" "}
                                credit(s) remaining.{" "}
                              </p>
                            )}
                            Your subscription is valid till{" "}
                            {profileResource.subscription.valid_till}
                          </p>
                        )}
                        {!profileResource.is_lifetime_subscription && (
                          <button
                            className="ui button general-button edit-payment-methods-button"
                            onClick={(e) =>
                              this.handleShowPaymentMethodsModal(e, true)
                            }
                          >
                            Edit card details
                          </button>
                        )}
                      </div>
                    ) : (
                      <div className="ui column pass">
                        <h2>No Subscription</h2>
                        <p>
                          Your subscription expired on{" "}
                          {profileResource.subscription.expiry_date}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="ui column pass">
                    <h2>No Subscription</h2>
                    <p>
                      Subscribe for access to all courses and become even
                      smarter
                    </p>
                  </div>
                )}

                {!profileResource.is_lifetime_subscription && (
                  <div className="ui column subscription-actions">
                    {profileResource.subscription ? (
                      <>
                        {profileResource.subscription.active && (
                          <>
                            {" "}
                            {profileResource.subscription.renew ? (
                              <div className="auto-renew-box active-subscription">
                                <span>Auto renew:</span>
                                <Checkbox
                                  toggle
                                  onChange={this.toggleAutoRenew}
                                  defaultChecked={
                                    profileResource.subscription.renew
                                  }
                                />
                                <p>
                                  By turning off auto renew your subscription
                                  will be cancelled and we will miss you dearly
                                  on {profileResource.subscription.valid_till}
                                </p>
                              </div>
                            ) : (
                              <div className="auto-renew-box active-subscription">
                                <span>Auto renew:</span>
                                <Checkbox
                                  toggle
                                  onChange={this.toggleAutoRenew}
                                  defaultChecked={
                                    profileResource.subscription.renew
                                  }
                                />
                                <p>
                                  By turning on auto renew we will love you more
                                  and your subscription will automatically renew
                                  on {profileResource.subscription.valid_till}
                                </p>
                              </div>
                            )}
                          </>
                        )}

                        {!profileResource.subscription.active && (
                          <>
                            {profileResource.subscription.renew ? (
                              <div className="auto-renew-box inactive-subscription">
                                <p>
                                  We miss you! Fancy coming back and getting
                                  smarter?
                                </p>
                                <Link
                                  className="ui button subscribe-action general-button"
                                  to={profileResource.subscription_url}
                                >
                                  Subscribe
                                </Link>
                              </div>
                            ) : (
                              <div className="auto-renew-box inactive-subscription">
                                <p>
                                  Your last payment failed, to re-subscribe at
                                  the same price you originally paid just click
                                  the subscribe button below 👌
                                </p>
                                <Link
                                  className="ui button subscribe-action general-button"
                                  to={profileResource.subscription_url}
                                >
                                  Subscribe
                                </Link>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <div className="no-subscription">
                        <p>
                          Did you know, 42courses subscribers are 10 times more
                          likely to win at life ❤️
                        </p>
                        <Link
                          className="ui button subscribe-action general-button"
                          to={profileResource.subscription_url}
                        >
                          Subscribe
                        </Link>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="ui segment login-settings">
              <div className="ui two column grid stackable">
                <div className="ui column login">
                  <h4>Login settings</h4>
                  <div className="ui input" id="profile-settings__email">
                    <input
                      type="text"
                      placeholder="Email"
                      className="general-input"
                      value={email}
                      onChange={this.handleChange.bind(this, "email")}
                    />
                  </div>
                  {emailError && (
                    <div className="ui red pointing above label error">
                      {emailError}
                    </div>
                  )}
                  <button
                    className="ui button general-button"
                    onClick={() => this.handleShowPasswordModal(true)}
                  >
                    Change password
                  </button>
                </div>
                <div className="ui column social">
                  <h4>Link to social for easy one click login</h4>
                  <div className="ui two column grid stackable inputs">
                    <div className="row">
                      <div className="ui column">
                        <a
                          className={`ui button general-button ${
                            providers.includes("linkedin") ? "selected" : ""
                          }`}
                          href={
                            providers.includes("linkedin")
                              ? "javascript: void(0)"
                              : "/oauth/linkedin"
                          }
                        >
                          {providers.includes("linkedin") ? (
                            <i className="check icon" />
                          ) : (
                            <i className="linkedin icon" />
                          )}
                          LinkedIn
                        </a>
                      </div>
                      <div className="ui column">
                        <a
                          className={`ui button general-button ${
                            providers.includes("facebook") ? "selected" : ""
                          }`}
                          href={
                            providers.includes("facebook")
                              ? "javascript: void(0)"
                              : "/oauth/facebook"
                          }
                        >
                          {providers.includes("facebook") ? (
                            <i className="check icon" />
                          ) : (
                            <i className="facebook f icon" />
                          )}
                          Facebook
                        </a>
                      </div>
                      <div className="ui column">
                        <a
                          className={`ui button general-button google ${
                            providers.includes("google") ? "selected" : ""
                          }`}
                          href={
                            providers.includes("google")
                              ? "javascript: void(0)"
                              : "/oauth/google"
                          }
                        >
                          {providers.includes("google") ? (
                            <i className="check icon" />
                          ) : (
                            <i className="google icon" />
                          )}
                          Google
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ui segment delete">
              <a
                href="#"
                onClick={this.handleShowDeleteMessage}
                className="delete-link"
              >
                <i className="trash alternate outline icon" />
                Delete your profile
              </a>
              <br />
              <br />
              <div className="delete-message" id="delete-container">
                {showCannotDeleteMessage && (
                  <>
                    <p className="ui transition visible">
                      Sorry you can’t delete your account as you still have{" "}
                      <strong>
                        {profileResource.subscription?.number_of_months_left}
                        months
                      </strong>{" "}
                      left on your payment plan. Please email{" "}
                      <a href="mailto:help@42courses.com">
                        help@42courses.com
                      </a>{" "}
                      and we will happily help you
                    </p>
                  </>
                )}
                {showDeleteMessage && (
                  <>
                    <h4 className="ui transition visible">
                      <i className="icon warning sign"></i>Are you 100% sure?
                      Clicking the Delete Account button will delete all your
                      records and information from our system.
                    </h4>
                    <a
                      data-confirm="Are you 100% sure you want to delete your account?"
                      className="ui large negative basic button general-button transition visible delete-account"
                      href="#"
                      onClick={this.deleteUser}
                    >
                      <i className="icon trash"></i>DELETE ACCOUNT
                    </a>
                  </>
                )}
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="ui segment">
              <button
                className="ui right large button cancel"
                onClick={() => this.hideModals()}
              >
                Cancel
              </button>
              <button
                className={`ui right large button save ${
                  savingUser ? "loading" : ""
                }`}
                onClick={this.updateUser}
              >
                Save
              </button>
            </div>
          </Modal.Actions>
        </Modal>
        <Password
          showPasswordModal={showPasswordModal}
          handleShowPasswordModal={this.handleShowPasswordModal}
          handleShowSettingsModal={handleShowSettingsModal}
          hideModals={this.hideModals}
          userId={profileResource.id}
        />
        <CancelAutoRenew
          showCancelAutoRenewModal={showCancelAutoRenewModal}
          handleShowCancelAutoRenewModal={this.handleShowCancelAutoRenewModal}
          handleShowSettingsModal={handleShowSettingsModal}
          subscriptionId={profileResource.subscription?.id}
          profileStore={profileStore}
        />
        <PaymentMethods
          showPaymentMethodsModal={showPaymentMethodsModal}
          handleShowPaymentMethodsModal={this.handleShowPaymentMethodsModal}
          handleShowSettingsModal={handleShowSettingsModal}
        />
      </>
    );
  }
}

export default observer(Settings);
