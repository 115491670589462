import React, { Component } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { Enrolment } from "../../../stores/EnrolmentStore/Types";
import { Link } from "react-router-dom";

interface Props {
  enrolment: Enrolment;
  loading: boolean;
}

class Landing extends Component<Props, {}> {
  reloadPage = () => {
    (window as any).reload();
  };

  enrolmentAction = () => {
    const { enrolment } = this.props;

    if (enrolment?.state == "passed" && enrolment?.embedded_host_url) {
      return (
        <>
          <a href={enrolment?.embedded_host_url}>
            <button className="ui large traditional click-busy fluid button">
              View again
            </button>
          </a>
        </>
      );
    } else if (enrolment?.state == "passed") {
      return (
        <>
          <Link to={{ pathname: `/enrolments/${enrolment?.id}/certificate` }}>
            <button className="ui large traditional click-busy fluid button">
              Get certificate
            </button>
          </Link>
        </>
      );
    } else if (enrolment?.state == "failed") {
      return <></>;
    } else if (enrolment?.locked) {
      return (
        <Link to={{ pathname: `/courses/${enrolment?.course?.slug}/pay` }}>
          <button className="ui large traditional click-busy fluid button">
            Buy Course
          </button>
        </Link>
      );
    } else if (enrolment.next_question_path) {
      return (
        <Link
          className={`${enrolment?.locked ? "click-disabled" : ""}`}
          to={{
            pathname: enrolment.next_question_path + "/answer"
          }}
        >
          <button className="ui large traditional click-busy fluid button">
            {enrolment?.started ? "Continue" : "Start"}
          </button>
        </Link>
      );
    } else if (enrolment.previous_question_path) {
      return (
        <Link
          className={`${enrolment?.locked ? "click-disabled" : ""}`}
          to={{
            pathname: enrolment.previous_question_path
          }}
        >
          <button className="ui large traditional click-busy fluid button">
            {enrolment?.started ? "Continue" : "Start"}
          </button>
        </Link>
      );
    } else {
      return (
        <a
          href="#"
          className={`${enrolment?.locked ? "click-disabled" : ""}`}
          onClick={this.reloadPage}
        >
          <button className="ui large traditional fluid button">
            {enrolment?.started ? "Continue" : "Start"}
          </button>
        </a>
      );
    }
  };

  render() {
    const { enrolment, loading } = this.props;
    const { course } = enrolment || {};
    let completed = ["passed", "failed"].includes(enrolment?.state);

    return (
      <>
        {loading ? (
          <>
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
            <div className="hero">
              <div
                className="bg"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${course?.masthead_image_url})`
                }}
              ></div>
            </div>
          </>
        ) : (
          <>
            <div className="hero">
              <div
                className="bg"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${course?.masthead_image_url})`
                }}
              ></div>
              <div className="course-header">
                <h1>{course?.name}</h1>
                <p>With {course?.thought_leader_name}</p>
              </div>
            </div>
            <div className="ui segment">
              <div className="ui container current-chapter-container">
                <div className="current-chapter">
                  <div className="progress">
                    <PieChart
                      data={[
                        {
                          value: enrolment?.percentage_completed,
                          color: "#36CBBD"
                        },
                        {
                          label: "",
                          value: 100 - enrolment?.percentage_completed,
                          isEmpty: true,
                          color: "#dbdee4"
                        }
                      ]}
                      totalValue={100}
                      lineWidth={20}
                      label={() => `${enrolment?.percentage_completed}%`}
                      labelStyle={{
                        fontSize: "30px",
                        fill: "392D40"
                      }}
                      labelPosition={0}
                      startAngle={270}
                    />
                    <div className="next">
                      <p className="title">
                        {completed
                          ? enrolment?.state == "passed"
                            ? "Congratulations"
                            : "Whoopsie Daisy"
                          : enrolment?.next_question
                          ? "Next"
                          : ""}
                      </p>
                      {completed && (
                        <p className="lesson">
                          You{" "}
                          {enrolment?.state == "passed" ? "passed" : "failed"}
                        </p>
                      )}
                      {!completed &&
                        !enrolment?.locked &&
                        enrolment?.next_question && (
                          <>
                            <p className="lesson">
                              Chapter{" "}
                              {enrolment?.next_question?.topic.position + 1} /
                              Lesson {enrolment?.next_question?.position + 1}
                            </p>
                            <p className="topic">
                              {enrolment?.next_question?.name}
                            </p>
                          </>
                        )}
                      {enrolment?.locked && !completed && (
                        <>
                          <p className="lesson">Course locked</p>
                          <p className="topic">
                            You still have access to the lessons you've taken
                          </p>
                        </>
                      )}
                      <div className="action mobile">
                        {this.enrolmentAction()}
                      </div>
                    </div>
                  </div>
                  <div className="action desktop">{this.enrolmentAction()}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Landing;
