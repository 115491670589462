import React from "react";
import { observer } from "mobx-react";
import GiftStore from "../../../stores/GiftStore";
import { Quotation } from "../../../stores/CoursePreviewStore/Types";

interface Props {
  store: GiftStore;
  quotation: Quotation;
  couponCode: string;
  refCode: string;
  refreshQuoteWithoutCoupon: () => void;
  handleShowCouponModal: (e: any, val: boolean) => void;
}

const CouponOrVoucher = ({
  couponCode,
  refCode,
  quotation,
  refreshQuoteWithoutCoupon,
  handleShowCouponModal
}) => {
  if (couponCode.length > 0 || refCode.length > 0) {
    return (
      <div className="active_coupon">
        <span>
          {couponCode || refCode} <i className="ticket icon" /> was{" "}
          {quotation?.currency}
          {quotation?.original_price.amount}
        </span>
        <i className="close icon" onClick={() => refreshQuoteWithoutCoupon()} />
      </div>
    );
  } else {
    return (
      <a className="" href="#" onClick={e => handleShowCouponModal(e, true)}>
        Redeem voucher / Promo code
      </a>
    );
  }
};

const DiscountMessage = ({ quotation }) => {
  if (quotation.discounted) {
    return (
      <div className="discounted">
        <span>
          Was {quotation?.currency}
          {Math.round(
            quotation?.original_price.amount / quotation.priceable_count
          )}{" "}
          per learner. Now {quotation?.currency}
          {quotation?.per_priceable.amount} per learner
        </span>
      </div>
    );
  } else {
    return (
      <div className="undiscounted">
        <span>
          {quotation?.currency}
          {quotation?.per_priceable.amount} per learner
        </span>
      </div>
    );
  }
};

const renderFurtherText = quotation =>
  quotation.discounted ? <span>further</span> : <></>;

const Preview: React.FC<Props> = ({
  store,
  couponCode,
  refCode,
  quotation,
  refreshQuoteWithoutCoupon,
  handleShowCouponModal
}) => (
  <div className="ui component preview">
    <div className="logo">
      <img
        className="ui image medium dark"
        src={require("../../../../../assets/images/facelift/logos/dark_rectangle.png")}
        alt="logo"
      />
    </div>
    <div className="ui card selected-voucher">
      <div className="image">
        <img
          src={`https://s3.amazonaws.com/42courses-user/coupons/${store.template}.png`}
          alt={store.template}
        />
      </div>
      <div className="content">
        <h4 className="header">To redeem:</h4>
        <div className="description">
          <ol>
            <li>Complete your purchase (Thank you!)</li>
            <li>An email is send to the recipient with instructions</li>
            <li>They are bowled over by your amazing gift</li>
            <li>They become more like Einstein and the world gets smarter</li>
          </ol>
          <p>
            <strong>Need help?</strong> Email us at{" "}
            <a href="mailto:gifts@42courses.com">gifts@42courses.com</a>
          </p>
        </div>
      </div>
    </div>
    <br />
    <div className="cost">
      <p className="total">Total cost: </p>
      <span className="currency-symbol">{store?.quotation?.currency}</span>
      <p className="amount">{store?.quotation?.amount}</p>
      <span className="currency">{store?.quotation?.currency_code}</span>
    </div>
    <div className="action">
      <button
        className={`ui button fl-button large highlight ${
          store.state.kind === "saving" ? "loading" : ""
        }`}
        type="submit"
      >
        {store?.quotation?.amount > 0
          ? "Proceed to checkout"
          : "Continue for Free"}
      </button>
    </div>
    <br />
    <br />
    <div className="redeem-voucher">
      <CouponOrVoucher
        couponCode={couponCode}
        refCode={refCode}
        quotation={store?.quotation}
        refreshQuoteWithoutCoupon={refreshQuoteWithoutCoupon}
        handleShowCouponModal={handleShowCouponModal}
      />
    </div>
    <br />
    <DiscountMessage quotation={store?.quotation} />
    <p className="undiscounted">
      Add <span>{quotation.more_value}</span> more learner(s) to receive a{" "}
      {renderFurtherText(quotation)} discount
    </p>
  </div>
);

export default observer(Preview);
