import React, { Component } from "react";
import * as Yup from "yup";
import { AxiosResponse } from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Modal, Transition } from "semantic-ui-react";
import { Api } from "../../utils/Api";
import { RootContext } from "../../stores/RootStore/Types";
import {
  notification,
  errorNotification,
  formErrors,
  Error
} from "../../utils/helpers";
import { getRequest, postRequest } from "../../utils/httpRequest";

interface State {
  showModal: boolean;
  submitting: boolean;
  subscribed: boolean;
  errors: Error;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
});

class NewsLetter extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      submitting: false,
      subscribed: false,
      errors: null
    };
  }

  componentDidMount() {
    getRequest(`/api/subscriptions.json`)
      .then((response: AxiosResponse) => {
        setTimeout(
          () =>
            this.setState({
              showModal: response.data.show_newsletter
            }),
          20000
        );
      })
      .catch(error => {
        this.setState({ showModal: false });

        errorNotification(
          "Error loading data",
          JSON.stringify(error.response.data)
        );
      });
  }

  closeModal = e => {
    e.preventDefault();

    this.setState({
      showModal: false
    });

    getRequest("/user/expire_cookie.json?expire_cookie=show_newsletter")
      .then((response: AxiosResponse) => {
        setTimeout(
          () =>
            this.setState({
              showModal: false
            }),
          5000
        );
      })
      .catch(error => {
        this.setState({ showModal: false });

        console.error(error.response?.statusText);
      });
  };

  handleSubmit = values => {
    this.setState({ submitting: true });

    const data = {
      subscription: {
        username: values.username,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email
      }
    };

    const subUrl =
      values.username.length > 0 ? "/api/spam.json" : "/api/subscriptions.json";

    postRequest(`/api/subscriptions.json`, data)
      .then((response: AxiosResponse) => {
        this.setState({ submitting: false, subscribed: true });
      })
      .catch(error => {
        const errors = formErrors(error.response?.data?.errors);

        this.setState({ errors, submitting: false });
      });
  };

  getErrorMessageFor = type => {
    const { errors } = this.state;

    if (!errors) {
      return;
    }

    return (errors[type] as unknown) as string;
  };

  render() {
    const { showModal, subscribed, submitting } = this.state;

    const firstNameError = this.getErrorMessageFor("first_name");
    const lastNameError = this.getErrorMessageFor("last_name");
    const emailError = this.getErrorMessageFor("email");

    return (
      <div>
        <Transition.Group duration={400}>
          {showModal && (
            <Modal
              open={showModal}
              closeIcon
              onClose={this.closeModal}
              className="news-letter-modal small"
            >
              <div className="news-letter-container">
                <div
                  className={`wrapper ${subscribed ? "subscribed" : ""}`}
                  style={{
                    backgroundImage: subscribed
                      ? `url(${require("../../../../assets/images/ads/fire.png")})`
                      : `url(${require("../../../../assets/images/ads/caveman.png")})`
                  }}
                >
                  {!subscribed && (
                    <div>
                      <h1>Let us nourish your noggin</h1>
                      <p style={{ fontSize: "16px" }}>
                        Sign up to our newsletter for new course info, weekend{" "}
                        <br />
                        reads and the occasional special offer.
                      </p>
                    </div>
                  )}
                  <>
                    {subscribed ? (
                      <h1 className="ui icon header">
                        <i className="checkmark icon"></i>
                        <div className="content">Thanks for subscribing!</div>
                      </h1>
                    ) : (
                      <Formik
                        initialValues={{
                          firstName: "",
                          lastName: "",
                          email: "",
                          username: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {() => (
                          <Form className="ui form">
                            <div className="field">
                              <Field
                                id="firstName"
                                name="firstName"
                                validate={true}
                                className="string required"
                                placeholder="First Name"
                              />
                              <ErrorMessage name="firstName">
                                {msg => <div className="error">{msg}</div>}
                              </ErrorMessage>
                              {firstNameError && (
                                <div className="error">{firstNameError}</div>
                              )}
                            </div>
                            <div className="ui input spc_username">
                              <input
                                type="text"
                                placeholder="Your username"
                                name="username"
                                autoComplete="off"
                              />
                            </div>
                            <div className="field">
                              <Field
                                id="lastName"
                                name="lastName"
                                validate={true}
                                className="string required"
                                placeholder="Last Name"
                              />
                              <ErrorMessage name="lastName">
                                {msg => <div className="error">{msg}</div>}
                              </ErrorMessage>
                              {lastNameError && (
                                <div className="error">{lastNameError}</div>
                              )}
                            </div>
                            <div className="field">
                              <Field
                                id="email"
                                name="email"
                                validate={true}
                                className="string required"
                                placeholder="Email"
                              />
                              <ErrorMessage name="email">
                                {msg => <div className="error">{msg}</div>}
                              </ErrorMessage>
                              {emailError && (
                                <div className="error">{emailError}</div>
                              )}
                            </div>
                            <div className="action">
                              <button
                                className={`ui button fl-button large highlight ${
                                  submitting ? "loading" : ""
                                }`}
                              >
                                Get Smarter
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </>
                </div>
              </div>
            </Modal>
          )}
        </Transition.Group>
      </div>
    );
  }
}

export default NewsLetter;
