import { AxiosError, AxiosResponse } from "axios";
import { deleteRequest } from "../../../../utils/httpRequest";
import { DELETE_COMMENT_SUCCESS, DELETE_COMMENT_REPLY_SUCCESS } from "../types";

const deleteCommentSuccess = (postId, commentId) => ({
  type: DELETE_COMMENT_SUCCESS,
  postId,
  commentId,
});

const deleteCommentReplySuccess = (postId, commentId, replyId) => ({
  type: DELETE_COMMENT_REPLY_SUCCESS,
  postId,
  commentId,
  replyId,
});

type DeleteCommentProps = {
  postId: number;
  commentId: number;
  replyId?: number;
};

export const deleteComment =
  ({ postId, commentId, replyId }: DeleteCommentProps) =>
  async (dispatch) => {
    deleteRequest(
      `/api/community/comments/${replyId ? replyId : commentId}.json`,
    )
      .then((response: AxiosResponse) => {
        if (replyId) {
          dispatch(deleteCommentReplySuccess(postId, commentId, replyId));
        } else {
          dispatch(deleteCommentSuccess(postId, commentId));
        }
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
      });
  };
