import { AxiosError, AxiosResponse } from "axios";
import { deleteRequest } from "../../../../utils/httpRequest";
import { DELETE_POST_SUCCESS, DELETE_POST_FAILURE } from "../types";

const deletePostSuccess = (postId) => ({
  type: DELETE_POST_SUCCESS,
  postId,
});

const deletePostFailure = (error) => ({
  type: DELETE_POST_FAILURE,
  payload: error,
});

// Thunk Action Creator for API Call
export const deletePost =
  ({ postId }) =>
  async (dispatch) => {
    deleteRequest(`/api/community/posts/${postId}.json`)
      .then((response: AxiosResponse) => {
        dispatch(deletePostSuccess(postId));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
        dispatch(deletePostFailure(error.response?.data.error));
      });
  };
