import React, { useState } from "react";
import { AxiosResponse } from "axios";
import {Input, Modal, Form, Rating, TextArea} from "semantic-ui-react";
import { postRequest } from "../../utils/httpRequest";
import {notification} from "../../utils/helpers";

interface Props {
  enrolmentId: number;
  courseName: string;
  showModal: boolean;
  handleHideNewReviewModal: (e: any) => void;
  refreshCertificate: () => void;
}

const NewReviewModal: React.FC<Props> = ({
 enrolmentId,
 courseName,
 showModal,
 handleHideNewReviewModal,
 refreshCertificate
 }) => {
  const [submitting, setSubmitting] = useState(false);
  const [rating, setRating] = useState(0);
  const [content, setContent] = useState("");
  const [recommendations, setRecommendations] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [courseRequest, setCourseRequest] = useState("");

  const handleRating = (e, { rating, maxRating }) => {
    setRating(rating)
  }

  const submitReview = e => {
    e.preventDefault();
    setSubmitting(true)

    let review = {
      rating: rating, content: content, recommendations: recommendations,
      utm_source: utmSource, course_request: courseRequest }

    postRequest(`/api/enrolments/${enrolmentId}/reviews`, review)
      .then((response: AxiosResponse) => {
        setSubmitting(false)
        notification("Thank you for your review!", 'success')
        refreshCertificate()
        handleHideNewReviewModal(e)
      })
      .catch(error => {
        setSubmitting(false)
        notification(error.response.data.errors, "error")
      });
  };

  return (
    <Modal
      closeIcon
      open={showModal}
      onClose={e => handleHideNewReviewModal(e)}
      size="tiny"
      className="new-review-modal"
    >
      <Modal.Content>
        <Form onSubmit={e => submitReview(e)}>
          <div className="ui container center aligned review-header">
            <h2>Review</h2>
            <h1>{courseName}</h1>
          </div>
          <Form.Field>
            <label>Overall, how would you rate the course?*</label>
            <Rating maxRating={5} clearable value={rating} defaultRating={rating} onRate={handleRating} icon='star' size='massive'/>
          </Form.Field>

          <Form.Field>
            <label>Please share a short review to help others*</label>
            <TextArea rows={2} value={content} onChange={e => setContent(e.target.value)}/>
          </Form.Field>

          <Form.Field>
            <label>How can we make your experience even better?</label>
            <TextArea rows={2} value={recommendations} onChange={e => setRecommendations(e.target.value)}/>
          </Form.Field>

          <Form.Field>
            <label>How did you hear about us?</label>
            <Input type='text' value={utmSource} onChange={e => setUtmSource(e.target.value)}/>
          </Form.Field>

          <Form.Field>
            <label>What else would you like to learn from us?</label>
            <Input type='text' value={courseRequest} onChange={e => setCourseRequest(e.target.value)}/>
          </Form.Field>

          <div className="ui container center aligned">
            <button
              className={`ui secondary circular button ${submitting ? 'loading':''}`}
              onClick={e => submitReview(e)}
            >
              Submit
            </button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default NewReviewModal;
