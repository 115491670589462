import React from "react";
import { Confirm } from "semantic-ui-react";
import { Link } from "react-router-dom";

const CreditsUsedConfirmation = ({ isOpen, onClose }) => (
  <Confirm
    className="credits-used-confirmation"
    size="tiny"
    open={isOpen}
    onCancel={onClose}
    onConfirm={onClose}
    content={
      <div className="ui content">
        <p>Hello you hero,</p>
        <p>
          You’ve used up all your course credits this month, you’ll receive 1
          more at your next renewal.
        </p>
        <p>
          If you’re enjoying this and want access to all the courses, you can{" "}
          <Link to="/passes/year/pay" target="_blank">
            upgrade your account
          </Link>{" "}
          here.
        </p>
      </div>
    }
  />
);

export default CreditsUsedConfirmation;
