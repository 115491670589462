import React, { Component } from "react";
import Chapter from "./Chapter";
import { Topic } from "../../../stores/CoursePreviewStore/Types";

interface Props {
  topics: Topic[];
  courseSlug: string;
}

interface State {
  topics: Topic[];
  showAllTopics: boolean;
}

class CourseChapters extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      topics: [],
      showAllTopics: false
    };
  }

  componentDidMount() {
    this.setState({
      topics: this.props.topics.slice(0, 4),
      showAllTopics: this.props.topics.length > 4
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.topics !== state.topics) {
      return {
        topics: props.topics.slice(0, 4),
        showAllTopics: props.topics.length > 4
      };
    }
  }

  showAllTopics = e => {
    e.preventDefault();

    const { topics } = this.state;

    this.setState({
      topics: this.props.topics,
      showAllTopics: false
    });
  };

  render() {
    const { topics, showAllTopics } = this.state;
    const { courseSlug } = this.props;

    return (
      <div className="ui segment course-chapters">
        <div className="ui container">
          <div className="header">
            <h1 className="">Course Chapters</h1>
          </div>
          <div className="content">
            {
              <ol>
                {topics.map((topic, idx) => (
                  <Chapter key={idx} courseSlug={courseSlug} topic={topic} />
                ))}
              </ol>
            }
            {showAllTopics && (
              <div className="see-all-chapters">
                <button className="ui button" onClick={this.showAllTopics}>
                  See all chapters
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CourseChapters;
