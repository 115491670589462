import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { ProfileLeaderboard } from "../../../stores/ProfileStore/Types";
import { CurrentUser } from "../../../stores/CurrentUserStore/Types";
import { getRequest } from "../../../utils/httpRequest";

interface Props {
  slug: string;
  currentUser: CurrentUser;
}

interface State {
  leaderboard: ProfileLeaderboard[];
  loading: boolean;
}

interface Leader {
  user: any;
  className?: string;
  idx: number;
  currentUser: CurrentUser;
}

const countryFlagImage = countryCode => {
  let img;
  try {
    img = require(`../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
  } catch {
    img = "";
  }

  return img;
};

const Leader = ({ user, className, idx, currentUser }: Leader) => (
  <div className={`leader ${className}`}>
    <div className="left">
      <div className="position">
        <span>{idx + 1}</span>
      </div>
      <div className="profile-pic">
        <img src={user.picture_url} className="user-image" />
      </div>
      <div className="name">
        {currentUser?.id === user.id ? "You" : user.protected_name}
      </div>
      <div className="country">
        <img src={countryFlagImage(user.country)} />
      </div>
    </div>
    <div className="right">
      <div className="score">
        {user.xp} <span>XP</span>
      </div>
    </div>
  </div>
);

class LandingLeaderboard extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      leaderboard: [],
      loading: true
    };
  }

  componentDidMount() {
    const { slug } = this.props;

    getRequest(`/api/teams/${slug}/leaderboard.json?count=4`)
      .then((response: AxiosResponse<any>) => {
        this.setState({
          leaderboard: response.data,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);

        this.setState({ loading: false });
      });
  }

  render() {
    const { currentUser } = this.props;
    const { loading, leaderboard } = this.state;

    return (
      <div className="ui column right profile-leaderboard">
        {loading && (
          <div className="ui active inverted dimmer" style={{ zIndex: 1 }}>
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="profile-leaderboard-container">
          <div className="header">Leaderboard</div>
          <div className="leaders">
            {leaderboard.map((user, i) => (
              <Leader
                currentUser={currentUser}
                user={user}
                key={i}
                idx={i}
                className={i >= 1 ? "add-border" : ""}
              />
            ))}
          </div>
          <div className="view-all">
            <a data-smooth-scroll-to="#team-leaderboard" href-="#">
              View full leaderboard
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingLeaderboard;
