import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { Redirect } from "react-router-dom";
import Navbar from "./Navbar";
import LessonSidebar from "./LessonSidebar";
import Landing from "./Landing";
import AnswerUpvotes from "./AnswerUpvotes";
import { Answer, Enrolment } from "../../../stores/EnrolmentStore/Types";
import {
  QuestionPreview,
  TopicPreview,
} from "../../../stores/CoursePreviewStore/Types";
import { getRequest, postRequest } from "../../../utils/httpRequest";
import { errorNotification } from "../../../utils/helpers";
import Certificate from "../Certificate";
import { NotificationResource } from "../../../stores/RootStore/Types";
import RecentNotifications from "./RecentNotifications";
import { getLastLesson } from "../helpers";
import { CurrentUser } from "../../../stores/CurrentUserStore/Types";
import Chat from "../../Chat";
import AiChat from "./AiChat";

interface Props {
  match: any;
  mode: string;
  getXp: (userId: number) => void;
  currentUser: CurrentUser;
  location: {
    search: string;
  };
}

interface State {
  enrolment: Enrolment;
  lesson: QuestionPreview;
  answer: Answer;
  loadingLesson: boolean;
  showSidebar: boolean;
  locked: boolean;
  chapters: TopicPreview[];
  answers: Answer[];
  notifications: NotificationResource[];
  loadingAnswer: boolean;
  showAiChat: boolean;
}

class Lesson extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      enrolment: null,
      lesson: null,
      answer: null,
      chapters: [],
      answers: [],
      notifications: [],
      loadingLesson: true,
      showSidebar: false,
      locked: false,
      loadingAnswer: true,
      showAiChat: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const enrolmentId = this.props.match.params.enrolmentId;

    return getRequest(`/api/enrolments/${enrolmentId}.json`)
      .then((response: AxiosResponse) => {
        this.setState({ enrolment: response.data }, () => {
          this.loadLesson();
          this.loadChapters(response.data);
          this.loadAnswers(response.data);
          this.getRecentNotifications();

          if (!response?.data?.enrolment?.sample) {
            $("chat-app").css({ display: "block" });
          }
        });
      })
      .catch((error) => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
      });
  }

  componentDidUpdate(nextProps: Readonly<Props>, nextContext: any) {
    if (
      this.props.match.params.questionId &&
      nextProps.match.params.questionId != this.props.match.params.questionId
    ) {
      this.setState({ loadingLesson: true, answer: null, loadingAnswer: true });
      window.scrollTo(0, 0);
      this.loadEnrolment(nextProps.match.params.enrolmentId);
      this.getRecentNotifications();
    }
  }

  getRecentNotifications = () => {
    getRequest("/api/notifications/recent.json")
      .then((response: AxiosResponse<NotificationResource[]>) => {
        this.setState({ notifications: response.data });
      })
      .catch((error) => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
      });
  };

  loadLesson = () => {
    const { enrolment } = this.state;
    const lessonId = this.props.match.params.questionId;

    return getRequest(
      `/api/courses/${enrolment?.course?.slug}/questions/${lessonId}.json`,
    )
      .then((response: AxiosResponse) => {
        this.setState({ lesson: response.data, loadingLesson: false });
      })
      .catch((error) => {
        this.setState({ loadingLesson: false });
        (window as any).honeybadger?.notify(error?.response?.data?.error);
        errorNotification(
          "Error loading lesson",
          JSON.stringify(error?.response?.data),
        );
      });
  };

  getAnswer = () => {
    const { enrolment } = this.state;
    const lessonId = this.props.match.params.questionId;

    return getRequest(
      `/api/enrolments/${enrolment.id}/questions/${lessonId}/answer.json`,
    )
      .then((response: AxiosResponse) => {
        this.setState({ answer: response.data });
      })
      .catch((error) => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
        errorNotification(
          "Error loading answer",
          JSON.stringify(error.response.data),
        );
      });
  };

  loadEnrolment = (enrolmentId) => {
    return getRequest(`/api/enrolments/${enrolmentId}.json`)
      .then((response: AxiosResponse) => {
        if (response.data.embedded_host_url) {
          window.location = response.data.embedded_host_url;
        }

        if (!response?.data?.enrolment?.sample) {
          $("chat-app").css({ display: "block" });
        }

        this.setState({ enrolment: response.data });
        this.loadLesson();
        this.loadTopics();
      })
      .catch((error) => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
      });
  };

  loadAnswers = (enrolment) => {
    const lessonId = this.props.match.params.questionId;
    const { lesson } = this.state;

    this.setState({ locked: false, loadingAnswer: true });
    return getRequest(`/api/answers.json?enrolment_id=${enrolment?.id}`)
      .then((response: AxiosResponse) => {
        this.setState({ answers: response.data });
        let answer = this.state.answers?.find((answer, i) => {
          return answer.question_id == lessonId;
        });

        this.setState({ answer: answer, loadingAnswer: false });

        if (!answer && enrolment.locked) {
          if (!["passed", "failed"].includes(enrolment.state)) {
            this.setState({ locked: true });
          }
        } else if (!answer && enrolment?.preview && !lesson?.preview) {
          this.setState({ locked: true });
        }
      })
      .catch((error) => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
        this.setState({ loadingAnswer: false });
        errorNotification(
          "Error loading lesson",
          JSON.stringify(error?.response?.data),
        );
      });
  };

  loadChapters = (enrolment) => {
    return getRequest(`/api/courses/${enrolment?.course.slug}/topics.json`)
      .then((response: AxiosResponse) => {
        this.setState({ chapters: response.data });
      })
      .catch((error) => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
        errorNotification(
          "Error loading lesson",
          JSON.stringify(error?.response?.data),
        );
      });
  };

  loadTopics = () => {
    const { enrolment } = this.state;

    return getRequest(`/api/courses/${enrolment?.course.slug}/topics.json`)
      .then((response: AxiosResponse) => {
        this.setState({ chapters: response.data });
        this.loadAnswers(enrolment);
      })
      .catch((error) => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
        errorNotification(
          "Error loading lesson",
          JSON.stringify(error?.response?.data),
        );
      });
  };

  handleShowSidebar = () => {
    this.setState({ showSidebar: true });
    $(".pusher").addClass("dimmed");
  };

  handleHideSidebar = () => {
    this.setState({ showSidebar: false, showAiChat: false });
    $(".pusher").removeClass("dimmed");
  };

  refreshAnswers = () => {
    const { enrolment } = this.state;
    this.loadAnswers(enrolment);
  };

  getXp = () => {
    this.props.getXp(this.props.currentUser.id);
  };

  handleShowAiChat = (e, value) => {
    e.preventDefault();

    this.setState({ showAiChat: value });
  };

  render() {
    const {
      enrolment,
      lesson,
      answer,
      chapters,
      answers,
      showSidebar,
      loadingLesson,
      locked,
      notifications,
      loadingAnswer,
      showAiChat,
    } = this.state;

    const {
      mode,
      currentUser,
      getXp,
      match: { params },
      location: { search },
    } = this.props;

    const lessonId = params.questionId;
    const lastLesson = getLastLesson(chapters);

    return (
      <div
        className={`ui facelift-course-inside facelift-new-homepage  course-lesson ${mode}-lesson`}
      >
        {locked && (
          <Redirect to={{ pathname: `/enrolments/${params.enrolmentId}` }} />
        )}
        {chapters.length > 0 && (
          <>
            <LessonSidebar
              showSidebar={showSidebar}
              handleHideSidebar={this.handleHideSidebar}
              enrolment={enrolment}
              lesson={lesson}
              chapters={chapters}
              answers={answers}
            />
            <Navbar
              handleShowSidebar={this.handleShowSidebar}
              enrolment={enrolment}
              lesson={lesson}
              handleShowAiChat={this.handleShowAiChat}
            />
          </>
        )}

        {mode === "answerUpvotes" ? (
          <AnswerUpvotes
            lesson={lesson}
            lessonId={lessonId}
            enrolment={enrolment}
            answer={answer}
            lastLesson={lastLesson}
            answerId={params.answerId}
            enrolmentId={params.enrolmentId}
            refreshAnswers={this.refreshAnswers}
            loadingLesson={loadingLesson}
            loadingAnswer={loadingAnswer}
            getRecentNotifications={this.getRecentNotifications}
            getAnswer={this.getAnswer}
            getXp={this.getXp}
            page={mode}
            currentUser={currentUser}
            search={search}
          />
        ) : mode === "certificate" ? (
          <Certificate enrolment={enrolment} enrolmentId={params.enrolmentId} />
        ) : (
          <Landing
            lesson={lesson}
            lessonId={lessonId}
            enrolment={enrolment}
            answer={answer}
            lastLesson={lastLesson}
            refreshAnswers={this.refreshAnswers}
            loadingLesson={loadingLesson}
            loadingAnswer={loadingAnswer}
            getRecentNotifications={this.getRecentNotifications}
            getXp={this.getXp}
            page={mode}
            currentUser={currentUser}
            enrolmentId={params.enrolmentId}
          />
        )}
        <AiChat
          showAiChat={showAiChat}
          handleShowAiChat={this.handleShowAiChat}
        />
        <RecentNotifications notifications={notifications} />
      </div>
    );
  }
}

export default Lesson;
