import React from "react";

const Header = () => {
  return (
    <div className="header">
      <h1>Checkout</h1>
      <div className="secure">
        <img
          src={require("../../../../assets/images/facelift/checkout/secure.png")}
          alt=""
        />
        <span>Secure checkout</span>
      </div>
    </div>
  );
};

export default Header;
