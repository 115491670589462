import React from "react";
import { Quotation } from "../../../stores/CoursePreviewStore/Types";

interface Props {
  quote: Quotation;
  customBundlePrice: number;
  loading: boolean;
}

const Plans: React.FC<Props> = ({ quote, customBundlePrice, loading }) => (
  <div className="ui segment plans" id="pricing-plans">
    <div className="ui container">
      <h2>Happy? Get started now</h2>

      <div className="ui two column centered relaxed grid stackable doubling container">
        <div className="column">
          <div className="card" id="all-access-pass">
            <div className="content">
              <div className="header">All access pass</div>
              <div className="meta">
                <div className="price">
                  {loading ? (
                    <div className="ui active inline loader" />
                  ) : (
                    <>
                      <div className="country">
                        <div
                          className={`currency-flag currency-flag-${quote?.currency_code.toLowerCase()}`}
                          data-content={quote?.currency_name}
                        />
                      </div>
                      <p>
                        <span className="currency-symbol">
                          {quote?.currency}
                        </span>
                        <span className="amount">{quote?.amount}</span>
                        <span className="per">per person</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="description">
                <ul>
                  <li>Access to all courses for a year</li>
                  <li>24/7 manually marking and support</li>
                  <li>Built in learner analytics and leaderboards</li>
                  <li>Certified by leading brands</li>
                </ul>
              </div>
            </div>
            <div className="extra content">
              <a
                className="ui button fl-button large highlight"
                href="/passes/year/gift"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Plans;
