import * as Yup from "yup";

export const freeValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
});

export const validateFormFields = (params, quote) => {
  let validationErrors = [];
  if ((params.billingName || "").length < 3) {
    validationErrors.push("Name");
  }

  if ((params.billingEmail || "").length < 3) {
    validationErrors.push("Email");
  }

  if (quote?.amount > 0) {
    if (params.address.length < 3) {
      validationErrors.push("Address");
    }

    if (params.city.length < 3) {
      validationErrors.push("City");
    }

    if (params.postalCode.length < 3) {
      validationErrors.push("Postal code");
    }
  }

  return validationErrors;
};

export const scrollToDiv = () => {
  const myDiv = document.getElementById("pay-button");
  myDiv.scrollIntoView({ behavior: "smooth" });
};

export const stripeStyles = {
  base: {
    fontSize: "16px",
    color: "#32325d",
    lineHeight: "40px",
    fontFamily: '"Averta CY", sans-serif',
    fontSmoothing: "antialiased",
    fontWeight: 400,
    "::placeholder": {
      color: "#CFD7E0"
    }
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};

export const requestButtonStyles = {
  style: {
    paymentRequestButton: {
      type: "default" as "default",
      theme: "dark" as "dark",
      height: "60px",
      width: "50%"
    }
  }
};

export const acceptableCountries = [
  "AE",
  "AT",
  "AU",
  "BE",
  "BG",
  "BR",
  "CA",
  "CH",
  "CI",
  "CR",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GI",
  "GR",
  "GT",
  "HK",
  "HU",
  "ID",
  "IE",
  "IN",
  "IT",
  "JP",
  "LI",
  "LT",
  "LU",
  "LV",
  "MT",
  "MX",
  "MY",
  "NL",
  "NO",
  "NZ",
  "PE",
  "PH",
  "PL",
  "PT",
  "RO",
  "SE",
  "SG",
  "SI",
  "SK",
  "SN",
  "TH",
  "TT",
  "US",
  "UY"
];
