import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { AxiosResponse } from "axios";
import { CommentResource } from "../../../../stores/CommentStore/Types";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";
import { postRequest, deleteRequest } from "../../../../utils/httpRequest";
import { errorNotification } from "../../../../utils/helpers";

interface Props {
  currentUser: CurrentUser;
  comment: CommentResource;
  updateComments: () => void;
}

interface State {
  liking: boolean;
  liked: boolean;
  likesCount: number;
  deleting: boolean;
  showCommentForm: boolean;
  viewReplies: boolean;
  comments: CommentResource[];
  commenting: boolean;
  content: string;
  showReplyCommentForm: boolean;
}

class CommentReplies extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      liking: false,
      liked: false,
      likesCount: 0,
      deleting: false,
      showCommentForm: true,
      content: "",
      viewReplies: false,
      comments: [],
      commenting: false,
      showReplyCommentForm: false
    };
  }

  componentDidMount() {
    const { currentUser, comment } = this.props;

    this.setState({
      liked: comment.liker_ids.includes(currentUser.id),
      likesCount: comment.likes_count
    });
  }

  countryFlagImage = countryCode => {
    let img;
    try {
      img = require(`../../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
    } catch {
      img = "";
    }

    return img;
  };

  likeComment = e => {
    e.preventDefault();
    this.setState({ liking: true });

    const { comment } = this.props;

    return postRequest(`/api/likes.json?comment_id=${comment.id}`, {})
      .then((response: AxiosResponse) => {
        this.setState({
          liking: false,
          liked: true,
          likesCount: this.state.likesCount + 1
        });
      })
      .catch(error => {
        this.setState({ liking: false });

        errorNotification(
          "Error liking comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  unlikeComment = e => {
    e.preventDefault();

    const { comment } = this.props;

    return postRequest(`/api/likes/unlike.json?comment_id=${comment.id}`, {})
      .then((response: AxiosResponse) => {
        this.setState({
          liking: false,
          liked: false,
          likesCount: this.state.likesCount - 1
        });
      })
      .catch(error => {
        this.setState({ liking: false });

        errorNotification(
          "Error unliking comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  deleteComment = e => {
    e.preventDefault();
    this.setState({ deleting: true });

    const { comment, updateComments } = this.props;

    const url = `/api/comments/${comment.id}.json?comment_id=${comment.id}`;

    return deleteRequest(url)
      .then((response: AxiosResponse) => {
        this.setState({ deleting: false });
        updateComments();
      })
      .catch(error => {
        this.setState({ deleting: false });

        errorNotification(
          "Error deleting comment",
          JSON.stringify(error.response.data)
        );
      });
  };

  handleViewReplies = e => {
    e.preventDefault();
    this.setState({ viewReplies: !this.state.viewReplies });
  };

  render() {
    const {
      comment: {
        id,
        user,
        created_at,
        formatted_content,
        likes_count,
        liker_ids
      },
      comment,
      currentUser
    } = this.props;

    const {
      liking,
      liked,
      likesCount,
      deleting,
      showCommentForm,
      content,
      commenting,
      showReplyCommentForm
    } = this.state;

    return (
      <div
        className="ui container conversation"
        id={`comment_${id}`}
        style={{ width: "100%" }}
      >
        {deleting && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="conversation__body">
          <div className="conversation__body--avatar">
            <img src={user?.profile_picture_url} className="avatar" />
            <div className="country">
              <img src={this.countryFlagImage(user?.country)} />
            </div>
          </div>
          <div className="conversation__body--content comment__body--content">
            <div className="name-container">
              <span className="name">{user?.protected_name}</span>
              <span className="time">{created_at}</span>
            </div>
            {/* @ts-ignore */}
            <div className="content">{ReactHtmlParser(formatted_content)}</div>
            <div className="reaction">
              {user?.id === currentUser?.id && (
                <a
                  className="reply delete"
                  href="#"
                  onClick={this.deleteComment}
                >
                  Delete
                </a>
              )}
              {liked ? (
                <span className="like repliable liked link">
                  <a href="#" onClick={this.unlikeComment}>
                    <i className="thumbs up icon"></i>
                  </a>
                  <span>{likesCount}</span>
                </span>
              ) : (
                <span className="like repliable link">
                  <a href="#" onClick={this.likeComment} className="reply">
                    <i className="thumbs up icon"></i>
                  </a>
                  <span>{likesCount}</span>
                </span>
              )}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default CommentReplies;
