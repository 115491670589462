import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Accordion, Icon } from "semantic-ui-react";
import FaqImage from "../../../../../assets/images/pages/faq.jpeg";
import data from "./categories.json";

interface Question {
  question: string;
  answer?: string;
  header?: string;
  html_answer?: string;
  sub_questions?: {
    question?: string;
    answer?: string;
    html_answer?: string;
  }[];
}

interface Category {
  name: string;
  questions: Question[];
}

interface State {
  activeIndex: number;
  activeAccordion: number;
  categories: Category[];
  loading: boolean;
}

class Faq extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: -1,
      activeAccordion: -1,
      categories: [],
      loading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ categories: data, loading: false });
  }

  handleClick = (idx, accordion) => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === idx ? -1 : idx;

    this.setState({ activeIndex: newIndex, activeAccordion: accordion });
  };

  render() {
    const { loading, categories, activeIndex, activeAccordion } = this.state;

    return (
      <div className="ui pages" id="faq">
        {loading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        <div className="responsive-masthead about-us">
          <div
            className="backdrop desktop"
            style={{
              backgroundImage: `url(${FaqImage})`
            }}
          ></div>
          <div
            className="backdrop mobile"
            style={{
              backgroundImage: `url(${FaqImage})`
            }}
          ></div>
        </div>

        <div className="content">
          <div className="ui text container padded">
            <div className="ui stackable one column grid very padded">
              {categories.map((category, index) => (
                <div className="column" key={index}>
                  <h1>{category.name}</h1>

                  <Accordion styled fluid key={index}>
                    {category.questions.map((question, idx) => (
                      <React.Fragment key={`${idx}-${index}`}>
                        <Accordion.Title
                          active={
                            activeIndex === idx && activeAccordion === index
                          }
                          index={idx}
                          onClick={() => this.handleClick(idx, index)}
                        >
                          <Icon name="dropdown" />
                          {question.question}
                        </Accordion.Title>
                        <Accordion.Content
                          active={
                            activeIndex === idx && activeAccordion === index
                          }
                        >
                          <>
                            {question?.sub_questions?.map((ques, i) => (
                              <div key={i}>
                                <strong
                                  dangerouslySetInnerHTML={{
                                    __html: ques.question
                                  }}
                                  style={{ textDecoration: "underline" }}
                                ></strong>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: ques.html_answer
                                  }}
                                ></p>
                              </div>
                            ))}
                            {ReactHtmlParser(question.html_answer)}
                          </>
                        </Accordion.Content>
                      </React.Fragment>
                    ))}
                  </Accordion>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Faq;
