import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { BlogPost as BlogPostProps } from "../../../../stores/BlogStore/Types";

interface Props {
  post: BlogPostProps;
  idx: number;
}

const BlogPost: React.FC<Props> = ({ post, idx }) => {
  const date = new Date(post.pubDate).toDateString();
  const dateElements = date.split(" ");
  dateElements.shift();
  const formattedDate = dateElements.join(" ");

  return (
    <div style={{ padding: "0 8px" }}>
      <div className="ui card blog-post">
        <a className="image" href={post?.link} target="_blank">
          <img
            src={post?.content?.url}
            alt={post?.content?.title}
            className=""
          />
        </a>
        <div className="content">
          <div className="header">{post?.title}</div>
          <div className="meta">
            <span className="date">{formattedDate}</span>
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: post?.description }}
          ></div>
        </div>
        <a
          href={post.link}
          target="_blank"
          className="ui bottom attached button"
        >
          Read more
        </a>
      </div>
    </div>
  );

  return <div />;
};

export default BlogPost;
