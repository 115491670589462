import React from "react";
import ReactPlayer from "react-player";
import { Modal } from "semantic-ui-react";

interface Props {
  showModal: boolean;
  videoLink: string;
  imageLink: string;
  handleHideModal: (e: any) => void;
}

const ContentModal: React.FC<Props> = ({
  videoLink,
  imageLink,
  showModal,
  handleHideModal
}) => {
  return (
    <Modal
      closeIcon
      size="small"
      dimmer="blurring"
      open={showModal}
      onClose={e => handleHideModal(e)}
      className="embedded-content-modal"
    >
      <Modal.Content>
        {videoLink ? (
          <div className="video-container">
            <iframe
              src={videoLink}
              width="100%"
              height="100%"
              frameBorder={0}
              allowFullScreen={true}
            ></iframe>
          </div>
        ) : (
          <div className="ui image">
            <img src={imageLink} />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ContentModal;
