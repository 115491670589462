import React from "react";
import { CurrentUser as CurrentUserProps } from "../../../stores/CurrentUserStore/Types";
import Notifications from "../../../components/Profile/Notifications";

interface Props {
  currentUser: CurrentUserProps;
  getCurrentUser: () => void;
  handleShowLoggedInSideBar: () => void;
  handleShowNotificationModal: (val: boolean) => void;
  showNotificationModal: boolean;
  userXp: number;
}

const CurrentUser: React.FC<Props> = ({
  currentUser,
  getCurrentUser,
  handleShowLoggedInSideBar,
  handleShowNotificationModal,
  showNotificationModal,
  userXp
}) => {
  const triggerNotificationModal = e => {
    e.preventDefault();

    handleShowNotificationModal(true);
  };

  return (
    <div className="right item menu currentUser">
      <div className="item notification">
        <a onClick={e => triggerNotificationModal(e)} href="#">
          <svg
            className="notification-svg"
            width="28"
            height="30"
            viewBox="0 0 31 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.1224 19.8118C25.4456 18.3976 25.2651 17.2808 25.7189 15.5873L25.8731 15.0115C26.4645 12.8046 26.3375 11.2467 25.6142 9.52888C24.5039 6.86462 21.9953 4.76597 19.1903 4.01439L19.071 3.98243C16.3251 3.24664 13.2093 3.77274 10.9102 5.42737C9.35694 6.56572 8.39268 7.89683 7.78498 10.1648L7.63069 10.7406C7.17692 12.4341 6.4917 13.3189 5.16898 14.1973C4.19544 14.8447 3.66505 15.87 3.34919 17.0488C3.03297 18.2289 3.12079 19.4504 3.65464 20.5672C4.37731 21.9282 5.62363 23.0074 7.0547 23.5204C9.10872 24.3299 11.2031 24.9886 13.3528 25.5646C15.501 26.1402 17.6606 26.5559 19.8291 26.9433C21.3236 27.2142 22.9426 26.9028 24.2489 26.0854C25.2683 25.3848 25.9564 24.3713 26.2727 23.1911C26.5885 22.0123 26.6418 20.8592 26.1224 19.8118Z"
              fill="#392D40"
            />
            <path
              opacity="0.4"
              d="M15.6146 27.9556C14.9791 27.631 10.8715 26.5304 10.1588 26.4937C9.54687 26.4724 8.84072 26.615 8.65878 27.2941C8.51871 27.9508 8.74477 28.6242 9.17296 29.1276L9.17125 29.1286C9.7088 29.8403 10.4159 30.3907 11.2185 30.7353C11.647 30.9156 12.1007 31.0343 12.5774 31.0994C13.4434 31.2019 14.331 31.0788 15.1524 30.7312L15.1514 30.7295C15.7739 30.5076 16.3064 30.0375 16.5135 29.3987C16.6954 28.7197 16.1552 28.2431 15.6146 27.9556Z"
              fill="#392D40"
            />
          </svg>

          <div className="number">
            <span>{currentUser.alerts > 9 ? "9+" : currentUser.alerts}</span>
          </div>
        </a>
        <Notifications
          currentUser={currentUser}
          getCurrentUser={getCurrentUser}
          showNotificationModal={showNotificationModal}
          handleShowNotificationModal={handleShowNotificationModal}
        />
      </div>

      <div className="item divider" />
      <a
        className="item right labeled"
        id="sidebar_toggle_right"
        onClick={() => handleShowLoggedInSideBar()}
      >
        <div className="user-image">
          <div className="ui avatar image has-achievements">
            <img src={currentUser.profile_picture} alt="profile picture" />
          </div>
          <svg
            viewBox="0 0 100 100"
            className="name-svg"
            style={{ display: "none" }}
          >
            <circle cx="50" cy="50" r="40"></circle>
          </svg>
        </div>
        {currentUser.super_user && <i className="spy icon only large" />}
        <div className="content">
          <div className="header">{currentUser.short_name}</div>
          <div className="description">
            {userXp} <span>XP</span>
          </div>
        </div>
        <i className="dropdown icon" tabIndex={0}>
          <div className="menu" tabIndex={-1}></div>
        </i>
      </a>
    </div>
  );
};
export default CurrentUser;
