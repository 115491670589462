import React, { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { TeamInfoState } from "../../../stores/TeamInfoStore/Types";
import TeamInfoStore from "../../../stores/TeamInfoStore";
import { postRequest } from "../../../utils/httpRequest";
import { formErrors, Errors } from "../../../utils/helpers";

interface Props {
  overviewFile: string;
  fileName: string;
  pathName: string;
  header: string;
  recaptchaRef?: any;
}

interface State {
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  teamName: string;
  teamSize: number;
  message: string;
  errors: any;
  captchaError: string;
  saving: boolean;
  requested: boolean;
  [x: number]: any;
}

class TeamForm extends Component<Props, State> {
  static defaultProps = { header: "Enquire Now & Get A Sexy Course Brochure" };

  constructor(props) {
    super(props);

    this.state = {
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      teamName: "",
      teamSize: null,
      message: "",
      errors: {},
      captchaError: "",
      saving: false,
      requested: false
    };
  }

  onSubmit = e => {
    const { recaptchaRef } = this.props;

    e.preventDefault();
    this.setState({ saving: true, errors: {} });

    const recaptchaValue = (recaptchaRef as any).current?.getValue();

    const {
      contactName,
      contactEmail,
      contactPhone,
      message,
      teamName,
      teamSize
    } = this.state;

    const teamInfoBody = {
      team_info: {
        contact_name: contactName,
        contact_email: contactEmail,
        contact_phone: contactPhone,
        message: message,
        team_name: teamName,
        team_size: teamSize,
        source: this.props.pathName
      },
      recaptcha_token: recaptchaValue
    };

    return postRequest("/api/team_infos.json", teamInfoBody)
      .then((response: AxiosResponse) => {
        this.setState({ saving: false, requested: true });
      })
      .catch((error: AxiosError<{ errors: Errors; captcha_error: string }>) => {
        this.setState({
          saving: false,
          errors: formErrors(error.response.data.errors),
          captchaError: error.response.data.captcha_error
        });
      });
  };

  downloadOverview = e => {
    e.preventDefault();

    fetch(this.props.overviewFile, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.props.fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  render() {
    const {
      contactName,
      contactEmail,
      teamName,
      teamSize,
      contactPhone,
      message,
      errors,
      captchaError,
      requested,
      saving
    } = this.state;

    const { recaptchaRef } = this.props;

    const getErrorMessageFor = type => {
      return errors[type];
    };

    const contactNameError = getErrorMessageFor("contact_name");
    const teamNameError = getErrorMessageFor("team_name");
    const contactEmailError = getErrorMessageFor("contact_email");
    const contactPhoneError = getErrorMessageFor("contact_phone");
    const messageError = getErrorMessageFor("message");
    const teamSizeError = getErrorMessageFor("team_size");

    return (
      <div className="ui segment team-form" id="team-form">
        <div className="ui container">
          <div className="form-wrapper">
            <div className="info">
              <p>
                <strong>
                  Ready to unlock your teams’ creative potential? Great!
                </strong>{" "}
                It’s as simple as filling out a form — we’ll get back to you
                with the rest, and send you a <strong>course brochure</strong>{" "}
                in the meantime!
              </p>
            </div>
            <div className="actions">
              <a
                className="ui button fl-button highlight"
                href="/passes/year/gift"
                target="_blank"
              >
                In a hurry? <strong>Buy now.</strong>
              </a>
              <a
                className="ui button fl-button highlight right"
                href="https://meetings-eu1.hubspot.com/chris-rawlinson"
                target="_blank"
              >
                Want a <strong>demo</strong> first? Done.
              </a>
            </div>
            <h4>
              Contact us{" "}
              <p className="contact-meta">
                (+ get a wonderful course brochure)
              </p>
            </h4>
            {requested ? (
              <div className="requested">
                <div className="ui icon header">
                  <i className="checkmark icon teal"></i>
                  <div className="content">
                    Thanks! We'll get back to you
                  </div>{" "}
                </div>
                <br />
                <a
                  onClick={e => this.downloadOverview(e)}
                  href="#"
                  className="fl-button basic click-loading"
                >
                  <i className="cloud download icon" />
                  Download Brochure
                </a>
              </div>
            ) : (
              <form className="ui large form">
                <div className="field">
                  <input
                    type="text"
                    value={contactName}
                    className="string required"
                    onChange={e => this.handleChange(e, "contactName")}
                  />
                  <label>Your name</label>
                  {contactNameError && (
                    <div className="error">{contactNameError}</div>
                  )}
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={teamName}
                    className="string required"
                    onChange={e => this.handleChange(e, "teamName")}
                  />
                  <label>Your company name</label>
                  {teamNameError && (
                    <div className="error">{teamNameError}</div>
                  )}
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={teamSize}
                    className="string required"
                    onChange={e => this.handleChange(e, "teamSize")}
                  />
                  <label>Your team size</label>
                  {teamSizeError && (
                    <div className="error">{teamSizeError}</div>
                  )}
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={contactEmail}
                    className="string required"
                    onChange={e => this.handleChange(e, "contactEmail")}
                  />
                  <label>Your e-mail address</label>
                  {contactEmailError && (
                    <div className="error">{contactEmailError}</div>
                  )}
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={contactPhone}
                    className="string required"
                    onChange={e => this.handleChange(e, "contactPhone")}
                  />
                  <label>Your contact number</label>
                  {contactPhoneError && (
                    <div className="error">{contactPhoneError}</div>
                  )}
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={message}
                    className="string required"
                    onChange={e => this.handleChange(e, "message")}
                  />
                  <label>Anything else we should know? (optional)</label>
                  {messageError && <div className="error">{messageError}</div>}
                </div>
                <div className="recaptcha">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.RECAPTCHA_SITE_TOKEN}
                  />
                </div>
                {captchaError && (
                  <div id="captcha-error" role="alert">
                    <i className="close icon util-clickable"></i>
                    <span>{captchaError}</span>
                  </div>
                )}
                <button
                  className={`ui button fl-button large highlight ${
                    saving ? "loading" : ""
                  }`}
                  type="submit"
                  onClick={e => this.onSubmit(e)}
                >
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TeamForm;
