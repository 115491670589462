import React from "react";
import { Priceable } from "../../Types";

interface Props {
  title: string;
  meta?: string;
  symbol: string;
  amount: string;
  code: string;
  discountAmount?: string;
  isActive?: boolean;
  priceable?: Priceable;
}

const Option: React.FC<Props> = ({
  title,
  meta,
  symbol,
  amount,
  code,
  isActive,
  priceable,
  discountAmount
}) => {
  const getClassName = () => {
    let className = "option";

    if (!meta) {
      className = className + " no-meta";
    }

    if (isActive) {
      className = className + " active selected";
    } else {
      className = className + " inactive";
    }

    return className;
  };

  return (
    <a className={getClassName()}>
      <div>
        <h3 className="title">{title}</h3>
        {meta && (
          <p className="meta" dangerouslySetInnerHTML={{ __html: meta }}></p>
        )}
      </div>
      <div className="price">
        <div>
          <span className="symbol">{symbol}</span>
          <span className="amount">
            <strong>{amount}</strong>
          </span>
        </div>
        {discountAmount && amount !== discountAmount && (
          <div className="discount">
            <span className="symbol">{symbol}</span>
            <span className="amount">
              <strong>{discountAmount}</strong>
            </span>
          </div>
        )}
      </div>
    </a>
  );
};

export default Option;
