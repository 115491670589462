import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AxiosResponse, AxiosError } from "axios";
import { Api } from "../../utils/Api";
import { postRequest } from "../../utils/httpRequest";
import { RootContext } from "../../stores/RootStore/Types";

interface State {
  showCookies: boolean;
  saving: boolean;
}

class Cookies extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      showCookies: false,
      saving: false
    };
  }

  componentDidMount() {
    const client = new Api({});

    client
      .get<any>("/api/cookies.json")
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        this.setState({
          showCookies: data.show_cookies
        });
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  }

  acceptCookies = e => {
    e.preventDefault();
    this.setState({ saving: true });
    return postRequest("/api/cookies.json", {})
      .then(() => {
        this.setState({
          showCookies: false,
          saving: false
        });
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  };

  render() {
    const { showCookies, saving } = this.state;
    const buttonCss = saving
      ? "ui button accept-button click-busy loading"
      : "ui button accept-button click-busy";

    const buttonText = saving ? "" : "Accept";
    return (
      <RootContext.Consumer>
        {({ loaded }) => {
          return (
            <div>
              {loaded && showCookies && (
                <div className="ui grid" id="cookie_bar">
                  <div
                    className="twelve wide column right aligned"
                    id="privacy_text"
                  >
                    <p>
                      This cookie contains 0 calories. We use cookies to provide
                      you with the best experience on our website.{" "}
                      <Link to="/privacy">Click here for more info</Link>.
                    </p>
                  </div>
                  <div
                    className="four wide column left aligned"
                    id="accept_button"
                  >
                    <a
                      className={buttonCss}
                      href="#"
                      onClick={e => this.acceptCookies(e)}
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </RootContext.Consumer>
    );
  }
}

export default Cookies;
