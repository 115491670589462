import * as React from "react";
import { CertificateResource } from "../../../stores/CoursesStore/Types";
import { Link } from "react-router-dom";

interface Props {
  course: CertificateResource;
  idx: number;
}

const CertificateImage: React.FC<Props> = ({ course, idx }) => {
  if (course.enrolment_id && course.passed) {
    return (
      <Link to={`/enrolments/${course.enrolment_id}/certificate`}>
        <img
          src={course.preview_image}
          alt={course.name}
          className={`certificate certificate${idx}`}
        />
      </Link>
    );
  } else {
    return (
      <img
        src={course.preview_image}
        alt={course.name}
        className={`certificate certificate${idx} ui image disabled`}
      />
    );
  }

  return <div />;
};

export default CertificateImage;
