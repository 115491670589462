import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./components/Home";
import Profile from "./components/Profile";
import CoursePreview from "./components/CoursePreview";
import Enrolment from "./components/Enrolment";
import Lesson from "./components/Enrolment/Lesson";
import CourseGift from "./components/Gifts/CourseGift";
import PassGift from "./components/Gifts/PassGift";
import CoursePay from "./components/Payments/CoursePay";
import SubscriptionPay from "./components/Payments/SubscriptionPay";
import GiftPay from "./components/Payments/GiftPay";
import GiftSuccess from "./components/Pages/GiftSuccess";
import PassSuccess from "./components/Pages/PassSuccess";
import About from "./components/Pages/About";
import Faq from "./components/Pages/Faq";
import TeamPlans from "./components/Pages/TeamPlans";
import Contact from "./components/Pages/Contact";
import Privacy from "./components/Pages/Privacy";
import Conditions from "./components/Pages/Conditions";
import Badges from "./components/Pages/Badges";
import DynamicLayoutRoute from "./DynamicLayoutRoute";
import UpgradePay from "./components/Payments/UpgradePay";
import RenewalPay from "./components/Payments/RenewalPay";
import Referral from "./components/Pages/Referral";
import UserSetup from "./components/Pages/UserSetup";
import Certification from "./components/Certifications";
import TeamManagement from "./components/TeamManagement";
import PaymentSuccessfull from "./components/Payments/PaymentSuccessful";
import ForgotPassword from "./components/Passwords/ForgotPassword";
import ResetPassword from "./components/Passwords/ResetPassword";
import Brands from "./components/Brands";
import Courses from "./components/Courses";
import DynamicPages from "./components/DynamicPages";
import Chat from "./components/Chat";
import Login from "./components/Login";
import Community from "./components/Community";
import Permissions from "./components/Permissions";

const Routes = () => (
  <Router>
    <Switch>
      <DynamicLayoutRoute exact path="/" component={Home} />
      <DynamicLayoutRoute
        exact
        path="/user/setup"
        component={UserSetup}
        layout="setup"
        showRecentNotifications={false}
      />
      <DynamicLayoutRoute exact path="/profile" component={Profile} />
      <DynamicLayoutRoute
        exact
        path="/courses/:slug"
        component={CoursePreview}
      />
      <DynamicLayoutRoute
        exact
        path="/courses/:slug/upcoming"
        mode="upcoming"
        component={CoursePreview}
      />
      <DynamicLayoutRoute
        exact
        layout="payment"
        path="/courses/:slug/gift"
        component={CourseGift}
      />
      <DynamicLayoutRoute
        exact
        layout="payment"
        path="/passes/:slug/gift"
        component={PassGift}
      />
      <DynamicLayoutRoute
        exact
        path={["/courses/:slug/pay", "/pay/course"]}
        component={CoursePay}
        layout="payment"
      />
      <DynamicLayoutRoute
        exact
        path="/passes/:slug/pay"
        component={SubscriptionPay}
        layout="payment"
      />
      <DynamicLayoutRoute
        exact
        path="/gift/:token/pay"
        component={GiftPay}
        layout="payment"
      />
      <DynamicLayoutRoute
        exact
        path={["/pass/:slug/renew", "/pass/:slug/renew"]}
        component={RenewalPay}
        layout="payment"
      />
      <DynamicLayoutRoute
        exact
        path="/pass/:slug/upgrade"
        component={UpgradePay}
        layout="payment"
      />
      <DynamicLayoutRoute
        exact
        path="/gift/:token/success"
        component={GiftSuccess}
      />
      <DynamicLayoutRoute
        exact
        path="/passes/:slug/success"
        component={PassSuccess}
      />
      <DynamicLayoutRoute exact path="/about_us" component={About} />
      <DynamicLayoutRoute exact path="/faqs" component={Faq} />
      <DynamicLayoutRoute exact path="/for_business" component={TeamPlans} />
      <DynamicLayoutRoute exact path="/team_plans" component={TeamPlans} />
      <DynamicLayoutRoute exact path="/contact" component={Contact} />
      <DynamicLayoutRoute exact path="/privacy" component={Privacy} />
      <DynamicLayoutRoute exact path="/conditions" component={Conditions} />
      <DynamicLayoutRoute exact path="/referrals" component={Referral} />
      <DynamicLayoutRoute exact path="/community" component={Community} />
      <DynamicLayoutRoute exact path="/permissions" component={Permissions} />
      <DynamicLayoutRoute
        exact
        path="/achievements/badges"
        component={Badges}
      />
      <DynamicLayoutRoute
        exact
        path="/enrolments/:enrolmentId"
        component={Enrolment}
        layout="lesson"
      />
      <DynamicLayoutRoute
        exact
        path="/enrolments/:enrolmentId/resources"
        component={Enrolment}
        mode="resources"
        layout="lesson"
      />
      <DynamicLayoutRoute
        exact
        path="/enrolments/:enrolmentId/lives"
        component={Enrolment}
        mode="lives"
        layout="lesson"
      />
      <DynamicLayoutRoute
        exact
        path={[
          "/enrolments/:enrolmentId/questions/:questionId",
          "/enrolments/:enrolmentId/questions/:questionId/answer",
          "/enrolments/:enrolmentId/questions/:questionId/answer/new",
        ]}
        component={Lesson}
        layout="lesson"
        showRecentNotifications={false}
      />
      <DynamicLayoutRoute
        exact
        path="/enrolments/:enrolmentId/questions/:questionId/answers/:answerId/votes"
        component={Lesson}
        mode="answerUpvotes"
        layout="lesson"
        showRecentNotifications={false}
      />
      <DynamicLayoutRoute
        exact
        path="/enrolments/:enrolmentId/certificate"
        component={Lesson}
        mode="certificate"
        layout="lesson"
        showRecentNotifications={false}
      />
      <DynamicLayoutRoute
        exact
        path="/teams/:slug/manage"
        component={TeamManagement}
        showRecentNotifications={false}
      />
      <DynamicLayoutRoute
        exact
        path="/certifications/:id"
        component={Certification}
      />
      <DynamicLayoutRoute
        exact
        path="/payments/successful/:userActivationStatus"
        component={PaymentSuccessfull}
        layout="lesson-hide-signin-button"
      />
      <DynamicLayoutRoute
        exact
        path="/session/password_resets/new"
        component={ForgotPassword}
      />
      <DynamicLayoutRoute
        exact
        path="/session/password_resets/:id/edit"
        component={ResetPassword}
      />
      <DynamicLayoutRoute exact path="/brands/:slug" component={Brands} />
      <DynamicLayoutRoute exact path="/courses" component={Courses} />
      <DynamicLayoutRoute
        exact
        path={[
          "/page/:pageSlug",
          "/brands/:slug/:pageSlug",
          "/courses/:slug/:pageSlug",
        ]}
        component={DynamicPages}
      />
      <DynamicLayoutRoute
        exact
        path="/chat"
        component={Chat}
        layout="payment"
      />
      <DynamicLayoutRoute
        exact
        path="/login"
        component={Login}
        layout="payment"
      />
    </Switch>
  </Router>
);

export default Routes;
