import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import SubscriptionStore from "../../../../stores/SubscriptionStore";
import { SubscriptionState } from "../../../../stores/SubscriptionStore/Types";

interface Props {
  state: SubscriptionState;
  store: SubscriptionStore;
}

class SubscriptionWrapper extends Component<Props, {}> {
  onSubmit = e => {
    e.preventDefault();

    this.props.store.saving();
  };

  renderView = () => {
    const { state, store } = this.props;

    switch (state.kind) {
      case "readyWithErrors": {
        const getErrorMessageFor = type => {
          return (state.errors[type] as unknown) as string;
        };

        const firstNameError = getErrorMessageFor("first_name");
        const lastNameError = getErrorMessageFor("last_name");
        const emailError = getErrorMessageFor("email");

        return (
          <>
            <h2 className="header">Let us nourish your noggin</h2>
            <h3 className="header-sub">
              Sign up to our newsletter for new course info, weekend <br />
              reads and 10% off your next course.
            </h3>
            <form>
              <div className={`ui input ${firstNameError ? "error" : ""}`}>
                <input
                  type="text"
                  placeholder="First name"
                  value={store.firstName}
                  onChange={e => store.setAttributes(e, "firstName")}
                />
                {firstNameError && (
                  <div className="ui red pointing above label error">
                    {firstNameError}
                  </div>
                )}
              </div>
              <div className="ui input spc_username">
                <input
                  type="text"
                  placeholder="Your username"
                  name="username"
                  autoComplete="off"
                  value={store.username}
                  onChange={e => store.setAttributes(e, "username")}
                />
              </div>
              <div className={`ui input ${lastNameError ? "error" : ""}`}>
                <input
                  type="text"
                  placeholder="Last name"
                  value={store.lastName}
                  onChange={e => store.setAttributes(e, "lastName")}
                />
                {lastNameError && (
                  <div className="ui red pointing above label error">
                    {lastNameError}
                  </div>
                )}
              </div>
              <div className={`ui input ${emailError ? "error" : ""}`}>
                <input
                  type="email"
                  placeholder="Email address"
                  value={store.email}
                  onChange={e => store.setAttributes(e, "email")}
                />
                {emailError && (
                  <div className="ui red pointing above label error">
                    {emailError}
                  </div>
                )}
              </div>
              <button
                className="ui fluid button submit"
                onClick={e => this.onSubmit(e)}
              >
                Sign me up Scotty
              </button>
            </form>
            <span className="footer">No spam, we promise</span>
          </>
        );
      }

      case "saving":
      case "ready":
        return (
          <>
            <h2 className="header">Let us nourish your noggin</h2>
            <h3 className="header-sub">
              Sign up to our newsletter for new course info, weekend <br />
              reads and 10% off your next course.
            </h3>
            <form>
              <div className="ui input">
                <input
                  type="text"
                  placeholder="First name"
                  value={store.firstName}
                  onChange={e => store.setAttributes(e, "firstName")}
                />
              </div>
              <div className="ui input spc_username">
                <input
                  type="text"
                  placeholder="Your username"
                  name="username"
                  autoComplete="off"
                  value={store.username}
                  onChange={e => store.setAttributes(e, "username")}
                />
              </div>
              <div className="ui input">
                <input
                  type="text"
                  placeholder="Last name"
                  value={store.lastName}
                  onChange={e => store.setAttributes(e, "lastName")}
                />
              </div>
              <div className="ui input">
                <input
                  type="email"
                  placeholder="Email address"
                  value={store.email}
                  onChange={e => store.setAttributes(e, "email")}
                />
              </div>
              <button
                className="ui fluid button submit"
                onClick={e => this.onSubmit(e)}
              >
                Sign me up Scotty
              </button>
            </form>
            <span className="footer">No spam, we promise</span>
          </>
        );
        break;

      case "subscribed":
        return (
          <h1 className="ui icon header">
            <i className="checkmark icon"></i>
            <div className="content">Thanks for subscribing!</div>
          </h1>
        );
        break;
      default:
        return <div />;
        break;
    }
  };

  render() {
    return this.renderView();
  }
}

export default observer(SubscriptionWrapper);
