import React, { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { postRequest } from "../../utils/httpRequest";

interface State {
  email: string;
  errorMessage: string;
  emailSent: boolean;
  saving: boolean;
}

class ForgotPassword extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errorMessage: "",
      emailSent: false,
      saving: false
    };
  }

  validateEmail = email => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }

    return false;
  };

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    this.setState({ errorMessage: "" });

    const { email } = this.state;

    if (!email) {
      this.setState({ errorMessage: "Please enter your email" });

      return;
    }

    if (!this.validateEmail(email)) {
      this.setState({ errorMessage: "Please enter a valid email address" });

      return;
    }

    const data = {
      session: {
        email
      }
    };

    this.setState({ saving: true });

    postRequest("/session/password_resets", data)
      .then((response: AxiosResponse) => {
        this.setState({ emailSent: true, saving: false });
        setTimeout(() => {
          (window as any).location = "/";
        }, 5000);
      })
      .catch((error: AxiosError) => {
        this.setState({ saving: false });
        console.log(error);
      });
  };

  onClose = () => {
    (window as any).location = "/";
  };

  render() {
    const { email, errorMessage, emailSent, saving } = this.state;

    return (
      <div className="facelift-new-homepage forgot-password-page">
        <div className="ui basic segment">
          <div className="ui text container center aligned">
            {emailSent ? (
              <div className="ui positive message">
                <i className="close icon" onClick={this.onClose}></i>

                <div className="header">
                  Instructions have been sent to your email.
                </div>
              </div>
            ) : (
              <form className="ui form">
                <p className="instructions">
                  Enter your email and we'll send you password reset
                  instructions
                </p>
                <div className="field">
                  <div className="ui left icon action input">
                    <input
                      type="email"
                      placeholder="Your email"
                      onChange={this.handleChange}
                      value={email}
                    />
                  </div>
                  {errorMessage && (
                    <div className="ui red pointing above label error">
                      {errorMessage}
                    </div>
                  )}
                </div>
                <div className="action">
                  <br />
                  <button
                    className={`ui button ${saving ? "loading" : ""}`}
                    onClick={this.onSubmit}
                  >
                    Continue
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
