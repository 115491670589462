import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../utils/httpRequest";
import {
  RootContext,
  NotificationResource
} from "../../stores/RootStore/Types";
import Notification from "./Notification";

interface State {
  notifications: NotificationResource[];
}

interface Props {
  showRecentNotifications: boolean;
}

class RecentNotifications extends Component<Props, State> {
  static defaultProps = { showRecentNotifications: true };

  constructor(props) {
    super(props);

    this.state = {
      notifications: []
    };
  }

  componentDidMount() {
    if (!this.props.showRecentNotifications) {
      return;
    }

    setTimeout(() => {
      getRequest("/api/notifications/recent.json").then(
        (response: AxiosResponse<NotificationResource[]>) => {
          this.setState({ notifications: response.data });
        }
      );
    }, 5000);
  }

  render() {
    const { notifications } = this.state;
    return (
      <RootContext.Consumer>
        {({ loaded }) => {
          return (
            <div>
              {this.props.showRecentNotifications && (
                <div className="notifications-modal toast-wrapper">
                  {notifications.map(notification => (
                    <div
                      className={`item ${notification.mood}`}
                      key={notification.id}
                    >
                      <Notification notification={notification} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        }}
      </RootContext.Consumer>
    );
  }
}

export default RecentNotifications;
