import React, { Component } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import { getRequest } from "../../../utils/httpRequest";
import { errorNotification } from "../../../utils/helpers";
import CoursesStore from "../../../stores/CoursesStore";
import {
  CourseResource,
  Filter,
  SelectedFilter
} from "../../../stores/CoursesStore/Types";
import CoursesReactions from "../../../stores/CoursesStore/CoursesReactions";
import Course from "../../Home/components/Courses/Course";

interface Props {
  courses: CourseResource[];
  store: CoursesStore;
  headerTitle?: string;
}

interface State {
  mode: string;
  sortBy: string;
  openFilters: boolean;
  selectedfilters: SelectedFilter[];
  categories: Filter[];
  brands: Filter[];
}

class Wrapper extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      mode: "",
      sortBy: "",
      openFilters: false,
      selectedfilters: [],
      categories: [],
      brands: []
    };
  }

  componentDidMount() {
    const { store } = this.props;

    this.setState({
      mode: store.mode
    });

    const selector = document.querySelector(".filters");

    selector.addEventListener("click", e => {
      this.setState({ openFilters: true });
    });

    const that = this;
    document.addEventListener("click", function(event) {
      var isClickInsideElement = (selector as any).contains(event.target);
      if (!isClickInsideElement) {
        that.setState({ openFilters: false });
      }
    });

    return getRequest(`/api/courses/filters.json`)
      .then((response: AxiosResponse) => {
        this.setState({
          categories: response.data.categories,
          brands: response.data.brands
        });
      })
      .catch(error => {
        errorNotification(
          "Error loading filter data",
          JSON.stringify(error?.response?.data)
        );
      });
  }

  onClickSortOption = (e, data, mode) => {
    const { store } = this.props;
    e.preventDefault();

    if (store.mode !== mode) {
      this.setState({ sortBy: data.text });
      store.sorting(mode, this.state.selectedfilters);
    }
  };

  openFilters = e => {
    this.setState({ openFilters: true });
  };

  closeFilters = e => {
    this.setState({ openFilters: false });
  };

  onSelectFilter = (event, data) => {
    let { selectedfilters, mode } = this.state;
    const { store } = this.props;

    let newFilters;

    if (data.checked && !this.findFilter(data.value)) {
      newFilters = selectedfilters.concat(data);
    } else {
      newFilters = selectedfilters.filter(val => val.value !== data.value);
    }

    this.setState({ selectedfilters: newFilters }, () => {
      store.sorting(mode, newFilters);
    });
  };

  removeFilter = value => {
    let { selectedfilters, mode } = this.state;
    const { store } = this.props;

    let newFilters = selectedfilters.filter(val => val.value !== value);
    this.setState({ selectedfilters: newFilters }, () => {
      store.sorting(mode, newFilters);
    });
  };

  findFilter = value =>
    _.find(this.state.selectedfilters, function(val) {
      return val.value === value;
    });

  clearFilters = e => {
    e.preventDefault();
    const { store } = this.props;

    this.setState({ selectedfilters: [] }, () => {
      this.closeFilters(e);
      store.sorting(this.state.mode, []);
    });
  };

  renderCourse = (course, i) => {
    return (
      <Course
        courseResource={course}
        idx={i}
        key={course.id}
        increaseHeight={true}
        categories={course.categories}
      />
    );
  };

  render() {
    const { courses, store, headerTitle } = this.props;
    const {
      sortBy,
      openFilters,
      selectedfilters,
      brands,
      categories
    } = this.state;

    return (
      <div className="ui container">
        <div className="header-with-filter">
          <div className="header-wrapper">
            <h1
              className="ui header aligned course-header"
              id="course-grid-title"
            >
              <span className="title">
                {headerTitle ? headerTitle : "All courses"}
              </span>
            </h1>
            <div className="selected-filters">
              {selectedfilters.map((filter, i) => (
                <div className="ui label" key={i}>
                  {filter.label}
                  <i
                    className="delete icon"
                    onClick={() => this.removeFilter(filter.value)}
                  ></i>
                </div>
              ))}

              {selectedfilters.length > 0 && (
                <a href="#" onClick={this.clearFilters}>
                  Clear all
                </a>
              )}
            </div>
          </div>
          <div>
            <Dropdown
              text="Filters"
              icon="sliders horizontal"
              open={openFilters}
              className="courses-sort-dropdown filters"
            >
              <Dropdown.Menu>
                <div className="items">
                  <Dropdown.Header
                    icon={
                      <a href="#" onClick={this.closeFilters}>
                        <i className="icon close" />
                      </a>
                    }
                    content="Filters"
                  />

                  <div className="item" id="categories">
                    Course Focus
                  </div>
                  {categories.map((category, i) => (
                    <Dropdown.Item key={i}>
                      <Checkbox
                        label={category.name}
                        onClick={this.onSelectFilter}
                        name="category"
                        value={category.slug}
                        checked={
                          this.findFilter(category.slug)?.checked || false
                        }
                      />
                    </Dropdown.Item>
                  ))}

                  <div className="item" id="categories">
                    Brands
                  </div>
                  {brands.map((brand, i) => (
                    <Dropdown.Item key={i}>
                      <Checkbox
                        label={brand.name}
                        onClick={this.onSelectFilter}
                        name="brand"
                        value={brand.slug}
                        checked={this.findFilter(brand.slug)?.checked || false}
                      />
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider />
                </div>
                <a href="#" className="clear" onClick={this.clearFilters}>
                  Clear
                </a>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              text={sortBy ? `Sort by: ${sortBy}` : `Sort by`}
              className="courses-sort-dropdown"
              onClick={this.closeFilters}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Top rated"
                  onClick={(e, data) =>
                    this.onClickSortOption(e, data, "rated")
                  }
                  className={`${store.mode === "rated" ? "selected" : ""}`}
                />
                <Dropdown.Item
                  text="Most popular"
                  onClick={(e, data) =>
                    this.onClickSortOption(e, data, "popularity")
                  }
                  className={`${store.mode === "popularity" ? "selected" : ""}`}
                />
                <Dropdown.Item
                  text="Most recent"
                  onClick={(e, data) =>
                    this.onClickSortOption(e, data, "recency")
                  }
                  className={`${store.mode === "recency" ? "selected" : ""}`}
                />
                <Dropdown.Item
                  text="Upcoming"
                  onClick={(e, data) =>
                    this.onClickSortOption(e, data, "upcoming")
                  }
                  className={`${store.mode === "upcoming" ? "selected" : ""}`}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {store.state.kind === "sorting" && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        <div className="courses-container ui inverted vertical segment center aligned">
          <div
            className="ui centered grid doubling stackable three column desktop"
            id="courses_grid"
          >
            {courses.map((course, i) => this.renderCourse(course, i))}
          </div>

          <div
            className="ui centered grid doubling stackable two column mobile"
            id="courses_grid"
          >
            {courses.map((course, i) => this.renderCourse(course, i))}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Wrapper);
