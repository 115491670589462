import { AxiosError, AxiosResponse } from "axios";
import { patchRequest } from "../../../../utils/httpRequest";
import { FEATURE_POST_SUCCESS, FEATURE_POST_FAILURE } from "../types";
import { FEATURE_POST } from "../../posts/types";
import { loadTrendingPostsSuccess } from "./fetchTrendingPosts";

const createFeaturePostSuccess = (data) => ({
  type: FEATURE_POST_SUCCESS,
  payload: data,
});

const createFeaturePostFailure = (error) => ({
  type: FEATURE_POST_FAILURE,
  payload: error,
});

const updatePostTrending = (postId, trending) => ({
  type: FEATURE_POST,
  postId,
  trending,
});

export const featurePost =
  ({ postId, trending }) =>
  async (dispatch) => {
    patchRequest(`api/community/posts/${postId}/mark_as_trending.json`, {
      trending,
    })
      .then((response: AxiosResponse) => {
        dispatch(updatePostTrending(postId, trending));
        dispatch(loadTrendingPostsSuccess(response.data));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
        // dispatch(createPostFailure(error.response?.data.error));
      });
  };
