import React, { Component } from "react";
import { Rating } from "semantic-ui-react";
import { ReviewResource } from "../../../stores/ReviewsStore/Types";
import ReviewModal from "../../Home/components/Reviews/ReviewModal";

interface Props {
  review: ReviewResource;
}

interface State {
  showModal: boolean;
}

class Review extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  onClickShowModal = (e, val) => {
    e.preventDefault();
    this.setState({ showModal: val });
  };

  render() {
    const { review } = this.props;
    const { showModal } = this.state;

    return (
      <div className="column course-review">
        <div>
          <div className="user">
            <img
              className="ui circular image profile-picture"
              src={review.profile_picture}
            />
            <div className="name">
              <h2 className="">
                {review.user_name}
                <span className="time">{review.created_at}</span>
              </h2>
              <div className="ratings">
                <Rating
                  disabled={true}
                  icon="star"
                  defaultRating={review.rating}
                  maxRating={5}
                />
              </div>
            </div>
          </div>
          <p
            className="review-content ellipsifyToModal"
            data-element=".course-review"
            data-excess="20"
            data-limit="140"
          >
            {review.content.slice(0, 140)}{" "}
            {review.content.length > 140 && (
              <a onClick={e => this.onClickShowModal(e, true)} href="">
                ...more
              </a>
            )}
            <ReviewModal
              showModal={showModal}
              onClickShowModal={this.onClickShowModal}
              review={review}
            />
          </p>
        </div>
      </div>
    );
  }
}

export default Review;
